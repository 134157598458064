import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import LinkButton from '@components/common/LinkButton';
import WorkspacesMenu from '@components/ProjectSettings/WorkspacesMenu';
import { useOrganization } from '@hooks/organization/useOrganization';
import { useActiveProjectUuid } from '@hooks/useActiveProject';
import { useLocale } from '@hooks/useLocale';
import {
    ActionIcon,
    Box,
    Button,
    createStyles,
    Flex,
    Menu,
    Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Briefcase, CaretRight, Gear, Swatches } from '@phosphor-icons/react';
import useApp from '@providers/App/useApp';
import React, { useCallback, useMemo } from 'react';
import { ChevronDown } from 'react-feather';
import { Link, useLocation, useParams } from 'react-router';
import { useToggle } from 'react-use';
import { useProjects } from '../../hooks/useProjects';
import { ButtonVariant } from '../../mantineTheme';

const useStyles = createStyles((_theme) => ({
    dropdown: {
        width: '220px !important',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}));

const ProjectSwitcher = () => {
    const { isInitialLoading: isLoadingProjects, data: projects } =
        useProjects();
    const { isLoading: isLoadingActiveProjectUuid, activeProjectUuid } =
        useActiveProjectUuid();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { isInitialLoading: isOrgLoading, data: organization } =
        useOrganization();
    const [projectsOpened, { open: openProjects, close: closeProjects }] =
        useDisclosure();
    const [
        demoProjectsOpened,
        { open: openDemoProjects, close: closeDemoProjects },
    ] = useDisclosure();
    const location = useLocation();
    const { user } = useApp();
    const [isOpen, toggleOpen] = useToggle(false);

    const { classes } = useStyles();
    const { t } = useLocale();
    const ability = useAbilityContext();
    const showWorkSpaceSettings = ability.can(
        'view',
        subject('ProjectSettings', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid: activeProjectUuid,
        }),
    );

    const showManageOrganization = ability.can(
        'manage',
        subject('OrganizationSetting', {
            organizationUuid: user.data?.organizationUuid,
        }),
    );

    const currentProject = useMemo(() => {
        if (isLoadingProjects || isLoadingActiveProjectUuid) return null;

        return projects?.find(
            (project) => project.projectUuid === activeProjectUuid,
        );
    }, [
        activeProjectUuid,
        isLoadingActiveProjectUuid,
        isLoadingProjects,
        projects,
    ]);
    const encodedRedirectUrl = useMemo(
        () => encodeURIComponent(location.pathname + location.search),
        [location.pathname, location.search],
    );
    const nonDemoProjects = useMemo(() => {
        return projects?.filter((p) => !p.isDemo);
    }, [projects]);
    const demoProjects = useMemo(() => {
        return projects?.filter((p) => p.isDemo);
    }, [projects]);

    const createToggleHandler = useCallback(
        (isOpened: boolean, close: () => void, open: () => void) => () => {
            if (isOpened) {
                close();
            } else {
                open();
            }
        },
        [],
    );

    const handleProjectsClick = useCallback(
        () =>
            createToggleHandler(projectsOpened, closeProjects, openProjects)(),
        [projectsOpened, closeProjects, openProjects, createToggleHandler],
    );

    const handleDemoProjectsClick = useCallback(
        () =>
            createToggleHandler(
                demoProjectsOpened,
                closeDemoProjects,
                openDemoProjects,
            )(),
        [
            demoProjectsOpened,
            closeDemoProjects,
            openDemoProjects,
            createToggleHandler,
        ],
    );

    if (
        isLoadingProjects ||
        isLoadingActiveProjectUuid ||
        isOrgLoading ||
        !projects ||
        projects.length === 0
    ) {
        return null;
    }

    return (
        <>
            <Menu
                position="bottom-end"
                offset={6}
                classNames={classes}
                opened={isOpen || projectsOpened || demoProjectsOpened}
                onChange={toggleOpen}
                styles={{
                    itemLabel: {
                        width: '100%',
                    },
                    item: {
                        width: '100% !important',
                    },
                }}
            >
                <Menu.Target>
                    <Button
                        data-testid="settings-menu"
                        className={`px-3 font-semibold text-white rounded-lg hover:rounded-lg  hover:!bg-none ${
                            isOpen ? 'active-nav-link' : 'inactive-nav-link'
                        }`}
                        variant={ButtonVariant.TRANSPARENT}
                        rightIcon={
                            <ChevronDown
                                className={`h-5 w-3.5 text-coal-dark ${
                                    isOpen
                                        ? `rotate-180 transform duration-300 ease-in-out`
                                        : `rotate-0 transform duration-300 ease-in-out`
                                }`}
                                strokeWidth={3}
                            />
                        }
                    >
                        <Flex align="center" gap={4}>
                            <Text>{currentProject?.name}</Text>
                            {currentProject?.isDemo && (
                                <Text>
                                    {t('workspace_settings.demo_in_brackets')}
                                </Text>
                            )}
                        </Flex>
                    </Button>
                </Menu.Target>

                <Menu.Dropdown className="z-50">
                    <Menu.Label>
                        <Text className="text-sm font-semibold text-gray-500">
                            {t(
                                'workspace_settings_menu.project_settings_current_workspace',
                            )}
                        </Text>
                    </Menu.Label>
                    <Menu.Label className="group">
                        {showWorkSpaceSettings ? (
                            <Flex className="flex items-center justify-between w-full gap-2 py-1 cursor-pointer ">
                                <Text className="truncate w-[150px] text-sm  font-medium text-gray-800">
                                    {currentProject?.name}
                                </Text>

                                <ActionIcon
                                    component={Link}
                                    to={`/projects/${projectUuid}/settings/overview`}
                                    className=" border border-shade-6"
                                    onClick={() => {
                                        toggleOpen(false);
                                    }}
                                >
                                    <Gear weight="duotone" />
                                </ActionIcon>
                            </Flex>
                        ) : (
                            <Text className="truncate w-[150px] text-sm  font-medium text-gray-800 hover:bg-none">
                                {currentProject?.name}
                            </Text>
                        )}
                    </Menu.Label>
                    <Menu.Item
                        closeMenuOnClick={false}
                        onClick={handleProjectsClick}
                    >
                        <Box>
                            <WorkspacesMenu
                                projects={nonDemoProjects ?? []}
                                targetButton={
                                    <Flex
                                        align="center"
                                        gap="xs"
                                        justify="space-between"
                                    >
                                        <Text className="text-sm font-medium text-gray-800">
                                            {t(
                                                'workspace_settings_menu.switch_workspace_section',
                                            )}
                                        </Text>
                                        <CaretRight weight="duotone" />
                                    </Flex>
                                }
                                isDemo={false}
                                opened={projectsOpened}
                                close={closeProjects}
                            />
                        </Box>
                    </Menu.Item>
                    {showManageOrganization && (
                        <>
                            <Menu.Divider className="border border-shade-2" />

                            <Menu.Label>
                                <Text className="text-sm font-semibold text-gray-500">
                                    {t(
                                        'workspace_settings_menu.project_settings_organization_section',
                                    )}
                                </Text>
                            </Menu.Label>

                            <Menu.Item
                                role="menuitem"
                                className="hover:bg-shade-4"
                                icon={<Briefcase weight="duotone" />}
                            >
                                <LinkButton
                                    href={`/organization/overview?redirect=${encodedRedirectUrl}`}
                                >
                                    <Text className="text-sm font-medium text-gray-800 truncate w-[165px]">
                                        {t(
                                            'workspace_settings_menu.manage_organization_btn',
                                            {
                                                organizationName:
                                                    organization?.name,
                                            },
                                        )}
                                    </Text>
                                </LinkButton>
                            </Menu.Item>
                        </>
                    )}

                    <Menu.Divider className="border border-shade-2" />
                    <Menu.Label>
                        <Text className="text-sm font-semibold text-gray-500">
                            {t(
                                'workspace_settings_menu.project_settings_demo_section',
                            )}
                        </Text>
                    </Menu.Label>
                    <Menu.Item
                        onClick={handleDemoProjectsClick}
                        closeMenuOnClick={false}
                    >
                        <Box>
                            <WorkspacesMenu
                                projects={demoProjects ?? []}
                                targetButton={
                                    <Flex
                                        align="center"
                                        gap="xs"
                                        justify="space-between"
                                    >
                                        <Flex gap="xs" align="center">
                                            <Swatches weight="duotone" />
                                            <Text className="text-sm font-medium text-gray-800">
                                                {t(
                                                    'workspace_settings_menu.project_settings_view_examples',
                                                )}
                                            </Text>
                                        </Flex>
                                        <CaretRight weight="duotone" />
                                    </Flex>
                                }
                                isDemo={true}
                                opened={demoProjectsOpened}
                                close={closeDemoProjects}
                            />
                        </Box>
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </>
    );
};

export default React.memo(ProjectSwitcher);
