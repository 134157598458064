import { type FieldValueProperty } from '@components/Audience/Filters/FiltersProvider/types';
import FilterSelectWrapper from '@components/common/Filters/FilterInputs/FilterSelectWrapper';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { getDynamicItemId } from '@lightdash/common';
import { ActionIcon } from '@mantine/core';
import { XCircle } from '@phosphor-icons/react';
import React, { useMemo } from 'react';
import FieldListItem from '../FieldListItem';

interface FilterDynamicValueFieldProps {
    selectedFieldId: string;
    dynamicFieldValues:
        | PropertySelectListType<FieldValueProperty>[]
        | undefined;
    onChange: () => void;
}

const FilterDynamicValueField = ({
    selectedFieldId,
    dynamicFieldValues,
    onChange,
}: FilterDynamicValueFieldProps) => {
    const selectedField = useMemo(() => {
        if (!dynamicFieldValues || !dynamicFieldValues.length) return null;
        for (const field of dynamicFieldValues) {
            const item = field.items.find(
                (i) => getDynamicItemId(i) === selectedFieldId,
            );
            if (item) {
                return item;
            }
        }
        return null;
    }, [dynamicFieldValues, selectedFieldId]);

    if (
        !selectedFieldId ||
        !dynamicFieldValues ||
        !dynamicFieldValues.length ||
        !selectedField
    )
        return null;

    return (
        <FilterSelectWrapper isEditMode={true}>
            <FieldListItem
                item={selectedField}
                checked={false}
                disabled={true}
                showCheckbox={false}
                showHoverIcon={false}
                className="py-0.5"
            />
            <ActionIcon
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    onChange();
                }}
            >
                <XCircle weight="duotone" />
            </ActionIcon>
        </FilterSelectWrapper>
    );
};

export default FilterDynamicValueField;
