import { useLocale } from '@hooks/useLocale';
import {
    CustomDimensionType,
    DimensionType,
    FieldType,
    type CustomDimension,
    type MetricType,
} from '@lightdash/common';
import { Box, Button, Flex, Text } from '@mantine/core';
import { CaretRight, Check, Function } from '@phosphor-icons/react';
import useCustomMetricContext from '@providers/CustomMetric/useCustomMetricContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useCallback, useMemo } from 'react';
import { ButtonVariant } from '../../../mantineTheme';
interface ManualModeInTraitModalProps {
    aiThinking: boolean;
    handleAdvancedMode: () => void;
}

const ManualModeInTraitModal: React.FC<ManualModeInTraitModalProps> = ({
    handleAdvancedMode,
    aiThinking,
}) => {
    const { t } = useLocale();
    const { activeRelation } = useRelationContext();
    const { selectedTable, fieldType } = useCustomMetricContext(
        (context) => context.reducerState,
    );
    const { setFieldType, setDefinition, selectTable, addBaseTableDetails } =
        useCustomMetricContext((context) => context.actions);
    const primaryTable = useMemo(() => {
        const primaryTableName = activeRelation?.baseTable;
        if (!primaryTableName) {
            return null;
        }
        return activeRelation?.tables[primaryTableName];
    }, [activeRelation]);
    const handleFieldSelection = useCallback(
        (selectedFieldType: FieldType) => {
            setFieldType(selectedFieldType);
            if (primaryTable) {
                selectTable(primaryTable);
                addBaseTableDetails(primaryTable);
            }
            if (selectedFieldType === FieldType.METRIC) {
                setDefinition({
                    sql: '',
                    name: '',
                    table: '',
                    filters: [],
                    tableLabel: '',
                    description: '',
                    tablesReferences: [],
                    // for now as inital state type can be empty
                    type: '' as MetricType,
                });
            } else {
                setDefinition({
                    sql: '',
                    name: '',
                    type: CustomDimensionType.SQL,
                    id: '',
                    dimensionType: DimensionType.STRING,
                    table: selectedTable?.name ?? '',
                } as CustomDimension);
            }
        },
        [
            setFieldType,
            setDefinition,
            selectedTable,
            primaryTable,
            addBaseTableDetails,
            selectTable,
        ],
    );
    return (
        <Box className="border border-gray-250 rounded-lg">
            <Box className="p-3 border-b border-gray-250">
                <Text className="text-gray-800 font-medium">
                    {t(
                        'custom_metric.create_new_trait_modal.select_field_type_title',
                    )}
                </Text>
            </Box>

            <Flex className="m-3 gap-2">
                <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={() => handleFieldSelection(FieldType.METRIC)}
                    disabled={aiThinking}
                    className={`${
                        fieldType === FieldType.METRIC
                            ? '!bg-gray-200 hover:!bg-gray-200'
                            : 'cursor-pointer'
                    }`}
                    rightIcon={
                        fieldType === FieldType.METRIC ? (
                            <Check weight="bold" />
                        ) : undefined
                    }
                    leftIcon={<Function weight="duotone" />}
                >
                    {t('custom_metric.create_new_trait_modal.metric')}
                </Button>
                <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={() => handleFieldSelection(FieldType.DIMENSION)}
                    disabled={aiThinking}
                    className={`${
                        fieldType === FieldType.DIMENSION
                            ? '!bg-gray-200 hover:!bg-gray-200'
                            : 'cursor-pointer'
                    }`}
                    rightIcon={
                        fieldType === FieldType.DIMENSION ? (
                            <Check weight="bold" />
                        ) : undefined
                    }
                    leftIcon={<Function weight="duotone" />}
                >
                    {t('custom_metric.create_new_trait_modal.dimension')}
                </Button>
                <Button
                    variant={ButtonVariant.OUTLINED}
                    rightIcon={<CaretRight weight="duotone" />}
                    onClick={handleAdvancedMode}
                    disabled={aiThinking}
                >
                    {t('custom_metric.create_new_trait_modal.help_me_decide')}
                </Button>
            </Flex>
        </Box>
    );
};

export default ManualModeInTraitModal;
