import { CustomListItem } from '@components/common/List';
import PropertySelect from '@components/common/Select/PropertySelect';
import { type AddditionalPropertySelectListProps } from '@components/common/Select/PropertySelect/type';
import { useLocale } from '@hooks/useLocale';
import {
    type Filters,
    type JourneyDataSchema,
    type JourneyEventMapperSchema,
} from '@lightdash/common';
import { useDisclosure } from '@mantine/hooks';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import React from 'react';
import {
    ExternalEventItem,
    InternalEventItem,
} from '../../ControlPanel/TriggerPanel/EventsList';

import { FiltersPropertySelectorFieldItem } from '../FiltersForm/FiltersPropertySelector';
import {
    JourneyEventGroupType as JourneyEventGroupTypeEnum,
    type JourneyEvent,
    type JourneyEventGroupType,
    type JourneyProperty,
} from '../types';
import { useJourneyEvents } from '../useJourneyProperties';

const EventSelectorFieldItem: React.FC<{
    item: (JourneyEventMapperSchema | JourneyProperty) &
        AddditionalPropertySelectListProps;
}> = ({ item }) => {
    if (!item.groupKey) return null;

    if (item.groupKey === JourneyEventGroupTypeEnum.ALL_EVENTS) {
        const event = item as JourneyEventMapperSchema;
        if (event.isInternal) {
            return (
                <CustomListItem data={event}>
                    <InternalEventItem event={event} />
                </CustomListItem>
            );
        }

        return (
            <CustomListItem data={event}>
                <ExternalEventItem event={event} />
            </CustomListItem>
        );
    }

    return <FiltersPropertySelectorFieldItem item={item as JourneyProperty} />;
};

interface EventSelectorProps {
    journeyEvents: JourneyEventMapperSchema[];
    targetElement: React.ReactNode;
    setEvent: (item: JourneyEvent) => void;
    journeyDataSchema: JourneyDataSchema;
    nodeID: string;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    filters: Filters;
}

const EventSelector = ({
    journeyEvents,
    setEvent,
    targetElement,
    journeyDataSchema,
    nodeID,
}: EventSelectorProps) => {
    const { nodes, edges, journeyPayload, isEditable } =
        useJourneyBuilderContext((context) => context.state);

    const [opened, { open, close }] = useDisclosure();
    const journeyProperties = useJourneyEvents({
        journeyEvents,
        journeyDataSchema,
        nodeID,
        nodes,
        edges,
        journeyNodes: journeyPayload.config?.nodes ?? [],
    });

    const { t } = useLocale();

    const handleSubmit = (field: JourneyEvent | JourneyProperty) => {
        if (!field || !field.groupKey) return;
        const groupKey = field.groupKey;

        if (groupKey === JourneyEventGroupTypeEnum.ALL_EVENTS) {
            const event = field as JourneyEventMapperSchema;
            setEvent(event);
        } else {
            const property = field as JourneyProperty;
            const tableName = property.table ?? '';
            const mapperTable = journeyDataSchema.tables[tableName];

            setEvent({
                eventName: mapperTable.eventName ?? '',
                source: mapperTable.eventSource ?? '',
                uuid: '',
                projectUuid: '',
                mapperSchema: {},
                sampleEventPayload: {},
                label: null,
                createdBy: null,
                createdAt: null,
                updatedBy: null,
                updatedAt: null,
                persistToWh: false,
                isInternal: false,
                sourceLabel: undefined,
                tags: [],
            });
        }
    };

    return (
        <PropertySelect<JourneyEvent | JourneyProperty, JourneyEventGroupType>
            width={500}
            items={journeyProperties}
            showGroup={true}
            headerRightSection={null}
            onSubmit={(field: (JourneyEvent | JourneyProperty)[]) => {
                if (!field[0]) return;
                handleSubmit(field[0]);
                close();
            }}
            itemTemplate={({ item }) => {
                return <EventSelectorFieldItem item={item} />;
            }}
            opened={opened}
            close={close}
            open={open}
            withinPortal={true}
            targetButton={targetElement}
            showSearch={true}
            searchKeys={['source', 'label', 'eventName']}
            searchPlaceholder={t('audience_filters.search_filter_label')}
            allowMultipleSelection={false}
            showAllItemsGroup={false}
            disabled={!isEditable}
        />
    );
};

export default EventSelector;
