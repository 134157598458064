import { useRef } from 'react';

const useHistoryStack = <T>() => {
    const historyStack = useRef<T[]>([]);
    const stackPointer = useRef(-1);

    const incrementPointer = () => (stackPointer.current += 1);

    const decrementPointer = () => (stackPointer.current -= 1);

    const addItem = (item: T) => {
        if (stackPointer.current === historyStack.current.length - 1) {
            historyStack.current.push(item);
        } else {
            const newStack = historyStack.current.slice(
                0,
                stackPointer.current + 1,
            );
            historyStack.current = [...newStack, item];
        }
        incrementPointer();
    };

    const undo = () => {
        if (stackPointer.current < 0) {
            return undefined;
        }

        if (historyStack.current.length && stackPointer.current >= 0) {
            decrementPointer();
        }
        return historyStack.current[stackPointer.current];
    };

    const redo = () => {
        if (
            historyStack.current.length === 0 ||
            stackPointer.current === historyStack.current.length - 1
        )
            return;

        if (
            historyStack.current.length &&
            stackPointer.current < historyStack.current.length
        ) {
            incrementPointer();
        }
        return historyStack.current[stackPointer.current];
    };

    const reset = () => {
        historyStack.current = [];
    };

    const clearAfterCurrentIndex = (index: number) => {
        if (index < 0 || index > historyStack.current.length) return;

        if (historyStack.current.length) {
            const newStack = historyStack.current.slice(0, index);
            historyStack.current = newStack;
        }
    };

    const clearBeforeCurrentIndex = (index: number) => {
        if (index < 0 || index > historyStack.current.length) return;

        if (historyStack.current.length) {
            const newStack = historyStack.current.slice(index);
            historyStack.current = newStack;
        }
    };

    return {
        addItem,
        undo,
        redo,
        reset,
        stack: historyStack.current,
        stackPointer: stackPointer.current,
        clearAfterCurrentIndex,
        clearBeforeCurrentIndex,
        incrementPointer,
        decrementPointer,
    };
};

export default useHistoryStack;
