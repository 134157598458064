import {
    campaignEventFieldRef,
    isCampaignEvent,
} from '@components/Audience/utils';
import TextInputVariableWidth from '@components/common/Inputs/TextInputVariableWidth';
import FilterOperatorSelect from '@components/common/Select/FilterOperatorSelect';
import { useFilterFields } from '@hooks/useFilterFields';
import { useLocale } from '@hooks/useLocale';
import {
    createFilterRuleFromField,
    FieldType,
    FilterOperator,
    FynoEventNames,
    getFieldRef,
    getItemId,
    RelationTableType,
    ReservedCampaignEventColumns,
    type AdditionalMetric,
    type FilterableField,
    type FilterRule,
    type Filters,
} from '@lightdash/common';
import { Group, Text } from '@mantine/core';
import useAudienceContext from '@providers/Audience/useAudienceContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import {
    getEventFilterOptions,
    getMetricEventFilterOptions,
} from '../../FilterInputs/utils';
import FilterRuleFormPropertySelect from '../../FilterRuleFormPropertySelect';
import { type FieldWithSuggestions } from '../../FiltersProvider/types';
import { prepareFilterRuleData } from '../../utils/createFilterRule';

interface EventMetricFilterRuleFormProps {
    isEditMode: boolean;
    selectedToggleOperator: FilterOperator;
    handleToggleChange: (inputValue: string) => void;
    selectedConditionalOperator: FilterOperator;
    handleOperatorChange: (changedValue: FilterOperator) => void;
    filterRule: FilterRule;
    handleValueChange: (value: number) => void;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    groupIndex: number;
    additionalMetrics: AdditionalMetric[];
    fields: FilterableField[];
    handleFieldChange: (filterRule: FilterRule) => void;
}
const EventMetricFilterRuleForm = ({
    isEditMode,
    selectedToggleOperator,
    handleToggleChange,
    selectedConditionalOperator,
    handleOperatorChange,
    filterRule,
    handleValueChange,
    filters,
    groupIndex,
    additionalMetrics,
    handleFieldChange,
}: EventMetricFilterRuleFormProps) => {
    const { t } = useLocale();
    const { activeRelation } = useRelationContext();
    const setAdditionalMetrics = useAudienceContext(
        (context) => context.actions.setAdditionalMetrics,
    );
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { events } = useFilterFields();

    // Info: While creating an event filter, additional metric is created by the
    // count_distinct on the primary key of the selected events table. The selected event name is added as a filter on the additional metric.
    // Hence, we need to get the event name from the additional metric.
    const activeField = useMemo(() => {
        if (!activeRelation) return;

        // Info: Find the additional metric that matches the fieldId of the filter rule.
        const matchedAdditionalMetric = additionalMetrics.find(
            (metric) => getItemId(metric) === filterRule.target.fieldId,
        );

        if (!matchedAdditionalMetric) return;

        // Info: Find the relation table that matches the table name of the additional metric.
        const matchedRelationTable =
            activeRelation.tables[matchedAdditionalMetric.table];
        if (
            !matchedRelationTable ||
            matchedRelationTable.type !== RelationTableType.EVENT
        )
            return;

        // Info: Find the dimension that matches the event name column of the selected events table.
        const eventNameDimension =
            matchedRelationTable.dimensions[
                matchedRelationTable.eventNameColumn
            ];

        if (!eventNameDimension) return;

        // Info: Find the filter on the additional metric that matches the event name dimension.
        const eventFilter = matchedAdditionalMetric.filters?.find(
            (filter) =>
                filter.target.fieldRef === getFieldRef(eventNameDimension),
        );
        if (!eventFilter) return;
        const fieldId = eventFilter.values?.[0];
        // Info: If the event is a Fyno event, we need to find the channel filter on the additional metric.
        if (projectUuid && fieldId in FynoEventNames) {
            //channel filter is the filter on the additional metric that matches the channel column
            const channelFilter = matchedAdditionalMetric.filters?.find(
                (filter) =>
                    filter.target.fieldRef ===
                    campaignEventFieldRef(
                        projectUuid,
                        ReservedCampaignEventColumns.CHANNEL,
                    ),
            );
            if (!channelFilter) return;
            // Info: Find the event that matches the event name and channel as it depends on both.
            return events.find(
                (event) =>
                    'fieldReference' in event &&
                    event.fieldReference === eventFilter?.values?.[0] &&
                    event.uniqueIdentifier === channelFilter?.values?.[0],
            );
        }

        // Info: The event name is store in fieldReference of the event object.
        return events.find(
            (event) =>
                'fieldReference' in event &&
                event.fieldReference === eventFilter.values?.[0],
        );
    }, [additionalMetrics, filterRule, events, activeRelation, projectUuid]);

    const onFieldChange = (field: FieldWithSuggestions) => {
        if (!activeField) return;
        const eventName = 'fieldReference' in field && field.fieldReference;
        if (!eventName || !projectUuid) return;

        const { additionalMetricData } = prepareFilterRuleData({
            field,
            additionalMetrics,
            activeRelation,
            filters,
            index: groupIndex,
            additionalMetricsFilters: undefined,
            projectUuid: projectUuid,
            customDimensions: [],
            relatedOneToManyTables: [],
        });
        if (!additionalMetricData) return;

        // Ensure additionalMetricData has the required fieldType property set to FieldType.METRIC
        const metricDataWithFieldType = {
            ...additionalMetricData,
            fieldType: FieldType.METRIC,
            isAutoGenerated: false,
        };

        // Create the updated filter rule using the validated metric data
        const updatedFilterRule = createFilterRuleFromField(
            metricDataWithFieldType as FilterableField,
            filterRule.values?.[0],
            filterRule.operator,
        );

        const newAdditionalMetrics = additionalMetrics.map((metric) => {
            if (getItemId(metric) === filterRule.target.fieldId) {
                return additionalMetricData;
            }
            return metric;
        });
        setAdditionalMetrics(newAdditionalMetrics, false, groupIndex);
        handleFieldChange(updatedFilterRule);
    };

    const filteredEventFields = useMemo(() => {
        return events.filter(
            (event) => event.fieldType === FieldType.DIMENSION,
        );
    }, [events]);
    if (!activeField) return null;

    return (
        <Group className="gap-1.5">
            <FilterOperatorSelect
                isDisabled={!isEditMode}
                filterOperatorOptions={getEventFilterOptions([
                    FilterOperator.EQUALS,
                    FilterOperator.NOT_EQUALS,
                ])}
                selectedOperator={selectedToggleOperator}
                onChange={(inputValue) => {
                    if (!inputValue) return;
                    handleToggleChange(inputValue);
                }}
            />
            <FilterRuleFormPropertySelect
                fields={filteredEventFields}
                onSubmit={(items) => {
                    onFieldChange(items[0]);
                }}
                isEditMode={isEditMode}
                activeField={activeField}
                withinPortal={false}
                showFieldSource={false}
                showSourceTable={isCampaignEvent(activeField)}
            />

            <FilterOperatorSelect
                isDisabled={!isEditMode}
                filterOperatorOptions={getMetricEventFilterOptions([
                    FilterOperator.EQUALS,
                    FilterOperator.GREATER_THAN_OR_EQUAL,
                    FilterOperator.LESS_THAN_OR_EQUAL,
                ])}
                selectedOperator={selectedConditionalOperator}
                onChange={(changedValue) => {
                    if (!changedValue) return;
                    handleOperatorChange(changedValue as FilterOperator);
                }}
            />
            <TextInputVariableWidth
                value={filterRule.values?.[0]}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleValueChange(Number(event.target.value));
                }}
                minWidth={40}
                maxWidth={100}
                type="number"
            />

            <Text className="text-sm font-medium text-gray-600">
                {filterRule.values?.[0] >= 1
                    ? t('common.time')
                    : t('common.times')}
            </Text>
        </Group>
    );
};

export default EventMetricFilterRuleForm;
