import { AiMessageTypes, Author, type TextContent } from '@lightdash/common';
import { Box } from '@mantine/core';
import useAiAnalystContext from '@providers/AiAnalyst/useAiAnalystContext';
import { memo, useCallback } from 'react';
import { PreviewMessage, ThinkingMessage } from './Message';
import { useScrollToBottom } from './useScrollToBottom';

interface MessagesProps {
    chatId: string;
    isLoading: boolean;
}

function PureMessages({ chatId, isLoading }: MessagesProps) {
    const [messagesContainerRef, messagesEndRef] =
        useScrollToBottom<HTMLDivElement>(isLoading);

    const { aiChatMessages } = useAiAnalystContext((context) => context.state);

    const thinkingMessage = useCallback(() => {
        if (
            aiChatMessages.length > 0 &&
            aiChatMessages[aiChatMessages.length - 1].author ===
                Author.SYSTEM &&
            aiChatMessages[aiChatMessages.length - 1].content.type ===
                AiMessageTypes.TEXT
        ) {
            return (
                aiChatMessages[aiChatMessages.length - 1].content as TextContent
            ).thinkingText;
        }
    }, [aiChatMessages]);

    return (
        <Box
            ref={messagesContainerRef}
            className="flex flex-col min-w-0 gap-6 flex-1 pt-4 overflow-y-scroll h-56"
        >
            {aiChatMessages.map((message, index) => (
                <PreviewMessage
                    key={message.messageId}
                    chatId={chatId}
                    message={message}
                    isLoading={isLoading && aiChatMessages.length - 1 === index}
                    index={index}
                />
            ))}

            {isLoading && aiChatMessages.length > 0 && (
                <ThinkingMessage label={thinkingMessage()} />
            )}

            <Box
                ref={messagesEndRef}
                className="shrink-0 min-w-[24px] min-h-[24px]"
            />
        </Box>
    );
}

export const Messages = memo(PureMessages, (prevProps, nextProps) => {
    if (prevProps.isLoading !== nextProps.isLoading) return false;
    if (prevProps.isLoading && nextProps.isLoading) return false;

    return true;
});
