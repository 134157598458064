import {
    createFilterRuleFromField,
    getFieldRef,
    isField,
    isFilterableField,
    type AdditionalMetric,
    type FilterableField,
} from '@lightdash/common';
import { type FieldWithSuggestions } from '../../FiltersProvider/types';

interface AddFieldRuleToDerivedMetricProps {
    field: FieldWithSuggestions;
    additionalMetrics: AdditionalMetric[];
    activeField: FilterableField | undefined;
}

/**
 * Find the matched additional metric for the given field
 * @param additionalMetrics - The list of additional metrics
 * @param activeField - The field to find the matched additional metric for
 * @returns The matched additional metric or undefined if no match is found
 */
export const getMatchedAdditionalMetric = (
    additionalMetrics: AdditionalMetric[],
    activeField: FilterableField | undefined,
) => {
    return additionalMetrics.find(
        (metric) =>
            metric.name === activeField?.name &&
            metric.table === activeField?.table,
    );
};

/** addFiledRUle to the dervied metrics
 *
 */
export const addFieldRuleToDerivedMetric = ({
    field,
    additionalMetrics,
    activeField,
}: AddFieldRuleToDerivedMetricProps) => {
    if (!field) return;

    if (isField(field) && isFilterableField(field)) {
        const newFilterRule = createFilterRuleFromField(field);
        const newAdditionalMetrics = [...additionalMetrics];

        const metricIndex = newAdditionalMetrics.findIndex(
            (metric) =>
                metric.name === activeField?.name &&
                metric.table === activeField?.table,
        );

        if (metricIndex !== -1) {
            newAdditionalMetrics[metricIndex].filters?.push({
                ...newFilterRule,
                target: {
                    fieldRef: getFieldRef(field),
                },
            });
        }
        return newAdditionalMetrics;
    }
};
