import {
    type FieldWithSelectStatus,
    type PropertySelectGroupType,
} from '@components/Audience/Filters/FieldListItem/utils';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import TimeInput from '@components/common/Inputs/TimeInput';
import FieldSelect from '@components/common/Select/FieldSelect';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import TimeInputWithOptions from '@components/common/Time/TimeInputWithOptions';
import { type FieldWithSuggestions } from '@components/Explorer/FiltersCard/useFieldsWithSuggestions';
import { getJourneyEventPropertySelectList } from '@components/Journeys/Builder/JourneyFilters/useJourneyProperties';
import { useActiveProjectUuid } from '@hooks/useActiveProject';
import { useLocale } from '@hooks/useLocale';
import { useProject } from '@hooks/useProject';
import {
    DifferenceOperator,
    DimensionType,
    JoinType,
    JourneyParamsNames,
    JOURNEY_DYNAMIC_VARIABLE_PREFIX,
    PeriodType as PeriodTypeEnum,
    Timezones,
    type PeriodType,
} from '@lightdash/common';
import { Box, Group, Input, Radio, Skeleton, Text } from '@mantine/core';
import { Clock } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import { filterTablesFromRelation } from '@utils/relation';
import moment from 'moment';
import React, { useMemo, useRef, useState } from 'react';
import { useJourneyNodeParams } from '../../useJourneyNodeParams';

enum WaitTillDate {
    IMMEDIATELY = 'IMMEDIATELY',
    BEFORE = 'BEFORE',
    AFTER = 'AFTER',
    SPECIFIC_DATE = 'SPECIFIC_DATE',
}

interface DateTimeDelayProps {
    actionTitle: React.ReactNode;
    selectedValue: string | number;
    time: string | undefined;
    timeDiffOperator: DifferenceOperator;
    timeDiffValue: number;
    onDataChange: (
        value: string | number,
        timeDiffValue: number,
        timeDiffOperator: DifferenceOperator,
        time: string,
        granularity: PeriodType,
    ) => void;
    withLeftPadding?: boolean;
    granularity: PeriodType;
    durationOptions: PeriodType[];
    nodeId: string;
}
const DateTimeDelay: React.FC<DateTimeDelayProps> = ({
    selectedValue,
    onDataChange,
    time,
    timeDiffOperator,
    timeDiffValue,
    granularity,
    durationOptions,
    nodeId,
}) => {
    const { t } = useLocale();
    const ref = useRef<HTMLInputElement>(null);
    const { getTableRelation, activeRelation } = useRelationContext();

    const { nodes, edges, journeyPayload } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const {
        isLoading: isJourneyNodeParamsLoading,
        // eventsData,
        journeyDataSchema,
    } = useJourneyNodeParams(nodeId);
    const journeyEventsPropertySelectList = useMemo(() => {
        if (isJourneyNodeParamsLoading) return [];
        // INFO: This is a temporary solution to get the journey events property select list
        // if (!eventsData || !eventsData.length || !journeyDataSchema) return [];
        return getJourneyEventPropertySelectList({
            journeyDataSchema,
            nodes,
            edges,
            nodeId,
            journeyNodes: journeyPayload.config?.nodes ?? [],
        });
    }, [
        // eventsData,
        journeyDataSchema,
        nodes,
        edges,
        nodeId,
        journeyPayload.config?.nodes,
        isJourneyNodeParamsLoading,
    ]);

    const { activeProjectUuid } = useActiveProjectUuid();
    const { data: projectData } = useProject(activeProjectUuid);
    const projectTimezone = projectData?.timezone ?? Timezones.UTC;
    const offset = moment().tz(projectTimezone).format('Z');

    const isWarehouseDynamicVariable = useMemo(() => {
        return time && time.startsWith(JOURNEY_DYNAMIC_VARIABLE_PREFIX);
    }, [time]);

    const waitTimeInitial = useMemo(() => {
        if (isWarehouseDynamicVariable) {
            return WaitTillDate.SPECIFIC_DATE;
        }
        if (timeDiffValue && timeDiffValue > 0) {
            if (timeDiffOperator === DifferenceOperator.ADD) {
                return WaitTillDate.AFTER;
            } else if (timeDiffOperator === DifferenceOperator.SUBTRACT) {
                return WaitTillDate.BEFORE;
            }
        }
        return WaitTillDate.IMMEDIATELY;
    }, [isWarehouseDynamicVariable, timeDiffValue, timeDiffOperator]);

    const [waitTime, setWaitTime] = useState(waitTimeInitial);

    const campaignRelationData = useMemo(() => {
        if (activeRelation) {
            const allowedRelationTables = getTableRelation([
                JoinType.one_one,
                JoinType.many_one,
                JoinType.many_many,
                JoinType.one_many,
            ]);
            if (!allowedRelationTables) return;
            const tableIds = allowedRelationTables.map((table) => table.name);
            const filteredRelation = filterTablesFromRelation(
                activeRelation,
                tableIds,
            );
            if (!filteredRelation) return;
            return filteredRelation;
        }
    }, [activeRelation, getTableRelation]);

    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: campaignRelationData,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });

    const varibleFields = fieldsWithSuggestions;

    const fieldWithSuggestionInArray = useMemo(() => {
        return Object.keys(varibleFields)
            ?.map((fieldKey: string) => {
                return {
                    ...varibleFields?.[fieldKey],
                    uniqueIdentifier: fieldKey,
                };
            })
            .filter(
                (field) =>
                    field.type === DimensionType.STRING ||
                    field.type === DimensionType.NUMBER ||
                    field.type === DimensionType.DATE ||
                    field.type === DimensionType.TIMESTAMP,
            );
    }, [varibleFields]);

    const beforeTimeInputOptions = useMemo(() => {
        if (waitTime !== WaitTillDate.BEFORE) return null;

        return (
            <Box className="mb-3.5">
                <TimeInputWithOptions
                    selectedDuration={timeDiffValue}
                    onDurationChange={(value, granularityValue) => {
                        onDataChange(
                            selectedValue ?? '',
                            value,
                            DifferenceOperator.SUBTRACT,
                            '00:00:00',
                            granularityValue,
                        );
                    }}
                    selectedGranularity={granularity ?? PeriodTypeEnum.HOUR}
                    granularityOptions={durationOptions ?? []}
                    focusOnMount={true}
                    disabled={false}
                />
            </Box>
        );
    }, [
        waitTime,
        timeDiffValue,
        selectedValue,
        granularity,
        durationOptions,
        onDataChange,
    ]);

    const afterTimeInputOptions = useMemo(() => {
        if (waitTime !== WaitTillDate.AFTER) return null;

        return (
            <Box className="mb-3.5">
                <TimeInputWithOptions
                    selectedDuration={timeDiffValue}
                    onDurationChange={(value, granularityValue) => {
                        onDataChange(
                            selectedValue ?? '',
                            value,
                            DifferenceOperator.ADD,
                            '00:00:00',
                            granularityValue,
                        );
                    }}
                    selectedGranularity={granularity ?? PeriodTypeEnum.HOUR}
                    granularityOptions={durationOptions ?? []}
                    focusOnMount={true}
                    disabled={false}
                />
            </Box>
        );
    }, [
        waitTime,
        timeDiffValue,
        selectedValue,
        granularity,
        durationOptions,
        onDataChange,
    ]);

    const specificDateInputOptions = useMemo(() => {
        if (waitTime !== WaitTillDate.SPECIFIC_DATE) return null;

        return (
            <Group>
                <TimeInput
                    ref={ref}
                    icon={
                        <Clock
                            size={13}
                            strokeWidth={2}
                            color={'rgb(var(--color-gray-600))'}
                        />
                    }
                    onClick={() => ref?.current?.showPicker()}
                    onChange={(event) => {
                        onDataChange(
                            selectedValue ?? '',
                            0,
                            timeDiffOperator ?? DifferenceOperator.ADD,
                            event.target.value,
                            granularity ?? PeriodTypeEnum.HOUR,
                        );
                    }}
                    value={time}
                    className="w-[120px]"
                />
                <Input.Label className="text-sm font-normal text-gray-600">
                    {`(GMT${offset})`}
                </Input.Label>
            </Group>
        );
    }, [
        waitTime,
        ref,
        onDataChange,
        selectedValue,
        timeDiffOperator,
        time,
        granularity,
        offset,
    ]);

    const selectedField = useMemo(() => {
        if (isJourneyNodeParamsLoading) return undefined;

        if (!selectedValue || typeof selectedValue !== 'string')
            return undefined;

        const selectedValueDecode = selectedValue.startsWith(
            JOURNEY_DYNAMIC_VARIABLE_PREFIX,
        )
            ? selectedValue.slice(JOURNEY_DYNAMIC_VARIABLE_PREFIX.length)
            : selectedValue;

        let field = fieldsWithSuggestions[selectedValueDecode];
        if (
            !field &&
            (selectedValueDecode.startsWith(JourneyParamsNames.JOURNEY_EVENT) ||
                selectedValueDecode.startsWith(
                    JourneyParamsNames.SRT_USER_TRAITS,
                )) &&
            journeyEventsPropertySelectList
        ) {
            return journeyEventsPropertySelectList
                .flatMap((item) => item.items)
                .find(
                    (subItem) =>
                        subItem.uniqueIdentifier === selectedValueDecode,
                );
        }
        return field;
    }, [
        fieldsWithSuggestions,
        selectedValue,
        isJourneyNodeParamsLoading,
        journeyEventsPropertySelectList,
    ]);

    if (isJourneyNodeParamsLoading) return <Skeleton height={70} />;

    return (
        <Box>
            <Text className="mb-3 text-xs font-medium text-gray-500">
                {t('journey.delay.select_date')}
            </Text>
            <FieldSelect
                size="xs"
                item={selectedField}
                items={fieldWithSuggestionInArray as FieldWithSuggestions[]}
                onChange={(value) => {
                    onDataChange(
                        `${JOURNEY_DYNAMIC_VARIABLE_PREFIX}${value.table}_${value.name}`,
                        timeDiffValue ?? 0,
                        timeDiffOperator ?? DifferenceOperator.ADD,
                        time ?? '00:00:00',
                        granularity ?? PeriodTypeEnum.HOUR,
                    );
                }}
                placeholder={t('date_time_delay.select_property')}
                isDisabled={false}
                additionalPropertySelectList={
                    journeyEventsPropertySelectList as PropertySelectListType<
                        FieldWithSelectStatus,
                        PropertySelectGroupType
                    >[]
                }
            />

            <Box>
                <Text className="pt-3.5 text-gray-500 text-xs font-medium">
                    {t('journey.delay.proceed_from_this_block')}
                </Text>
                <Radio.Group
                    className="pt-3.5"
                    value={waitTime}
                    onChange={(value) => {
                        setWaitTime(value as WaitTillDate);
                        onDataChange(
                            selectedValue ?? '',
                            0,
                            value === WaitTillDate.AFTER
                                ? DifferenceOperator.ADD
                                : DifferenceOperator.SUBTRACT,
                            time ?? '00:00:00',
                            granularity ?? PeriodTypeEnum.HOUR,
                        );
                    }}
                    withAsterisk
                >
                    <Radio
                        className="pb-3.5 text-sm text-gray-800 font-medium"
                        value={WaitTillDate.IMMEDIATELY}
                        label={t('journey.delay.immediately')}
                    />
                    <Radio
                        className="pb-3.5 text-sm text-gray-800 font-medium"
                        value={WaitTillDate.BEFORE}
                        label={t('journey.delay.before')}
                    />
                    {waitTime === WaitTillDate.BEFORE && beforeTimeInputOptions}
                    <Radio
                        className="pb-3.5 text-sm text-gray-800 font-medium"
                        value={WaitTillDate.AFTER}
                        label={t('journey.delay.after')}
                    />
                    {waitTime === WaitTillDate.AFTER && afterTimeInputOptions}
                    <Radio
                        className="pb-3.5 text-sm text-gray-800 font-medium"
                        value={WaitTillDate.SPECIFIC_DATE}
                        label={t('journey.delay.at_specific_time')}
                    />
                    {waitTime === WaitTillDate.SPECIFIC_DATE &&
                        specificDateInputOptions}
                </Radio.Group>
            </Box>
        </Box>
    );
};

export default DateTimeDelay;
