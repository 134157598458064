// journeyUtils.ts
import {
    createJourneyFilterGroupFromFilterableField,
    getBranchesInSplitBlock,
    getLabelForChildOfSplitBlock,
    isExperimentBlock,
    isSplitBlock,
} from '@components/Journeys/Builder/ControlPanel/Actions/Split/utils';
import {
    ControlPanel,
    JourneyEdgeEnum,
    JourneyNodeEnum,
    type JourneyNodeData,
} from '@components/Journeys/Builder/types';
import {
    getActionSeedData,
    getAllChildNodeIds,
    getEdgeId,
} from '@components/Journeys/Builder/utils';
import {
    ActionType as JourneyActionType,
    BranchConcurrencyTypes,
    BranchConditionalTypes,
    generateShortUUID,
    type Branch,
    type ExperimentAction,
    type JourneyNode,
} from '@lightdash/common';
import { type JourneyReducerState } from '@providers/Journey/types';
import { t as translate } from 'i18next';
import { type Edge, type Node } from 'reactflow';

interface AddGhostNodeParams {
    nodeId: string;
    branch: Branch | undefined;
    state: JourneyReducerState;
    edgeName: string | undefined;
}

export function getGhostNodeAndEdge({
    nodeId,
    branch,
    state,
    edgeName,
}: AddGhostNodeParams): JourneyReducerState {
    const sourceNode = state.nodes.find((node) => node.id === nodeId);
    if (!sourceNode) return state;

    const parentJourneyNodeData = state.journeyPayload.config?.nodes.find(
        (node) => node.id === nodeId,
    );
    if (!parentJourneyNodeData) return state;

    const everyOneElseNodeId = state.edges.find(
        (edge) =>
            edge.source === nodeId && edge.type === JourneyEdgeEnum.DEFAULT,
    )?.target;

    const isEveryOneElseNodeInJourneyConfig =
        state.journeyPayload.config!.nodes.find(
            (node) => node.id === everyOneElseNodeId,
        );

    const everyOneElseEdge = state.edges.find(
        (edge) =>
            edge.source === nodeId && edge.type === JourneyEdgeEnum.DEFAULT,
    );

    const ghostNodeId = branch ? branch.destination : generateShortUUID();

    const existingGhostNode = state.nodes.some(
        (node) => node.id === ghostNodeId,
    );
    if (existingGhostNode) return state;

    const isDefaultBranch = branch?.isDefault ?? false;
    const ghostNode: Node<JourneyNodeData> = {
        id: ghostNodeId,
        position: {
            x: sourceNode.position.x + (isDefaultBranch ? 200 : 100),
            y: sourceNode.position.y,
        },
        type: JourneyNodeEnum.GHOSTNODE,
        data: {
            type: JourneyNodeEnum.GHOSTNODE,
            nodeId: ghostNodeId,
        },
    };

    const newEdge: Edge = {
        id: getEdgeId(nodeId, ghostNodeId),
        source: nodeId,
        target: ghostNodeId,
        type: JourneyEdgeEnum.BRANCHING,
        label: getLabelForChildOfSplitBlock(
            state.nodes,
            nodeId,
            ghostNodeId,
            edgeName,
            state.edges,
        ),
    };

    const branchesLength = getBranchesInSplitBlock({
        edges: state.edges,
        nodes: state.nodes,
        parentNodeId: nodeId,
        includeEveryOneElse: false,
    });
    const updatedEveryOneElseEdge = {
        ...everyOneElseEdge,
        label: isEveryOneElseNodeInJourneyConfig
            ? `${branchesLength.length + 1} ${
                  edgeName ??
                  translate('journey_builder.split_every_one_else_label')
              }`
            : `${branchesLength.length + 2} ${
                  edgeName ??
                  translate('journey_builder.split_every_one_else_label')
              }`,
    };

    const nonDefaultBranches =
        parentJourneyNodeData.branchConfig?.children.branches.filter(
            (eachBranch) => !eachBranch.isDefault,
        ) ?? [];

    const defaultBranches =
        parentJourneyNodeData.branchConfig?.children.branches.filter(
            (eachBranch) => eachBranch.isDefault,
        ) ?? [];

    const updatedBranchConfig = {
        ...parentJourneyNodeData.branchConfig,
        children: {
            ...parentJourneyNodeData.branchConfig?.children,
            branches: [
                ...nonDefaultBranches.filter(
                    (eachBranch) =>
                        JSON.stringify(eachBranch.conditions) !==
                        JSON.stringify(branch?.conditions),
                ),
                // {
                //     destination: ghostNodeId,
                //     conditions: branch
                //         ? branch.conditions
                //         : createJourneyFilterGroupFromFilterableField(
                //               state.splitActiveFields[nodeId]?.field,
                //               state.splitActiveFields[nodeId]?.isJourneyField,
                //           ),
                //     isDefault: isDefaultBranch,
                // },
                ...defaultBranches,
            ],
        },
    };

    const updatedNodes = state.journeyPayload.config!.nodes.map((node) =>
        node.id === nodeId
            ? { ...node, branchConfig: updatedBranchConfig }
            : node,
    );

    const updatedNodesInReactFlow = [...state.nodes, ghostNode].filter(
        (node) => node.id !== everyOneElseNodeId,
    );

    const everyOneElseNode = [...state.nodes, ghostNode].find(
        (node) => node.id === everyOneElseNodeId,
    );

    const updatedEdges = everyOneElseEdge
        ? [
              ...state.edges.filter(
                  (edge) =>
                      edge.id !== newEdge.id && edge.id !== everyOneElseEdge.id,
              ),
              newEdge,
              updatedEveryOneElseEdge,
          ]
        : [...state.edges.filter((edge) => edge.id !== newEdge.id), newEdge];

    return {
        ...state,
        edges: updatedEdges as Edge[],
        nodes: [
            ...updatedNodesInReactFlow,
            ...(everyOneElseNode ? [everyOneElseNode] : []),
        ],
        journeyPayload: {
            ...state.journeyPayload,
            config: {
                ...state.journeyPayload.config,
                nodes: updatedNodes as JourneyNode[],
            },
        },
    };
}

interface AddNodeParams {
    state: JourneyReducerState;
    blockId: string;
    reactFlowNodeId: string;
}

export function addNodeUtil({
    state,
    blockId,
    reactFlowNodeId,
}: AddNodeParams): JourneyReducerState {
    if (!blockId || !reactFlowNodeId) return state;
    const targetNode = state.nodes.find((node) => node.id === reactFlowNodeId);
    if (!targetNode) return state;

    const { nodes, edges } = state;
    const targetNodeIndex = nodes.findIndex(
        (node) => node.id === reactFlowNodeId,
    );

    let filteredNodes = nodes
        .filter((node) => node.id !== reactFlowNodeId)
        .map((node) => ({ ...node, selected: false }));

    const blockData = state.blocksList.find((block) => block.id === blockId);
    if (!blockData) return state;

    const newNodeId = generateShortUUID();
    const blockActions = blockData?.actions ?? [];
    const newNode: Node<JourneyNodeData> = {
        id: newNodeId,
        position: targetNode.position,
        type: JourneyNodeEnum.BLOCK,
        data: {
            type: JourneyNodeEnum.BLOCK,
            nodeId: newNodeId,
            blockId: blockData?.id,
        },
        selected: true,
    };

    const updatedReactNodes = [
        ...filteredNodes.slice(0, targetNodeIndex),
        newNode,
        ...filteredNodes.slice(targetNodeIndex),
    ];

    const parentEdge = edges.find((edge) => edge.target === reactFlowNodeId);
    const parentNodeId = parentEdge ? parentEdge.source : null;
    const parentNode = state.nodes.find((node) => node.id === parentNodeId);
    const parentJourneyNodeData = state.journeyPayload.config?.nodes.find(
        (node) => node.id === parentNodeId,
    );

    let updatedEdges = edges.map((edge) => {
        if (edge.source === reactFlowNodeId) {
            return {
                ...edge,
                source: newNodeId,
                id: getEdgeId(newNodeId, edge.target),
            };
        }
        if (edge.target === reactFlowNodeId) {
            return {
                ...edge,
                target: newNodeId,
                id: getEdgeId(edge.source, newNodeId),
            };
        }
        return edge;
    });

    const childNodeId = edges.find(
        (edge) => edge.source === reactFlowNodeId,
    )?.target;
    const childNode = state.nodes.find((node) => node.id === childNodeId);

    const actionPayload: JourneyNode = {
        id: newNodeId,
        title: blockData?.title ?? '',
        description: blockData?.description ?? '',
        actions: getActionSeedData(blockActions),
        metadata: {
            blockId: blockData?.id,
        },
        branchConfig: {
            type: BranchConditionalTypes.IFIF,
            children: {
                type:
                    blockActions.some(
                        (eachAction) =>
                            eachAction.actionType === JourneyActionType.SPLIT,
                    ) ||
                    (blockActions.length >= 1 &&
                        blockActions[0].config?.allowEveryoneElse)
                        ? BranchConcurrencyTypes.PARALLEL
                        : BranchConcurrencyTypes.SEQUENTIAL,
                branches:
                    childNodeId &&
                    childNode?.data.type === JourneyNodeEnum.BLOCK
                        ? [{ destination: childNodeId, isDefault: false }]
                        : [],
            },
        },
    };

    const filteredNodesConfig = (
        state.journeyPayload.config?.nodes ?? []
    ).filter((node) => node.id !== reactFlowNodeId);

    const updatedConfigNodes =
        parentNode?.data.type === JourneyNodeEnum.TRIGGER
            ? [actionPayload, ...filteredNodesConfig]
            : [...filteredNodesConfig, actionPayload];

    let updatedNodes = updatedConfigNodes.map((node) => {
        if (node.id === parentNodeId) {
            const newBranch: Branch = {
                destination: newNodeId,
                isDefault:
                    parentEdge?.type === JourneyEdgeEnum.DEFAULT ? true : false,
            };

            let updatedBranchConfig = parentJourneyNodeData?.branchConfig;
            if (isSplitBlock(parentJourneyNodeData)) {
                const branches =
                    parentJourneyNodeData?.branchConfig?.children.branches;

                if (
                    targetNode.data.type === JourneyNodeEnum.PLACEHOLDER &&
                    parentEdge?.type === JourneyEdgeEnum.DEFAULT
                ) {
                    updatedBranchConfig = {
                        type: BranchConditionalTypes.IFIF,
                        children: {
                            type: BranchConcurrencyTypes.PARALLEL,
                            branches: [...(branches ?? []), newBranch],
                        },
                    };
                } else {
                    const targetedNode = branches?.find(
                        (eachBranch) =>
                            eachBranch.destination === reactFlowNodeId,
                    );
                    if (targetedNode) {
                        updatedBranchConfig = {
                            type: BranchConditionalTypes.IFIF,
                            children: {
                                type: BranchConcurrencyTypes.PARALLEL,
                                branches: [
                                    ...(branches?.map((eachBranch) => ({
                                        ...eachBranch,
                                        destination:
                                            eachBranch.destination ===
                                            reactFlowNodeId
                                                ? newNodeId
                                                : eachBranch.destination,
                                    })) ?? []),
                                ],
                            },
                        };
                    } else {
                        updatedBranchConfig = {
                            type: BranchConditionalTypes.IFIF,
                            children: {
                                type: BranchConcurrencyTypes.PARALLEL,
                                branches: [
                                    ...(branches ?? []),
                                    {
                                        ...newBranch,
                                        destination: newNodeId,
                                        conditions:
                                            createJourneyFilterGroupFromFilterableField(
                                                state.splitActiveFields[
                                                    parentNodeId
                                                ]?.field,
                                                state.splitActiveFields[
                                                    parentNodeId
                                                ]?.isJourneyField,
                                            ),
                                    },
                                ],
                            },
                        };
                    }
                }
            } else {
                if (
                    updatedBranchConfig?.children.type ===
                    BranchConcurrencyTypes.PARALLEL
                ) {
                    updatedBranchConfig = {
                        ...updatedBranchConfig,
                        children: {
                            ...updatedBranchConfig?.children,
                            branches: [
                                ...(updatedBranchConfig?.children?.branches.filter(
                                    (eachBranch) =>
                                        eachBranch.destination !==
                                        reactFlowNodeId,
                                ) ?? []),
                                newBranch,
                            ],
                        },
                    };
                } else {
                    updatedBranchConfig = {
                        type: BranchConditionalTypes.IFIF,
                        children: {
                            type: BranchConcurrencyTypes.SEQUENTIAL,
                            branches: [newBranch],
                        },
                    };
                }
            }
            return {
                ...node,
                branchConfig: updatedBranchConfig,
            };
        }
        return node;
    });

    if (childNodeId && blockData.title === translate('common.split')) {
        const allChildNodeIds = getAllChildNodeIds(reactFlowNodeId, edges);
        filteredNodes = filteredNodes.filter(
            (node) => !allChildNodeIds.includes(node.id),
        );
        updatedEdges = updatedEdges.filter(
            (edge) =>
                !allChildNodeIds.includes(edge.source) &&
                !allChildNodeIds.includes(edge.target),
        );
        updatedNodes = updatedNodes.filter(
            (node) => !allChildNodeIds.includes(node.id),
        );
    }

    const newState: JourneyReducerState = {
        ...state,
        nodes: updatedReactNodes,
        edges: updatedEdges,
        controlPanel: {
            isOpen: true,
            type: ControlPanel.BLOCK_CONFIG,
            nodeId: newNodeId,
        },
        journeyPayload: {
            ...state.journeyPayload,
            config: {
                ...state.journeyPayload.config,
                nodes: updatedNodes,
            },
        },
    };

    if (isExperimentBlock(actionPayload)) {
        const totalExperimentVariants =
            (
                actionPayload.actions.find(
                    (action) => action.type === JourneyActionType.EXPERIMENT,
                ) as ExperimentAction
            )?.config?.experimentConfig?.variantConfig?.length ?? 0;

        if (!totalExperimentVariants) return newState;

        let accumulatedState: JourneyReducerState = newState;
        for (let i = 0; i < totalExperimentVariants; i++) {
            accumulatedState = getGhostNodeAndEdge({
                nodeId: newNodeId,
                branch: undefined,
                state: accumulatedState,
                edgeName: undefined,
            });
        }
        return accumulatedState;
    }

    return newState;
}
