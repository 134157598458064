import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import UpdateTraitsModal from '@components/UpdateTraits/UpdateTraitsModal';
import { useLocale } from '@hooks/useLocale';
import { type AnyType } from '@lightdash/common';
import { Box, Button, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PlusCircle } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { ButtonVariant } from '../../../../../../../mantineTheme';

interface EmptyDynamicTraitPageProps {
    items: PropertySelectListType<AnyType>[];
}
const EmptyDynamicTraitPage: React.FC<EmptyDynamicTraitPageProps> = ({}) => {
    const { isEditable } = useJourneyBuilderContext((context) => context.state);
    const { t } = useLocale();
    const [opened, { open, close }] = useDisclosure(false);
    return (
        <Stack>
            <Text className={'text-sm text-gray-600'}>
                {t('journey_builder.update_trait_empty_trait_text')}
            </Text>
            <Box className={'w-fit'}>
                <Button
                    variant={ButtonVariant.OUTLINED_ACCENTED}
                    leftIcon={
                        <PlusCircle color={'rgb(var(--color-blu-800))'} />
                    }
                    className={'py-2'}
                    onClick={open}
                >
                    {t('journey_builder.update_trait_create_trait_button_text')}
                </Button>
            </Box>
            <UpdateTraitsModal
                opened={opened}
                onClose={close}
                items={undefined}
                isEditMode={isEditable}
                updateTrait={undefined}
                updateTraitFieldConfig={undefined}
            />
        </Stack>
    );
};

export default EmptyDynamicTraitPage;
