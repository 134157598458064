import { motion } from 'framer-motion';

import { useAiAnalystPrompts } from '@hooks/useAiAnalyst';
import { type SuggestedPrompt } from '@lightdash/common';
import { Box, Text } from '@mantine/core';
import { Sparkle } from '@phosphor-icons/react';
import { memo, useCallback } from 'react';
import useAIMessageContext from './useAIMessageContext';

interface SuggestedActionsProps {
    handleSendMessage: (message?: string) => Promise<void>;
    threadId: string;
    setChatInput: (value: string) => void;
}

function PureSuggestedActions({ setChatInput }: SuggestedActionsProps) {
    const { getCurrentContext } = useAIMessageContext();
    const { data: prompts } = useAiAnalystPrompts({
        context: getCurrentContext,
    });

    const getPromptClassName = useCallback(
        (index: number) => {
            let classes = '';
            if (index === 0) {
                classes += 'rounded-t-lg ';
            }
            if (prompts && index === prompts.length - 1) {
                classes += 'rounded-b-lg border-b-1';
                return classes.trim();
            }
            classes += 'border-b-0';
            return classes.trim();
        },
        [prompts],
    );

    const handlePromptClick = useCallback(
        async (prompt: SuggestedPrompt) => {
            setChatInput(prompt.prompt);
        },
        [setChatInput],
    );

    const renderPrompts = useCallback(() => {
        if (prompts) {
            return (
                <Box>
                    {prompts?.map((prompt, index) => (
                        <motion.div
                            key={prompt.prompt}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ delay: index * 0.1, duration: 0.3 }}
                        >
                            <Box
                                className={`p-3 flex items-center cursor-pointer border border-gray-800 bg-white/6 ${getPromptClassName(
                                    index,
                                )}`}
                                key={prompt.prompt}
                                onClick={() => handlePromptClick(prompt)}
                            >
                                <Box className="p-2 border border-gray-800 rounded-md bg-white/6">
                                    <Sparkle
                                        size={18}
                                        weight="duotone"
                                        color="white"
                                    />
                                </Box>
                                <Box className="ml-3">
                                    <Text className="text-sm font-semibold text-white">
                                        {prompt.prompt}
                                    </Text>
                                    <Text className="text-xs font-normal text-white/60">
                                        {prompt.example}
                                    </Text>
                                </Box>
                            </Box>
                        </motion.div>
                    ))}
                </Box>
            );
        }
        return null;
    }, [prompts, getPromptClassName, handlePromptClick]);

    return <div className="flex flex-col gap-2">{renderPrompts()}</div>;
}

export const SuggestedActions = memo(
    PureSuggestedActions,
    (prevProps, nextProps) => {
        return prevProps.threadId === nextProps.threadId;
    },
);
