import PropertySelect from '@components/common/Select/PropertySelect';
import { type AddditionalPropertySelectListProps } from '@components/common/Select/PropertySelect/type';
import SkeletonLoader from '@components/common/SkeletonLoader';
import CreateCustomMetric from '@components/CustomMetric/CreateCustomMetric';
import { useFilterFields } from '@hooks/useFilterFields';
import { useLocale } from '@hooks/useLocale';
import {
    FieldType,
    JoinType,
    RelationTableType,
    type AdditionalMetric,
    type CompiledRelationTable,
    type CustomSqlDimension,
    type Filters,
} from '@lightdash/common';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PlusCircle } from '@phosphor-icons/react';
import { getTablePrimaryField } from '@utils/relation';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { ButtonVariant } from '../../../mantineTheme';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import { convertFieldsIntoPropertySelectListType } from './FieldListItem/utils';
import { FilterFieldSelectItem } from './FilterInputs/FilterFieldSelect';
import { type FieldWithSuggestions } from './FiltersProvider/types';
import useFiltersContext from './FiltersProvider/useFiltersContext';
import { createFilterRule } from './utils/createFilterRule';
interface FilterFormPropertySelectProps {
    targetButton?: React.ReactNode;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    index: number;
    additionalMetrics: AdditionalMetric[] | undefined;
    setAdditionalMetrics:
        | ((
              value: AdditionalMetric[],
              shouldFetchResults: boolean,
              index: number,
          ) => void)
        | undefined;
    customDimensions: CustomSqlDimension[] | undefined;
    setCustomDimensions: (
        customDimensions: CustomSqlDimension[],
        shouldFetchResults: boolean,
        index: number,
    ) => void;
}

const FilterFormPropertySelect: React.FC<FilterFormPropertySelectProps> = ({
    targetButton,
    filters,
    setFilters,
    index,
    additionalMetrics,
    setAdditionalMetrics,
    customDimensions,
    setCustomDimensions,
}) => {
    const {
        dimensions,
        metrics,
        events,
        audienceMap,
        customDimensions: customDimensionsFromMetrics,
    } = useFilterFields();
    const { isLoading, relatedOneToManyTables } = useFiltersContext();
    const { t } = useLocale();
    const { activeRelation } = useRelationContext();
    const [opened, { open, close }] = useDisclosure(false);
    const [openCustomMetric, setOpenCustomMetric] = useState<
        CompiledRelationTable | undefined
    >(undefined);
    const { projectUuid } = useParams<{ projectUuid: string }>();

    //FIXME: Change the type of propertySelectItems
    const propertySelectItems: any[] = useMemo(() => {
        if (!activeRelation) return [];
        // Removing all the related tables from the filterable fields that are one to many
        // since we need to create subgroups for related tables that contain related properties and tables with records
        const dimensionsExculdingRelatedTablesAndEvents = dimensions.filter(
            (dimension) => {
                const table = activeRelation.tables[dimension.table];
                if (table.type === RelationTableType.EVENT) return false;
                if (table.type !== RelationTableType.RELATED) return true;
                return !relatedOneToManyTables?.includes(table.name);
            },
        );

        const filterableFieldsWithoutRelatedTables =
            convertFieldsIntoPropertySelectListType(
                [
                    ...dimensionsExculdingRelatedTablesAndEvents,
                    ...events,
                    ...metrics,
                    ...(customDimensionsFromMetrics || []),
                    ...audienceMap,
                ],
                true,
            );

        //Info: Getting all the one to many related tables
        // const oneToManyTables = Object.values(activeRelation.tables).filter(
        //     (table) =>
        //         table.type === RelationTableType.RELATED &&
        //         getTableRelationType(table.name, activeRelation) ===
        //             JoinType.one_many,
        // );
        const oneToManyTables = relatedOneToManyTables?.map(
            (tableName) => activeRelation.tables[tableName],
        );

        if (!oneToManyTables?.length)
            return filterableFieldsWithoutRelatedTables;

        //Info: Getting all the primary fields of the one to many related tables
        const primaryFieldsOfOneToManyTables = oneToManyTables.map((table) => {
            const primaryField = getTablePrimaryField(
                table.name,
                activeRelation,
            );
            return primaryField;
        });

        const relatedTableRecordsGroup = primaryFieldsOfOneToManyTables.map(
            (primaryField) => {
                return {
                    subGroupLabel: 'Has related records in...',
                    subGroupKey: RelationTableType.RELATED + JoinType.one_many,
                    ...primaryField,
                };
            },
        );

        //Info: If one to many related tables are present, we need to add related group and under a subgroup for them
        const updatedFields = filterableFieldsWithoutRelatedTables.map(
            (group) => {
                if (group.groupKey === RelationTableType.RELATED) {
                    return {
                        ...group,
                        items: [...relatedTableRecordsGroup, ...group.items],
                    };
                }
                return group;
            },
        );

        return updatedFields;
    }, [
        activeRelation,
        dimensions,
        events,
        metrics,
        audienceMap,
        customDimensionsFromMetrics,
        relatedOneToManyTables,
    ]);

    return (
        <>
            <PropertySelect<
                FieldWithSuggestions & AddditionalPropertySelectListProps
            >
                items={propertySelectItems}
                showGroup={true}
                headerRightSection={
                    <Button
                        leftIcon={
                            <PlusCircle color="rgb(var(--color-gray-700))" />
                        }
                        variant={ButtonVariant.OUTLINED}
                        className="my-2.5 me-2.5"
                        onClick={() => {
                            close();
                            setOpenCustomMetric(
                                activeRelation?.tables[
                                    activeRelation.baseTable
                                ],
                            );
                        }}
                    >
                        {t('profiles_view.add_custom_metric_button')}
                    </Button>
                }
                onSubmit={(items: FieldWithSuggestions[]) => {
                    close();
                    if (!items[0] || !projectUuid) return;
                    createFilterRule({
                        field: items[0],
                        additionalMetrics,
                        setAdditionalMetrics,
                        activeRelation,
                        filters,
                        setFilters,
                        index,
                        additionalMetricsFilters: undefined,
                        projectUuid: projectUuid,
                        customDimensions,
                        setCustomDimensions,
                        relatedOneToManyTables,
                    });
                }}
                itemTemplate={({ item }) => {
                    return (
                        <FilterFieldSelectItem
                            activeField={item}
                            isEditMode={true}
                            showFieldSource={true}
                            activeRelation={activeRelation}
                            showHoverIcon={true}
                            showRelatedTableLabelOnly={true}
                            isOneToManyTable={
                                relatedOneToManyTables?.includes(
                                    item.table ?? '',
                                ) ?? false
                            }
                        />
                    );
                }}
                opened={opened}
                close={close}
                open={open}
                targetButton={
                    isLoading ? (
                        <SkeletonLoader height={20} width={80} />
                    ) : (
                        targetButton
                    )
                }
                showSearch={true}
                searchKeys={['label', 'tableLabel', 'table', 'name']}
                searchPlaceholder={t('audience_filters.search_filter_label')}
                allowMultipleSelection={false}
                height={350}
                width={450}
                groupPanelWidth={120}
            />
            <CreateCustomMetric
                tableName={openCustomMetric}
                onModalClose={() => {
                    setOpenCustomMetric(undefined);
                }}
                openBaseTable={undefined}
                isViewMode={undefined}
                isDuplicate={undefined}
                customMetricData={undefined}
                customMetricId={undefined}
                disableTableChange={false}
                fieldType={FieldType.METRIC}
            />
        </>
    );
};
export default FilterFormPropertySelect;
