import {
    createFilterRuleForCampaignEvent,
    isTimeWindowFilterInCampaignEvent,
} from '@components/Audience/Filters/utils';
import { campaignEventFieldRef } from '@components/Audience/utils';
import FilterOperatorSelect from '@components/common/Select/FilterOperatorSelect';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { TextWithTooltip } from '@components/common/TextWithTooltip';
import { useCampaigns } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import {
    CampaignStatus,
    FilterOperator,
    getFilterRuleWithDefaultValue,
    ReservedCampaignEventColumns,
    type AdditionalMetric,
    type CommunicationChannel,
    type FilterableField,
    type FilterRule,
} from '@lightdash/common';
import { ActionIcon, Button, Flex, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PaperPlaneTilt, PlusCircle, X } from '@phosphor-icons/react';
import { CAMPAIGN_ID_FILTER_INDEX } from '@utils/constants';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { ButtonVariant } from '../../../../../../mantineTheme';
import CampaignMenu from './CampaignMenu';
import CampaignSpecifyLink from './CampaignSpecifyLink';

interface CampaignFilterRuleFormProps {
    campaignFilters: FilterRule[];
    activeField: FilterableField;
    isEditMode: boolean;
    additionalMetrics: AdditionalMetric[];
    setAdditionalMetrics: (
        metrics: AdditionalMetric[],
        isDerivedMetric: boolean,
        groupIndex: number,
    ) => void;
    groupIndex: number;
}

const CampaignFilterRuleForm = ({
    campaignFilters,
    activeField,
    isEditMode,
    additionalMetrics,
    setAdditionalMetrics,
    groupIndex,
}: CampaignFilterRuleFormProps) => {
    const [opened, { open, close }] = useDisclosure();
    const { data: campaignData, isLoading: isCampaignsLoading } = useCampaigns({
        perPage: 1000,
        currentPage: 1,
        query: ``,
        polling: false,
    });
    const { t } = useLocale();
    const filterRule = campaignFilters[0];
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [activeCampaignId, setActiveCampaignId] = useState<string | null>(
        filterRule ? (filterRule?.values?.[0] as string) : null,
    );

    const handleCampaignFilterChange = useCallback(
        (newFilterRule: FilterRule[]) => {
            if (!projectUuid) return additionalMetrics;
            const newAdditionalMetrics = additionalMetrics.map((metric) => {
                if (
                    metric.name === activeField?.name &&
                    metric.table === activeField?.table
                ) {
                    let baseFilterRules = metric.filters?.slice(0, 2);
                    const timeWindowFilter = metric.filters?.find(
                        (eachFilter) =>
                            isTimeWindowFilterInCampaignEvent(
                                eachFilter,
                                projectUuid,
                            ),
                    );
                    if (timeWindowFilter) {
                        baseFilterRules = [
                            ...(baseFilterRules ?? []),
                            timeWindowFilter,
                        ];
                    }
                    if (!baseFilterRules) return metric;
                    const addingFieldRef = newFilterRule.map((rule) => {
                        return {
                            ...rule,
                            target: {
                                ...rule.target,
                                fieldRef: campaignEventFieldRef(
                                    projectUuid,
                                    ReservedCampaignEventColumns.CUSTOM_ID,
                                ),
                            },
                        };
                    });
                    return {
                        ...metric,
                        filters: [...baseFilterRules, ...addingFieldRef],
                    };
                }
                return metric;
            });
            setAdditionalMetrics(newAdditionalMetrics, false, groupIndex);
        },
        [
            activeField?.name,
            activeField?.table,
            additionalMetrics,
            groupIndex,
            setAdditionalMetrics,
            projectUuid,
        ],
    );

    const handleCampaignIDFilterDelete = useCallback(() => {
        const newAdditionalMetrics = [...additionalMetrics];
        const alteredAdditionalMetrics = newAdditionalMetrics.map((metric) => {
            if (
                metric.name === activeField?.name &&
                metric.table === activeField?.table
            ) {
                return {
                    ...metric,
                    filters: metric.filters?.filter(
                        (_filter, index) => index !== CAMPAIGN_ID_FILTER_INDEX,
                    ),
                };
            }
            return metric;
        });
        setAdditionalMetrics(alteredAdditionalMetrics, false, groupIndex);
    }, [activeField, additionalMetrics, groupIndex, setAdditionalMetrics]);
    const filterOperatorOptions = useMemo(() => {
        return [
            { label: t('common.is'), value: FilterOperator.EQUALS },
            { label: t('common.is_not'), value: FilterOperator.NOT_EQUALS },
        ];
    }, [t]);
    const activeChannel = useMemo(() => {
        if (!activeField || !('filters' in activeField)) return null;
        return activeField?.filters?.[1]?.values?.[0] as CommunicationChannel;
    }, [activeField]);
    const filteredCampaignData = useMemo(() => {
        const campaigns = campaignData?.data ?? [];
        return campaigns.filter((campaign) => {
            if (campaign.channel !== activeChannel) {
                return false;
            }
            if (campaign.status === CampaignStatus.COMPLETED) {
                return true;
            }
            if (campaign.status === CampaignStatus.SCHEDULED) {
                //return campaign if campaign sent atleast once for that checking the schedule.children grater than 1;
                return (
                    campaign.schedule?.children &&
                    Number(campaign.schedule.children) > 1
                );
            }
            return false;
        });
    }, [campaignData, activeChannel]);
    const renderSelectComponent = useMemo(() => {
        if (!activeField) return null;
        if (campaignFilters.length === 0) return null;
        return (
            <FilterOperatorSelect
                isDisabled={!isEditMode}
                filterOperatorOptions={filterOperatorOptions}
                selectedOperator={filterRule.operator}
                onChange={(value) => {
                    if (!value) return;
                    handleCampaignFilterChange([
                        getFilterRuleWithDefaultValue(
                            activeField,
                            {
                                ...filterRule,
                                operator: value as FilterRule['operator'],
                            },
                            (filterRule.values?.length || 0) > 0
                                ? filterRule.values
                                : [1],
                        ),
                    ]);
                }}
            />
        );
    }, [
        activeField,
        filterOperatorOptions,
        filterRule,
        isEditMode,
        handleCampaignFilterChange,
        campaignFilters.length,
    ]);
    const campaignName = useMemo(() => {
        if (campaignFilters.length === 0) return null;
        if (!filterRule.values?.[0]) return null;
        const campaign = filteredCampaignData?.find(
            (eachCampaign) => eachCampaign.id === filterRule.values?.[0],
        );
        if (!campaign) return null;
        return campaign.name;
    }, [filteredCampaignData, filterRule, campaignFilters.length]);
    const targetButton = useMemo(() => {
        return (
            <Button
                variant={ButtonVariant.SUBTLE}
                className="text-gray-800 w-fit"
                size="md"
            >
                <PlusCircle
                    color="rgb(var(--color-gray-800))"
                    className="mr-1"
                />
                {t('audience_filters.specify_campaign')}
            </Button>
        );
    }, [t]);

    const filledTargetButton = useMemo(() => {
        return (
            <Button
                variant={ButtonVariant.UNSTYLED}
                className={`p-2 min-h-[2.3rem] rounded-lg bg-white border border-shade-6 shadow-card  text-gray-600 text-sm cursor-${
                    isEditMode ? 'auto' : 'pointer hover:bg-gray-50'
                }`}
                form="campaign_filter"
            >
                <Flex gap={4} align="center">
                    <PaperPlaneTilt color={'rgb(var(--color-lime))'} />
                    <Text className="flex-grow text-sm font-medium text-gray-800  max-w-[10rem] ">
                        <TextWithTooltip
                            text={campaignName ?? ''}
                            className="!m-0 rounded text-sm text-gray-800 font-medium"
                        />
                    </Text>
                </Flex>
            </Button>
        );
    }, [campaignName, isEditMode]);
    const onChangeOfLink = useCallback(
        (value: FilterRule) => {
            handleCampaignFilterChange([filterRule, value]);
        },
        [handleCampaignFilterChange, filterRule],
    );
    const handleDeletionOfLink = useCallback(() => {
        handleCampaignFilterChange([filterRule]);
    }, [handleCampaignFilterChange, filterRule]);
    const handleCampaignMenuChange = useCallback(
        (id: string) => {
            if (!id || !projectUuid) return;
            setActiveCampaignId(id);
            handleCampaignFilterChange([
                createFilterRuleForCampaignEvent({
                    dimension: ReservedCampaignEventColumns.CUSTOM_ID,
                    value: id,
                    projectUuid,
                    operator: FilterOperator.EQUALS,
                }),
            ]);
        },
        [handleCampaignFilterChange, projectUuid],
    );
    if (campaignFilters.length === 0) {
        if (!isEditMode) {
            return null;
        }
        return (
            <CampaignMenu
                campaignData={filteredCampaignData ?? []}
                targetButton={
                    isCampaignsLoading ? (
                        <SkeletonLoader height={20} width={130} />
                    ) : (
                        targetButton
                    )
                }
                opened={opened}
                onOpen={open}
                onClose={close}
                isDisabled={!isEditMode}
                onChange={handleCampaignMenuChange}
            />
        );
    }

    return (
        <Stack className="gap-2">
            <Flex gap={6} align="center">
                <Text className="text-sm text-gray-600">
                    {t('common.where')}
                </Text>
                <Flex gap={4} align="center">
                    <PaperPlaneTilt color={'rgb(var(--color-lime))'} />
                    <Text className="text-sm font-medium text-gray-800">
                        {t('common.campaign')}
                    </Text>
                </Flex>
                {renderSelectComponent}
                <CampaignMenu
                    campaignData={filteredCampaignData ?? []}
                    targetButton={
                        isCampaignsLoading ? (
                            <SkeletonLoader height={20} width={130} />
                        ) : (
                            filledTargetButton
                        )
                    }
                    opened={opened}
                    onOpen={open}
                    onClose={close}
                    isDisabled={!isEditMode}
                    onChange={(id) => {
                        setActiveCampaignId(id);
                        handleCampaignFilterChange([
                            {
                                ...filterRule,
                                values: [id],
                            },
                        ]);
                    }}
                />
                <ActionIcon
                    onClick={handleCampaignIDFilterDelete}
                    variant={ButtonVariant.UNSTYLED}
                    className="rounded-lg hover:bg-shade-4"
                >
                    <X
                        size={14}
                        color={'rgb(var(--color-gray-700))'}
                        weight="bold"
                    />
                </ActionIcon>
            </Flex>
            {activeCampaignId && false && (
                <CampaignSpecifyLink
                    campaignId={activeCampaignId ?? ''}
                    filterRule={campaignFilters[1]}
                    activeField={activeField}
                    isEditMode={isEditMode}
                    onChange={onChangeOfLink}
                    onDelete={handleDeletionOfLink}
                />
            )}
        </Stack>
    );
};

export default CampaignFilterRuleForm;
