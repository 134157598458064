import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import {
    useGetOrganisationDescription,
    useOrganization,
    useUpdateOrganizationDescriptionMutation,
} from '@hooks/organization/useOrganization';
import { useOrganizationUpdateMutation } from '@hooks/organization/useOrganizationUpdateMutation';
import { useIsEqual } from '@hooks/useIsEqual';
import { useLocale } from '@hooks/useLocale';
import {
    KBSingleDocumentContentTypes,
    KnowledgeBaseEntities,
    type UpdateOrganization,
} from '@lightdash/common';
import { List, Stack, Text, Textarea, TextInput } from '@mantine/core';
import useApp from '@providers/App/useApp';
import React, { useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import UnsavedChangesModal from '../../common/modal/UnsavedChangesModal';

interface WorkspaceSettingsFormProps {
    activeProjectUuid: string;
}

const WorkspaceSettingsForm: React.FC<WorkspaceSettingsFormProps> = ({
    activeProjectUuid,
}) => {
    const { t } = useLocale();
    const { isLoading: isOrganizationLoading, data: orgData } =
        useOrganization();

    const {
        data: organizationDescription,
        isLoading: isOrganizationDescriptionLoading,
    } = useGetOrganisationDescription({
        query: `entity=${KnowledgeBaseEntities.ORGANIZATION}&entityId=${orgData?.organizationUuid}&contentType=${KBSingleDocumentContentTypes.INFO}`,
        projectUuid: activeProjectUuid ?? '',
        organizationUuid: orgData?.organizationUuid ?? '',
    });
    const { user } = useApp();
    const {
        isLoading: isOrganizationUpdateLoading,
        mutate: updateOrganization,
    } = useOrganizationUpdateMutation();
    const {
        isLoading: isOrganizationDescriptionUpdateLoading,
        mutate: updateOrganizationDescription,
    } = useUpdateOrganizationDescriptionMutation(activeProjectUuid ?? '');

    const isLoading =
        isOrganizationUpdateLoading ||
        isOrganizationLoading ||
        isOrganizationDescriptionLoading ||
        isOrganizationDescriptionUpdateLoading;

    const ability = useAbilityContext();
    const canUpdateOrganization = ability.can(
        'update',
        subject('Organization', {
            organizationUuid: user.data?.organizationUuid,
        }),
    );
    const defaultValues: UpdateOrganization & { description: string } =
        useMemo(() => {
            return {
                name: orgData?.name ?? '',
                description: organizationDescription?.[0]?.content ?? '',
            };
        }, [orgData, organizationDescription]);

    const form = useForm<UpdateOrganization & { description: string }>({
        defaultValues,
    });

    const { control, handleSubmit, register, reset, setValue } = form;

    useEffect(() => {
        if (isLoading) return;
        if (!orgData) return;
        setValue('name', orgData.name);
    }, [orgData, setValue, isLoading]);

    const watchFields = useWatch<UpdateOrganization & { description: string }>({
        control,
    });
    const hasFormChanged = useIsEqual<
        UpdateOrganization & { description: string }
    >(defaultValues, watchFields);

    const submitForm = (
        formData: UpdateOrganization & { description: string },
    ) => {
        const { name, description } = formData;
        updateOrganizationDescription({
            content: description,
            contentType: KBSingleDocumentContentTypes.INFO,
            entity: KnowledgeBaseEntities.ORGANIZATION,
            entityId: orgData?.organizationUuid ?? '',
        });
        updateOrganization({ name: name });
    };

    const onSubmit = () => {
        void handleSubmit(submitForm)();
    };

    const listItems: { id: number; description: string }[] = [
        {
            id: 1,
            description: t('organization_settings.description_first'),
        },
        {
            id: 2,
            description: t('organization_settings.description_second'),
        },
        {
            id: 3,
            description: t('organization_settings.description_third'),
        },
    ];

    return (
        <>
            <form>
                <Stack>
                    <TextInput
                        id="organization-name-input"
                        placeholder={t(
                            'organization_settings_form.name_placeholder',
                        )}
                        label={t('organization_settings_form.name_label')}
                        type="text"
                        required
                        disabled={isLoading || !canUpdateOrganization}
                        data-cy="organization-name-input"
                        {...register('name')}
                        withAsterisk={false}
                        className="w-72"
                    />
                    <Textarea
                        id="workspace-description-input"
                        placeholder={t(
                            'organization_settings.description_placeholder',
                        )}
                        label={t('organization_settings.description_label')}
                        labelProps={{
                            className: 'text-sm font-semibold',
                        }}
                        description={
                            <List>
                                {listItems.map((item) => (
                                    <List.Item key={item.id}>
                                        <Text className="text-sm text-gray-600 font-medium my-0">
                                            {item.description}
                                        </Text>
                                    </List.Item>
                                ))}
                            </List>
                        }
                        descriptionProps={{
                            className: 'text-sm w-[30rem] font-medium',
                        }}
                        disabled={isLoading || !canUpdateOrganization}
                        data-cy="workspace-description-input"
                        {...register('description')}
                        className="w-72"
                    />
                </Stack>
            </form>

            <UnsavedChangesModal
                opened={hasFormChanged}
                primaryActionButtonClick={() => onSubmit()}
                secondaryActionButtonClick={() => reset()}
                disableButtons={isLoading}
            />
        </>
    );
};

export default WorkspaceSettingsForm;
