import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type ApiSuccessEmpty,
    type KnowledgeBaseDocument,
    type Organization,
} from '@lightdash/common';
import {
    useMutation,
    useQuery,
    useQueryClient,
    type UseQueryOptions,
} from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';

const getOrganization = async () =>
    sortmentApi<Organization>({
        url: `/org`,
        method: 'GET',
        body: undefined,
    });

export const useOrganization = (
    useQueryOptions?: UseQueryOptions<Organization, ApiError>,
) =>
    useQuery<Organization, ApiError>({
        queryKey: [QueryKeys.ORGANIZATION],
        queryFn: getOrganization,
        ...useQueryOptions,
    });

interface UseGetOrganisationDescriptionProps {
    query: string;
    projectUuid: string;
    organizationUuid: string;
}

const getOrganisationDescription = async (projectUuid: string, query: string) =>
    sortmentApi<KnowledgeBaseDocument[]>({
        url: `/projects/${projectUuid}/ai/knowledge-base/documents?${query}`,
        method: 'GET',
        body: undefined,
    });

export const useGetOrganisationDescription = ({
    query,
    projectUuid,
    organizationUuid,
}: UseGetOrganisationDescriptionProps) => {
    const projectDescription = useQuery<KnowledgeBaseDocument[], ApiError>({
        queryKey: [QueryKeys.ORGANIZATION_DESCRIPTION, projectUuid],
        queryFn: () => getOrganisationDescription(projectUuid || '', query),
        enabled: !!projectUuid && !!organizationUuid,
    });
    return projectDescription;
};

const updateOrganizationDescription = async (
    projectUuid: string,
    data: Pick<
        KnowledgeBaseDocument,
        'content' | 'contentType' | 'entity' | 'entityId'
    >,
) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectUuid}/ai/knowledge-base/document`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useUpdateOrganizationDescriptionMutation = (
    projectUuid: string,
) => {
    const queryClient = useQueryClient();
    return useMutation<
        ApiSuccessEmpty,
        ApiError,
        Pick<
            KnowledgeBaseDocument,
            'content' | 'contentType' | 'entity' | 'entityId'
        >
    >((data) => updateOrganizationDescription(projectUuid || '', data), {
        mutationKey: [QueryKeys.UPDATE_ORGANIZATION_DESCRIPTION, projectUuid],
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: [QueryKeys.ORGANIZATION_DESCRIPTION, projectUuid],
            });
        },
    });
};
