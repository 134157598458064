import { useEffect, useRef, useState, type RefObject } from 'react';

export function useScrollToBottom<T extends HTMLElement>(
    isLoading: boolean,
): [RefObject<T | null>, RefObject<T | null>] {
    const containerRef = useRef<T | null>(null);
    const endRef = useRef<T>(null);
    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

    useEffect(() => {
        if (isLoading && !hasScrolledToBottom) {
            setHasScrolledToBottom(true);
            endRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }
    }, [isLoading, hasScrolledToBottom]);

    useEffect(() => {
        if (!isLoading && hasScrolledToBottom) {
            setHasScrolledToBottom(false);
        }
    }, [isLoading, hasScrolledToBottom]);

    return [containerRef, endRef];
}
