import { useFieldsWithEvents } from '@components/Audience/Filters/FiltersCard/useFieldsWithEvents';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { FiltersProvider } from '@components/Audience/Filters/FiltersProvider/FiltersProvider';
import useProjectContext from '@providers/Project/useProjectContext';
import React from 'react';
import { useParams } from 'react-router';
import CustomMetricProvider from '../../../providers/CustomMetric/CustomMetricProvider';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import CalculatedTraitBaseModal from '../CalculatedTraitBaseModal';
import CustomMetricManagerModal from '../CustomMetricManagerModal';
import {
    useCustomMetricInitialData,
    type CreateCustomMetricProps,
} from '../utils';
import useCreateCustomMetric from './useCreateCustomMetric';

const CustomMetricModals: React.FC<CreateCustomMetricProps> = (props) => {
    useCreateCustomMetric(props);
    return (
        <>
            <CalculatedTraitBaseModal />
            <CustomMetricManagerModal />
        </>
    );
};

const CreateCustomMetric: React.FC<CreateCustomMetricProps> = ({
    ...props
}) => {
    const { activeRelation, activeRelationUuid } = useRelationContext();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { projectData } = useProjectContext();
    const initialState = useCustomMetricInitialData(
        undefined,
        activeRelationUuid,
        activeRelation,
    );
    const { data: eventsData, eventsTableNames } = useFieldsWithEvents({
        relationData: activeRelation,
        activeRelationUuid,
        projectUuid,
    });
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });

    return (
        <>
            <FiltersProvider
                projectUuid={projectUuid}
                fieldsMap={fieldsWithSuggestions}
                startOfWeek={
                    projectData?.warehouseConnection?.startOfWeek ?? undefined
                }
                eventsMap={eventsData}
                eventTables={eventsTableNames}
            >
                <CustomMetricProvider initialState={initialState}>
                    <CustomMetricModals
                        openBaseTable={props.openBaseTable}
                        tableName={props.tableName}
                        customMetricData={props.customMetricData}
                        isDuplicate={props.isDuplicate}
                        isViewMode={props.isViewMode}
                        onModalClose={props.onModalClose}
                        customMetricId={props.customMetricId}
                        disableTableChange={props.disableTableChange}
                        fieldType={props.fieldType}
                    />
                </CustomMetricProvider>
            </FiltersProvider>
        </>
    );
};
export default CreateCustomMetric;
