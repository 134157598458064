import OrganizationSettingsSidebar from '@components/OrganizationSettings/Sidebar';
import Page from '@components/Page/Page';
import { useLocale } from '@hooks/useLocale';
import React from 'react';
import { Outlet } from 'react-router';

const OrganizationSettings: React.FC<{}> = ({}) => {
    const { t } = useLocale();

    return (
        <Page
            withFitContent
            withPaddedContent
            title={t('organization_settings.page_title')}
            sidebar={<OrganizationSettingsSidebar />}
            className="h-[100vh] bg-gray-100"
        >
            <Outlet />
        </Page>
    );
};

export default OrganizationSettings;
