import DataTypeMenu from '@components/Journeys/Builder/ControlPanel/Actions/CallAPI/ResponsePayloadMapper/DataTypeMenu';
import { useLocale } from '@hooks/useLocale';
import { useRelationAceEditorCompleter } from '@hooks/useRelationAceEditorCompleter';
import { type CustomSqlDimension } from '@lightdash/common';
import { Box, Stack, Text } from '@mantine/core';
import useCustomMetricContext from '@providers/CustomMetric/useCustomMetricContext';
import 'ace-builds/src-noconflict/mode-sql';
import { useRef } from 'react';
import AceEditor from 'react-ace';
interface CustomDimensionLogicProps {
    isLoading: boolean;
}
const CustomDimensionLogic: React.FC<CustomDimensionLogicProps> = ({
    isLoading,
}) => {
    const { t } = useLocale();
    const editorRef = useRef<InstanceType<typeof AceEditor> | null>(null);
    const { viewMode, customAttributePayload } = useCustomMetricContext(
        (context) => context.reducerState,
    );
    const { setSqlInCustomSqlDimension, setDefinition } =
        useCustomMetricContext((context) => context.actions);
    const { setAceEditor } = useRelationAceEditorCompleter();
    return (
        <Stack>
            <Stack spacing="xs">
                <Text className="text-gray-800 font-semimedium text-lg">
                    {t('custom_metric.create_new_trait_modal.sql')}
                </Text>
                <Box className="h-[150px] border border-gray-200 rounded-xl overflow-hidden">
                    <AceEditor
                        ref={editorRef}
                        width="100%"
                        height="100%"
                        mode="sql"
                        theme="xcode"
                        name="custom-metric-sql-editor"
                        readOnly={viewMode || isLoading}
                        onChange={(value) => {
                            setSqlInCustomSqlDimension(value);
                        }}
                        showPrintMargin={false}
                        fontSize={14}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={
                            (
                                customAttributePayload.definition as CustomSqlDimension
                            )?.sql
                        }
                        wrapEnabled={true}
                        setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            tabSize: 2,
                            indentedSoftWrap: true,
                            wrap: true,
                        }}
                        onLoad={(editor) => {
                            setAceEditor(editor);
                        }}
                    />
                </Box>
            </Stack>
            <Box className="w-[272px]">
                <Text className="text-gray-800 font-semimedium text-lg">
                    {t('custom_metric.create_new_trait_modal.dimension_type')}
                </Text>
                <DataTypeMenu
                    value={
                        (
                            customAttributePayload.definition as CustomSqlDimension
                        )?.dimensionType
                    }
                    onSelect={(value) => {
                        setDefinition({
                            ...customAttributePayload.definition,
                            dimensionType: value,
                        } as CustomSqlDimension);
                    }}
                    disabled={isLoading || viewMode}
                />
            </Box>
        </Stack>
    );
};

export default CustomDimensionLogic;
