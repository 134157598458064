import { type EventMapperSchema } from '@lightdash/common';
import { type EventReducerState } from '@providers/Events/types';
import { t as translate } from 'i18next';
import { useMemo } from 'react';
import { EventBuilderStep } from '../types';
import {
    convertKeysToSelectData,
    convertMapperSchemaToProperties,
} from '../utils';

export const useEventInitialData = (
    eventData: EventMapperSchema | undefined,
    isClone: boolean,
    isDuplicate: boolean,
): EventReducerState => {
    return useMemo(() => {
        const baseEventPayload = {
            source: eventData?.source || '',
            eventName: '',
            mapperSchema: {},
            label: '',
            sampleEventPayload: eventData?.sampleEventPayload || {},
            persistToWh: false,
            isInternal: false,
            tags: [],
        };

        const initialState = {
            eventPayload: { ...baseEventPayload },
            isCloneEvent: isClone,
            jsonPayloadKeys:
                convertKeysToSelectData(eventData?.sampleEventPayload || {}) ||
                [],
            sourceKeys: [],
            currentStep: EventBuilderStep.PAYLOAD,
            currentStepCallback: null,
            previousStepCallback: null,
            showFooterButtons: {
                next: true,
                disableNext: true,
                back: false,
            },
            initialEventPayload: { ...baseEventPayload },
            jsonString:
                Object.values(baseEventPayload.sampleEventPayload).length > 0
                    ? JSON.stringify(
                          baseEventPayload.sampleEventPayload,
                          null,
                          2,
                      )
                    : undefined,
        };
        if (!isClone && eventData) {
            const preSelectedProperties = convertMapperSchemaToProperties(
                eventData.mapperSchema,
            );
            const preSelectedPropertiesKeys = preSelectedProperties.map(
                (prop) => prop.key,
            );
            initialState.eventPayload = {
                ...baseEventPayload,
                eventName: eventData.eventName || '',
                mapperSchema: eventData.mapperSchema || {},
                label: eventData.label || '',
                persistToWh: eventData.persistToWh || false,
            };
            initialState.jsonPayloadKeys = [
                ...preSelectedProperties,
                ...initialState.jsonPayloadKeys.filter(
                    (propObj) =>
                        !preSelectedPropertiesKeys.includes(propObj.key),
                ),
            ];
            initialState.initialEventPayload = { ...initialState.eventPayload };
            initialState.eventPayload = {
                ...initialState.eventPayload,
                eventName: isDuplicate
                    ? translate('events_duplicate.initial_event_name', {
                          event_name: initialState.eventPayload.eventName,
                      })
                    : initialState.eventPayload.eventName,
                label: isDuplicate
                    ? translate('events_duplicate.initial_event_name', {
                          event_name: initialState.eventPayload.label,
                      })
                    : initialState.eventPayload.label,
            };
            initialState.initialEventPayload = initialState.eventPayload;
        }

        return initialState;
    }, [eventData, isClone, isDuplicate]);
};
