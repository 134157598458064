import { isCampaignEvent } from '@components/Audience/utils';
import Checkbox from '@components/common/Checkbox';
import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import FieldIcon from '@components/common/IconPack/FieldIcon';
import TableIcon from '@components/common/IconPack/TableIcon';
import TraitIcon from '@components/common/IconPack/TraitIcon';
import { TextWithTooltip } from '@components/common/TextWithTooltip';
import {
    isCustomDimension,
    isCustomSqlDimension,
    RelationTableType,
    type CommunicationChannel,
    type FilterableDimension,
} from '@lightdash/common';
import { Box, Flex, Group } from '@mantine/core';
import { CaretRight, Clock } from '@phosphor-icons/react';
import React, { useMemo } from 'react';
import {
    isCustomAttribute,
    renderTableIcon,
    type FieldListItemProps,
} from './utils';

export const FieldListBoxWrapper: React.FC<
    React.ComponentProps<typeof Box> & {
        checked: boolean;
        disableHover?: boolean;
        children: React.ReactNode;
        showHoverIcon?: boolean;
    }
> = ({
    checked,
    disableHover = false,
    children,
    showHoverIcon = true,
    ...props
}) => {
    return (
        <Box
            className={`h-full w-full flex items-center px-2 rounded-md justify-between ${
                checked
                    ? 'bg-blu-800/6'
                    : disableHover
                    ? ''
                    : 'hover:bg-gray-50'
            }`}
            sx={(_theme) => ({
                position: 'relative',
                '&:hover': {
                    "& input[type='checkbox']": {
                        border: '1px solid',
                        borderColor: 'rgba(1, 149, 255, 1)',
                        boxShadow: '0px 1px 2px 3px rgba(1, 149, 255, 0.15)',
                    },
                },
                '&:hover .icon': {
                    opacity: 1,
                },
            })}
            {...props}
        >
            {children}
            {showHoverIcon && (
                <Box
                    className="icon"
                    sx={{
                        position: 'absolute',
                        right: '0px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        opacity: 0,
                        transition: 'opacity 0.1s ease-in-out',
                    }}
                >
                    <Flex align="center" gap={4}>
                        <CaretRight />
                    </Flex>
                </Box>
            )}
        </Box>
    );
};

const FieldListItem: React.FC<FieldListItemProps> = ({
    item,
    checked,
    showCheckbox,
    disabled,
    disableHover,
    showFieldSource = true,
    showHoverIcon = true,
    tableLabelCustomClasses,
    showSourceTable = false,
}) => {
    const tableLabel = useMemo(() => {
        return (item as FilterableDimension)?.tableLabel ?? item?.table;
    }, [item]);

    const itemLabel = useMemo(() => {
        if (item?.tableType === RelationTableType.AUDIENCE) {
            return (item as FilterableDimension).tableLabel;
        }
        if (isCustomDimension(item)) {
            return item.name;
        }
        return item?.label;
    }, [item]);

    const fieldIcon = useMemo(() => {
        if (item?.tableType === RelationTableType.AUDIENCE) {
            return <TableIcon type={item?.tableType} size={14} />;
        }
        if (
            item?.tableType === RelationTableType.EVENT &&
            isCampaignEvent(item)
        ) {
            return (
                <ChannelIcon
                    channel={item.uniqueIdentifier as CommunicationChannel}
                    color="rgb(var(--color-gray-400))"
                    showChannelName={false}
                />
            );
        }
        if (isCustomSqlDimension(item)) {
            return <FieldIcon type={item?.dimensionType} size={14} />;
        }
        return <FieldIcon type={item?.type} size={14} />;
    }, [item]);
    const tableIcon = useMemo(() => {
        if (
            item?.tableType === RelationTableType.EVENT &&
            isCampaignEvent(item)
        ) {
            return (
                <Clock
                    weight="duotone"
                    color="rgb(var(--color-gray-400))"
                    size={14}
                />
            );
        }
        return renderTableIcon(item?.tableType, 'rgb(var(--color-gray-400)');
    }, [item]);

    const showSource = useMemo(() => {
        return (
            showFieldSource &&
            item?.tableType !== RelationTableType.AUDIENCE &&
            item?.tableType !== RelationTableType.PRIMARY &&
            item?.tableType !== RelationTableType.EVENT &&
            !isCustomSqlDimension(item)
        );
    }, [showFieldSource, item]);

    return (
        <FieldListBoxWrapper
            checked={checked}
            disableHover={disableHover}
            key={`${item?.table} ${item?.name}`}
            showHoverIcon={showHoverIcon}
        >
            <Box className={`flex !items-center gap-1`}>
                {showCheckbox && (
                    <Checkbox disabled={disabled} checked={checked} size="xs" />
                )}

                {isCustomAttribute(item) && <TraitIcon showFunction />}
                {fieldIcon}
                <Box className={`truncate`}>
                    {itemLabel && (
                        <TextWithTooltip
                            text={itemLabel}
                            className="!m-0  rounded text-sm text-gray-800 font-medium"
                        />
                    )}
                </Box>
                {(showSource || showSourceTable) && (
                    <Group className="gap-1 !items-center flex flex-nowrap text-xs">
                        <Box className="!p-0">
                            <Box className="flex items-center gap-0.5">
                                <Box className="flex items-center justify-center gap-10 rounded-md ">
                                    {tableIcon}
                                </Box>
                                <Box
                                    className={`font-medium max-w-[120px] truncate text-gray-400 ${tableLabelCustomClasses}`}
                                >
                                    {tableLabel && (
                                        <TextWithTooltip text={tableLabel} />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Group>
                )}
            </Box>
        </FieldListBoxWrapper>
    );
};

export default React.memo(FieldListItem);
