import AnalystChat from '@components/AnalystChat';
import { Box } from '@mantine/core';
import { NAVBAR_HEIGHT } from '@utils/constants';

const MainLayout = ({ children }: { children: React.ReactNode }) => {
    return (
        <Box
            className={`flex w-full`}
            style={{
                height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
            }}
        >
            <Box
                className={`bg-gray-100 w-full mx-2 rounded-t-lg overflow-y-scroll`}
            >
                {children}
            </Box>
            <AnalystChat />
        </Box>
    );
};

export default MainLayout;
