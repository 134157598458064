import {
    isField,
    type Audience,
    type CustomSqlDimension,
    type DashboardFilters,
    type FilterableItem,
    type FilterRule,
    type WeekDay,
} from '@lightdash/common';
import { type PopoverProps } from '@mantine/core';
import { useCallback, useMemo, type FC } from 'react';
import { v4 as uuid4 } from 'uuid';
import { convertAudienceToFilterableDimension } from '../utils';
import FiltersProviderContext from './context';
import { type FieldWithSuggestions } from './types';

type Props = {
    projectUuid?: string;
    fieldsMap?: Record<string, FieldWithSuggestions>;
    eventsMap?: FieldWithSuggestions[];
    eventTables?: string[];
    startOfWeek?: WeekDay;
    dashboardFilters?: DashboardFilters;
    popoverProps?: Omit<PopoverProps, 'children'>;
    audienceData?: Audience[];
    customDimensions?: CustomSqlDimension[];
    isLoading?: boolean;
    relatedOneToManyTables?: string[];
};

export const FiltersProvider: FC<React.PropsWithChildren<Props>> = ({
    projectUuid,
    fieldsMap = {},
    eventsMap = [],
    eventTables = [],
    audienceData = [],
    customDimensions = [],
    startOfWeek,
    dashboardFilters,
    popoverProps,
    children,
    isLoading = false,
    relatedOneToManyTables = [],
}) => {
    const getField = useCallback(
        (filterRule: FilterRule) => {
            if (fieldsMap) {
                return fieldsMap[filterRule.target.fieldId];
            }
        },
        [fieldsMap],
    );

    const getAutocompleteFilterGroup = useCallback(
        (filterId: string, item: FilterableItem) => {
            if (!dashboardFilters || !isField(item)) {
                return undefined;
            }
            return {
                id: uuid4(),
                and: dashboardFilters.dimensions.filter(
                    (dimensionFilterRule) => {
                        const isNotSelectedFilter =
                            dimensionFilterRule.id !== filterId;
                        const hasSameTable =
                            dimensionFilterRule.target.tableName === item.table;
                        return isNotSelectedFilter && hasSameTable;
                    },
                ),
            };
        },
        [dashboardFilters],
    );

    const audienceMap = useMemo(() => {
        if (!audienceData) return [];
        return convertAudienceToFilterableDimension(audienceData);
    }, [audienceData]);

    return (
        <FiltersProviderContext.Provider
            value={{
                projectUuid,
                fieldsMap,
                eventsMap,
                startOfWeek,
                getField,
                getAutocompleteFilterGroup,
                popoverProps,
                eventTables,
                audienceMap,
                isLoading,
                customDimensions,
                relatedOneToManyTables,
            }}
        >
            {children}
        </FiltersProviderContext.Provider>
    );
};
