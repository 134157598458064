import { CronInternalInputs } from '@components/Audience/Scheduler/ReactHookForm/CronInput';
import StepTitle from '@components/Campaigns/Builder/Steps/StepTitle';
import Checkbox from '@components/common/Checkbox';
import TimeInput from '@components/common/Inputs/TimeInput';
import useNotify from '@hooks/toaster/useNotify';
import { useActiveProjectUuid } from '@hooks/useActiveProject';
import { useLocale } from '@hooks/useLocale';
import { useProject } from '@hooks/useProject';
import useTimestamp from '@hooks/useTimestamp';
import {
    AudienceType,
    CampaignScheduleType,
    CampaignType,
    Timezones,
} from '@lightdash/common';
import { Box, Group, Input, Radio, Text } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import cronstrue from 'cronstrue';
import moment from 'moment';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Calendar, Clock } from 'react-feather';

type CampaignSchedulerProps = {
    showRadio: boolean;
};

const CampaignScheduler: React.FC<CampaignSchedulerProps> = ({ showRadio }) => {
    const { t } = useLocale();
    const ref = useRef<HTMLInputElement>(null);
    const { showToastError } = useNotify();
    const refEndTime = useRef<HTMLInputElement>(null);
    const { getTimestamp } = useTimestamp();

    const { activeProjectUuid } = useActiveProjectUuid();
    const { data: projectData } = useProject(activeProjectUuid);
    const projectTimezone = projectData?.timezone ?? Timezones.UTC;
    const offset = moment().tz(projectTimezone).format('Z');

    const { state, actions } = useCampaignContext((context) => context);
    const { campaignPayload } = state;
    const {
        setCampaignScheduleType,
        setSchedulerExecutionTime,
        setSchedulerEndTime,
        setCampaignRunType,
        setRecurringDetails,
        setCron,
    } = actions;

    useEffect(() => {
        if (
            !showRadio &&
            campaignPayload.schedule?.type === CampaignScheduleType.MANUAL
        ) {
            setCampaignScheduleType(CampaignScheduleType.DELAYED);
        }
    }, [campaignPayload.schedule?.type, setCampaignScheduleType, showRadio]);

    const [repeat, setRepeat] = useState(
        campaignPayload.schedule?.type === CampaignScheduleType.DELAYED &&
            campaignPayload.type === CampaignType.RECURRING,
    );
    const [until, setUntil] = useState(
        !!campaignPayload.schedule?.recurringDetails?.endTime,
    );

    const [cronData, setCronData] = useState(
        campaignPayload.schedule?.recurringDetails?.cron ?? '0 0 1 * *',
    );

    const convertToProjectTimezone = useCallback(
        (date: Date): Date => {
            const localTime = moment(date);
            const projectTime = moment.tz(
                localTime.format('YYYY-MM-DDTHH:mm:ss'),
                projectTimezone,
            );
            return new Date(projectTime.format());
        },
        [projectTimezone],
    );

    const minimumDateTime = useMemo(() => {
        if (campaignPayload.schedule?.executionTime) {
            if (typeof campaignPayload.schedule?.executionTime === 'string') {
                return new Date(campaignPayload.schedule?.executionTime);
            }
            return new Date(
                getTimestamp(campaignPayload.schedule.executionTime.getTime()),
            );
        }

        const date = new Date();
        date.setMinutes(date.getMinutes() + 15);
        if (campaignPayload.schedule?.type === CampaignScheduleType.DELAYED) {
            setSchedulerExecutionTime(new Date(getTimestamp(date.getTime())));
        }
        return new Date(getTimestamp(date.getTime()));
    }, [
        campaignPayload?.schedule?.executionTime,
        campaignPayload?.schedule?.type,
        getTimestamp,
        setSchedulerExecutionTime,
    ]);

    const minimumEndDateTime = useMemo(() => {
        if (campaignPayload.schedule?.recurringDetails?.endTime) {
            if (
                typeof campaignPayload.schedule?.recurringDetails?.endTime ===
                'string'
            ) {
                return new Date(
                    campaignPayload.schedule?.recurringDetails?.endTime,
                );
            }
            return new Date(
                getTimestamp(
                    campaignPayload.schedule?.recurringDetails?.endTime.getTime(),
                ),
            );
        }
        const date = new Date(minimumDateTime);
        date.setMinutes(date.getMinutes() + 15);
        return date;
    }, [
        campaignPayload.schedule?.recurringDetails?.endTime,
        minimumDateTime,
        getTimestamp,
    ]);

    const [scheduleTime, setScheduleTime] = useState<Date>(minimumDateTime);
    const [endTime, setEndTime] = useState<Date>(minimumEndDateTime);

    useEffect(() => {
        if (ref.current) {
            ref.current.value = `${scheduleTime
                .getHours()
                .toString()
                .padStart(2, '0')}:${scheduleTime
                .getMinutes()
                .toString()
                .padStart(2, '0')}`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (refEndTime.current) {
            refEndTime.current.value = `${endTime
                .getHours()
                .toString()
                .padStart(2, '0')}:${endTime
                .getMinutes()
                .toString()
                .padStart(2, '0')}`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRadioSelect = useCallback(
        (value: string) => {
            setCampaignScheduleType(value as CampaignScheduleType);
        },
        [setCampaignScheduleType],
    );

    const handleDateChange = useCallback(
        (value: Date) => {
            setScheduleTime(value);
            const datetime = convertToProjectTimezone(value);
            setSchedulerExecutionTime(datetime);
        },
        [setScheduleTime, setSchedulerExecutionTime, convertToProjectTimezone],
    );

    const handleTimeChange = useCallback(
        (value: string) => {
            const [hours, minutes] = value.split(':');
            const date = new Date(scheduleTime);
            date.setHours(parseInt(hours, 10));
            date.setMinutes(parseInt(minutes, 10));

            const currentTime = new Date();
            currentTime.setMinutes(currentTime.getMinutes() + 15);

            if (date < currentTime) {
                showToastError({
                    title: t('campaign_scheduler.time_error'),
                });
                return;
            }

            setScheduleTime(date);
            const datetime = convertToProjectTimezone(date);
            setSchedulerExecutionTime(datetime);
        },
        [
            scheduleTime,
            convertToProjectTimezone,
            setSchedulerExecutionTime,
            showToastError,
            t,
        ],
    );

    const handleEndDateChange = useCallback(
        (value: Date) => {
            setEndTime(value);
            const datetime = convertToProjectTimezone(value);
            setSchedulerEndTime(datetime);
        },
        [convertToProjectTimezone, setSchedulerEndTime],
    );

    const handleEndTimeChange = useCallback(
        (value: string) => {
            const [hours, minutes] = value.split(':');
            const date = new Date(endTime);
            date.setHours(parseInt(hours, 10));
            date.setMinutes(parseInt(minutes, 10));

            const scheduleTimeBuffer = new Date(
                scheduleTime.getTime() + 15 * 60000,
            );
            if (date < scheduleTimeBuffer) {
                showToastError({
                    title: t('campaign_scheduler.end_time_error'),
                });
                return;
            }

            setEndTime(date);
            const datetime = convertToProjectTimezone(date);
            setSchedulerEndTime(datetime);
        },
        [
            endTime,
            scheduleTime,
            convertToProjectTimezone,
            setSchedulerEndTime,
            showToastError,
            t,
        ],
    );

    const cronHelperText = useMemo(() => {
        const cronHumanString = cronstrue.toString(cronData, {
            verbose: true,
            throwExceptionOnParseError: false,
        });
        return cronHumanString;
    }, [cronData]);

    const renderCampaignScheduleTypeNote = useMemo(() => {
        if (campaignPayload.schedule?.type === CampaignScheduleType.MANUAL) {
            return t('campaign_scheduler.manual_note');
        }

        if (
            campaignPayload.schedule?.type === CampaignScheduleType.DELAYED &&
            campaignPayload.type === CampaignType.ONE_TIME
        ) {
            return t('campaign_scheduler.scheduled_note', {
                time: scheduleTime.toLocaleString(), //FIXME: change this to the readable string
            });
        }

        if (
            campaignPayload.schedule?.type === CampaignScheduleType.DELAYED &&
            campaignPayload.type === CampaignType.RECURRING
        ) {
            return `${t('Campaign will be triggered')} ${
                cronHelperText.charAt(0).toLowerCase() + cronHelperText.slice(1)
            }`;
        }

        return '';
    }, [
        campaignPayload.schedule?.type,
        campaignPayload.type,
        cronHelperText,
        scheduleTime,
        t,
    ]);

    const renderUntilSection = useMemo(
        () => (
            <Box className="mt-3">
                <Text className="text-sm text-gray-800 font-medium mb-1.5">
                    {t('campaign_scheduler.end_date_and_time')}
                </Text>
                <Group className="gap-3" align="center">
                    <DateInput
                        valueFormat="DD MMM YYYY"
                        placeholder="DD/MM/YYYY"
                        icon={
                            <Calendar
                                size={13}
                                strokeWidth={2}
                                color={'rgb(var(--color-gray-600))'}
                            />
                        }
                        minDate={scheduleTime}
                        onChange={handleEndDateChange}
                        value={endTime}
                        popoverProps={{
                            withinPortal: true,
                        }}
                    />
                    <TimeInput
                        ref={refEndTime}
                        icon={
                            <Clock
                                size={13}
                                strokeWidth={2}
                                color={'rgb(var(--color-gray-600))'}
                            />
                        }
                        onClick={() => refEndTime?.current?.showPicker()}
                        onChange={(event) =>
                            handleEndTimeChange(event.target.value)
                        }
                        className="w-[120px]"
                        value={`${endTime
                            .getHours()
                            .toString()
                            .padStart(2, '0')}:${endTime
                            .getMinutes()
                            .toString()
                            .padStart(2, '0')}`}
                    />
                    <Input.Label className="text-sm font-normal text-gray-600">
                        {`(GMT${offset})`}
                    </Input.Label>
                </Group>
            </Box>
        ),
        [
            t,
            scheduleTime,
            handleEndDateChange,
            endTime,
            offset,
            handleEndTimeChange,
        ],
    );

    const renderRepeatSection = useMemo(
        () => (
            <Box className="mt-3">
                <CronInternalInputs
                    disabled={false}
                    onChange={(value) => {
                        setCronData(value);
                        setCron(value);
                    }}
                    value={cronData}
                    name="cron"
                    disableHourly={false}
                />

                <Checkbox
                    className="mt-3"
                    label={t('campaign_scheduler.until')}
                    checked={until}
                    onChange={(event) => {
                        setUntil(event.currentTarget.checked);
                        setSchedulerEndTime(
                            event.currentTarget.checked ? endTime : null,
                        );
                    }}
                />
                {until && renderUntilSection}
            </Box>
        ),
        [
            cronData,
            t,
            until,
            renderUntilSection,
            setCron,
            setSchedulerEndTime,
            endTime,
        ],
    );

    const renderRadioGroup = useMemo(() => {
        if (!showRadio) return null;

        return (
            <Box className="my-3">
                <Radio.Group
                    name="campaign-trigger"
                    value={campaignPayload.schedule?.type}
                    onChange={handleRadioSelect}
                    className="flex flex-col gap-3"
                >
                    <Radio
                        value={CampaignScheduleType.MANUAL}
                        label={t('campaign_schedule.manual')}
                    />
                    {
                        <Radio
                            value={CampaignScheduleType.DELAYED}
                            label={t('campaign_schedule.delayed_label')}
                        />
                    }
                </Radio.Group>
            </Box>
        );
    }, [showRadio, campaignPayload.schedule?.type, handleRadioSelect, t]);

    return (
        <Box>
            <StepTitle
                title={t('campaign_scheduler.step_title')}
                customClassName="!text-sm"
            />
            {renderRadioGroup}

            {campaignPayload.schedule?.type ===
                CampaignScheduleType.DELAYED && (
                <Box>
                    <Box className="flex flex-col items-start pt-3">
                        <Text className="text-sm text-gray-800 font-medium mb-1.5">
                            {t('campaign_scheduler.start_date_and_time')}
                        </Text>
                        <Group className="gap-3" align="center">
                            <DateInput
                                valueFormat="DD MMM YYYY"
                                placeholder="DD/MM/YYYY"
                                icon={
                                    <Calendar
                                        size={13}
                                        strokeWidth={2}
                                        color={'rgb(var(--color-gray-600))'}
                                    />
                                }
                                minDate={new Date()}
                                onChange={handleDateChange}
                                value={scheduleTime}
                                popoverProps={{
                                    withinPortal: true,
                                }}
                            />
                            <TimeInput
                                ref={ref}
                                icon={
                                    <Clock
                                        size={13}
                                        strokeWidth={2}
                                        color={'rgb(var(--color-gray-600))'}
                                    />
                                }
                                onClick={() => ref?.current?.showPicker()}
                                onChange={(event) =>
                                    handleTimeChange(event.target.value)
                                }
                                className="w-[120px]"
                                value={`${scheduleTime
                                    .getHours()
                                    .toString()
                                    .padStart(2, '0')}:${scheduleTime
                                    .getMinutes()
                                    .toString()
                                    .padStart(2, '0')}`}
                            />
                            <Input.Label className="text-sm font-normal text-gray-600">
                                {`(GMT${offset})`}
                            </Input.Label>
                        </Group>

                        {campaignPayload.audienceType ===
                            AudienceType.WAREHOUSE && (
                            <Box className="mt-3">
                                <Checkbox
                                    label="Repeat"
                                    checked={repeat}
                                    onChange={(event) => {
                                        setRepeat(event.currentTarget.checked);
                                        setCampaignRunType(
                                            event.currentTarget.checked
                                                ? CampaignType.RECURRING
                                                : CampaignType.ONE_TIME,
                                        );
                                        setRecurringDetails(
                                            event.currentTarget.checked
                                                ? {
                                                      endTime: until
                                                          ? endTime
                                                          : null,
                                                      cron: cronData,
                                                  }
                                                : undefined,
                                        );
                                    }}
                                />
                                {repeat && renderRepeatSection}
                            </Box>
                        )}
                    </Box>
                </Box>
            )}

            <Box className="mt-3">
                <Text className="text-sm text-gray-600">
                    {renderCampaignScheduleTypeNote}
                </Text>
            </Box>
        </Box>
    );
};

export default React.memo(CampaignScheduler);
