import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import {
    JourneyEventGroupType,
    JourneyEventType,
    type ActionEventField,
    type JourneyEvent,
    type JourneyProperty,
    type PropertySelectAccumulator,
} from '@components/Journeys/Builder/JourneyFilters/types';

import {
    JourneyTableType,
    type JourneyDimension,
    type JourneyEventMapperSchema,
} from '@lightdash/common';
import { useEffect, useRef, useState } from 'react';

import { convertToFilterableField } from '@components/Journeys/Builder/JourneyFilters/utils';
import { useLocale } from '@hooks/useLocale';
import { CursorClick, Scan } from '@phosphor-icons/react';
import useCampaignContext from '@providers/Campaign/useCampaignContext';

export const useEventMapperProperties = (
    eventField: ActionEventField,
    eventPayload: {
        dimensions: Record<string, JourneyDimension>;
        eventSource: string;
        eventName: string;
    }[],
): PropertySelectListType<JourneyProperty>[] => {
    const eventRef = useRef<string | null>(null);
    const [properties, setProperties] = useState<
        PropertySelectListType<JourneyProperty>[]
    >([]);

    useEffect(() => {
        if (!eventField.eventSource || !eventField.eventName) return;
        if (
            eventRef.current ===
            `${eventField.eventSource}_${eventField.eventName}`
        ) {
            return;
        }
        eventRef.current = `${eventField.eventSource}_${eventField.eventName}`;
        const table = eventPayload
            ? Object.values(eventPayload).find(
                  (t) =>
                      t.eventSource === eventField.eventSource &&
                      t.eventName === eventField.eventName,
              )
            : null;

        if (!table) return;

        const key = JourneyTableType.EVENT;
        const groupLabel = JourneyTableType.EVENT;
        const groupIcon = <Scan color="rgb(var(--color-gray-800))" />;

        const dataFields: PropertySelectAccumulator<JourneyProperty> = {
            [key]: {
                groupKey: key,
                groupLabel: groupLabel,
                groupIcon: groupIcon,
                items: Object.values(table.dimensions).map((dimension) => ({
                    ...convertToFilterableField(dimension),
                })),
            },
        };

        setProperties([dataFields[key]]);
    }, [eventPayload, eventField.eventSource, eventField.eventName]);
    return properties;
};

interface useEventsProps {
    eventsList: JourneyEventMapperSchema[] | undefined;
}

export const useEventProperties = ({
    eventsList,
}: useEventsProps): PropertySelectListType<
    JourneyEvent | JourneyProperty,
    JourneyEventGroupType
>[] => {
    const { t } = useLocale();
    const { campaignPayload } = useCampaignContext((context) => context.state);
    const [properties, setProperties] = useState<
        PropertySelectListType<
            JourneyEvent | JourneyProperty,
            JourneyEventGroupType
        >[]
    >([]);

    useEffect(() => {
        let dataFields: PropertySelectAccumulator<
            JourneyEvent | JourneyProperty,
            JourneyEventGroupType
        > = {};

        if (eventsList) {
            const key = JourneyEventGroupType.ALL_EVENTS;
            const groupLabel = t(
                'campaign_builder.event_filter_group.all_events',
            );
            const groupIcon = (
                <CursorClick color="rgb(var(--color-gray-800))" />
            );

            if (dataFields[key] === undefined) {
                dataFields[key] = {
                    groupKey: key,
                    groupLabel: groupLabel,
                    groupIcon: groupIcon,
                    items: [],
                };
            }

            // Separate whitelisted and internal events
            const whitelistedEvents = eventsList.filter(
                (event) => !event.isInternal,
            );
            const internalEvents = eventsList.filter(
                (event) =>
                    event.isInternal &&
                    event.sourceLabel === campaignPayload.channel,
            );

            // Add whitelisted events first
            whitelistedEvents.forEach((event) => {
                dataFields[key].items.push({
                    ...event,
                    subGroupKey: JourneyEventType.WHITELISTED_EVENT,
                    groupKey: key,
                });
            });

            // Add internal events next
            internalEvents.forEach((event) => {
                dataFields[key].items.push({
                    ...event,
                    subGroupKey: JourneyEventType.INTERNAL_EVENT,
                    groupKey: key,
                });
            });
        }

        setProperties(Object.values(dataFields));
    }, [campaignPayload.channel, eventsList, t]);

    return properties;
};
