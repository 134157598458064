import TextArea from '@components/common/Inputs/TextArea';
import TextInput from '@components/common/Inputs/TextInput';
import Modal from '@components/common/modal/Modal';
import TagsMultiSelect from '@components/common/Tags/TagsMultiSelect';
import useNotify from '@hooks/toaster/useNotify';
import {
    useAudienceUpdation,
    useGenerateAudienceNameDescription,
} from '@hooks/useAudience';
import { useLocale } from '@hooks/useLocale';
import {
    AIMessageContext,
    QueryGenerationStrategy,
    type SqlAudienceDescriptionRequest,
    type VisualAudienceDescriptionRequest,
} from '@lightdash/common';
import { Button, Stack } from '@mantine/core';
import useAudienceContext from '@providers/Audience/useAudienceContext';
import { DefaultAudienceName } from '@providers/Audience/utils';
import useRelationContext from '@providers/Relation/useRelationContext';
import _ from 'lodash';
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    type FC,
} from 'react';
import { useParams } from 'react-router';
import { ButtonVariant } from '../../mantineTheme';

interface AudienceTitleModalProps {
    opened: boolean;
    close: () => void;
    isEditMode: boolean;
    onUpdate: () => void;
}
const AudienceTitleModal: FC<AudienceTitleModalProps> = ({
    opened,
    close,
    isEditMode,
    onUpdate,
}) => {
    const { t } = useLocale();
    const { setAudienceName, setAudienceDescription, setAudienceTags } =
        useAudienceContext((context) => context.actions);
    const { activeRelationUuid } = useRelationContext();
    const { audienceUuid } = useParams<{ audienceUuid: string }>();
    const { audiencePayload } = useAudienceContext((context) => context.state);
    const { showToastError } = useNotify();
    const { mutateAsync: mutateAsyncUpdate, isLoading: isUpdating } =
        useAudienceUpdation(true); // INFO: hide toast for now as we want to customise mesage here.

    const {
        data: nameDescription,
        mutateAsync: mutateAsyncNameDescription,
        isLoading: isLoadingNameDescription,
    } = useGenerateAudienceNameDescription();

    const audienceNameRef = useRef(audiencePayload.name);
    const audienceDescriptionRef = useRef(audiencePayload.description);

    const [name, setName] = useState<string>(audiencePayload?.name || '');
    const [description, setDescription] = useState<string>(
        audiencePayload?.description || '',
    );
    const [tagsAudience, setTagsAudience] = useState<string[]>(
        audiencePayload?.tags || [],
    );

    useEffect(() => {
        if (
            opened &&
            (!audiencePayload.name || !audiencePayload.description) &&
            !nameDescription
        ) {
            let payload = undefined;
            if (
                audiencePayload.generationStrategy ===
                    QueryGenerationStrategy.AUDIENCE_BUILDER &&
                audiencePayload.nestedMetricQuery
            ) {
                payload = {
                    context: AIMessageContext.VISUAL_AUDIENCE,
                    nestedMetricQuery: audiencePayload.nestedMetricQuery,
                } as VisualAudienceDescriptionRequest;
            }
            if (
                audiencePayload.generationStrategy ===
                    QueryGenerationStrategy.MANUAL &&
                audiencePayload.sqlQuery
            ) {
                payload = {
                    sqlQuery: audiencePayload.sqlQuery,
                    context: AIMessageContext.SQL_AUDIENCE,
                } as SqlAudienceDescriptionRequest;
            }
            if (payload && isEditMode) {
                void mutateAsyncNameDescription(payload).then((res) => {
                    if (
                        !audiencePayload.name ||
                        audiencePayload.name === DefaultAudienceName
                    ) {
                        setName(res.name);
                    }
                    if (!audiencePayload.description) {
                        setDescription(res.description);
                    }
                });
            }
        }
    }, [
        mutateAsyncNameDescription,
        audiencePayload,
        opened,
        setName,
        setDescription,
        nameDescription,
        isEditMode,
    ]);

    useEffect(() => {
        if (
            audienceNameRef.current !== audiencePayload.name ||
            audienceDescriptionRef.current !== audiencePayload.description
        ) {
            audienceNameRef.current = audiencePayload.name;
            audienceDescriptionRef.current = audiencePayload.description;
            onUpdate();
            close();
        }
    }, [
        name,
        description,
        audiencePayload.name,
        audiencePayload.description,
        onUpdate,
        close,
    ]);
    const handleClose = useCallback(() => {
        setName(audiencePayload.name || '');
        setDescription(audiencePayload.description || '');
        setTagsAudience(audiencePayload.tags || []);
        close();
    }, [audiencePayload, close, setName, setDescription, setTagsAudience]);
    const handleClick = useCallback(async () => {
        const trimmedName = name.trim();
        if (isEditMode) {
            setAudienceName(trimmedName);
            setAudienceDescription(description.trim());
            setAudienceTags(tagsAudience);
            return;
        }
        if (!audienceUuid) {
            return;
        }

        if (!trimmedName) {
            showToastError({
                title: t('audience_create.name_required_error'),
            });
            return;
        }
        await mutateAsyncUpdate({
            payload: {
                name: name.trim(),
                description: description.trim(),
                tags: tagsAudience,
            },
            relationUuid: activeRelationUuid,
            audienceId: audienceUuid,
        });
        setAudienceName(trimmedName);
        setAudienceDescription(description.trim());
        setAudienceTags(tagsAudience);
        handleClose();
    }, [
        description,
        name,
        setAudienceDescription,
        setAudienceName,
        setAudienceTags,
        tagsAudience,
        isEditMode,
        mutateAsyncUpdate,
        activeRelationUuid,
        audienceUuid,
        handleClose,
        t,
        showToastError,
    ]);

    const hasChanges = useMemo(() => {
        return (
            name.trim() !== audiencePayload.name ||
            description.trim() !== audiencePayload.description ||
            !_.isEqual(tagsAudience, audiencePayload.tags)
        );
    }, [name, description, tagsAudience, audiencePayload]);

    return (
        <Modal
            opened={opened}
            onClose={handleClose}
            keepMounted={true}
            title={t('audience_create.modal_title')}
            footerRightSection={
                <Button
                    onClick={handleClick}
                    variant={ButtonVariant.PRIMARY}
                    loading={isUpdating}
                    disabled={isUpdating || !hasChanges}
                >
                    {t('common.okay')}
                </Button>
            }
            size="lg"
        >
            <Stack spacing={12}>
                <TextInput
                    placeholder={t('audience_create.modal_name_placeholder')}
                    label={t('audience_create.name_label')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    isAiGeneratingData={isLoadingNameDescription}
                    aiGeneratedData={nameDescription?.name}
                    disabled={isUpdating}
                />
                <TextArea
                    placeholder={t(
                        'audience_create.modal_description_placeholder',
                    )}
                    label={t('audience_create.modal_description_label')}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    isAiGeneratingData={isLoadingNameDescription}
                    aiGeneratedData={nameDescription?.description}
                    disabled={isUpdating}
                />

                <TagsMultiSelect
                    tags={tagsAudience}
                    onChange={setTagsAudience}
                    isDisabled={isUpdating}
                />
            </Stack>
        </Modal>
    );
};

export default AudienceTitleModal;
