import DeactivateJourneyModal from '@components/Journeys/Settings/DeactivateJourneyModal';
import LaunchErrorModal from '@components/Journeys/Settings/ErrorModal';
import LaunchModal from '@components/Journeys/Settings/LaunchModal';
import { JourneyBuilderEnums } from '@components/Journeys/utils';
import useNotify from '@hooks/toaster/useNotify';
import { useUpdateJourney } from '@hooks/useJourney';
import { useLocale } from '@hooks/useLocale';
import useSearchParams from '@hooks/useSearchParams';
import { JourneyStatus, JourneyVersionStatus } from '@lightdash/common';
import { Box, Button, Group, Loader, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CopySimple, PauseCircle, Pencil } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../../mantineTheme';

interface ActionButtonsProps {}

const ActionButtons = ({}: ActionButtonsProps) => {
    const { t } = useLocale();
    const journeyTemplateId = useSearchParams(JourneyBuilderEnums.TEMPLATE_ID);

    const {
        journeyPayload,
        isLoading,
        journeyStatus,
        uuid,
        journeyVersions,
        selectedJourneyVersionId,
    } = useJourneyBuilderContext((context) => context.state);
    const { showToastSuccess, showToastError } = useNotify();
    const {
        mutateAsyncJourney,
        createDraftVersion,
        canSave,
        canLaunch,
        mutateActivateJourney,
    } = useJourneyBuilderContext((context) => context.actions);

    const [showLaunch, { open: openLaunch, close: closeLaunch }] =
        useDisclosure(false);
    const [showErrorModal, { open: openErrorModal, close: closeErrorModal }] =
        useDisclosure(false);

    const [
        showDeactivateModal,
        { open: openDeactivateModal, close: closeDeactivateModal },
    ] = useDisclosure(false);
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const navigate = useNavigate();

    const {
        mutateAsync: mutateAsyncUpdateJourney,
        isLoading: isUpdatingJourney,
    } = useUpdateJourney(uuid ?? '');

    const queryClient = useQueryClient();

    //Info: Only one draft version is allowed per journey. A draft is already created from an active journey if the journey is active.
    const containsDraftVersion = useMemo(() => {
        if (!journeyVersions) return false;
        return journeyVersions.some(
            (version) => version.status === JourneyVersionStatus.DRAFT,
        );
    }, [journeyVersions]);

    const containsActiveVersion = useMemo(() => {
        if (!journeyVersions) return false;
        return journeyVersions.some(
            (version) => version.status === JourneyVersionStatus.ACTIVE,
        );
    }, [journeyVersions]);

    const handleLaunch = useCallback(() => {
        if (!journeyPayload.name || journeyPayload.name.trim() === '') {
            showToastError({
                title: t('journey_builder.journey_name_empty_error'),
            });
            return;
        }
        if (!canSave() || !canLaunch()) {
            openErrorModal();
            return;
        }

        //Info: if there is an active version, then activate any other versions without asking for confirmation.
        if (containsActiveVersion) {
            void mutateActivateJourney({
                status: JourneyStatus.ACTIVE,
                ...journeyPayload,
            });
            return;
        }

        openLaunch();
    }, [
        journeyPayload,
        canSave,
        canLaunch,
        containsActiveVersion,
        openLaunch,
        showToastError,
        t,
        openErrorModal,
        mutateActivateJourney,
    ]);

    const handleSave = useCallback(async () => {
        if (!journeyPayload.name || journeyPayload.name.trim() === '') {
            showToastError({
                title: t('journey_builder.journey_name_empty_error'),
            });
            return;
        }

        await mutateAsyncJourney();
    }, [journeyPayload.name, mutateAsyncJourney, showToastError, t]);

    const handleDeactivate = useCallback(async () => {
        openDeactivateModal();
    }, [openDeactivateModal]);

    const handleClone = useCallback(() => {
        void navigate(
            `/projects/${projectUuid}/journeys/create?templateId=${uuid}`,
        );
        showToastSuccess({
            title: t('journeys_clone.toast.title'),
            subtitle: t('journeys_clone.toast.subtitle'),
        });
    }, [navigate, projectUuid, showToastSuccess, t, uuid]);

    const handleEdit = useCallback(() => {
        if (!journeyVersions) return;
        if (containsDraftVersion) {
            const draftVersionId = journeyVersions.find(
                (version) => version.status === JourneyVersionStatus.DRAFT,
            )?.id;
            void navigate(
                `/projects/${projectUuid}/journeys/${uuid}/edit?versionId=${draftVersionId}`,
            );
            return;
        }
        void createDraftVersion();
    }, [
        containsDraftVersion,
        journeyVersions,
        navigate,
        projectUuid,
        createDraftVersion,
        uuid,
    ]);

    const handleResetDraft = useCallback(async () => {
        if (!journeyPayload.name || journeyPayload.name.trim() === '') {
            showToastError({
                title: t('journey_builder.journey_name_empty_error'),
            });
            return;
        }
        const currentVersion = journeyVersions?.find(
            (version) => version.id === journeyPayload.currentVersionId,
        );
        if (!currentVersion) return;
        const currentVersionPayload = {
            journeyPayload: {
                ...journeyPayload,
                config: currentVersion.config,
                triggers: currentVersion.triggers,
                entryLogic: currentVersion.entryLogic,
            },
            query: `versionId=${selectedJourneyVersionId}`,
        };
        await mutateAsyncUpdateJourney(currentVersionPayload);
        await queryClient.invalidateQueries({
            queryKey: [QueryKeys.GET_JOURNEY_BY_ID, uuid],
        });
        showToastSuccess({
            title: t('journey_builder.journey_reset_draft_success'),
        });
    }, [
        journeyPayload,
        journeyVersions,
        selectedJourneyVersionId,
        mutateAsyncUpdateJourney,
        queryClient,
        uuid,
        showToastSuccess,
        t,
        showToastError,
    ]);

    const renderSaveButton = useMemo(
        () => (
            <Button
                variant={ButtonVariant.OUTLINED}
                onClick={handleSave}
                disabled={isLoading}
            >
                {isUpdatingJourney ? (
                    <Box className="flex items-center gap-2">
                        <Loader size={14} />
                        <Text>Saving journey...</Text>
                    </Box>
                ) : (
                    t('journey_header.save_draft')
                )}
            </Button>
        ),
        [handleSave, isLoading, isUpdatingJourney, t],
    );

    const renderLaunchButton = useMemo(
        () => (
            <Button
                onClick={handleLaunch}
                variant={ButtonVariant.PRIMARY}
                disabled={isLoading || isUpdatingJourney}
            >
                {t('journey_header.launch')}
            </Button>
        ),
        [handleLaunch, isLoading, isUpdatingJourney, t],
    );

    const renderCloneButton = useMemo(
        () => (
            <Button
                onClick={handleClone}
                variant={ButtonVariant.OUTLINED}
                leftIcon={
                    <CopySimple
                        size={14}
                        weight="duotone"
                        color="rgb(var(--color-gray-700))"
                    />
                }
                isDisabled={isLoading || isUpdatingJourney}
            >
                {t('journey_header.clone')}
            </Button>
        ),
        [handleClone, isLoading, isUpdatingJourney, t],
    );

    const renderResetDraftButton = useMemo(
        () => (
            <Button
                variant={ButtonVariant.OUTLINED}
                onClick={handleResetDraft}
                disabled={isLoading || isUpdatingJourney}
            >
                {t('journey_header.reset_draft')}
            </Button>
        ),
        [handleResetDraft, isLoading, isUpdatingJourney, t],
    );

    const renderEditButton = useMemo(
        () => (
            <Button
                onClick={handleEdit}
                variant={ButtonVariant.OUTLINED}
                leftIcon={<Pencil size={14} weight="duotone" />}
                disabled={isLoading || isUpdatingJourney}
            >
                {containsDraftVersion
                    ? t('journey_header.action_buttons_edit_draft_version')
                    : t('journey_header.action_buttons_edit')}
            </Button>
        ),
        [containsDraftVersion, handleEdit, t, isLoading, isUpdatingJourney],
    );

    const renderDeactivateButton = useMemo(
        () => (
            <Button
                className="text-halt-800"
                variant={ButtonVariant.OUTLINED}
                onClick={handleDeactivate}
                disabled={isLoading || isUpdatingJourney}
                leftIcon={
                    <PauseCircle
                        size={14}
                        weight="duotone"
                        color="rgb(var(--color-halt-800))"
                    />
                }
            >
                {t('journey_header.deactivate')}
            </Button>
        ),
        [handleDeactivate, isLoading, isUpdatingJourney, t],
    );

    const headerButtons = useMemo(() => {
        //Info: If the journey builder is in clone of another journey, then just show Save and Launch buttons.
        //Info: If the journey builder is in a create mode, no versions will be present, hence show Save and Launch buttons.
        if (journeyTemplateId || !journeyVersions) {
            return (
                <>
                    {renderSaveButton}
                    {renderLaunchButton}
                </>
            );
        }

        const selectedVersion = journeyVersions.find(
            (version) => version.id === selectedJourneyVersionId,
        );
        if (!selectedVersion) return renderCloneButton;

        //Info: If the selected version is the current version, then show the appropriate buttons based on the journey status.
        if (selectedVersion.id === journeyPayload.currentVersionId) {
            //Info: If the journey is sunset, then show the clone button.
            const endDate =
                journeyPayload.endDate && new Date(journeyPayload.endDate);
            const currentDate = new Date();
            if (endDate && endDate < currentDate) {
                return renderCloneButton;
            }
            switch (journeyStatus) {
                case JourneyStatus.DRAFT:
                    return (
                        <>
                            {renderCloneButton}
                            {renderSaveButton}
                            {renderLaunchButton}
                        </>
                    );
                case JourneyStatus.ACTIVATING:
                case JourneyStatus.ACTIVATION_FAILED:
                case JourneyStatus.CANCELED:
                    return renderCloneButton;
                case JourneyStatus.ACTIVE:
                    return (
                        <>
                            {renderCloneButton}
                            {renderEditButton}
                            {renderDeactivateButton}
                        </>
                    );
                case JourneyStatus.SCHEDULED:
                    return (
                        <>
                            {renderCloneButton}
                            {renderDeactivateButton}
                        </>
                    );
            }
        }

        const selectedVersionStatus = selectedVersion.status;
        switch (selectedVersionStatus) {
            case JourneyVersionStatus.DRAFT:
            case null:
                return (
                    <>
                        {containsActiveVersion && renderResetDraftButton}
                        {renderSaveButton}
                        {renderLaunchButton}
                    </>
                );
            case JourneyVersionStatus.ACTIVE:
                return null;
            default:
                return renderCloneButton;
        }
    }, [
        containsActiveVersion,
        journeyPayload.currentVersionId,
        journeyPayload.endDate,
        journeyStatus,
        journeyTemplateId,
        journeyVersions,
        renderCloneButton,
        renderDeactivateButton,
        renderEditButton,
        renderLaunchButton,
        renderResetDraftButton,
        renderSaveButton,
        selectedJourneyVersionId,
    ]);

    return (
        <>
            <Group className="gap-2">{headerButtons}</Group>
            <LaunchModal
                showLaunch={showLaunch}
                onClose={() => closeLaunch()}
            />
            <LaunchErrorModal
                showErrorModal={showErrorModal}
                onClose={() => closeErrorModal()}
            />
            <DeactivateJourneyModal
                uuid={uuid ?? ''}
                endDate={journeyPayload.endDate}
                journeyStatus={journeyStatus}
                showDeactivateModal={showDeactivateModal}
                onClose={() => closeDeactivateModal()}
            />
        </>
    );
};

export default React.memo(ActionButtons);
