import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Modal from '@components/common/modal/Modal';
import ButtonGroup from '@components/ProjectSettings/DeliveryControls/ButtonGroup';
import useNotify from '@hooks/toaster/useNotify';
import { useUpdateEvent } from '@hooks/useEvents';
import { CommonReservedTags, type EventMapperSchema } from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    Archive,
    ArrowCounterClockwise,
    CaretRight,
    CopySimple,
    PencilSimpleLine,
} from '@phosphor-icons/react';
import { ArrowBendDownLeft } from '@phosphor-icons/react/dist/ssr';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocale } from '../../../hooks/useLocale';
import { ButtonVariant } from '../../../mantineTheme';
import { createEventUpdatePayload } from '../utils';

interface EventsMenuItemProps {
    rowData: EventMapperSchema;
}

const EventsMenuItem: React.FC<EventsMenuItemProps> = ({ rowData }) => {
    const { t } = useLocale();
    const navigate = useNavigate();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [
        openedHideConfirmModal,
        { open: openHideConfirmModal, close: closeHideConfirmModal },
    ] = useDisclosure();
    const { showToastSuccess, showToastError } = useNotify();
    const { mutateAsync: mutateAsyncUpdate, isLoading: isUpdating } =
        useUpdateEvent({
            eventUuid: rowData.uuid,
            source: rowData.source,
            hideToast: true,
        });
    const handleView = useCallback(
        (uuid: string, source: string) => {
            void navigate(
                `/projects/${projectUuid}/events/${uuid}/${source}/view`,
            );
        },
        [navigate, projectUuid],
    );
    const handleDuplicate = useCallback(
        (uuid: string, source: string) => {
            void navigate(
                `/projects/${projectUuid}/events/create?templateId=${uuid}&source=${source}`,
            );
        },
        [navigate, projectUuid],
    );
    const handleEdit = useCallback(
        (uuid: string, source: string) => {
            void navigate(
                `/projects/${projectUuid}/events/${uuid}/${source}/edit`,
            );
        },
        [navigate, projectUuid],
    );
    const hideItems = useMemo(
        () => ({
            leftSection: (
                <Archive
                    weight="duotone"
                    color={'rgb(var(--color-halt-800))'}
                />
            ),
            children: <Text className="text-halt-800">{t('common.hide')}</Text>,
            customClass: 'hover:bg-halt-800/6',
            onClick: () => openHideConfirmModal(),
        }),
        [t, openHideConfirmModal],
    );
    const restoreItems = useMemo(
        () => ({
            leftSection: <ArrowCounterClockwise weight="duotone" />,
            children: t('common.restore'),
            onClick: async (event: EventMapperSchema) => {
                const filteredTags = event.tags?.filter(
                    (tag) => tag !== CommonReservedTags.HIDDEN,
                );
                await mutateAsyncUpdate(
                    createEventUpdatePayload(event, filteredTags || []),
                    {
                        onSuccess: () => {
                            showToastSuccess({
                                title: t('common.hidden_restore_success', {
                                    entityName: t('common.event'),
                                }),
                            });
                        },
                        onError: (error) => {
                            showToastError({
                                title: t('common.hidden_restore_error', {
                                    entityName: t('common.event'),
                                }),
                                subtitle: error.error.message,
                            });
                        },
                    },
                );
            },
        }),
        [t, mutateAsyncUpdate, showToastError, showToastSuccess],
    );

    const menuItems = useMemo(() => {
        const items: CustomMenuItemProps<EventMapperSchema>[] = [
            {
                leftSection: <ArrowBendDownLeft weight={'duotone'} />,
                children: t('event_manager.menu.view_details'),
                onClick: (values) => handleView(values.uuid, values.source),
            },
            {
                leftSection: <CopySimple weight="duotone" />,
                children: t('event_manager.menu.duplicate'),
                onClick: (values) => {
                    handleDuplicate(values.uuid, values.source);
                },
            },
            {
                leftSection: <PencilSimpleLine weight="duotone" />,
                children: t('event_manager.menu.edit_details'),
                onClick: (values) => {
                    handleEdit(values.uuid, values.source);
                },
            },
            ...(rowData.tags?.includes(CommonReservedTags.HIDDEN)
                ? [restoreItems]
                : [hideItems]),
        ];
        return items;
    }, [
        handleEdit,
        handleDuplicate,
        handleView,
        t,
        rowData.tags,
        hideItems,
        restoreItems,
    ]);

    const handlePrimaryButtonClick = useCallback(async () => {
        const updatedTags = [
            ...(rowData.tags || []),
            CommonReservedTags.HIDDEN,
        ];
        await mutateAsyncUpdate(
            createEventUpdatePayload(rowData, updatedTags),
            {
                onSuccess: () => {
                    showToastSuccess({
                        title: t('common.hidden_success', {
                            entityName: t('common.event'),
                        }),
                    });
                },
                onError: (error) => {
                    showToastError({
                        title: t('common.hidden_error', {
                            entityName: t('common.event'),
                        }),
                        subtitle: error.error.message,
                    });
                },
            },
        );
        closeHideConfirmModal();
    }, [
        rowData,
        mutateAsyncUpdate,
        showToastError,
        showToastSuccess,
        t,
        closeHideConfirmModal,
    ]);

    return (
        <>
            <CustomMenu<EventMapperSchema>
                menuItems={menuItems}
                data={rowData}
            />
            <Modal
                opened={openedHideConfirmModal}
                onClose={closeHideConfirmModal}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                title={
                    <Box>
                        <Text>
                            {t('events_manager.confirm_hide_modal_title')}
                        </Text>
                        <Text className="text-sm font-normal text-gray-600">
                            {rowData.label}
                        </Text>
                    </Box>
                }
                footerRightSection={
                    <ButtonGroup
                        primaryButtonLabel={t(
                            'audience_manager.confirm_hide_prim_button',
                        )}
                        secondaryButtonLabel={t('common.no')}
                        isUpdating={isUpdating}
                        handlePrimaryButtonClick={handlePrimaryButtonClick}
                        handleSecondaryButtonClick={closeHideConfirmModal}
                        primaryButtonVariant={ButtonVariant.FILLED_DESTRUCTIVE}
                        primaryButtonRightIcon={<CaretRight color="white" />}
                    />
                }
                closeButtonProps={{
                    className: 'absolute top-3 right-3',
                }}
            >
                <Flex direction={'column'} gap={12}>
                    <Text className="text-sm font-semibold text-gray-800">
                        {t('events_manager.confirm_hide_modal_header')}
                    </Text>
                    <Text className="text-sm text-gray-800">
                        {t('events_manager.confirm_hide_modal_description')}
                    </Text>
                </Flex>
            </Modal>
        </>
    );
};

export default EventsMenuItem;
