import Modal from '@components/common/modal/Modal';
import { toFieldsWithSuggestions } from '@components/Profiles/utils';
import {
    useAudiencePreviewById,
    useAudiencePreviewByPayload,
} from '@hooks/useAudiencePreview';
import { useLocale } from '@hooks/useLocale';
import {
    getItemId,
    JoinType,
    QueryGenerationStrategy,
    type ApiSqlQueryResults,
    type AudiencePreviewConfig,
    type AudiencePreviewPayload,
    type DimensionType,
} from '@lightdash/common';
import { Flex, Stack, Text } from '@mantine/core';
import { WifiSlash } from '@phosphor-icons/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import { modifyFieldsWithSelectionStatus } from '../Filters/FieldListItem/utils';
import { useFieldsWithSuggestions } from '../Filters/FiltersCard/useFieldsWithSuggestions';
import { getDimensionsAndMetrics } from '../utils';
import AudiencePreviewPropertySelect from './AudiencePreviewPropertySelect';
import AudiencePreviewTable from './AudiencePreviewTable';

interface AudiencePreviewModalProps {
    isEditMode: boolean;
    data: Record<string, unknown>[];
    fields: Record<string, { type: DimensionType }>;
    opened: boolean;
    close: () => void;
    getAudiencePayload?: AudiencePreviewPayload;
    initialColumns?: AudiencePreviewConfig;
    generationStrategy: string;
    onColumnChange?: (columns: string[]) => void;
    audienceId?: string;
    handlePreview: ((fields: string[]) => Promise<void>) | undefined;
    isValidQuery: boolean | undefined;
    showPropertySelect: boolean;
    footerRightSection: React.ReactNode;
    bottomSection: React.ReactNode;
    isApiCallTimeout: boolean | undefined;
}

const AudiencePreviewModal = ({
    isEditMode,
    opened,
    close,
    data,
    fields,
    initialColumns,
    generationStrategy,
    getAudiencePayload,
    onColumnChange,
    audienceId,
    handlePreview,
    isValidQuery,
    footerRightSection,
    showPropertySelect,
    bottomSection,
    isApiCallTimeout,
}: AudiencePreviewModalProps) => {
    const MODALHEIGHT = `${Object.keys(data).length > 0 ? '39.8rem' : ''}`;
    const { activeRelation } = useRelationContext();
    const { activeRelationUuid, getTableRelation } = useRelationContext();
    const [previewSuccess, setPreviewSuccess] = useState<boolean>(true);
    const { mutateAsync: mutateAsyncPreview, isLoading: isPreviewDataLoading } =
        useAudiencePreviewByPayload();
    const { mutateAsync: mutateAsyncView, isLoading: isViewLoading } =
        useAudiencePreviewById();

    const { t } = useLocale();

    const { audienceUuid: audienceUuiParam } = useParams<{
        audienceUuid: string;
    }>();
    const audienceUuid = audienceUuiParam ?? audienceId;

    const [audiencePreviewData, setAudiencePreviewData] =
        useState<ApiSqlQueryResults>({
            fields: {},
            rows: [],
        });

    const filteredRelation = getTableRelation([
        JoinType.one_one,
        JoinType.many_one,
    ]);
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });
    const filteredFields = toFieldsWithSuggestions(
        filteredRelation,
        fieldsWithSuggestions,
    );

    useEffect(() => {
        setAudiencePreviewData({ fields, rows: data });
    }, [data, fields]);
    const getAudiencePreviewData = useCallback(
        async (previewDataColumns: string[]) => {
            const { dimensions: dimensionsFieldIds, metrics: metricsFieldIds } =
                getDimensionsAndMetrics(
                    previewDataColumns,
                    fieldsWithSuggestions,
                );
            if (handlePreview != undefined && !isValidQuery) {
                await handlePreview(previewDataColumns);
            } else if (isEditMode && getAudiencePayload) {
                await mutateAsyncPreview(
                    {
                        relationUuid: activeRelationUuid,
                        data: getAudiencePayload,
                        dimensions: dimensionsFieldIds,
                        metrics: metricsFieldIds,
                    },
                    {
                        onSuccess: (response) => {
                            setPreviewSuccess(true);
                            const newColumns = Object.keys(response.fields);
                            if (onColumnChange) onColumnChange(newColumns);
                            setAudiencePreviewData(response);
                        },
                    },
                );
            } else if (audienceUuid) {
                await mutateAsyncView(
                    {
                        dimensions: dimensionsFieldIds,
                        metrics: metricsFieldIds,
                        audienceId: audienceUuid,
                    },
                    {
                        onSuccess: (response) => {
                            setPreviewSuccess(true);
                            const newColumns = Object.keys(response.fields);
                            if (onColumnChange) onColumnChange(newColumns);
                            setAudiencePreviewData(response);
                        },
                    },
                );
            }
        },
        [
            mutateAsyncView,
            mutateAsyncPreview,
            activeRelationUuid,
            audienceUuid,
            isEditMode,
            getAudiencePayload,
            onColumnChange,
            handlePreview,
            isValidQuery,
            fieldsWithSuggestions,
        ],
    );
    const previewRows = useMemo(() => {
        if (isApiCallTimeout) {
            return (
                <Stack>
                    <Flex
                        justify="center"
                        align="center"
                        h={350}
                        className="bg-gray-100 border border-gray-200 rounded-md"
                    >
                        {' '}
                        <Flex gap={4} align="center">
                            <WifiSlash weight="duotone" />
                            <Text className="text-sm font-normal text-gray-800 ">
                                {t('audience_preview_data.api_call_timeout')}
                            </Text>
                        </Flex>
                    </Flex>
                    {bottomSection}
                </Stack>
            );
        }
        if (
            Object.keys(audiencePreviewData.rows).length <= 0 ||
            !generationStrategy
        ) {
            return (
                <Text className="text-sm font-normal text-gray-800 ">
                    {t(
                        'audience_preview_data.there_are_no_users_in_this_audience_right_now',
                    )}
                </Text>
            );
        }
        return (
            <AudiencePreviewTable
                isFetching={isPreviewDataLoading || isViewLoading}
                data={audiencePreviewData.rows}
                fields={audiencePreviewData.fields}
                generationStrategy={generationStrategy}
                customClass={bottomSection ? ' h-[22rem] ' : ' h-[26rem] '}
            />
        );
    }, [
        isApiCallTimeout,
        audiencePreviewData,
        generationStrategy,
        isPreviewDataLoading,
        isViewLoading,
        t,
        bottomSection,
    ]);

    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                title={
                    <>
                        <h2>{t('preview_data.title')}</h2>
                        {audiencePreviewData.rows.length > 0 &&
                            initialColumns &&
                            generationStrategy &&
                            generationStrategy ===
                                QueryGenerationStrategy.AUDIENCE_BUILDER &&
                            showPropertySelect && (
                                <AudiencePreviewPropertySelect
                                    isLoading={
                                        isPreviewDataLoading || isViewLoading
                                    }
                                    fields={modifyFieldsWithSelectionStatus({
                                        fields: Object.values(filteredFields),
                                        selectedFieldIds: Object.keys(
                                            audiencePreviewData.fields,
                                        ),
                                        shouldDisableChecked: false,
                                    })}
                                    previewSuccess={previewSuccess}
                                    onSubmit={async (items) => {
                                        setPreviewSuccess(false);
                                        await getAudiencePreviewData(
                                            items.map((item) =>
                                                getItemId(item),
                                            ),
                                        );
                                    }}
                                />
                            )}
                    </>
                }
                centered
                size={'xl'}
                styles={(_params) => ({
                    header: {
                        borderBottom: 'unset !important',
                    },
                    content: {
                        height: MODALHEIGHT,
                    },
                    title: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    },
                    close: {
                        margin: '0px !important',
                    },
                    body: {
                        padding: '0px',
                    },
                })}
                footerRightSection={footerRightSection}
            >
                {previewRows}
                {bottomSection}
            </Modal>
        </>
    );
};

export default AudiencePreviewModal;
