import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { FieldType, type AdditionalMetric } from '@lightdash/common';
import useCustomMetricContext from '@providers/CustomMetric/useCustomMetricContext';
import { useEffect } from 'react';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import {
    getInsertCustomAttribute,
    type CreateCustomMetricProps,
} from '../utils';

const useCreateCustomMetric = ({
    openBaseTable,
    tableName,
    customMetricData,
    isDuplicate,
    isViewMode,
    onModalClose,
    customMetricId,
    disableTableChange,
    fieldType,
}: CreateCustomMetricProps) => {
    const { actions } = useCustomMetricContext((context) => context);
    const { activeRelation } = useRelationContext();
    const {
        openCustomMetricBaseTableModal,
        openCustomMetricManagerModal,
        selectTable,
        addBaseTableDetails,
        selectDimension,
        toogleIsDuplicated,
        toggleIsViewMode,
        setCustomMetricData,
        toggleIsBackButtonDisabled,
        setFieldType,
    } = actions;
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });

    useEffect(() => {
        if (openBaseTable && !isDuplicate) {
            if (fieldType) {
                setFieldType(fieldType);
            }
            openCustomMetricBaseTableModal();
            onModalClose?.();
        }
    }, [
        openBaseTable,
        openCustomMetricBaseTableModal,
        isDuplicate,
        onModalClose,
        setFieldType,
        fieldType,
    ]);

    useEffect(() => {
        if (tableName) {
            selectTable(tableName);
            addBaseTableDetails(tableName);
            openCustomMetricManagerModal();
            onModalClose?.();
        }
    }, [
        tableName,
        selectTable,
        addBaseTableDetails,
        openCustomMetricManagerModal,
        onModalClose,
        toggleIsBackButtonDisabled,
    ]);
    useEffect(() => {
        if (disableTableChange) {
            toggleIsBackButtonDisabled(true);
        }
    }, [disableTableChange, toggleIsBackButtonDisabled]);
    useEffect(() => {
        if (
            isDuplicate &&
            customMetricId &&
            customMetricData &&
            typeof customMetricData === 'object' &&
            !Array.isArray(customMetricData) &&
            customMetricId === customMetricData.uuid &&
            activeRelation
        ) {
            const metricSelectedTableName = (
                customMetricData.definition as AdditionalMetric
            )?.table;
            const metricSelectedDimension = (
                customMetricData.definition as AdditionalMetric
            )?.baseDimensionName;
            selectDimension(
                fieldsWithSuggestions[
                    `${metricSelectedTableName}_${metricSelectedDimension?.replaceAll(
                        '.',
                        '__',
                    )}`
                ],
            );
            if (customMetricData.type === FieldType.METRIC) {
                setFieldType(FieldType.METRIC);
            } else {
                setFieldType(FieldType.DIMENSION);
            }
            setCustomMetricData(getInsertCustomAttribute(customMetricData));
            selectTable(activeRelation.tables[customMetricData.srcTable]);
            toogleIsDuplicated(true);
            openCustomMetricManagerModal();
            onModalClose?.();
        }
    }, [
        activeRelation,
        selectDimension,
        selectTable,
        fieldsWithSuggestions,
        toogleIsDuplicated,
        openCustomMetricManagerModal,
        isDuplicate,
        customMetricData,
        setCustomMetricData,
        onModalClose,
        customMetricId,
        setFieldType,
    ]);
    useEffect(() => {
        if (
            isViewMode &&
            customMetricData &&
            typeof customMetricData === 'object' &&
            !Array.isArray(customMetricData) &&
            customMetricId === customMetricData.uuid &&
            activeRelation &&
            Object.keys(fieldsWithSuggestions).length > 0
        ) {
            selectTable(activeRelation?.tables[customMetricData.srcTable]);
            selectDimension(
                fieldsWithSuggestions[
                    `${
                        (customMetricData.definition as AdditionalMetric)?.table
                    }_${(
                        customMetricData.definition as AdditionalMetric
                    )?.baseDimensionName?.replaceAll('.', '__')}`
                ],
            );
            if (customMetricData.type === FieldType.METRIC) {
                setFieldType(FieldType.METRIC);
            } else {
                setFieldType(FieldType.DIMENSION);
            }
            setCustomMetricData(getInsertCustomAttribute(customMetricData));
            openCustomMetricManagerModal();
            toggleIsViewMode(true);
            toggleIsBackButtonDisabled(true);
            onModalClose?.();
        }
    }, [
        activeRelation,
        customMetricData,
        selectDimension,
        selectTable,
        openCustomMetricManagerModal,
        fieldsWithSuggestions,
        toggleIsViewMode,
        isViewMode,
        setCustomMetricData,
        customMetricId,
        toggleIsBackButtonDisabled,
        onModalClose,
        setFieldType,
    ]);

    return null;
};

export default useCreateCustomMetric;
