import { type AnyType } from './any';

export type AuditLogResponseDto = {
    status: string;
    results: AuditLogEntryFilter;
};

export enum Action {
    CREATED = 'Created',
    UPDATED = 'Updated',
    DELETED = 'Deleted',
    CREATED_RUN = 'Created run',
    UPDATED_RUN = 'Updated run',
    DELETED_RUN = 'Deleted run',
    CREATED_RELATION = 'Created relation',
    UPDATED_RELATION = 'Updated relation',
    DELETED_RELATION = 'Deleted relation',
    DELETED_TABLE = 'Deleted table',
    CREATED_RELATION_TABLE = 'Create relation table',
    UPDATED_RELATION_TABLE = 'Updated table',
    UPDATED_TEMPLATE_CONTENT = 'Updated template content',
    UPDATED_WEIGHTS = 'Updated weights',
}

export enum EntityType {
    Schema = 'Schema',
    Audience = 'Audiences',
    Journey = 'Journeys',
    Campaign = 'Campaigns',
    Template = 'Templates',
    Project = 'Project',
    CustomProperty = 'Custom property',
    Blob = 'Blob storage',
    DataConnection = 'Data connection',
    Integration = 'Integration',
    SubscriptionGroups = 'Subscription groups',
    DeliveryControls = 'Delivery controls',
}

export interface AuditLogEntry {
    timestamp: Date;
    userId: string;
    projectUuid: string;
    action: Action;
    entityType: EntityType;
    entityId: string;
    entityName: string;
    userName: string;
    metadata: AnyType;
}

export interface AuditLogEntryInDb {
    timestamp: Date;
    userId: string;
    projectUuid: string;
    action: Action;
    entityType: EntityType;
    entityId: string;
    entityName: string;
    metadata: AnyType;
}

export type AuditLogEntryFilter = {
    data: AuditLogEntry[] | null;
    paginate: {
        total?: number;
        lastPage?: number;
        currentPage: number;
        perPage: number;
        from: number;
        to: number;
    };
};
