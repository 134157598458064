import AudiencePreviewModal from '@components/Audience/AudiencePreview/AudiencePreviewData';
import { ItemComponent } from '@components/Campaigns/Builder/Steps/CampaignBuilderAudience/SelectCampaignAudience';
import SkeletonLoader from '@components/common/SkeletonLoader';
import UserCount from '@components/common/UserCount';
import { useAudiencePreviewById } from '@hooks/useAudiencePreview';
import { useLocale } from '@hooks/useLocale';
import { AudienceType, CampaignStatus, type Audience } from '@lightdash/common';
import { Box, Button, Card, Group, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    CaretUpDown,
    DownloadSimple,
    FileCsv,
    Table,
    UsersThree,
} from '@phosphor-icons/react';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import { CSV_AUDIENCE_PREVIEW_LIMIT } from '@utils/constants';
import Papa from 'papaparse';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { ButtonVariant } from '../../../../../mantineTheme';
import CsvAudiencePreviewModal from './CsvAudiencePreviewModal';

interface SelectedCampaignAudienceProps {
    withHeader?: boolean;
    audiences: Audience[] | null | undefined;
    isLoading: boolean;
}

const SelectedCampaignAudience: React.FC<SelectedCampaignAudienceProps> = ({
    withHeader = true,
    audiences,
    isLoading,
}) => {
    const location = useLocation();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const {
        campaignPayload,
        isEditMode,
        uuid: campaignId,
        status,
    } = useCampaignContext((context) => context.state);

    const {
        setCampaignAudience,
        setShowFooterButtons,
        setAudienceCsvUploadData,
        setAudienceCsvData,
    } = useCampaignContext((context) => context.actions);

    const [opened, { open, close }] = useDisclosure(false);

    const selectedCampaignAudience = useMemo(
        () =>
            campaignPayload.audienceType === AudienceType.WAREHOUSE
                ? audiences?.find(
                      (obj) => obj?.id === campaignPayload.audienceId,
                  )
                : undefined,
        [audiences, campaignPayload],
    );

    const isAudienceFileUploaded = useMemo(
        () =>
            campaignPayload.audienceType === AudienceType.CSV &&
            campaignPayload.csvUploadDetails?.uploadId,
        [campaignPayload],
    );

    const {
        mutateAsync: mutateAsyncView,
        isLoading: isFetchingPreview,
        data: previewAudienceData,
    } = useAudiencePreviewById();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (
            queryParams &&
            queryParams.get('templateId') &&
            campaignPayload.audienceType === AudienceType.CSV
        ) {
            setAudienceCsvUploadData(undefined);
        }
    }, [
        campaignPayload.audienceType,
        location.search,
        setAudienceCsvUploadData,
    ]);

    const handleSelectedAudiencePreview = useCallback(async () => {
        if (campaignPayload.audienceType === AudienceType.CSV) {
            if (campaignId) {
                // INFO - When campaign is already created
                const fileUrl = `/api/v1/projects/${projectUuid}/campaigns/${campaignId}/csv`;
                Papa.parse(fileUrl, {
                    header: true,
                    download: true,
                    preview: CSV_AUDIENCE_PREVIEW_LIMIT,
                    complete(results) {
                        if (results.data.length > 0) {
                            setAudienceCsvData(results.data);
                            open();
                        }
                    },
                });
            } else {
                // INFO - During campaign creation
                open();
            }
            return;
        }
        if (!selectedCampaignAudience) return;
        await mutateAsyncView({
            dimensions: [],
            metrics: [],
            audienceId: selectedCampaignAudience.id,
        });
        open();
    }, [
        campaignId,
        campaignPayload.audienceType,
        mutateAsyncView,
        open,
        projectUuid,
        selectedCampaignAudience,
        setAudienceCsvData,
    ]);

    const handleResetSelectedAudience = useCallback(() => {
        if (!isEditMode) return;
        setCampaignAudience(undefined);
        if (campaignPayload.audienceType === AudienceType.CSV) {
            setAudienceCsvUploadData(undefined);
        }
    }, [
        isEditMode,
        setCampaignAudience,
        campaignPayload.audienceType,
        setAudienceCsvUploadData,
    ]);

    useEffect(() => {
        setShowFooterButtons({
            next: Boolean(selectedCampaignAudience || isAudienceFileUploaded),
            back: true,
        });
    }, [
        setShowFooterButtons,
        selectedCampaignAudience,
        isAudienceFileUploaded,
    ]);

    const getPreviewInitialColumns = useCallback(() => {
        if (!previewAudienceData) return [];
        return Object.keys(previewAudienceData.fields);
    }, [previewAudienceData]);

    const handleDownload = useCallback(async () => {
        try {
            const response = await fetch(
                `/api/v1/projects/${projectUuid}/campaigns/${campaignId}/csv`,
            );
            const data = await response.json();
            const a = document.createElement('a');
            a.href = data;
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }, [projectUuid, campaignId]);

    if (isLoading) return <SkeletonLoader height={100} />;

    if (
        !isAudienceFileUploaded &&
        campaignPayload.audienceType === AudienceType.CSV
    )
        return null;

    if (
        !selectedCampaignAudience &&
        campaignPayload.audienceType === AudienceType.WAREHOUSE
    )
        return null;

    return (
        <>
            <Box>
                <Stack className="gap-2">
                    {withHeader && (
                        <Text className="text-sm font-medium text-gray-800">
                            {t(
                                'campaigns_builder_audience.selected_audience_label',
                            )}
                        </Text>
                    )}

                    <Card className="p-0 border-base shadow-card">
                        <Stack className="gap-0 divide-y">
                            <Group
                                position="apart"
                                className={`px-3 py-2 ${
                                    isEditMode ? 'cursor-pointer' : ''
                                } `}
                                onClick={handleResetSelectedAudience}
                            >
                                {selectedCampaignAudience ? (
                                    <ItemComponent
                                        icon={
                                            <UsersThree
                                                color={
                                                    'rgb(var(--color-pink-800)'
                                                }
                                            />
                                        }
                                        name={selectedCampaignAudience?.name}
                                        lastRun={selectedCampaignAudience?.lastRunAt?.toString()}
                                    />
                                ) : (
                                    <ItemComponent
                                        icon={
                                            <FileCsv
                                                size={14}
                                                color={
                                                    'rgb(var(--color-gray-700)'
                                                }
                                            />
                                        }
                                        name={
                                            campaignPayload.audienceType ===
                                                AudienceType.CSV &&
                                            campaignPayload.csvUploadDetails
                                                ? campaignPayload
                                                      .csvUploadDetails.fileName
                                                : ''
                                        }
                                        lastRun=""
                                    />
                                )}

                                {isEditMode && <CaretUpDown weight="regular" />}
                            </Group>
                            <Group
                                className="w-full px-3 py-2 bg-shade-2"
                                position="apart"
                            >
                                <Stack>
                                    <UserCount
                                        count={
                                            campaignPayload.audienceType ===
                                                AudienceType.CSV &&
                                            campaignPayload.csvUploadDetails
                                                ? campaignPayload
                                                      .csvUploadDetails
                                                      .audienceCount
                                                : selectedCampaignAudience?.totalCount
                                        }
                                        formatValue={false}
                                        withRightSection={true}
                                    />
                                </Stack>
                                <Group>
                                    {campaignPayload.audienceType ===
                                        AudienceType.CSV &&
                                    status !== CampaignStatus.DRAFT &&
                                    campaignId ? (
                                        <Button
                                            variant={ButtonVariant.OUTLINED}
                                            leftIcon={
                                                <DownloadSimple
                                                    color="rgba(var(--color-gray-700))"
                                                    weight="regular"
                                                    size={14}
                                                />
                                            }
                                            onClick={handleDownload}
                                            loading={isFetchingPreview}
                                        >
                                            {t('common.download_file')}
                                        </Button>
                                    ) : null}
                                    {campaignPayload.audienceType ===
                                        AudienceType.WAREHOUSE && (
                                        <Button
                                            loading={isFetchingPreview}
                                            variant={ButtonVariant.OUTLINED}
                                            leftIcon={<Table />}
                                            onClick={(
                                                e: React.MouseEvent<HTMLButtonElement>,
                                            ) => {
                                                e.stopPropagation();
                                                void handleSelectedAudiencePreview();
                                            }}
                                        >
                                            {t('audience.preview.title')}
                                        </Button>
                                    )}
                                </Group>
                            </Group>
                        </Stack>
                    </Card>
                </Stack>
            </Box>
            {campaignPayload.audienceType === AudienceType.CSV && (
                <CsvAudiencePreviewModal
                    open={opened}
                    onClose={close}
                    fileName={undefined}
                    count={undefined}
                />
            )}
            {previewAudienceData &&
                !isFetchingPreview &&
                selectedCampaignAudience && (
                    <AudiencePreviewModal
                        handlePreview={undefined}
                        isValidQuery={undefined}
                        footerRightSection={
                            selectedCampaignAudience?.totalCount && (
                                <Button
                                    variant={ButtonVariant.OUTLINED}
                                    onClick={() => close()}
                                >
                                    {t('password_modal.secondary_btn_text')}
                                </Button>
                            )
                        }
                        showPropertySelect={true}
                        bottomSection={null}
                        isEditMode={true}
                        opened={opened}
                        close={close}
                        data={previewAudienceData.rows}
                        fields={previewAudienceData.fields}
                        generationStrategy={
                            selectedCampaignAudience?.generationStrategy
                        }
                        initialColumns={{
                            previewFields: getPreviewInitialColumns(),
                        }}
                        audienceId={selectedCampaignAudience.id}
                        isApiCallTimeout={undefined}
                    />
                )}
        </>
    );
};

export default React.memo(SelectedCampaignAudience);
