import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { addFieldIdToMetricFilterRule } from '@components/Explorer/CustomMetricModal/utils';
import {
    getItemId,
    RelationTableType,
    type AdditionalMetric,
    type FilterableField,
    type FilterRule,
} from '@lightdash/common';
import { Box, Flex, Group, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useCallback, useMemo } from 'react';
import {
    type FieldValueProperty,
    type FieldWithSuggestions,
} from '../../FiltersProvider/types';
import { isFilterRuleEventNameMetric } from '../../utils';
import { SetTimeWindowButton } from '../DerivedMetric/SetTimeWIndowButton';
import FilterRuleForm from '../FilterRuleForm';
import { DerivedMetricPropertySelect } from './DerivedMetricPropertySelect';
import {
    addFieldRuleToDerivedMetric,
    getMatchedAdditionalMetric,
} from './utils';

interface DerivedMetricChildFiltersProps {
    additionalMetrics: AdditionalMetric[];
    activeField: FilterableField | undefined;
    setAdditionalMetrics: (
        newAdditionalMetrics: AdditionalMetric[],
        isEditMode: boolean,
        groupIndex: number,
    ) => void;
    tableFields: FilterableField[];
    groupIndex: number;
    filterRule: FilterRule;
    isEditMode: boolean;
    dynamicFieldValues:
        | PropertySelectListType<FieldValueProperty>[]
        | undefined;
}
const DerivedMetricChildFilters: React.FC<DerivedMetricChildFiltersProps> = ({
    additionalMetrics,
    activeField,
    setAdditionalMetrics,
    tableFields,
    groupIndex,
    filterRule,
    isEditMode,
    dynamicFieldValues,
}) => {
    const { activeRelation } = useRelationContext();
    const [opened, { open, close }] = useDisclosure(false);
    let renderedGroupCount = 0;
    const matchedAdditionalMetric = useMemo(
        () => getMatchedAdditionalMetric(additionalMetrics, activeField),
        [additionalMetrics, activeField],
    );
    const customMetricFiltersWithIds = matchedAdditionalMetric?.filters?.map(
        (rule) => addFieldIdToMetricFilterRule(rule),
    );
    const isEventNameMetric = useMemo(() => {
        if (!filterRule || !additionalMetrics || !activeRelation) return false;
        return isFilterRuleEventNameMetric({
            filterRule,
            additionalMetrics,
            activeRelation,
        });
    }, [filterRule, additionalMetrics, activeRelation]);
    const handleAdditionalMetricFilterChange = useCallback(
        (metricFilterIndex: number, newFilterRule: FilterRule) => {
            const newAdditionalMetrics = additionalMetrics.map((metric) => {
                if (
                    metric.name === activeField?.name &&
                    metric.table === activeField?.table
                ) {
                    const metricFilters = metric.filters?.map(
                        (filter, filterIndex) => {
                            if (metricFilterIndex === filterIndex) {
                                const tableField = tableFields.find(
                                    (field) =>
                                        getItemId(field) ===
                                        newFilterRule.target.fieldId,
                                );
                                return {
                                    ...newFilterRule,
                                    target: {
                                        ...newFilterRule.target,
                                        fieldRef: `${tableField?.table}.${tableField?.name}`,
                                    },
                                };
                            }
                            return filter;
                        },
                    );
                    return {
                        ...metric,
                        filters: metricFilters,
                    };
                }
                return metric;
            });
            setAdditionalMetrics(newAdditionalMetrics, false, groupIndex);
        },
        [
            activeField?.name,
            activeField?.table,
            additionalMetrics,
            groupIndex,
            setAdditionalMetrics,
            tableFields,
        ],
    );
    const shouldSkipFilterRendering = useCallback(
        (filter: FilterRule): boolean => {
            if (
                isEventNameMetric &&
                activeField?.table &&
                activeRelation?.tables &&
                customMetricFiltersWithIds
            ) {
                //Info: When an event name is selected, we create an additional metric on the fly with the eventName column as the target field and the selected event as the value
                // We need to determine if the event name metric filter rule should be skipped while rendering in the UI
                const matchedTable = activeRelation.tables[activeField.table];
                if (!matchedTable) return false;
                if (matchedTable.type !== RelationTableType.EVENT) return false;
                const eventNameColumnId = matchedTable.eventNameColumn;
                if (!eventNameColumnId) return false;
                const eventNameField =
                    matchedTable.dimensions[eventNameColumnId];
                if (!eventNameField) return false;
                return filter.target.fieldId === getItemId(eventNameField);
            }
            return false;
        },
        [
            activeField,
            activeRelation,
            customMetricFiltersWithIds,
            isEventNameMetric,
        ],
    );
    const handleAdditionalMetricFilterDelete = useCallback(
        (metricIndex: number) => {
            const newAdditionalMetrics = additionalMetrics.map((metric) => {
                if (
                    metric.name === activeField?.name &&
                    metric.table === activeField?.table
                ) {
                    const metricFilters = metric.filters?.filter(
                        (_filter, filterIndex) => {
                            if (metricIndex === filterIndex) {
                                return false;
                            }
                            return true;
                        },
                    );
                    return {
                        ...metric,
                        filters: metricFilters,
                    };
                }
                return metric;
            });
            setAdditionalMetrics(newAdditionalMetrics, false, groupIndex);
        },
        [
            activeField?.name,
            activeField?.table,
            additionalMetrics,
            groupIndex,
            setAdditionalMetrics,
        ],
    );
    const handleSubmitFieldRule = useCallback(
        (items: FieldWithSuggestions[]) => {
            if (!items[0]) return;
            const newAdditionalMetrics = addFieldRuleToDerivedMetric({
                field: items[0],
                additionalMetrics,
                activeField,
            });
            setAdditionalMetrics(newAdditionalMetrics ?? [], false, groupIndex);
            close();
        },
        [
            activeField,
            additionalMetrics,
            groupIndex,
            setAdditionalMetrics,
            close,
        ],
    );
    const getTimeWindowField = useCallback(() => {
        if (!activeField?.table || !activeRelation?.tables) return;
        const eventTable = activeRelation.tables[activeField.table];
        if (eventTable.type !== RelationTableType.EVENT) return;
        const timeWindowColumn = eventTable?.eventTimestampColumn;
        const timeWindowField =
            timeWindowColumn && eventTable?.dimensions[timeWindowColumn];
        return timeWindowField;
    }, [activeField?.table, activeRelation?.tables]);
    const handleAddTimeWindowFilter = useCallback(() => {
        try {
            // Early return with all required checks in one line
            const timeWindowField = getTimeWindowField();

            if (timeWindowField) {
                const newAdditionalMetrics = addFieldRuleToDerivedMetric({
                    field: timeWindowField,
                    additionalMetrics,
                    activeField,
                });
                setAdditionalMetrics(
                    newAdditionalMetrics ?? [],
                    false,
                    groupIndex,
                );
            }
        } catch {}
    }, [
        getTimeWindowField,
        activeField,
        additionalMetrics,
        groupIndex,
        setAdditionalMetrics,
    ]);
    const showTimeWindowFilter = useMemo(() => {
        const filtersInGroup = matchedAdditionalMetric?.filters;
        const timeWindowField = getTimeWindowField();

        // Early return if required data is missing
        if (!filtersInGroup || !timeWindowField || !additionalMetrics)
            return false;

        return !filtersInGroup.find(
            (filter) =>
                filter.target.fieldRef.split('.')[1] === timeWindowField.name,
        );
    }, [getTimeWindowField, matchedAdditionalMetric, additionalMetrics]);
    return (
        <Stack className="gap-2 pl-4 mb-4 ml-4 border-l-4 border-shade-4">
            {customMetricFiltersWithIds?.map((rule, index) => {
                if (shouldSkipFilterRendering(rule)) return null;
                renderedGroupCount++;
                return (
                    <Group key={rule.id} className="flex-nowrap">
                        <Box className="w-12 text-sm text-gray-600 text-end">
                            {Boolean(renderedGroupCount === 1)
                                ? 'Where'
                                : 'and'}
                        </Box>
                        <FilterRuleForm
                            filterRule={rule}
                            fields={tableFields}
                            isEditMode={isEditMode}
                            onChange={(value) =>
                                handleAdditionalMetricFilterChange(
                                    index,
                                    value as FilterRule,
                                )
                            }
                            onDelete={() =>
                                handleAdditionalMetricFilterDelete(index)
                            }
                            filters={{}}
                            showFieldSource={false}
                            setFilters={() => {}}
                            groupIndex={groupIndex}
                            additionalMetrics={additionalMetrics}
                            dynamicFieldValues={dynamicFieldValues}
                        />
                    </Group>
                );
            })}

            {isEditMode && (
                <Flex gap={8} align="center">
                    <DerivedMetricPropertySelect
                        tableFields={tableFields}
                        onSubmit={handleSubmitFieldRule}
                        opened={opened}
                        close={close}
                        open={open}
                    />
                    {showTimeWindowFilter && (
                        <SetTimeWindowButton
                            onClick={handleAddTimeWindowFilter}
                        />
                    )}
                </Flex>
            )}
        </Stack>
    );
};

export default DerivedMetricChildFilters;
