import { JourneyTriggerType } from '@lightdash/common';
import { Button } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { useCallback } from 'react';
import { Handle, Position, type NodeProps } from 'reactflow';
import { ButtonVariant } from '../../../../../mantineTheme';
import { ControlPanel, type JourneyNodeGhostData } from '../../types';
import { updatePlaceholdersToGhostNodes } from '../../utils';

const GhostNode: React.FC<NodeProps<JourneyNodeGhostData>> = ({ data }) => {
    const { nodeId } = data;
    const { nodes, edges, journeyPayload, controlPanel } =
        useJourneyBuilderContext((context) => context.state);
    const {
        openControlPanel,
        setNodes,
        removePlaceholderNodes,
        callGenerateNodeDescription,
    } = useJourneyBuilderContext((context) => context.actions);

    const handleChangeButtonClick = useCallback(() => {
        if (!nodeId) return;

        //TODO: This is a temporary solution to generate the node description when the ghost node is clicked.
        //TODO: We can modularise this approach as part of swap revamp.
        const { isOpen } = controlPanel;
        let currentNodeId: string | undefined;
        if (isOpen) {
            currentNodeId =
                controlPanel.type === ControlPanel.BLOCK_CONFIG
                    ? controlPanel.nodeId
                    : controlPanel.type === ControlPanel.TRIGGER_CONFIG
                    ? JourneyTriggerType.ENTRY
                    : undefined;
            if (currentNodeId) {
                callGenerateNodeDescription(currentNodeId);
            }
        }

        removePlaceholderNodes();
        setNodes(
            updatePlaceholdersToGhostNodes({
                nodes,
                edges,
                journeyPayload,
                targetNodeId: nodeId,
            }),
        );
        openControlPanel({
            type: ControlPanel.BLOCKS_LIST,
            reactFlowNodeId: nodeId,
        });
    }, [
        nodeId,
        controlPanel,
        removePlaceholderNodes,
        setNodes,
        nodes,
        edges,
        journeyPayload,
        openControlPanel,
        callGenerateNodeDescription,
    ]);
    return (
        <Button
            variant={ButtonVariant.SUBTLE}
            onClick={handleChangeButtonClick}
        >
            <PlusCircle
                color={'rgb(var(--color-blu-800))'}
                className="w-4 h-4"
            />
            <Handle
                type="source"
                position={Position.Bottom}
                isConnectable={false}
                className="w-0 h-0 !bg-transparent !border-none"
            />
            <Handle
                type="target"
                position={Position.Top}
                isConnectable={false}
                className="w-0 h-0 !bg-transparent !border-none"
            />
        </Button>
    );
};

export default GhostNode;
