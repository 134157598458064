import {
    JourneyEdgeEnum,
    JourneyNodeEnum,
} from '@components/Journeys/Builder/types';
import { getEdgeId } from '@components/Journeys/Builder/utils';
import { useLocale } from '@hooks/useLocale';
import {
    ActionType,
    BranchConcurrencyTypes,
    BranchConditionalTypes,
    type Branch,
    type FilterRule,
    type JourneyFilterRule,
} from '@lightdash/common';
import { Button, Flex, Text } from '@mantine/core';
import { ArrowsSplit, PlusCircle } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { useCallback, useMemo } from 'react';
import { ButtonVariant } from '../../../../../../../mantineTheme';
import {
    createJourneyFilterGroupFromFilterableField,
    getBranchesInSplitBlock,
    getFilterRuleFromConditionsInSplit,
    updateFilterRuleInJourneyFilterConfig,
} from '../utils';
import EachSplitPath from './EachSplitPath';

interface SplitPathProps {
    nodeId: string;
}

const SplitPath: React.FC<SplitPathProps> = ({ nodeId }) => {
    const { t } = useLocale();
    const {
        addEdgeWithGhostNode,
        updateBranchConfig,
        deleteAllChildBranches,
        deleteNode,
        updateBranchingEdgeLabel,
        createEveryOneElsePath,
    } = useJourneyBuilderContext((context) => context.actions);
    const { journeyPayload, splitActiveFields, edges, blocksList, nodes } =
        useJourneyBuilderContext((context) => context.state);
    const splitBlockId = useMemo(() => {
        return blocksList?.find((b) =>
            b.actions.some((a) => a.actionType === ActionType.SPLIT),
        )?.id;
    }, [blocksList]);
    const branchConfig = useMemo(() => {
        return journeyPayload.config?.nodes.find((node) => node.id === nodeId)
            ?.branchConfig;
    }, [journeyPayload.config, nodeId]);

    const handleChange = useCallback(
        (filterRule: JourneyFilterRule | FilterRule, destination: string) => {
            updateBranchConfig(nodeId, {
                type: branchConfig?.type ?? BranchConditionalTypes.IFIF,
                children: {
                    type:
                        branchConfig?.children.type ??
                        BranchConcurrencyTypes.PARALLEL,
                    branches:
                        branchConfig?.children?.branches?.map(
                            (branch: Branch) => {
                                if (
                                    branch.conditions &&
                                    branch.destination === destination
                                ) {
                                    return {
                                        ...branch,
                                        conditions:
                                            updateFilterRuleInJourneyFilterConfig(
                                                branch.conditions,
                                                filterRule,
                                                branch.conditions.journeyFilters
                                                    ? true
                                                    : false,
                                            ),
                                    };
                                }
                                return branch;
                            },
                        ) ?? [],
                },
            });
        },
        [branchConfig, nodeId, updateBranchConfig],
    );

    const handleDelete = useCallback(
        (destination: string) => {
            deleteAllChildBranches(destination);
            deleteNode(destination);
            const parentNodeId = edges.find(
                (eachEdge) => eachEdge.target === destination,
            )?.source;
            const branchesInSplitBlock = getBranchesInSplitBlock({
                edges,
                nodes,
                parentNodeId,
                includeEveryOneElse: false,
            });
            const eachBranches = branchesInSplitBlock.filter(
                (branch) => branch?.id !== destination,
            );
            eachBranches.map((branch, index) => {
                updateBranchingEdgeLabel(
                    getEdgeId(nodeId, branch?.id ?? ''),
                    `${index + 1} ${t('common.path', {
                        index: index + 1,
                    })}`,
                );
            });

            const everyoneElseNodeId = edges.find(
                (eachEdge) =>
                    eachEdge.source === parentNodeId &&
                    eachEdge.type === JourneyEdgeEnum.DEFAULT,
            )?.target;
            if (!everyoneElseNodeId) return;
            if (
                journeyPayload.config?.nodes.find(
                    (node) => node.id === everyoneElseNodeId,
                )
            ) {
                updateBranchingEdgeLabel(
                    getEdgeId(nodeId, everyoneElseNodeId),
                    `${eachBranches.length === 0 ? 1 : eachBranches.length} ${t(
                        'journey_builder.split_every_one_else_label',
                    )}`,
                );
            } else {
                updateBranchingEdgeLabel(
                    getEdgeId(nodeId, everyoneElseNodeId),
                    `${
                        eachBranches.length === 0 ? 1 : eachBranches.length + 1
                    } ${t('journey_builder.split_every_one_else_label')}`,
                );
            }
        },
        [
            deleteAllChildBranches,
            deleteNode,
            t,
            updateBranchingEdgeLabel,
            nodeId,
            edges,
            journeyPayload.config?.nodes,
            nodes,
        ],
    );
    const branchesInSplitBlock = useMemo(() => {
        return getBranchesInSplitBlock({
            edges,
            nodes,
            parentNodeId: nodeId,
            includeEveryOneElse: true,
        });
    }, [edges, nodes, nodeId]);
    const handleAddPath = useCallback(() => {
        if (branchesInSplitBlock.length === 0) {
            createEveryOneElsePath(nodeId, splitBlockId ?? '');
        }
        addEdgeWithGhostNode(nodeId, undefined, undefined);
    }, [
        addEdgeWithGhostNode,
        nodeId,
        splitBlockId,
        createEveryOneElsePath,
        branchesInSplitBlock,
    ]);
    const branches = useMemo(() => {
        // Extract IDs of child nodes that are not 'EVERY_ONE_ELSE'
        const nonEveryOneElseBranches = branchesInSplitBlock
            .filter(
                (node) =>
                    node && node.data.type !== JourneyNodeEnum.EVERY_ONE_ELSE,
            )
            .map((node) => node?.id)
            .filter((id) => id !== undefined);

        // Map these IDs to branch configurations or create new configurations
        return nonEveryOneElseBranches.map((id) => {
            const existingBranch = branchConfig?.children.branches.find(
                (branch) => branch.destination === id,
            );
            if (existingBranch) {
                return existingBranch;
            }

            // Create a new branch configuration if it doesn't exist
            return {
                destination: id,
                conditions: createJourneyFilterGroupFromFilterableField(
                    splitActiveFields?.[nodeId]?.field,
                    splitActiveFields?.[nodeId]?.isJourneyField,
                ),
                isDefault: false,
            };
        });
    }, [branchConfig, nodeId, splitActiveFields, branchesInSplitBlock]);
    return (
        <Flex direction="column" gap={6}>
            <Flex gap={4} align="center" className="p-3">
                <ArrowsSplit weight="duotone" />
                <Text className="text-sm font-medium text-gray-500 uppercase">
                    {t('common.paths')}
                </Text>
            </Flex>

            {!splitActiveFields?.[nodeId] && (
                <Text className="text-sm text-gray-500">
                    {t('journey_builder.split_path_no_properties')}
                </Text>
            )}
            {branches.map((branch, index) => {
                return (
                    <EachSplitPath
                        key={branch.destination}
                        filterRule={
                            getFilterRuleFromConditionsInSplit(
                                branch.conditions,
                            ) as FilterRule
                        }
                        onChange={(filterRule) => {
                            handleChange(filterRule, branch.destination);
                        }}
                        nodeId={nodeId}
                        index={index}
                        onDelete={() => {
                            handleDelete(branch.destination);
                        }}
                        destination={branch.destination}
                    />
                );
            })}
            {splitActiveFields?.[nodeId] && (
                <Button
                    variant={ButtonVariant.SUBTLE}
                    leftIcon={<PlusCircle weight="duotone" />}
                    className="w-[6.5rem]"
                    onClick={handleAddPath}
                >
                    {t('journey_builder.split_path_add_path')}
                </Button>
            )}
        </Flex>
    );
};

export default SplitPath;
