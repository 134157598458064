import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import Modal from '@components/common/modal/Modal';
import { addFieldIdToMetricFilterRule } from '@components/Explorer/CustomMetricModal/utils';
import { useGenerateCustomAttribute } from '@hooks/useCustomMetric';
import { useFilteringTables } from '@hooks/useGetRelatedTables';
import { useLocale } from '@hooks/useLocale';
import {
    FieldType,
    type AdditionalMetric,
    type CompiledRelationTable,
    type FilterGroup,
    type FilterGroupItem,
} from '@lightdash/common';
import { Box, Button, Stack, Text } from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react';
import useCustomMetricContext from '@providers/CustomMetric/useCustomMetricContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useCallback, useMemo, useState } from 'react';
import { ButtonVariant } from '../../../mantineTheme';
import { getFilteredTables } from '../utils';
import AIModeInTraitModal from './AIModeInTraitModal';
import ManualModeInTraitModal from './ManualModeInTraitModal';
import TablesListMenu from './TableListMenu';

const CalculatedTraitBaseModal = () => {
    const { showBaseTableModal, selectedTable, fieldType } =
        useCustomMetricContext((context) => context.reducerState);
    const {
        setFieldType,
        setCustomMetricData,
        selectTable,
        addBaseTableDetails,
        selectDimension,
        openCustomMetricManagerModal,
        closeCustomMetricBaseTableModal,
        addSelectedDimensionDetails,
        setFilters,
    } = useCustomMetricContext((context) => context.actions);
    const { activeRelation } = useRelationContext();
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });
    const [advancedMode, setAdvancedMode] = useState<boolean>(false);
    const { t } = useLocale();

    const { mutateAsync: generateCustomAttribute, isLoading: aiThinking } =
        useGenerateCustomAttribute();

    const [instructions, setInstructions] = useState<string>('');
    const { availableTables, nonAvailableTables } =
        useFilteringTables(activeRelation);

    const handleModalClose = useCallback(() => {
        if (!aiThinking) {
            closeCustomMetricBaseTableModal();
            setAdvancedMode(false);
            setInstructions('');
        }
    }, [
        aiThinking,
        closeCustomMetricBaseTableModal,
        setAdvancedMode,
        setInstructions,
    ]);
    const handleAdvancedMode = useCallback(() => {
        setAdvancedMode(!advancedMode);
    }, [advancedMode]);

    const handleGenerateCustomAttribute = useCallback(async () => {
        if (advancedMode) {
            closeCustomMetricBaseTableModal();
            setAdvancedMode(false);
            setInstructions('');
            openCustomMetricManagerModal();
            return;
        }

        try {
            const data = await generateCustomAttribute({
                instructions,
                columnArray: [],
            });
            if (data) {
                setFieldType(data.type);
                if (data.type === FieldType.METRIC) {
                    const metricSelectedTableName =
                        data.insertCustomAttributeData.definition?.table;
                    const metricSelectedDimension = (
                        data.insertCustomAttributeData
                            .definition as AdditionalMetric
                    )?.baseDimensionName;
                    const field =
                        fieldsWithSuggestions[
                            `${metricSelectedTableName}_${metricSelectedDimension?.replaceAll(
                                '.',
                                '__',
                            )}`
                        ];
                    selectDimension(field);
                    addSelectedDimensionDetails(field);
                    setFilters({
                        dimensions: {
                            and: (
                                data.insertCustomAttributeData
                                    .definition as AdditionalMetric
                            )?.filters?.map(
                                addFieldIdToMetricFilterRule,
                            ) as FilterGroupItem[],
                        } as FilterGroup,
                    });
                }

                setCustomMetricData(data.insertCustomAttributeData);

                selectTable(
                    activeRelation?.tables[
                        data.insertCustomAttributeData.srcTable
                    ],
                );
                openCustomMetricManagerModal();
                handleModalClose();
            }
        } catch {}
    }, [
        instructions,
        generateCustomAttribute,
        advancedMode,
        closeCustomMetricBaseTableModal,
        openCustomMetricManagerModal,
        handleModalClose,
        fieldsWithSuggestions,
        selectDimension,
        addSelectedDimensionDetails,
        setFilters,
        setCustomMetricData,
        setFieldType,
        activeRelation,
        selectTable,
    ]);

    const handleTableSelect = useCallback(
        (table: CompiledRelationTable) => {
            selectTable(table);
            addBaseTableDetails(table);
        },
        [selectTable, addBaseTableDetails],
    );
    const { filteredAvailableTables, filteredNonAvailableTables } =
        useMemo(() => {
            return getFilteredTables(
                availableTables,
                nonAvailableTables,
                fieldType,
            );
        }, [availableTables, nonAvailableTables, fieldType]);

    return (
        <Modal
            opened={showBaseTableModal}
            onClose={handleModalClose}
            title={t('custom_metric.create_new_trait_modal.title')}
            size="xl"
            footerRightSection={
                <Button
                    variant={ButtonVariant.PRIMARY}
                    rightIcon={
                        !aiThinking && (
                            <CaretRight weight="duotone" color="white" />
                        )
                    }
                    onClick={handleGenerateCustomAttribute}
                    loading={aiThinking}
                    disabled={
                        aiThinking || (advancedMode && fieldType === undefined)
                    }
                >
                    {aiThinking
                        ? t('custom_metric.create_new_trait_modal.generating')
                        : t('custom_metric.create_new_trait_modal.start')}
                </Button>
            }
            footerLeftSection={
                <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={handleModalClose}
                    disabled={aiThinking}
                >
                    {t('common.cancel')}
                </Button>
            }
        >
            <Stack spacing={12}>
                {advancedMode && (
                    <Box>
                        <Text className="text-gray-800 font-medium">
                            {t(
                                'custom_metric.create_new_trait_modal.destination_table',
                            )}
                        </Text>
                        <TablesListMenu
                            selectedTable={selectedTable}
                            tables={filteredAvailableTables}
                            nonAvailableTables={filteredNonAvailableTables}
                            handleTableSelect={handleTableSelect}
                            disabled={aiThinking}
                        />
                    </Box>
                )}
                {advancedMode ? (
                    <ManualModeInTraitModal
                        handleAdvancedMode={handleAdvancedMode}
                        aiThinking={aiThinking}
                    />
                ) : (
                    <AIModeInTraitModal
                        handleAdvancedMode={handleAdvancedMode}
                        aiThinking={aiThinking}
                        setInstructions={setInstructions}
                        instructions={instructions}
                    />
                )}
            </Stack>
        </Modal>
    );
};

export default CalculatedTraitBaseModal;
