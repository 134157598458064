import { useLocale } from '@hooks/useLocale';
import { Loader, LoadingOverlay } from '@mantine/core';
import React, { useMemo } from 'react';
import { PageLoaderColor } from '../../mantineTheme';

interface PageSpinnerProps {
    withOverlay?: boolean;
    isVisible?: boolean;
    loaderColor?: PageLoaderColor;
    loaderText?: string;
}

const PageSpinner: React.FC<PageSpinnerProps> = ({
    withOverlay,
    isVisible,
    loaderColor,
    loaderText,
}) => {
    const { t } = useLocale();

    const getLoaderColor = useMemo(() => {
        if (loaderColor === PageLoaderColor.GRAY) {
            return 'rgb(var(--color-gray-500))';
        }
        return 'rgb(var(--color-blu-800))';
    }, [loaderColor]);

    const loader = useMemo(
        () => (
            <div className="flex flex-col items-center gap-2">
                <Loader color={getLoaderColor} variant="bars" />
                <p className="text-gray-600">
                    {loaderText ?? t('page_spinner.default_loader_text')}
                </p>
            </div>
        ),
        [getLoaderColor, loaderText, t],
    );

    if (withOverlay) {
        return (
            <LoadingOverlay
                visible={isVisible ?? true}
                overlayBlur={2}
                overlayOpacity={0.4}
                loader={loader}
            />
        );
    }

    return (
        <div className="flex flex-col items-center justify-center h-screen w-full">
            {loader}
        </div>
    );
};

export default PageSpinner;
