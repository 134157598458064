import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import SettingsTitle from '@components/common/Settings/SettingsTitle';
import WorkspaceSettingsForm from '@components/Workspace/WorkspaceSettingsForm';
import { useLocale } from '@hooks/useLocale';
import { useGetProjectDescription } from '@hooks/useProject';
import {
    KBSingleDocumentContentTypes,
    KnowledgeBaseEntities,
} from '@lightdash/common';
import { Box, Flex } from '@mantine/core';
import useApp from '@providers/App/useApp';
import useProjectContext from '@providers/Project/useProjectContext';
import React from 'react';
import { Navigate, useParams } from 'react-router';

const Overview: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { projectData } = useProjectContext();
    const { data: projectDescription } = useGetProjectDescription({
        query: `entity=${KnowledgeBaseEntities.PROJECT}&entityId=${projectUuid}&contentType=${KBSingleDocumentContentTypes.INFO}`,
    });
    const ability = useAbilityContext();
    const { user } = useApp();
    const canAccessProjectSetting = ability.can(
        'view',
        subject('ProjectSettings', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    if (!canAccessProjectSetting) {
        return <Navigate to="/no-access" />;
    }

    if (!projectData || !projectUuid || !projectDescription) return null;
    return (
        <Box>
            <Flex className="items-center justify-between w-full">
                <SettingsTitle title={t('workspace_settings.overview_title')} />
            </Flex>

            <Box className="my-6">
                <WorkspaceSettingsForm
                    projectUuid={projectUuid}
                    projectData={projectData}
                    projectDescription={projectDescription}
                />
            </Box>
        </Box>
    );
};

export default React.memo(Overview);
