import AuthorizationError from '@components/AuthorizationError';
import ErrorState from '@components/common/ErrorState';
import PageSpinner from '@components/PageSpinner';
import { useOrganization } from '@hooks/organization/useOrganization';
import { useProjects } from '@hooks/useProjects';
import { OrganizationMemberRole } from '@lightdash/common';
import useApp from '@providers/App/useApp';
import { type ComponentProps, type FC } from 'react';
import { Navigate, useLocation, type Route } from 'react-router';

const AppRoute: FC<React.PropsWithChildren<ComponentProps<typeof Route>>> = ({
    children,
}) => {
    const { health, user } = useApp();
    const orgRequest = useOrganization();
    const projectData = useProjects();
    const location = useLocation();

    if (health.isInitialLoading || orgRequest.isInitialLoading) {
        return <PageSpinner />;
    }

    if (orgRequest.error || health.error) {
        return (
            <ErrorState
                error={orgRequest.error?.error || health.error?.error}
            />
        );
    }
    if (!orgRequest?.data?.name || orgRequest.data.name === '') {
        return (
            <Navigate to="/create-organization" state={{ from: location }} />
        );
    }
    if (orgRequest?.data?.needsProject) {
        return (
            <Navigate
                to={{
                    pathname: '/create-workspace',
                }}
                state={{ from: location }}
            />
        );
    }

    if (projectData.data?.length === 0) {
        if (user.data?.role === OrganizationMemberRole.MEMBER)
            return <AuthorizationError />;
        return <Navigate to="/create-workspace" />;
    }

    return <>{children}</>;
};

export default AppRoute;
