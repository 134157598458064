import { useUpdateCampaign } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import { type AnyType, type CampaignUpdateRequest } from '@lightdash/common';
import { Box, Button, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { ClockCountdown } from '@phosphor-icons/react/dist/ssr';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { ButtonVariant } from '../../../../mantineTheme';
import CampaignSchedulerModal from '../Steps/CampaignBuilderScheduler/CampaignSchedulerModal';
import { CampaignBuilderStep } from '../types';
import { CampaignBuilderSteps } from './../utils';

const CampaignBuilderFooter: React.FC<{}> = () => {
    const initialData = useRef<AnyType>(null);
    const { campaignUuid } = useParams<{ campaignUuid: string }>();
    const { t } = useLocale();
    const {
        currentStep,
        currentStepCallback,
        previousStepCallback,
        showFooterButtons,
        campaignPayload,
    } = useCampaignContext((context) => context.state);
    const { setCurrentStep, setShowFooterButtons } = useCampaignContext(
        (context) => context.actions,
    );

    const [
        showSchedulerModal,
        { open: schedulerModalOpen, close: schedulerModalClose },
    ] = useDisclosure(false);

    const { mutate: updateCampaign } = useUpdateCampaign(campaignUuid);

    useEffect(() => {
        if (initialData.current === null) {
            initialData.current = campaignPayload;
        }
    }, [campaignPayload]);

    const getNextStep = useCallback(() => {
        const currentStepIndex = CampaignBuilderSteps.findIndex(
            (step) => step.key === currentStep,
        );

        return CampaignBuilderSteps[currentStepIndex + 1]?.key;
    }, [currentStep]);

    const getPreviousStep = useCallback(() => {
        const currentStepIndex = CampaignBuilderSteps.findIndex(
            (step) => step.key === currentStep,
        );
        return CampaignBuilderSteps[currentStepIndex - 1]?.key;
    }, [currentStep]);

    const handleBack = useCallback(() => {
        const previousStep = getPreviousStep();
        if (previousStepCallback) {
            const skip = previousStepCallback();
            if (skip) {
                return;
            }
        }
        if (previousStep) {
            setShowFooterButtons({
                next: true,
                back: true,
            });
            setCurrentStep(previousStep);
        }
    }, [
        getPreviousStep,
        previousStepCallback,
        setCurrentStep,
        setShowFooterButtons,
    ]);

    const handleNext = useCallback(() => {
        if (currentStepCallback) {
            const skipExecution = currentStepCallback();
            //Skips moving to the next step unless specified in the setCallback
            if (skipExecution) {
                return;
            }
        }
        if (currentStep === CampaignBuilderStep.SCHEDULE) {
            if (
                initialData.current.name !== campaignPayload.name ||
                initialData.current.description !==
                    campaignPayload.description ||
                !_.isEqual(
                    _.sortBy(initialData.current.tags),
                    _.sortBy(campaignPayload.tags),
                )
            ) {
                const payload: CampaignUpdateRequest = {
                    name: campaignPayload.name,
                    description: campaignPayload.description,
                    tags: campaignPayload.tags,
                };
                updateCampaign(payload);
            }
            schedulerModalOpen();
            return;
        }
        const nextStep = getNextStep();
        if (!nextStep) return;
        setShowFooterButtons({
            next: true,
            back: true,
        });
        if (!window.location.href.includes('journeys')) {
            setCurrentStep(nextStep);
        }
    }, [
        currentStep,
        getNextStep,
        currentStepCallback,
        updateCampaign,
        campaignPayload.name,
        campaignPayload.description,
        schedulerModalOpen,
        setShowFooterButtons,
        setCurrentStep,
        campaignPayload.tags,
    ]);

    return (
        <Group className="justify-between w-full">
            {showFooterButtons.back ? (
                <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={handleBack}
                    leftIcon={<CaretLeft />}
                >
                    Go back
                </Button>
            ) : (
                <Box className="flex-1"></Box>
            )}

            {showFooterButtons.next && (
                <Button
                    variant={ButtonVariant.PRIMARY}
                    onClick={handleNext}
                    rightIcon={
                        currentStep !== CampaignBuilderStep.SCHEDULE && (
                            <CaretRight color={'white'} />
                        )
                    }
                    leftIcon={
                        currentStep === CampaignBuilderStep.SCHEDULE && (
                            <ClockCountdown size={14} color="white" />
                        )
                    }
                >
                    {currentStep === CampaignBuilderStep.SCHEDULE
                        ? t('campaign_builder.schedule_and_publish')
                        : t('campaign_builder.next')}
                </Button>
            )}
            {showSchedulerModal && (
                <CampaignSchedulerModal
                    showSchedulerModal={showSchedulerModal}
                    schedulerModalClose={schedulerModalClose}
                    showRadio={true}
                />
            )}
        </Group>
    );
};

export default React.memo(CampaignBuilderFooter);
