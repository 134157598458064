import TableIcon from '@components/common/IconPack/TableIcon';
import { TextWithTooltip } from '@components/common/TextWithTooltip';
import { useLocale } from '@hooks/useLocale';
import {
    RelationTableType,
    type CompiledRelationTable,
} from '@lightdash/common';
import { Box, Button, Flex, Menu, Text } from '@mantine/core';
import { CaretDown } from '@phosphor-icons/react';
import { ButtonVariant, GlobalStyles } from '../../../mantineTheme';

interface TablesListMenuProps {
    tables: CompiledRelationTable[];
    nonAvailableTables: CompiledRelationTable[];
    handleTableSelect: (table: CompiledRelationTable) => void;
    selectedTable: CompiledRelationTable | undefined;
    disabled: boolean;
}
interface FieldButtonProps {
    label: string;
    isEditMode: boolean;
    tableType: RelationTableType | undefined;
}
export const TableButton: React.FC<FieldButtonProps> = ({
    label,
    isEditMode,
    tableType,
}) => (
    <Button
        variant={ButtonVariant.UNSTYLED}
        className={`ps-2 pe-3 min-h-[2.3rem] min-w-[15rem] rounded-lg bg-white border border-shade-6 shadow-card  text-gray-600 text-sm cursor-${
            isEditMode ? 'auto' : 'pointer hover:bg-gray-50'
        }`}
        rightIcon={<CaretDown weight="bold" />}
        styles={{
            label: {
                justifyContent: 'start',
                gap: '0.25rem',
            },
        }}
    >
        {tableType && (
            <TableIcon
                type={tableType}
                strokeWidth={2.5}
                color={GlobalStyles.tableStyles[tableType].textColor}
            />
        )}
        <Text className="flex-grow text-sm font-medium text-gray-800  max-w-[10rem] ">
            <TextWithTooltip
                text={label}
                className="!m-0 rounded text-sm text-gray-800 font-medium"
            />
        </Text>
    </Button>
);
const TablesListMenu: React.FC<TablesListMenuProps> = ({
    tables,
    nonAvailableTables,
    handleTableSelect,
    selectedTable,
    disabled,
}) => {
    const { t } = useLocale();
    return (
        <Menu
            width={300}
            withinPortal
            position="bottom-start"
            transitionProps={{ transition: 'fade', duration: 200 }}
            disabled={disabled}
        >
            <Menu.Target>
                <Box>
                    <TableButton
                        tableType={selectedTable?.type}
                        label={
                            selectedTable?.label ??
                            t('custom_metric.create_new_trait_modal.tables')
                        }
                        isEditMode={false}
                    />
                </Box>
            </Menu.Target>
            <Menu.Dropdown className="max-h-[300px] overflow-y-auto">
                {tables.map((table) => (
                    <Menu.Item
                        key={table.name}
                        onClick={() => {
                            handleTableSelect(table);
                        }}
                        className={
                            selectedTable?.name === table.name
                                ? 'bg-shade-4'
                                : ''
                        }
                    >
                        <Flex direction="row" gap="xs" align="center">
                            <TableIcon
                                type={table.type ?? RelationTableType.RELATED}
                                strokeWidth={2.5}
                                color={
                                    GlobalStyles.tableStyles[
                                        table.type ?? RelationTableType.RELATED
                                    ].textColor
                                }
                            />
                            {table.label}
                        </Flex>
                    </Menu.Item>
                ))}
                {nonAvailableTables.map((table) => (
                    <Menu.Label key={table.name} className="text-gray-400">
                        <Flex direction="row" gap="xs" align="center">
                            <TableIcon
                                type={table.type ?? RelationTableType.RELATED}
                                strokeWidth={2.5}
                                color={'rgb(var(--color-gray-400))'}
                            />
                            {table.label}
                        </Flex>
                    </Menu.Label>
                ))}
            </Menu.Dropdown>
        </Menu>
    );
};

export default TablesListMenu;
