import { useAiAnalystThread } from '@hooks/useAiAnalyst';
import {
    AIMessageContext,
    type AgentJsonContent,
    type AgentMessage,
} from '@lightdash/common';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from 'react';
import { useParams } from 'react-router';
import AiAnalystProviderContext from './context';
import {
    ActionType,
    type AiAnalystContext,
    type AiAnalystReducerState,
} from './types';

// Define the actions for AiAnalyst
type Action =
    | { type: ActionType.SET_SHOW_AI_ANALYST; payload: boolean }
    | { type: ActionType.SET_AI_CHAT_MESSAGES; payload: Array<AgentMessage> }
    | { type: ActionType.SET_THREAD_ID; payload: string }
    | { type: ActionType.SET_AI_CONTEXT; payload: AIMessageContext }
    | { type: ActionType.SET_AI_CHAT_MESSAGES; payload: Array<AgentMessage> };

// Define the reducer function
function reducer(
    state: AiAnalystReducerState,
    action: Action,
): AiAnalystReducerState {
    switch (action.type) {
        case ActionType.SET_SHOW_AI_ANALYST: {
            return {
                ...state,
                showAiAnalyst: action.payload,
            };
        }

        case ActionType.SET_AI_CHAT_MESSAGES: {
            return {
                ...state,
                aiChatMessages: [...state.aiChatMessages, ...action.payload],
            };
        }

        case ActionType.SET_THREAD_ID: {
            return {
                ...state,
                threadId: action.payload,
                aiChatMessages: [],
            };
        }

        case ActionType.SET_AI_CONTEXT: {
            return {
                ...state,
                aiContext: action.payload,
            };
        }

        default: {
            throw new Error('Unexpected action');
        }
    }
}

// Define the AiAnalystProvider component
const AiAnalystProvider: React.FC<React.PropsWithChildren<{}>> = ({
    children,
}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { mutateAsync: mutateAsyncCreateAiAnalystThread } =
        useAiAnalystThread();

    const [reducerState, dispatch] = useReducer(reducer, {
        showAiAnalyst: false,
        threadId: '',
        aiChatMessages: [],
        aiContext: AIMessageContext.SCHEMA_SEARCH,
    });
    const responseCallback = useRef<((data: AgentJsonContent) => void) | null>(
        null,
    );

    const registerCallback = useCallback(
        (callback: (data: AgentJsonContent) => void) => {
            responseCallback.current = callback;
        },
        [],
    );

    const unregisterCallback = useCallback(() => {
        responseCallback.current = null;
    }, []);

    const setAiContext = useCallback((context: AIMessageContext) => {
        dispatch({
            type: ActionType.SET_AI_CONTEXT,
            payload: context,
        });
    }, []);

    const setShowAiAnalyst = useCallback((showAiAnalyst: boolean) => {
        dispatch({
            type: ActionType.SET_SHOW_AI_ANALYST,
            payload: showAiAnalyst,
        });
    }, []);

    const setAIChatMessages = useCallback((messages: Array<AgentMessage>) => {
        dispatch({
            type: ActionType.SET_AI_CHAT_MESSAGES,
            payload: messages,
        });
    }, []);

    const setThreadId = useCallback((id: string) => {
        dispatch({
            type: ActionType.SET_THREAD_ID,
            payload: id,
        });
    }, []);

    const onSuccess = useCallback((json: AgentJsonContent) => {
        if (responseCallback.current) {
            responseCallback.current(json);
        }
    }, []);

    const handleCreateThread = useCallback(async () => {
        await mutateAsyncCreateAiAnalystThread().then((res) => {
            setThreadId(res.threadId);
        });
    }, [mutateAsyncCreateAiAnalystThread, setThreadId]);

    useEffect(() => {
        if (!reducerState.threadId && projectUuid) {
            void handleCreateThread();
        }
    }, [
        handleCreateThread,
        reducerState.threadId,
        reducerState.showAiAnalyst,
        projectUuid,
    ]);

    const state = useMemo(
        () => ({
            ...reducerState,
        }),
        [reducerState],
    );

    const actions = useMemo(
        () => ({
            setShowAiAnalyst,
            registerCallback,
            unregisterCallback,
            onSuccess,
            setAiContext,
            setAIChatMessages,
            handleCreateThread,
        }),
        [
            setShowAiAnalyst,
            registerCallback,
            unregisterCallback,
            onSuccess,
            setAiContext,
            setAIChatMessages,
            handleCreateThread,
        ],
    );

    const value: AiAnalystContext = useMemo(
        () => ({
            state,
            actions,
        }),
        [state, actions],
    );

    return (
        <AiAnalystProviderContext.Provider value={value}>
            {children}
        </AiAnalystProviderContext.Provider>
    );
};

export default AiAnalystProvider;
