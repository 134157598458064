import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { FiltersProvider } from '@components/Audience/Filters/FiltersProvider/FiltersProvider';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { useGetJourneyEvents } from '@hooks/useJourney';
import {
    getFilterRulesFromGroup,
    type FilterGroup,
    type Filters,
    type JourneyFilterGroup,
} from '@lightdash/common';
import { Stack } from '@mantine/core';
import useRelationContext from '@providers/Relation/useRelationContext';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import EventFilters from './EventFilters';
import useEventFilter from './useEventFilter';

interface EventFilterGroupProps {
    eventConfig: {
        eventName: string;
        eventSource: string;
    };
    setEvent: (eventSource: string, eventName: string) => void;
    filters: JourneyFilterGroup | undefined;
    setFilters: (filters: JourneyFilterGroup) => void;
}

const EventFilterGroup: React.FC<EventFilterGroupProps> = ({
    eventConfig,
    setEvent,
    filters,
    setFilters,
}) => {
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();

    const { activeRelation } = useRelationContext();

    const { data: eventsList, isLoading: eventsLoading } =
        useGetJourneyEvents();

    const filterRules = useMemo(() => {
        if (!filters) return [];
        return getFilterRulesFromGroup(filters as FilterGroup);
    }, [filters]);

    const handleSetEvent = useCallback(
        (eventSource: string, eventName: string) => {
            setEvent(eventSource, eventName);
        },
        [setEvent],
    );

    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });

    const { eventsFields } = useEventFilter(eventsList ?? []);

    const allFields = useMemo(
        () => Object.values({ ...fieldsWithSuggestions, ...eventsFields }),
        [fieldsWithSuggestions, eventsFields],
    );

    if (eventsLoading) {
        return (
            <Stack className="gap-4">
                <SkeletonLoader height={70} />
            </Stack>
        );
    }
    if (!eventsList) return null;

    return (
        <FiltersProvider projectUuid={projectUuid}>
            <Stack>
                <EventFilters
                    eventList={eventsList}
                    filterRules={filterRules}
                    isEditMode={true}
                    onChange={() => {}}
                    filters={{ dimensions: filters } as Filters}
                    setFilters={(values) => {
                        setFilters(values.dimensions as JourneyFilterGroup);
                    }}
                    projectUuid={projectUuid ?? ''}
                    eventConfig={eventConfig}
                    allFields={allFields}
                    setEventConfig={(event) => {
                        handleSetEvent(event.eventName, event.eventSource);
                    }}
                />
            </Stack>
        </FiltersProvider>
    );
};

export default React.memo(EventFilterGroup);
