import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import useFiltersContext from '@components/Audience/Filters/FiltersProvider/useFiltersContext';
import Select from '@components/common/Select';
import { getCustomMetricType } from '@components/Explorer/ExploreTree/TableTree/Tree/utils';
import { useGetRelatedTables } from '@hooks/useGetRelatedTables';
import { useLocale } from '@hooks/useLocale';
import {
    friendlyName,
    type AdditionalMetric,
    type CompiledDimension,
    type MetricType,
} from '@lightdash/common';
import { Box, Text } from '@mantine/core';
import useCustomMetricContext from '@providers/CustomMetric/useCustomMetricContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useMemo } from 'react';
import { type UseFormReturn } from 'react-hook-form';
import CustomMetricFilter from '../CustomMetricFilter';
import { type CustomMetricForm } from '../CustomMetricManagerModal';
import CustomMetricPropertySelect from '../CustomMetricPropertySelect';

interface CustomMetricLogicProps {
    isLoading: boolean;
    activeField: CompiledDimension | FieldWithSuggestions | undefined;
    setActiveField: (
        field: CompiledDimension | FieldWithSuggestions | undefined,
    ) => void;
    form: UseFormReturn<CustomMetricForm, any, undefined>;
}
const CustomMetricLogic: React.FC<CustomMetricLogicProps> = ({
    isLoading,
    activeField,
    setActiveField,
    form,
}) => {
    const { t } = useLocale();
    const { activeRelation } = useRelationContext();
    const {
        selectedTable,
        selectedDimension,
        viewMode,
        customAttributePayload,
    } = useCustomMetricContext((context) => context.reducerState);
    const {
        selectDimension,
        addSelectedDimensionDetails,
        addMetricTypeDetailsToPayload,
    } = useCustomMetricContext((context) => context.actions);
    const { fieldsMap } = useFiltersContext();
    const relatedTables = useGetRelatedTables(
        selectedTable?.name ?? '',
        activeRelation,
        fieldsMap,
    );
    const didSelectedDimension = useMemo(
        () => (selectedDimension ? true : false),
        [selectedDimension],
    );

    return (
        <>
            <Box className="!min-w-[265px]">
                <Text className="mb-1">
                    {t('custom_metric.dimension_modal_property_label')}
                </Text>
                <CustomMetricPropertySelect
                    fields={
                        relatedTables
                            ? (Object.values(
                                  relatedTables,
                              ) as FieldWithSuggestions[])
                            : []
                    }
                    onSubmit={(field) => {
                        selectDimension(field[0]);
                        addSelectedDimensionDetails(field[0]);
                        setActiveField(field[0]);
                    }}
                    isLoading={isLoading}
                    activeField={activeField}
                />
            </Box>
            <Select
                withinPortal={true}
                label={
                    <Text
                        className={!didSelectedDimension ? 'text-gray-400' : ''}
                    >
                        {t('custom_metric.dimension_modal_function_label')}
                    </Text>
                }
                placeholder={t(
                    'custom_metric.dimension_modal_function_description',
                )}
                data={
                    selectedDimension
                        ? getCustomMetricType(
                              (selectedDimension as CompiledDimension).type,
                          ).map((name) => ({
                              label: friendlyName(name),
                              value: name,
                          }))
                        : ['']
                }
                className="!w-[272px]"
                disabled={!didSelectedDimension || isLoading}
                readOnly={viewMode}
                required
                withAsterisk={false}
                value={
                    (customAttributePayload.definition as AdditionalMetric)
                        ?.type
                }
                onChange={(value) => {
                    form.setValue('metric', value as MetricType);
                    addMetricTypeDetailsToPayload(value as MetricType);
                }}
                readOnlyClasses="!w-[272px]"
            />

            <Text className={!didSelectedDimension ? 'text-gray-400' : ''}>
                {t('custom_metric.dimension_modal.filter_label')}
            </Text>
            <Box className={!didSelectedDimension ? 'opacity-70' : ''}>
                <CustomMetricFilter isLoading={isLoading} />
            </Box>
        </>
    );
};

export default CustomMetricLogic;
