import {
    AudienceType,
    CampaignScheduleType,
    CampaignType,
    CommunicationChannel,
    TemplateType,
    type Campaign,
    type CampaignUpdateRequest,
} from '@lightdash/common';
import { type CampaignReducerState } from '@providers/Campaign/types';
import { useMemo } from 'react';
import { CampaignBuilderStep } from './Builder/types';

export const getInitialCampaignPayload = (
    campaignData: Campaign | undefined,
    isEditMode?: boolean,
): CampaignUpdateRequest => {
    return {
        name: campaignData?.name || '',
        description: campaignData?.description || '',
        channel: campaignData?.channel || CommunicationChannel.EMAIL,
        communicationDetails: campaignData?.communicationDetails || {
            id: '',
            providerId: '',
            eventId: '',
        },
        audienceId: campaignData?.audienceId || null,
        audienceType: campaignData?.audienceType || AudienceType.WAREHOUSE,
        csvUploadDetails: campaignData?.csvUploadDetails || null,
        templateDetails: campaignData?.templateDetails || {
            id: '',
            version: 1,
            type: TemplateType.INTERNAL,
        },
        contentDetails: campaignData?.contentDetails || undefined,
        sendTo: campaignData?.sendTo || undefined,
        subscriptionGroupId: campaignData?.subscriptionGroupId || undefined,
        contentMappings: campaignData?.contentMappings || {},
        schedule:
            !campaignData?.schedule ||
            Object.keys(campaignData.schedule).length === 0
                ? {
                      type: CampaignScheduleType.MANUAL,
                      executionTime: null,
                  }
                : campaignData?.schedule,
        campaignTrackingParameters:
            campaignData?.campaignTrackingParameters ?? {},
        tags: isEditMode ? campaignData?.tags ?? [] : [],
        type: campaignData?.type ?? CampaignType.ONE_TIME,
        senderDetails: campaignData?.senderDetails ?? undefined,
        throttleConfig: campaignData?.throttleConfig ?? undefined,
        conversionConfig: campaignData?.conversionConfig ?? undefined,
        previewText: campaignData?.previewText ?? undefined,
    };
};
export const useCampaignInitialData = (
    campaignData: Campaign | undefined,
    isEditMode?: boolean,
): CampaignReducerState => {
    return useMemo(() => {
        return {
            campaignPayload: getInitialCampaignPayload(
                campaignData,
                isEditMode,
            ),
            currentStep: CampaignBuilderStep.CHANNEL,
            currentStepCallback: null,
            previousStepCallback: null,
            showFooterButtons: {
                next: false,
                back: false,
            },
            editor: undefined,
            status: campaignData?.status ?? null,
        };
    }, [campaignData, isEditMode]);
};
