import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import TableIcon from '@components/common/IconPack/TableIcon';
import Timestamp from '@components/common/Timestamp';
import { useLocale } from '@hooks/useLocale';
import {
    CommonReservedTags,
    RelationTableType,
    type CustomAttribute,
    type UserTraitFieldConfig,
    type UserTraits,
} from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import { Archive, CaretLeft, Function, Textbox } from '@phosphor-icons/react';
import { type ColumnDef, type Row } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { GlobalStyles } from '../../../mantineTheme';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import { CustomMetricTab } from '../type';
import { getCustomAttributeName } from '../utils';
import CreatedAtTableCell from './CreatedAtTableCell';
import CustomMetricMenuItem from './CustomMetricMenuItem';
import CustomMetricUpdateTraitMenuItem from './CustomMetricUpdateTraitMenuItem';

const CUSTOM_METRIC_TABLE_CELL_CLASSNAME = `flex flex-col gap-0.5 justify-center items-start !w-56`;
const CUSTOM_METRIC_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 cursor-pointer truncate !w-56`;

export const useCustomMetricCoulumns = (
    activePill: CustomMetricTab,
    userTraits: UserTraits | undefined,
) => {
    const [columns, setColumns] =
        useState<ColumnDef<CustomAttribute | UserTraitFieldConfig>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const { activeRelation } = useRelationContext();
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });

    useEffect(() => {
        if (projectUuid && activePill === CustomMetricTab.CALCULATED) {
            setColumns([
                {
                    accessorKey: 'name',
                    header: t('custom_metric_manager.th_custom_metric_name'),
                    cell: ({ row }) => {
                        return (
                            <Box className={CUSTOM_METRIC_TABLE_CELL_CLASSNAME}>
                                <Box className="flex flex-col items-start justify-center gap-1 ">
                                    <Flex
                                        justify="center"
                                        align="center"
                                        className="gap-1"
                                    >
                                        {(
                                            row.original as CustomAttribute
                                        ).tags?.includes(
                                            CommonReservedTags.HIDDEN,
                                        ) && <Archive weight="duotone" />}
                                        <Function
                                            color={'rgb(var(--color-gray-600)'}
                                            size={13}
                                            strokeWidth={2}
                                            className="inline me-1"
                                        />
                                        <Text
                                            span
                                            className={
                                                CUSTOM_METRIC_TABLE_CELL_NAME
                                            }
                                        >
                                            {getCustomAttributeName(
                                                row.original as CustomAttribute,
                                            )}
                                        </Text>
                                        <CaretLeft
                                            color={'rgb(var(--color-gray-600)'}
                                        />
                                        <TableIcon
                                            type={
                                                activeRelation?.tables[
                                                    (
                                                        row.original as CustomAttribute
                                                    ).srcTable
                                                ]?.type ??
                                                RelationTableType.RELATED
                                            }
                                            strokeWidth={2.5}
                                        />
                                        {activeRelation?.tables[
                                            (row.original as CustomAttribute)
                                                .srcTable
                                        ]?.type && (
                                            <Text
                                                className={
                                                    `
                                ${
                                    GlobalStyles.tableStyles[
                                        activeRelation?.tables[
                                            (row.original as CustomAttribute)
                                                .srcTable
                                        ].type
                                    ].textColorTailwind
                                }` + ' whitespace-nowrap'
                                                }
                                            >
                                                {
                                                    activeRelation?.tables[
                                                        (
                                                            row.original as CustomAttribute
                                                        ).srcTable
                                                    ].label
                                                }
                                            </Text>
                                        )}
                                    </Flex>
                                    {(row.original as CustomAttribute)
                                        .description && (
                                        <Text
                                            span
                                            className="text-xs font-normal text-gray-600 "
                                        >
                                            {
                                                (
                                                    row.original as CustomAttribute
                                                ).description
                                            }
                                        </Text>
                                    )}
                                </Box>
                            </Box>
                        );
                    },
                },
                {
                    accessorKey: 'createdAt',
                    header: t('common.created_th'),
                    cell: ({ getValue, row }) => (
                        <Box
                            className="text-sm text-gray-800"
                            onClick={(
                                event: React.MouseEvent<HTMLButtonElement>,
                            ) => {
                                event.stopPropagation();
                            }}
                        >
                            <Timestamp timestamp={getValue<string>()} />
                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`by ${
                                    (row.original as CustomAttribute).createdBy
                                        ?.firstName || ''
                                } ${
                                    (row.original as CustomAttribute).createdBy
                                        ?.lastName || ''
                                }`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'menu',
                    header: '',
                    cell: ({ row }) => (
                        <CustomMetricMenuItem
                            rowData={row as Row<CustomAttribute>}
                        />
                    ),
                },
            ]);
        } else if (projectUuid && activePill === CustomMetricTab.DYNAMIC) {
            setColumns([
                {
                    accessorKey: 'name',
                    header: t('custom_metric_manager.th_custom_metric_name'),
                    cell: ({ row }) => (
                        <Box className={CUSTOM_METRIC_TABLE_CELL_CLASSNAME}>
                            <Box className="flex flex-col items-start justify-center gap-1 ">
                                <Flex
                                    justify="center"
                                    align="center"
                                    className="gap-1"
                                >
                                    <Textbox
                                        color={'rgb(var(--color-gray-600)'}
                                        size={13}
                                        strokeWidth={2}
                                        className="inline me-1"
                                    />
                                    <Text
                                        span
                                        className={
                                            CUSTOM_METRIC_TABLE_CELL_NAME
                                        }
                                    >
                                        {
                                            (
                                                row.original as UserTraitFieldConfig
                                            ).label
                                        }
                                    </Text>
                                    <CaretLeft
                                        color={'rgb(var(--color-gray-600)'}
                                    />
                                    <TableIcon
                                        type={
                                            activeRelation?.tables[
                                                activeRelation?.baseTable
                                            ]?.type ?? RelationTableType.RELATED
                                        }
                                        strokeWidth={2.5}
                                    />
                                    {activeRelation?.tables[
                                        activeRelation?.baseTable
                                    ]?.type && (
                                        <Text
                                            className={
                                                `
                                ${
                                    GlobalStyles.tableStyles[
                                        activeRelation?.tables[
                                            activeRelation?.baseTable
                                        ].type
                                    ].textColorTailwind
                                }` + ' whitespace-nowrap'
                                            }
                                        >
                                            {
                                                activeRelation?.tables[
                                                    activeRelation?.baseTable
                                                ].label
                                            }
                                        </Text>
                                    )}
                                </Flex>
                            </Box>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'createdAt',
                    header: t('common.created_th'),
                    cell: ({ row }) => (
                        <CreatedAtTableCell
                            createdAt={row.original.createdAt}
                            createdBy={`${
                                (row.original.createdBy?.firstName || '') +
                                ' ' +
                                (row.original.createdBy?.lastName || '')
                            }`}
                        />
                    ),
                },
                {
                    accessorKey: 'menu',
                    header: '',
                    cell: ({ row }) => (
                        <CustomMetricUpdateTraitMenuItem
                            rowData={row}
                            userTraits={userTraits}
                        />
                    ),
                },
            ]);
        }
    }, [
        t,
        projectUuid,
        activeRelation,
        fieldsWithSuggestions,
        activePill,
        userTraits,
    ]);
    return columns;
};
