import { FiltersProvider } from '@components/Audience/Filters/FiltersProvider/FiltersProvider';
import SkeletonLoader from '@components/common/SkeletonLoader';
import JourneyEventFilters from '@components/Journeys/Builder/JourneyFilters/EventFilters';
import { type JourneyEventFilterConfig } from '@components/Journeys/Builder/types';
import {
    getFilterRulesFromGroup,
    JourneyTableType,
    type FilterGroup,
    type Filters,
    type JourneyFilterGroup,
    type JourneyFiltersConfig,
} from '@lightdash/common';
import { Stack } from '@mantine/core';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { useJourneyNodeParams } from '../../useJourneyNodeParams';

interface BaseTriggerEventFilterProps {
    nodeId: string;
    eventConfig: JourneyEventFilterConfig;
    setEvent: (eventSource: string, eventName: string) => void;
    filters: JourneyFiltersConfig | undefined;
    setFilters: (filters: JourneyFiltersConfig) => void;
}

const BaseTriggerEventFilter: React.FC<BaseTriggerEventFilterProps> = ({
    nodeId,
    eventConfig,
    setEvent,
    filters,
    setFilters,
}) => {
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();
    const { journeyEvents, isEditable, journeyPayload } =
        useJourneyBuilderContext((context) => context.state);

    const prevEventConfigRef = useRef<JourneyEventFilterConfig | undefined>(
        undefined,
    );

    const {
        journeyDataSchema,
        isLoading: isJourneyNodeParamsLoading,
        getJourneyNodeParams,
        allFields,
    } = useJourneyNodeParams(nodeId);

    /* [INFO]
     ** This useEffect is used to trigger the getJourneyNodeParams function when the eventConfig changes.
     ** This is done to update the journeyDataSchema when the eventConfig changes.
     ** This is necessary because the journeyDataSchema is used in the JourneyEventFilters component to render the filters.
     ** If the journeyDataSchema is not updated, the event filters will not be rendered correctly.
     */
    useEffect(() => {
        if (
            prevEventConfigRef.current &&
            (eventConfig.eventName !== prevEventConfigRef.current.eventName ||
                eventConfig.eventSource !==
                    prevEventConfigRef.current.eventSource)
        ) {
            void getJourneyNodeParams();
        }

        prevEventConfigRef.current = eventConfig;
    }, [eventConfig, getJourneyNodeParams]);

    const filterRules = useMemo(() => {
        if (!filters?.journeyFilters) return [];
        return getFilterRulesFromGroup(filters.journeyFilters as FilterGroup);
    }, [filters]);

    const handleSetEvent = useCallback(
        (eventSource: string, eventName: string) => {
            setEvent(eventSource, eventName);
        },
        [setEvent],
    );

    const filteredJourneyEvents = useMemo(() => {
        if (!journeyEvents) return null;
        if (journeyPayload.triggers?.entry[0].isBusinessEventTrigger) {
            return journeyEvents.filter(
                (event) => !('srt_user_id' in event.mapperSchema),
            );
        }

        return journeyEvents.filter(
            (event) => 'srt_user_id' in event.mapperSchema,
        );
    }, [journeyEvents, journeyPayload.triggers?.entry]);

    const filteredJourneyDataSchema = useMemo(() => {
        if (isJourneyNodeParamsLoading || !journeyDataSchema) {
            return;
        }
        journeyDataSchema.tables = Object.fromEntries(
            Object.entries(journeyDataSchema.tables).filter(
                ([, table]) => table.type === JourneyTableType.EVENT,
            ),
        );
        return journeyDataSchema;
    }, [isJourneyNodeParamsLoading, journeyDataSchema]);

    if (isJourneyNodeParamsLoading) {
        return (
            <Stack className="gap-4">
                <SkeletonLoader height={70} />
            </Stack>
        );
    }
    if (!filteredJourneyDataSchema || !filteredJourneyEvents || !projectUuid)
        return null;

    return (
        <FiltersProvider projectUuid={projectUuid}>
            <Stack>
                {/* Restricting to filter rules for now. No multiple filter groups are allowed */}
                <JourneyEventFilters
                    journeyEvents={filteredJourneyEvents}
                    filterRules={filterRules}
                    isEditMode={isEditable}
                    onChange={() => {}}
                    filters={{ dimensions: filters?.journeyFilters } as Filters}
                    setFilters={(values) => {
                        setFilters({
                            journeyFilters:
                                values.dimensions as JourneyFilterGroup,
                            audienceFilters: undefined,
                            id: uuidv4(),
                        });
                    }}
                    eventConfig={eventConfig}
                    allFields={allFields}
                    setEventConfig={(event) => {
                        handleSetEvent(event.eventName, event.eventSource);
                    }}
                    journeyDataSchema={filteredJourneyDataSchema}
                    nodeID={nodeId}
                />
            </Stack>
        </FiltersProvider>
    );
};

export default React.memo(BaseTriggerEventFilter);
