import { TemplateActions } from '@components/Templates/TemplateActions';
import TemplateTitleModal from '@components/Templates/TemplateTitleModal';
import { type Action } from '@components/Templates/types';
import { useLocale } from '@hooks/useLocale';
import { ActionIcon, Button, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import useTemplateBuilderContext from '@providers/TemplateBuilder/useTemplateBuilderContext';
import React from 'react';

interface HeaderProps {
    onTitleChange: (title: string) => void;
    onAction: (action: Action) => void;
    onSave: () => void;
}

const Header: React.FC<HeaderProps> = ({ onAction, onSave }) => {
    const { t } = useLocale();
    const { setOpenCreateTemplateModal, setTemplatePayload } =
        useTemplateBuilderContext((context) => context.actions);
    const [titleModalOpened, { open: titleModalOpen, close: titleModalClose }] =
        useDisclosure(false);
    const { templatePayload } = useTemplateBuilderContext(
        (context) => context.state,
    );

    return (
        <Group
            className="sticky z-10 w-full px-3 py-2 bg-white border-b border-b-shade-6"
            position="apart"
            noWrap
        >
            <ActionIcon onClick={() => setOpenCreateTemplateModal(false)}>
                <CaretLeft weight="bold" />
            </ActionIcon>
            <TemplateActions
                title={templatePayload.name ?? ''}
                placeholder={t('campaigns_builder_editor.title.placeholder')}
                onTitleClick={titleModalOpen}
                onAction={onAction}
            />
            <Button
                rightIcon={<CaretRight weight="regular" color="white" />}
                onClick={onSave}
            >
                Continue
            </Button>
            <TemplateTitleModal
                isEditMode={true}
                tags={templatePayload?.tags ?? []}
                name={templatePayload?.name}
                description={templatePayload?.description ?? ''}
                opened={titleModalOpened}
                open={titleModalOpen}
                close={titleModalClose}
                handleTitleChange={(name, description, tags) => {
                    setTemplatePayload({
                        ...templatePayload,
                        name,
                        description,
                        tags,
                    });
                }}
            />
        </Group>
    );
};

export default Header;
