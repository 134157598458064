import { FilterInputComponent } from '@components/Audience/Filters/FilterInputs';
import { getFilterOperatorOptions } from '@components/Audience/Filters/FilterInputs/utils';
import { isAudienceField } from '@components/Audience/Filters/utils';
import FilterOperatorSelect from '@components/common/Select/FilterOperatorSelect';
import { useLocale } from '@hooks/useLocale';
import {
    FilterType,
    getFilterRuleWithDefaultValue,
    getFilterTypeFromItem,
    type FilterRule,
    type JourneyFilterRule,
} from '@lightdash/common';
import { ActionIcon, Box, Flex, Group, Text } from '@mantine/core';
import { X } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import { useMemo } from 'react';

interface EachSplitPathProps {
    filterRule: JourneyFilterRule | FilterRule;
    onChange: (filterRule: JourneyFilterRule | FilterRule) => void;
    nodeId: string;
    index: number;
    onDelete: () => void;
    destination: string;
}

const EachSplitPath: React.FC<EachSplitPathProps> = ({
    filterRule,
    onChange,
    nodeId,
    index,
    onDelete,
}) => {
    const { t } = useLocale();
    const { splitActiveFields } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const { activeRelation } = useRelationContext();
    const activeField = useMemo(() => {
        return splitActiveFields?.[nodeId]?.field;
    }, [nodeId, splitActiveFields]);
    const isActiveFieldAudience = useMemo(() => {
        return activeField && activeRelation && isAudienceField(activeField);
    }, [activeRelation, activeField]);
    const filterType = useMemo(() => {
        if (isActiveFieldAudience) {
            return FilterType.AUDIENCE;
        }
        return activeField
            ? getFilterTypeFromItem(activeField)
            : FilterType.STRING;
    }, [activeField, isActiveFieldAudience]);
    const filterOperatorOptions = useMemo(() => {
        return getFilterOperatorOptions(filterType);
    }, [filterType]);
    if (!activeField) return null;
    return (
        <Box className="p-1 border rounded-xl">
            <Flex
                justify="space-between"
                align="center"
                className="p-3 rounded-lg bg-gray-50"
            >
                <Flex gap={6}>
                    <Flex className="font-medium bg-white rounded-lg w-[1rem] flex  justify-center ">
                        {index + 1}
                    </Flex>
                    <Text className="font-semibold">
                        {t('common.path', { index: index + 1 })}
                    </Text>
                </Flex>
                <ActionIcon onClick={onDelete}>
                    <X weight="bold" />
                </ActionIcon>
            </Flex>
            <Box className="p-3">
                <Group className="flex flex-row items-center gap-1.5 w-full flex-nowrap">
                    <Box>
                        {filterRule?.operator && (
                            <FilterOperatorSelect
                                isDisabled={false}
                                filterOperatorOptions={filterOperatorOptions}
                                selectedOperator={
                                    filterRule?.operator as FilterRule['operator']
                                }
                                onChange={(value) => {
                                    if (!value) return;
                                    onChange(
                                        getFilterRuleWithDefaultValue(
                                            activeField,
                                            {
                                                ...filterRule,
                                                operator:
                                                    value as FilterRule['operator'],
                                            } as FilterRule,
                                            (filterRule?.values?.length || 0) >
                                                0
                                                ? filterRule?.values
                                                : [1],
                                        ),
                                    );
                                }}
                            />
                        )}
                    </Box>
                    <Box>
                        {filterType !== FilterType.AUDIENCE && (
                            <FilterInputComponent
                                filterType={filterType}
                                field={activeField}
                                rule={filterRule as FilterRule}
                                onChange={onChange}
                                disabled={false}
                                popoverProps={undefined}
                                dynamicFieldValues={[]}
                            />
                        )}
                    </Box>
                </Group>
            </Box>
        </Box>
    );
};

export default EachSplitPath;
