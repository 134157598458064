import SkeletonLoader from '@components/common/SkeletonLoader';
import { Box, Container, Flex, Group, Text, Title } from '@mantine/core';
import { type ManagerBuilderContainerProps } from '../builderContainerTypes';

const ManagerBuilderContainer: React.FC<ManagerBuilderContainerProps> = (
    props,
) => {
    return (
        // <Container fluid p={2} className="relative w-full p-0 bg-white rounded-2xl overflow-y-scroll px-2">
        <Container fluid p={2} className="relative w-full p-0 bg-gray">
            <Flex
                className=" py-3"
                gap={0}
                justify="space-between"
                align="flex-start"
                direction="row"
                wrap="wrap"
            >
                <Flex
                    gap={0}
                    justify="flex-start"
                    align="flex-start"
                    direction="column"
                    wrap="wrap"
                >
                    {props.isLoading ? (
                        <>
                            <SkeletonLoader />
                        </>
                    ) : (
                        <>
                            {props.title && (
                                <Title
                                    order={2}
                                    className="text-lg font-semibold text-gray-800"
                                >
                                    {props.title}
                                </Title>
                            )}
                            {props.description && (
                                <Text
                                    size={'xs'}
                                    className="text-xs text-gray-600"
                                >
                                    {props.description}
                                </Text>
                            )}
                            {props.leftSection && (
                                <Box>{props.leftSection}</Box>
                            )}
                        </>
                    )}
                </Flex>
                <Group>{props.rightSection}</Group>
            </Flex>
            {props.children && (
                <Box
                    className={`w-full bg-white ${
                        props.withContentPadding ? 'p-3.5' : ''
                    }`}
                >
                    {props.children}
                </Box>
            )}
        </Container>
    );
};
export default ManagerBuilderContainer;
