import TableIcon from '@components/common/IconPack/TableIcon';
import { useLocale } from '@hooks/useLocale';
import { RelationTableType } from '@lightdash/common';
import { Button, Menu } from '@mantine/core';
import useAiAnalystContext from '@providers/AiAnalyst/useAiAnalystContext';
import { PlusCircle } from 'react-feather';
import { FontSizes } from '../../../mantineTheme';

const AddTableBtn = ({
    onClick,
    open,
    setOpen,
}: {
    onClick: (type: RelationTableType) => void;
    open: boolean;
    setOpen: (val: boolean) => void;
}) => {
    const { t } = useLocale();
    const { showAiAnalyst } = useAiAnalystContext((context) => context.state);
    return (
        <Menu
            position="bottom-end"
            offset={2}
            withArrow
            arrowPosition="side"
            closeOnClickOutside
            closeOnEscape
            transitionProps={{
                transition: 'rotate-left',
                duration: 150,
            }}
            opened={open}
        >
            <Menu.Target>
                <Button
                    variant="primary"
                    leftIcon={<PlusCircle size={FontSizes.sm} />}
                    className={`absolute top-16  ${
                        showAiAnalyst ? 'right-[26vw]' : 'right-24'
                    }  z-10`}
                    onClick={() => setOpen(true)}
                >
                    {t('workflow_builder.add_table')}
                </Button>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item
                    onClick={() => onClick(RelationTableType.RELATED)}
                    icon={
                        <TableIcon
                            type={RelationTableType.RELATED}
                            size={FontSizes.lg}
                        />
                    }
                >
                    {t('workflow_builder.related_table')}
                </Menu.Item>

                <Menu.Item
                    onClick={() => onClick(RelationTableType.EVENT)}
                    icon={
                        <TableIcon
                            type={RelationTableType.EVENT}
                            size={FontSizes.lg}
                        />
                    }
                >
                    {t('workflow_builder.events')}
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};

export default AddTableBtn;
