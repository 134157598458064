import {
    createFilterRuleFromCampaignEvent,
    createFilterRuleFromEventNameField,
    isCampaignEvent,
    prepareAdditionalMetricData,
    shouldConvertFieldToAdditionalMetric,
} from '@components/Audience/utils';
import { type MetricFilterRuleWithFieldId } from '@components/Explorer/CustomMetricModal/FilterForm';
import { addFieldIdToMetricFilterRule } from '@components/Explorer/CustomMetricModal/utils';
import {
    addFilterRule,
    DimensionType,
    FieldType,
    FilterOperator,
    isCustomSqlDimension,
    isField,
    isFilterableField,
    MetricType,
    RelationTableType,
    ReservedAudienceKeys,
    type AdditionalMetric,
    type CompiledRelation,
    type CustomSqlDimension,
    type Filters,
    type MetricFilterRule,
} from '@lightdash/common';
import { getBaseTableUserFieldId } from '.';
import { type FieldWithSuggestions } from '../FiltersProvider/types';

interface CreateFilterRuleProps {
    field: FieldWithSuggestions;
    projectUuid: string;
    additionalMetrics: AdditionalMetric[] | undefined;
    setAdditionalMetrics:
        | ((
              value: AdditionalMetric[],
              shouldFetchResults: boolean,
              index: number,
          ) => void)
        | undefined;
    activeRelation: CompiledRelation | undefined;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    index: number;
    additionalMetricsFilters: MetricFilterRule[] | undefined;
    customDimensions: CustomSqlDimension[] | undefined;
    setCustomDimensions: (
        customDimensions: CustomSqlDimension[],
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    relatedOneToManyTables: string[];
}

interface FilterRuleResult {
    additionalMetricData?: AdditionalMetric;
    filterRule?: Filters;
    customDimensions?: CustomSqlDimension[];
}

/**
 * is customDimension in the custom Dimension of audience.
 * if it is, we need to add it to the customDimensions array
 */
const isCustomDimensionInAudience = (
    field: FieldWithSuggestions,
    customDimensions: CustomSqlDimension[] | undefined,
) => {
    if (!isCustomSqlDimension(field)) return false;
    return customDimensions?.some(
        (customDimension) => customDimension.id === field.id,
    );
};

/**
 * Helper function to prepare data for additional metrics and filter rules.
 * @param props - Properties required to create filter rules.
 * @returns An object containing additional metric data and filter rule.
 */
export const prepareFilterRuleData = ({
    field,
    activeRelation,
    filters,
    additionalMetricsFilters,
    projectUuid,
    customDimensions,
    relatedOneToManyTables,
}: Omit<
    CreateFilterRuleProps,
    'setAdditionalMetrics' | 'setFilters' | 'setCustomDimensions'
>): FilterRuleResult => {
    let value: string | number | boolean | undefined = undefined;
    if (
        activeRelation &&
        shouldConvertFieldToAdditionalMetric({
            field,
            activeRelation,
            relatedOneToManyTables,
        })
    ) {
        let newMetricFilters: MetricFilterRuleWithFieldId[] =
            additionalMetricsFilters?.map(addFieldIdToMetricFilterRule) ?? [];

        if (
            field.tableType === RelationTableType.EVENT &&
            field.type === DimensionType.EVENT
        ) {
            newMetricFilters = [
                ...newMetricFilters,
                addFieldIdToMetricFilterRule(
                    createFilterRuleFromEventNameField(
                        field,
                        field.fieldReference ?? '',
                    ),
                ),
            ];
        }
        if (isCampaignEvent(field)) {
            /**
             * Here we create the filter rule from the campaign event
             * to achieve this using two filter rules:
             * 1. to make sure the eventName is the same as the field name
             * 2. to make sure the channel is the same as the field uniqueIdentifier
             */
            const campaignFilterRules = createFilterRuleFromCampaignEvent(
                field,
                projectUuid,
            );
            newMetricFilters = [
                ...newMetricFilters,
                ...campaignFilterRules?.map(addFieldIdToMetricFilterRule),
            ];
        }

        const data = prepareAdditionalMetricData({
            item: field,
            type: MetricType.COUNT_DISTINCT,
            metricFilters: newMetricFilters,
            activeRelation,
            projectUuid,
        });
        if (!data) return {};

        const newFilterRule = addFilterRule({
            filters,
            field: {
                ...data,
                isAutoGenerated: false,
                fieldType: FieldType.METRIC,
                label: data.label ?? '',
                tableLabel: data.tableLabel ?? '',
                hidden: false,
            },
            value: 1,
            operator: FilterOperator.GREATER_THAN_OR_EQUAL,
        });

        return {
            additionalMetricData: data,
            filterRule: newFilterRule,
        };
    }

    if (isCustomSqlDimension(field)) {
        if (isCustomDimensionInAudience(field, customDimensions)) {
            return {
                filterRule: addFilterRule({
                    filters,
                    field,
                    value: '',
                    operator: FilterOperator.EQUALS,
                }),
            };
        }
        return {
            customDimensions: [...(customDimensions ?? []), field],
            filterRule: addFilterRule({
                filters,
                field,
                value: '',
                operator: FilterOperator.EQUALS,
            }),
        };
    }

    if (field.tableType === RelationTableType.AUDIENCE) {
        value = `${ReservedAudienceKeys.SRT_AUDIENCE}${field.table}`;
        field.name = activeRelation
            ? getBaseTableUserFieldId(activeRelation)
            : field.name;
    }
    if (isField(field) && isFilterableField(field)) {
        const newFilterRule = addFilterRule({
            filters,
            field,
            ...(Boolean(value) && { value }),
        });
        return { filterRule: newFilterRule };
    }

    return {};
};

/**
 * Main function to create filter rules and update state.
 * @param props - Properties required to create filter rules.
 */
export const createFilterRule = (props: CreateFilterRuleProps) => {
    const { setAdditionalMetrics, setFilters, index, setCustomDimensions } =
        props;
    const { additionalMetricData, filterRule, customDimensions } =
        prepareFilterRuleData(props);

    if (additionalMetricData && setAdditionalMetrics) {
        setAdditionalMetrics(
            [...(props.additionalMetrics ?? []), additionalMetricData],
            false,
            index,
        );
    }

    if (filterRule) {
        setFilters(filterRule, false, index);
    }
    if (customDimensions && setCustomDimensions) {
        setCustomDimensions(customDimensions, false, index);
    }
};
