import { JoinType, RelationTableType } from '../types/relation/enums';
import { type CompiledRelation } from '../types/relation/relations';

// Whether a table has one to many relationship with primary table
export const isOneToManyRelation = (
    relation: CompiledRelation,
    tableName: string,
): boolean => {
    if (tableName === relation.baseTable || !relation.tables[tableName]) {
        return false;
    }

    if (relation.tables[tableName].type !== RelationTableType.RELATED) {
        return false;
    }

    for (const joinedTable of relation.joinedTables) {
        if (tableName === joinedTable.target.table) {
            if (
                joinedTable.relationType === JoinType.one_many ||
                joinedTable.relationType === JoinType.many_many
            ) {
                return true;
            }
            return isOneToManyRelation(relation, joinedTable.source.table);
        }
    }

    return false;
};
