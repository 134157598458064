import Checkbox from '@components/common/Checkbox';
import UnsavedChangesModal from '@components/common/modal/UnsavedChangesModal';
import { extractDate, extractTime } from '@components/Journeys/utils';
import { useActiveProjectUuid } from '@hooks/useActiveProject';
import { useLocale } from '@hooks/useLocale';
import { useProject } from '@hooks/useProject';
import useTimestamp from '@hooks/useTimestamp';
import { Timezones } from '@lightdash/common';
import { Box, Input, Text } from '@mantine/core';
import { DateInput, TimeInput } from '@mantine/dates';
import { Calendar, Clock } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import moment from 'moment';
import React, { useCallback, useMemo, useRef, useState } from 'react';

interface StatusContentProps {
    startText: string;
    endText: string;
    isStartCheck: boolean;
    isEndCheck: boolean;
    isStartReadOnly?: boolean;
    isEndReadOnly?: boolean;
}

const StatusContent: React.FC<StatusContentProps> = ({
    startText,
    endText,
    isStartCheck,
    isEndCheck,
    isStartReadOnly,
    isEndReadOnly,
}) => {
    const { t } = useLocale();
    const { getTimestamp } = useTimestamp();
    const startTimeRef = useRef<HTMLInputElement>(null);
    const endTimeRef = useRef<HTMLInputElement>(null);
    const { journeyPayload, initialJourneyPayload } = useJourneyBuilderContext(
        (context) => context.state,
    );
    const { updateJourneyPayload, mutateAsyncJourney } =
        useJourneyBuilderContext((actions) => actions.actions);
    const { activeProjectUuid } = useActiveProjectUuid();
    const { data: projectData } = useProject(activeProjectUuid);
    const projectTimezone = projectData?.timezone ?? Timezones.UTC;

    const [startTimeError, setStartTimeError] = useState(false);
    const [endTimeError, setEndTimeError] = useState(false);
    const [startChecked, setStartChecked] = useState(
        !!journeyPayload.startDate,
    );
    const [endChecked, setEndChecked] = useState(!!journeyPayload.endDate);
    const [isDataChanged, setIsDataChanged] = useState(false);

    const offset = moment().tz(projectTimezone).format('Z');

    const convertToProjectTimezone = useCallback(
        (date: Date) => {
            const localTime = moment(date);
            const projectTime = moment.tz(
                localTime.format('YYYY-MM-DDTHH:mm:ss'),
                projectTimezone,
            );
            return new Date(projectTime.format());
        },
        [projectTimezone],
    );

    const handleDateChange = useCallback(
        (dateType: 'startDate' | 'endDate', value: Date) => {
            setIsDataChanged(true);
            const datetime = convertToProjectTimezone(value);
            updateJourneyPayload({ [dateType]: datetime });
        },
        [convertToProjectTimezone, updateJourneyPayload],
    );

    const handleTimeChange = useCallback(
        (timeType: 'startDate' | 'endDate', value: string) => {
            const [hours, minutes] = value.split(':');
            if (Number(minutes) % 15 !== 0) {
                if (timeType === 'startDate') setStartTimeError(true);
                if (timeType === 'endDate') setEndTimeError(true);
                return;
            }

            setIsDataChanged(true);
            if (timeType === 'startDate') setStartTimeError(false);
            if (timeType === 'endDate') setEndTimeError(false);

            const date =
                journeyPayload &&
                journeyPayload[timeType] &&
                getTimestamp(
                    new Date(journeyPayload[timeType] ?? '').getTime(),
                );

            if (!date) return;

            const datePart = date.split('T')[0];
            const newDate = new Date(datePart);
            newDate.setHours(parseInt(hours, 10));
            newDate.setMinutes(parseInt(minutes, 10));
            const datetime = convertToProjectTimezone(newDate);
            updateJourneyPayload({ [timeType]: datetime });
        },
        [
            convertToProjectTimezone,
            getTimestamp,
            journeyPayload,
            updateJourneyPayload,
        ],
    );

    const handleSave = useCallback(async () => {
        setIsDataChanged(false);
        await mutateAsyncJourney();
    }, [mutateAsyncJourney]);

    const startDate = useMemo(
        () =>
            journeyPayload.startDate
                ? extractDate(
                      getTimestamp(
                          new Date(journeyPayload.startDate).getTime(),
                      ),
                  )
                : undefined,
        [journeyPayload, getTimestamp],
    );
    const endDate = useMemo(
        () =>
            journeyPayload.endDate
                ? extractDate(
                      getTimestamp(new Date(journeyPayload.endDate).getTime()),
                  )
                : undefined,
        [journeyPayload, getTimestamp],
    );

    const { hours: startHours, minutes: startMinutes } =
        journeyPayload.startDate
            ? extractTime(
                  getTimestamp(new Date(journeyPayload.startDate).getTime()),
              )
            : { hours: '', minutes: '' };
    const { hours: endHours, minutes: endMinutes } = journeyPayload.endDate
        ? extractTime(getTimestamp(new Date(journeyPayload.endDate).getTime()))
        : { hours: '', minutes: '' };

    return (
        <Box className="p-3">
            {isStartCheck ? (
                <Checkbox
                    radius="xs"
                    checked={startChecked}
                    onChange={(e) => setStartChecked(e.target.checked)}
                    label={startText}
                />
            ) : (
                <Text>{startText}</Text>
            )}

            <Box>
                {(!isStartCheck || startChecked) && (
                    <Box className="flex my-3 space-x-2">
                        <DateInput
                            valueFormat="DD MMM YYYY"
                            placeholder="DD/MM/YYYY"
                            icon={
                                <Calendar
                                    size={13}
                                    strokeWidth={2}
                                    color="rgb(var(--color-gray-600))"
                                />
                            }
                            minDate={new Date()}
                            onChange={(value: Date) =>
                                handleDateChange('startDate', value)
                            }
                            value={startDate}
                            readOnly={isStartReadOnly}
                        />
                        <Text className="my-2">at</Text>
                        <TimeInput
                            ref={startTimeRef}
                            icon={
                                <Clock
                                    size={13}
                                    strokeWidth={2}
                                    color="rgb(var(--color-gray-600))"
                                />
                            }
                            onClick={() => startTimeRef?.current?.showPicker()}
                            onChange={(event) =>
                                handleTimeChange(
                                    'startDate',
                                    event.target.value,
                                )
                            }
                            error={
                                startTimeError &&
                                t('campaign_scheduler.minutes_error')
                            }
                            className="w-[120px]"
                            value={`${startHours}:${startMinutes}`}
                            readOnly={isStartReadOnly}
                        />
                        <Input.Label className="my-2 text-sm font-normal text-gray-600">
                            {`(GMT${offset})`}
                        </Input.Label>
                    </Box>
                )}
                {isEndCheck ? (
                    <Checkbox
                        className="my-3"
                        checked={endChecked}
                        onChange={(e) => setEndChecked(e.target.checked)}
                        label={endText}
                    />
                ) : (
                    <Text>{endText}</Text>
                )}

                {(!isEndCheck || endChecked) && (
                    <Box className="flex my-3 space-x-2">
                        <DateInput
                            valueFormat="DD MMM YYYY"
                            placeholder="DD/MM/YYYY"
                            icon={
                                <Calendar
                                    size={13}
                                    strokeWidth={2}
                                    color="rgb(var(--color-gray-600))"
                                />
                            }
                            minDate={startDate}
                            onChange={(value: Date) =>
                                handleDateChange('endDate', value)
                            }
                            value={endDate}
                            readOnly={isEndReadOnly}
                        />
                        <Text className="my-2">at</Text>
                        <TimeInput
                            ref={endTimeRef}
                            icon={
                                <Clock
                                    size={13}
                                    strokeWidth={2}
                                    color="rgb(var(--color-gray-600))"
                                />
                            }
                            onClick={() => endTimeRef?.current?.showPicker()}
                            onChange={(event) =>
                                handleTimeChange('endDate', event.target.value)
                            }
                            error={
                                endTimeError &&
                                t('campaign_scheduler.minutes_error')
                            }
                            className="w-[120px]"
                            value={`${endHours}:${endMinutes}`}
                            readOnly={isEndReadOnly}
                        />
                        <Input.Label className="my-2 text-sm font-normal text-gray-600">
                            {`(GMT${offset})`}
                        </Input.Label>
                    </Box>
                )}
            </Box>
            <UnsavedChangesModal
                opened={isDataChanged}
                secondaryActionButtonClick={() => {
                    setIsDataChanged(false);
                    updateJourneyPayload({
                        startDate: initialJourneyPayload.startDate,
                        endDate: initialJourneyPayload.endDate,
                    });
                }}
                disableButtons={false}
                primaryActionButtonClick={handleSave}
            />
        </Box>
    );
};

export default StatusContent;
