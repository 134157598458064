import FilterSelectWrapper from '@components/common/Filters/FilterInputs/FilterSelectWrapper';
import TextInputVariableWidth from '@components/common/Inputs/TextInputVariableWidth';
import FilterOperatorSelect from '@components/common/Select/FilterOperatorSelect';
import { useLocale } from '@hooks/useLocale';
import {
    FilterOperator,
    getItemId,
    type AdditionalMetric,
    type FilterableField,
    type FilterRule,
    type Filters,
} from '@lightdash/common';
import { Group, Text } from '@mantine/core';
import useAudienceContext from '@providers/Audience/useAudienceContext';
import useRelationContext from '@providers/Relation/useRelationContext';
import React, { useMemo } from 'react';
import FilterFormPropertySelect from '../../FilterFormPropertySelect';
import { FilterFieldSelectItem } from '../../FilterInputs/FilterFieldSelect';
import {
    getDerivedCustomMetricFilterOptions,
    getMetricEventFilterOptions,
} from '../../FilterInputs/utils';
import useFiltersContext from '../../FiltersProvider/useFiltersContext';

interface RelatedRecordFilterRuleFormProps {
    isEditMode: boolean;
    selectedToggleOperator: FilterOperator;
    handleToggleChange: (inputValue: string) => void;
    selectedConditionalOperator: FilterOperator;
    handleOperatorChange: (changedValue: FilterOperator) => void;
    filterRule: FilterRule;
    handleValueChange: (value: number) => void;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    groupIndex: number;
    additionalMetrics: AdditionalMetric[];
    fields: FilterableField[];
}
const RelatedRecordFilterRuleForm = ({
    isEditMode,
    selectedToggleOperator,
    handleToggleChange,
    selectedConditionalOperator,
    handleOperatorChange,
    filterRule,
    handleValueChange,
    filters,
    setFilters,
    groupIndex,
    additionalMetrics,
    fields,
}: RelatedRecordFilterRuleFormProps) => {
    const { t } = useLocale();
    const { relatedOneToManyTables } = useFiltersContext();
    const { activeRelation } = useRelationContext();
    const setAdditionalMetrics = useAudienceContext(
        (context) => context.actions.setAdditionalMetrics,
    );

    const activeField = useMemo(() => {
        return fields.find(
            (field) => getItemId(field) === filterRule.target.fieldId,
        );
    }, [filterRule, fields]);

    return (
        <Group className="gap-1.5">
            <FilterOperatorSelect
                isDisabled={!isEditMode}
                filterOperatorOptions={getDerivedCustomMetricFilterOptions([
                    FilterOperator.EQUALS,
                    FilterOperator.NOT_EQUALS,
                ])}
                selectedOperator={selectedToggleOperator}
                onChange={(inputValue) => {
                    if (!inputValue) return;
                    handleToggleChange(inputValue);
                }}
            />

            <FilterOperatorSelect
                isDisabled={!isEditMode}
                filterOperatorOptions={getMetricEventFilterOptions([
                    FilterOperator.EQUALS,
                    FilterOperator.GREATER_THAN_OR_EQUAL,
                    FilterOperator.LESS_THAN_OR_EQUAL,
                ])}
                selectedOperator={selectedConditionalOperator}
                onChange={(changedValue) => {
                    if (!changedValue) return;
                    handleOperatorChange(changedValue as FilterOperator);
                }}
            />
            <TextInputVariableWidth
                value={filterRule.values?.[0]}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleValueChange(Number(event.target.value));
                }}
                minWidth={40}
                maxWidth={100}
                type="number"
            />

            <Text className="text-sm font-medium text-gray-600">
                {filterRule.values?.[0] >= 1
                    ? t('derived_metric_filter_rule_form.record_in_one')
                    : t('derived_metric_filter_rule_form.record_in_many')}
            </Text>
            <FilterFormPropertySelect
                filters={filters}
                setFilters={setFilters}
                index={groupIndex}
                additionalMetrics={additionalMetrics}
                setAdditionalMetrics={setAdditionalMetrics}
                targetButton={
                    <FilterSelectWrapper isEditMode={isEditMode}>
                        <FilterFieldSelectItem
                            activeField={activeField}
                            isEditMode={isEditMode}
                            showFieldSource={false}
                            activeRelation={activeRelation}
                            showHoverIcon={false}
                            showRelatedTableLabelOnly={true}
                            isOneToManyTable={
                                relatedOneToManyTables?.includes(
                                    activeField?.table ?? '',
                                ) ?? false
                            }
                            isRelatedRecord={true}
                        />
                    </FilterSelectWrapper>
                }
                customDimensions={undefined}
                setCustomDimensions={() => {}}
            />
        </Group>
    );
};

export default React.memo(RelatedRecordFilterRuleForm);
