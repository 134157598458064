import AiChart from '@components/AIWidget/AiChart';

import { useLocale } from '@hooks/useLocale';
import {
    AIMessageContext,
    Author,
    type AgentMessage,
    type Chart,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import { Sparkle } from '@phosphor-icons/react';
import { motion } from 'framer-motion';
import React, { memo, useState } from 'react';
import StreamingText from './StreamingText';

const PurePreviewMessage = ({
    message,
    index,
}: {
    chatId: string;
    message: AgentMessage;
    isLoading: boolean;
    index: number;
}) => {
    const [streamedMessages, setStreamedMessages] = useState<number[]>([]);

    return (
        <motion.div
            className="w-full mx-auto max-w-3xl px-4 group/message"
            initial={{ y: 5, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            data-role={message.author}
        >
            <Box
                className={`flex gap-4 w-full group-data-[role=user]/message:ml-auto group-data-[role=user]/message:max-w-2xl group-data-[role=user]/message:w-fit`}
            >
                <Box className="flex flex-col gap-2 w-full">
                    <Box className="flex flex-row gap-2 items-start">
                        <Box
                            className={`flex flex-col gap-4 ${
                                message.author === 'user'
                                    ? 'bg-blu-800 text-white px-3 py-2 rounded-xl'
                                    : 'text-white'
                            }`}
                        >
                            {message.context === AIMessageContext.CHART &&
                            (message.content.value as Chart).context ===
                                AIMessageContext.CHART ? (
                                <AiChart
                                    metricQuery={
                                        (message.content.value as Chart)
                                            .metricQuery
                                    }
                                    chartConfig={
                                        (message.content.value as Chart)
                                            .chartConfig
                                    }
                                />
                            ) : (
                                <StreamingText
                                    stream={message.author === Author.SYSTEM}
                                    streamedMessages={streamedMessages}
                                    setStreamedMessages={setStreamedMessages}
                                    index={index}
                                    html={message.content.value as string}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </motion.div>
    );
};

export const PreviewMessage = memo(
    PurePreviewMessage,
    (prevProps, nextProps) => {
        if (prevProps.isLoading !== nextProps.isLoading) return false;
        if (prevProps.message.content.value !== nextProps.message.content.value)
            return false;
        return true;
    },
);

interface ThinkingMessageProps {
    label: string | undefined;
}

export const ThinkingMessage = ({ label }: ThinkingMessageProps) => {
    const role = Author.SYSTEM;
    const { t } = useLocale();
    return (
        <motion.div
            className="w-full mx-auto max-w-3xl px-4 group/message "
            initial={{ y: 5, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 1 } }}
            data-role={role}
        >
            <Box className="flex items-center gap-4 group-data-[role=user]/message:px-3 w-full group-data-[role=user]/message:w-fit group-data-[role=user]/message:ml-auto group-data-[role=user]/message:max-w-2xl group-data-[role=user]/message:py-2 rounded-xl group-data-[role=user]/message:bg-muted">
                <Box className="size-8 flex items-center rounded-full justify-center ring-1 shrink-0 ring-border">
                    <Sparkle size={14} />
                </Box>

                <Box className="flex flex-col gap-2 w-full">
                    <Box className="flex flex-col gap-4 text-gray-600">
                        {label || t('chat.thinking')}
                    </Box>
                </Box>
            </Box>
        </motion.div>
    );
};
