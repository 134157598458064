import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type CustomAttribute,
    type CustomAttributeRequest,
    type CustomAttributeTagsUpdateRequest,
    type GenerateCustomAttribute,
    type InsertCustomAttribute,
} from '@lightdash/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

const getCustomMetrics = async (projectUuid: string, query?: string) =>
    sortmentApi<CustomAttribute[]>({
        url: `/projects/${projectUuid}/custom-attributes${
            query ? `?${query}` : ''
        }`,
        method: 'GET',
        body: undefined,
    });

const getCustomMetricById = async (
    projectUuid: string,
    customMetricUuid: string,
) =>
    sortmentApi<CustomAttribute>({
        url: `/projects/${projectUuid}/custom-attributes/${customMetricUuid}`,
        method: 'GET',
        body: undefined,
    });

const createCustomMetric = async (
    data: InsertCustomAttribute,
    projectUuid: string,
) =>
    sortmentApi<CustomAttribute>({
        url: `/projects/${projectUuid}/custom-attributes`,
        method: 'POST',
        body: JSON.stringify(data),
    });
export const useGetCustomMetrics = ({ query }: { query?: string }) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const customMetrics = useQuery<CustomAttribute[], ApiError>({
        queryKey: [QueryKeys.GET_CUSTOM_METRICS, projectUuid, query],
        queryFn: () => getCustomMetrics(projectUuid, query),
    });
    return customMetrics;
};
export const useGetCustomMetricById = (customMetricUuid: string) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    return useQuery<CustomAttribute, ApiError>({
        queryKey: [QueryKeys.GET_CUSTOM_METRICS_BY_ID, projectUuid],
        queryFn: () => getCustomMetricById(projectUuid, customMetricUuid),
        enabled: !!projectUuid && !!customMetricUuid,
        retry: false,
    });
};

export const useCreateCustomMetricMutation = () => {
    const { showToastError, showToastSuccess } = useNotify();
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    const queryClient = useQueryClient();
    const { t } = useLocale();
    return useMutation<CustomAttribute, ApiError, InsertCustomAttribute>(
        (data) => createCustomMetric(data, projectUuid),
        {
            mutationKey: [QueryKeys.CREATE_CUSTOM_METRIC],
            onSuccess: async () => {
                await queryClient.invalidateQueries([
                    QueryKeys.GET_CUSTOM_METRICS,
                ]);
                await queryClient.invalidateQueries([
                    QueryKeys.RELATION_SCHEMA,
                ]);
                showToastSuccess({
                    title: t('create_custom_metric.toast_success_message'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('create_custom_metric.toast_failure_message'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const updateCustomMetricTags = async (
    projectUuid: string,
    customMetricUuid: string,
    tags: CustomAttributeTagsUpdateRequest,
) =>
    sortmentApi<CustomAttribute>({
        url: `/projects/${projectUuid}/custom-attributes/${customMetricUuid}/tags`,
        method: 'PATCH',
        body: JSON.stringify(tags),
    });

export const useUpdateCustomMetricTags = (customMetricUuid: string) => {
    const { projectUuid = '' } = useParams<{ projectUuid: string }>();
    return useMutation<
        CustomAttribute,
        ApiError,
        CustomAttributeTagsUpdateRequest
    >((tags: CustomAttributeTagsUpdateRequest) =>
        updateCustomMetricTags(projectUuid, customMetricUuid, tags),
    );
};

const generateCustomAttribute = async (
    projectUuid: string,
    request: CustomAttributeRequest,
) =>
    sortmentApi<GenerateCustomAttribute>({
        url: `/projects/${projectUuid}/custom-attributes/ai/custom-attribute`,
        method: 'POST',
        body: JSON.stringify(request),
    });

export const useGenerateCustomAttribute = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const { showToastError } = useNotify();
    return useMutation<
        GenerateCustomAttribute,
        ApiError,
        CustomAttributeRequest
    >(
        (request) => {
            if (!projectUuid) {
                throw new Error(t('error.project_uuid_required'));
            }
            return generateCustomAttribute(projectUuid, request);
        },
        {
            mutationKey: [QueryKeys.GENERATE_CUSTOM_ATTRIBUTE],
            onError: (error) => {
                showToastError({
                    title: t('create_custom_metric.ai_toast_failure_message'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};
