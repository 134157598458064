import { type FieldValueProperty } from '@components/Audience/Filters/FiltersProvider/types';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { useLocale } from '@hooks/useLocale';
import {
    FilterGroupOperator,
    getFilterGroupItemsPropertyName,
    getItemId as getFieldId,
    getItemsFromFilterGroup,
    isAndFilterGroup,
    isFilterGroup,
    type AdditionalMetric,
    type CompiledRelation,
    type FilterableField,
    type FilterGroup,
    type FilterRule,
    type Filters,
} from '@lightdash/common';
import { Box, Button, Stack, Text } from '@mantine/core';
import { useCallback, type FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ButtonVariant } from '../../../mantineTheme';
import {
    isDerivedAdditionalMetric,
    isEventDimension,
    isEventMetricCreatedByAdditionalMetric,
} from '../utils';
import FilterRuleForm from './FilterRules/FilterRuleForm';
import useFiltersContext from './FiltersProvider/useFiltersContext';
import { isAudienceFilterRule, isFilterRuleAudience } from './utils';

type Props = {
    hideButtons?: boolean;
    hideLine?: boolean;
    allowConvertToGroup?: boolean;
    conditionLabel: string;
    fields: FilterableField[];
    filterGroup: FilterGroup;
    isEditMode: boolean;
    onChange: (value: FilterGroup) => void;
    onDelete: () => void;
    nestedLevel?: number;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    groupIndex: number;
    relation: CompiledRelation | undefined;
    showFieldSource?: boolean;
    additionalMetrics: AdditionalMetric[] | undefined;
    setAdditionalMetrics:
        | ((
              value: AdditionalMetric[],
              shouldFetchResults: boolean,
              index: number,
          ) => void)
        | undefined;
    customCss?: string;
    dynamicFieldValues:
        | PropertySelectListType<FieldValueProperty>[]
        | undefined;
};

const FilterGroupForm: FC<Props> = ({
    allowConvertToGroup,
    conditionLabel,
    fields,
    filterGroup,
    isEditMode,
    onChange,
    onDelete,
    nestedLevel = 1,
    filters,
    setFilters,
    groupIndex,
    showFieldSource = true,
    relation,
    additionalMetrics,
    setAdditionalMetrics,
    customCss,
    dynamicFieldValues,
}) => {
    const { t } = useLocale();
    const items = getItemsFromFilterGroup(filterGroup);
    const marginLeft = nestedLevel > 1 ? `ml-8` : '';
    const { eventsMap, fieldsMap } = useFiltersContext();
    const onDeleteItem = useCallback(
        (index: number) => {
            if (items.length <= 1) {
                onDelete();
                return;
            }

            const newItems = items.filter((_, i) => i !== index);

            onChange({
                ...filterGroup,
                [getFilterGroupItemsPropertyName(filterGroup)]: newItems,
            });

            if (newItems.length === 0) {
                onDelete();
            }
        },
        [filterGroup, items, onChange, onDelete],
    );
    const onChangeItem = useCallback(
        (index: number, item: FilterRule | FilterGroup) => {
            onChange({
                ...filterGroup,
                [getFilterGroupItemsPropertyName(filterGroup)]: [
                    ...items.slice(0, index),
                    item,
                    ...items.slice(index + 1),
                ],
            });
        },
        [filterGroup, items, onChange],
    );

    const onChangeOperator = useCallback(
        (value: FilterGroupOperator) => {
            onChange({
                id: filterGroup.id,
                [value]: items,
            } as FilterGroup);
        },
        [filterGroup, items, onChange],
    );

    const getFilterRulePrefix = useCallback(
        (item: FilterRule) => {
            const fieldId = item.target.fieldId;
            const field = fieldsMap[fieldId];
            if (!field) return null;
            const selectedField = fields.find(
                (updatedField) => getFieldId(updatedField) === fieldId,
            );
            if (
                isEventDimension(item, fieldsMap, eventsMap) &&
                !isEventMetricCreatedByAdditionalMetric(
                    field,
                    eventsMap,
                    additionalMetrics,
                )
            )
                return null;
            if (
                selectedField &&
                relation &&
                (isAudienceFilterRule(item, fields) ||
                    isFilterRuleAudience(item, relation))
            ) {
                return t('filter_group_form.group_prefix_who');
            }
            if (
                isEventMetricCreatedByAdditionalMetric(
                    field,
                    eventsMap,
                    additionalMetrics,
                )
            )
                return t('filter_group_form.group_prefix_who');
            if (
                additionalMetrics &&
                isDerivedAdditionalMetric(field, additionalMetrics)
            ) {
                return t('filter_group_form.group_prefix_who');
            }
            return t('filter_group_form.group_prefix_where');
        },
        [fields, relation, t, eventsMap, fieldsMap, additionalMetrics],
    );

    return (
        <Stack pos="relative">
            <Stack style={{ flexGrow: 1 }} className="gap-2">
                {items.map((item, index) => (
                    <Box
                        key={item.id}
                        className={`flex flex-row items-baseline w-full gap-2 ${
                            index > 0 ? marginLeft : ''
                        } ${
                            !isFilterGroup(item) &&
                            isEventDimension(item, fieldsMap, eventsMap) &&
                            !isEventMetricCreatedByAdditionalMetric(
                                fieldsMap[item.target.fieldId],
                                eventsMap,
                                additionalMetrics,
                            )
                                ? 'pl-8'
                                : ''
                        }`}
                    >
                        {!isFilterGroup(item) ? (
                            <>
                                <Box
                                    className={`w-16 text-sm text-gray-600 text-end ${customCss}`}
                                >
                                    {Boolean(index === 0) &&
                                    !(
                                        isEventDimension(
                                            item,
                                            fieldsMap,
                                            eventsMap,
                                        ) &&
                                        !isEventMetricCreatedByAdditionalMetric(
                                            fieldsMap[item.target.fieldId],
                                            eventsMap,
                                            additionalMetrics,
                                        )
                                    ) ? (
                                        <Text className="text-sm font-normal">
                                            {getFilterRulePrefix(item)}
                                        </Text>
                                    ) : (
                                        <Button
                                            onClick={() =>
                                                onChangeOperator(
                                                    isAndFilterGroup(
                                                        filterGroup,
                                                    )
                                                        ? FilterGroupOperator.or
                                                        : FilterGroupOperator.and,
                                                )
                                            }
                                            disabled={!isEditMode}
                                            variant={ButtonVariant.SUBTLE}
                                            className="p-2 text-sm font-normal text-gray-600 border-dashed hover:font-medium border-base border-gray-250"
                                        >
                                            {isAndFilterGroup(filterGroup)
                                                ? FilterGroupOperator.and
                                                : FilterGroupOperator.or}
                                        </Button>
                                    )}
                                </Box>
                                <FilterRuleForm
                                    filterRule={item}
                                    fields={fields}
                                    isEditMode={isEditMode}
                                    onChange={(value) =>
                                        onChangeItem(index, value)
                                    }
                                    onDelete={() => onDeleteItem(index)}
                                    onConvertToGroup={
                                        allowConvertToGroup
                                            ? () => {
                                                  onChangeItem(index, {
                                                      id: uuidv4(),
                                                      and: [item],
                                                  });
                                              }
                                            : undefined
                                    }
                                    filters={filters}
                                    setFilters={setFilters}
                                    groupIndex={groupIndex}
                                    showFieldSource={showFieldSource}
                                    additionalMetrics={additionalMetrics}
                                    dynamicFieldValues={dynamicFieldValues}
                                />
                            </>
                        ) : (
                            <Box>
                                <FilterGroupForm
                                    allowConvertToGroup={false}
                                    isEditMode={isEditMode}
                                    filterGroup={item}
                                    conditionLabel={conditionLabel}
                                    fields={fields}
                                    onChange={(value) =>
                                        onChangeItem(index, value)
                                    }
                                    onDelete={() => onDeleteItem(index)}
                                    nestedLevel={nestedLevel + 1}
                                    filters={filters}
                                    setFilters={setFilters}
                                    groupIndex={groupIndex}
                                    relation={relation}
                                    additionalMetrics={additionalMetrics}
                                    setAdditionalMetrics={setAdditionalMetrics}
                                    dynamicFieldValues={dynamicFieldValues}
                                />
                            </Box>
                        )}
                    </Box>
                ))}
            </Stack>
        </Stack>
    );
};

export default FilterGroupForm;
