import { type FieldValueProperty } from '@components/Audience/Filters/FiltersProvider/types';
import PropertySelect from '@components/common/Select/PropertySelect';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { FiltersPropertySelectorFieldItem } from '@components/Journeys/Builder/JourneyFilters/FiltersForm/FiltersPropertySelector';
import { useLocale } from '@hooks/useLocale';
import { getDynamicItemId } from '@lightdash/common';
import { ActionIcon } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { At } from '@phosphor-icons/react';
import React from 'react';

interface FieldValuePropertySelectProps {
    fields: PropertySelectListType<FieldValueProperty>[];
    disabled: boolean;
    onSubmit: (value: string) => void;
}

const FieldValuePropertySelect: React.FC<FieldValuePropertySelectProps> = ({
    fields,
    disabled,
    onSubmit,
}) => {
    const [opened, { open, close }] = useDisclosure();
    const { t } = useLocale();

    const handleSubmit = (field: FieldValueProperty[]) => {
        if (!field[0]) return;
        const fieldValue = field[0];
        if (!fieldValue) return;
        const fieldId = getDynamicItemId(fieldValue);
        if (!fieldId) return;
        onSubmit(fieldId);
    };

    if (!fields.length) return null;

    return (
        <PropertySelect<FieldValueProperty>
            items={fields}
            showGroup={true}
            headerRightSection={null}
            onSubmit={(field: FieldValueProperty[]) => {
                handleSubmit(field);
                close();
            }}
            itemTemplate={({ item }) => {
                return <FiltersPropertySelectorFieldItem item={item} />;
            }}
            opened={opened}
            close={close}
            open={open}
            withinPortal={true}
            targetButton={
                <ActionIcon
                    className="w-full"
                    onClick={() => {
                        open();
                    }}
                >
                    <At size={14} />
                </ActionIcon>
            }
            showSearch={true}
            searchKeys={['label', 'name', 'tableLabel']}
            searchPlaceholder={t('audience_filters.search_filter_label')}
            allowMultipleSelection={false}
            showAllItemsGroup={true}
            width={500}
            height={350}
            disabled={disabled}
        />
    );
};

export default React.memo(FieldValuePropertySelect);
