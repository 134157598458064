import FieldListItem from '@components/Audience/Filters/FieldListItem';
import {
    convertFieldsIntoPropertySelectListType,
    type FieldWithSelectStatus,
} from '@components/Audience/Filters/FieldListItem/utils';
import PropertySelect from '@components/common/Select/PropertySelect';
import { type AddditionalPropertySelectListProps } from '@components/common/Select/PropertySelect/type';
import CreateCustomMetric from '@components/CustomMetric/CreateCustomMetric';
import { useLocale } from '@hooks/useLocale';
import { FieldType, type CompiledRelationTable } from '@lightdash/common';
import { Button } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { ButtonVariant } from '../../../mantineTheme';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import { type FieldWithSuggestions } from '../../Audience/Filters/FiltersProvider/types';

interface ProfileManagerPropertySelectProps {
    fields: FieldWithSelectStatus[];
    onSubmit: (items: FieldWithSuggestions[]) => void;
    isLoading: boolean;
    isSuccess: boolean;
    targetButton: React.ReactNode;
    opened: boolean;
    open: () => void;
    close: () => void;
}

const ProfileManagerPropertySelect: React.FC<
    ProfileManagerPropertySelectProps
> = ({
    fields,
    onSubmit,
    isLoading,
    isSuccess,
    targetButton,
    opened,
    close,
    open,
}) => {
    const { t } = useLocale();
    const [openCustomMetric, setOpenCustomMetric] = useState<
        CompiledRelationTable | undefined
    >(undefined);
    const { activeRelation } = useRelationContext();
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if (isSuccess) close();
    }, [isSuccess, close]);

    return (
        <>
            <PropertySelect<
                FieldWithSuggestions & AddditionalPropertySelectListProps
            >
                items={convertFieldsIntoPropertySelectListType(fields, true)}
                showGroup={true}
                headerRightSection={
                    <Button
                        leftIcon={<PlusCircle />}
                        variant={ButtonVariant.OUTLINED}
                        className="my-2.5 me-2.5"
                        disabled={isLoading}
                        onClick={() => {
                            setIsModalVisible(true);
                            setOpenCustomMetric(
                                activeRelation?.tables[
                                    activeRelation.baseTable
                                ],
                            );
                            close();
                        }}
                    >
                        {t('profiles_view.add_custom_metric_button')}
                    </Button>
                }
                onSubmit={(items: FieldWithSuggestions[]) => {
                    onSubmit(items);
                }}
                itemTemplate={({ item }) => {
                    return (
                        <FieldListItem
                            item={item}
                            checked={item.isChecked ?? false}
                            showCheckbox={true}
                            disabled={item.isDisabled ?? false}
                        />
                    );
                }}
                opened={opened}
                close={close}
                open={open}
                targetButton={targetButton}
                showSearch={true}
                searchKeys={['label', 'tableLabel']}
                searchPlaceholder={t('audience_filters.search_filter_label')}
                allowMultipleSelection={true}
                isLoading={isLoading}
            />
            {isModalVisible && (
                <CreateCustomMetric
                    tableName={openCustomMetric}
                    onModalClose={() => {
                        setOpenCustomMetric(undefined);
                    }}
                    openBaseTable={undefined}
                    isViewMode={undefined}
                    isDuplicate={undefined}
                    customMetricData={undefined}
                    customMetricId={undefined}
                    disableTableChange={true}
                    fieldType={FieldType.METRIC}
                />
            )}
        </>
    );
};

export default ProfileManagerPropertySelect;
