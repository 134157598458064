import { type AnyType } from '@lightdash/common';

export enum SchemaBuilderStep {
    SETUP = 'setup',
    PERSONALISE = 'personalise',
}

export enum SchemaConfigStep {
    CONFIGURE = 'configure',
    RELATIONSHIPS = 'relationships',
    EVENTS = 'events',
    PROPERTIES = 'properties',
}

export interface SchemaStepMap {
    key: SchemaBuilderStep | SchemaConfigStep;
    label: string;
    component: React.ComponentType<AnyType>;
}
