import TextArea from '@components/common/Inputs/TextArea';
import { useLocale } from '@hooks/useLocale';
import { Box, Button, Flex, Stack, Text } from '@mantine/core';
import { Sparkle } from '@phosphor-icons/react';
import { ButtonVariant } from '../../../mantineTheme';

interface AIModeInTraitModalProps {
    handleAdvancedMode: () => void;
    aiThinking: boolean;
    setInstructions: (value: string) => void;
    instructions: string;
}

const AIModeInTraitModal: React.FC<AIModeInTraitModalProps> = ({
    handleAdvancedMode,
    aiThinking,
    setInstructions,
    instructions,
}) => {
    const { t } = useLocale();
    return (
        <Stack className="border border-gray-250 rounded-lg">
            <Flex
                justify="space-between"
                align="center"
                className="p-3 border-b border-gray-250"
            >
                <Flex align="center" gap="xs">
                    <Sparkle
                        weight="duotone"
                        color="rgb(var(--color-blu-800))"
                    />
                    <Text className="text-gray-800 font-medium">
                        {t(
                            'custom_metric.create_new_trait_modal.getting_started_with_ai',
                        )}
                    </Text>
                </Flex>
                <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={handleAdvancedMode}
                    disabled={aiThinking}
                >
                    {t('custom_metric.create_new_trait_modal.advanced_mode')}
                </Button>
            </Flex>
            <Box className="m-3">
                <TextArea
                    placeholder={t('common.type_here')}
                    label={t(
                        'custom_metric.create_new_trait_modal.use_case_desc',
                    )}
                    description={t(
                        'custom_metric.create_new_trait_modal.use_case_desc_description',
                    )}
                    value={instructions}
                    onChange={(e) => setInstructions(e.target.value)}
                    readOnly={aiThinking}
                />
            </Box>
        </Stack>
    );
};

export default AIModeInTraitModal;
