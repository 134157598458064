import BlockNode from '@components/Journeys/Builder/ReactFlow/Nodes/BlockNode';
import PlaceholderNode from '@components/Journeys/Builder/ReactFlow/Nodes/Placeholders/PlaceholderNode';
import {
    ActionType,
    type ActionTypeWithConfig,
    type AnyType,
} from '@lightdash/common';
import { t as translate } from 'i18next';
import { type EdgeTypes, type NodeTypes } from 'reactflow';
import { JourneyEdgeEnum, JourneyNodeEnum } from '../types';
import BlockEdge from './Edges/BlockEdge';
import BranchingEdge from './Edges/BranchingEdge';
import DefaultEdge from './Edges/DefaultEdge';
import GhostNode from './Nodes/GhostNode';
export const reactFlowNodeTypes: NodeTypes = {
    [JourneyNodeEnum.PLACEHOLDER]: PlaceholderNode,
    [JourneyNodeEnum.BLOCK]: BlockNode,
    [JourneyNodeEnum.TRIGGER]: BlockNode,
    [JourneyNodeEnum.GHOSTNODE]: GhostNode,
};

export const reactFlowEdgeTypes: EdgeTypes = {
    [JourneyEdgeEnum.BLOCK]: BlockEdge,
    [JourneyEdgeEnum.BRANCHING]: BranchingEdge,
    [JourneyEdgeEnum.DEFAULT]: DefaultEdge,
};

function isConfigWithRequiresEveryOneElse(
    config: AnyType,
): config is { allowEveryoneElse: boolean } {
    return config && typeof config.allowEveryoneElse === 'boolean';
}

export interface BlockWithEveryOneElse {
    ifLabel: string;
    everyoneElseLabel: string;
}

export const getEdgeLabel = (
    blockActions: ActionTypeWithConfig<ActionType>[] | undefined,
) => {
    if (
        blockActions &&
        blockActions.length >= 1 &&
        isConfigWithRequiresEveryOneElse(blockActions[0].config)
    ) {
        switch (blockActions[0].actionType) {
            case ActionType.SPLIT:
                return {
                    ifLabel: translate('journey_builder.split_if_label'),
                    everyoneElseLabel: translate(
                        'journey_builder.split_every_one_else_label',
                    ),
                };
            case ActionType.FILTER:
                return {
                    ifLabel: translate('journey_builder.filter_if_label'),
                    everyoneElseLabel: translate(
                        'journey_builder.filter_every_one_else_label',
                    ),
                };
            case ActionType.WAIT_UNTIL:
                return {
                    ifLabel: translate('journey_builder.wait_until_if_label'),
                    everyoneElseLabel: translate(
                        'journey_builder.wait_until_every_one_else_label',
                    ),
                };
            default:
                return {
                    ifLabel: '',
                    everyoneElseLabel: '',
                };
        }
    }
    return {
        ifLabel: '',
        everyoneElseLabel: '',
    };
};
