import { getDedupedEventTableName } from '@components/Audience/utils';
import { useGetCampaignById } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import {
    CLICKED_EVENT_STATUS,
    CommunicationChannel,
    CONVERSION_EVENT_STATUS,
    DELIVERED_EVENT_STATUS,
    EventStatus,
    OPENED_EVENT_STATUS,
    ReservedEventColumns,
    SENT_EVENT_STATUS,
    type ApiQueryResults,
} from '@lightdash/common';
import { Card, Flex, Text } from '@mantine/core';
import EChartsReact from 'echarts-for-react';
import React, { useMemo, useRef } from 'react';
import { useLocation, useParams } from 'react-router';

const tooltipItemConfig = {
    trigger: 'item',
    // any type because we don't have proper types defined for echarts library
    formatter: (params: any) => {
        // Custom formatter for tooltip content
        return `${params.seriesName}: ${params.value}`;
    },
};
const primaryBarColor = '#0195ff';
const secondaryBarColor = '#f0f0f0';

const BaseFunnelChart = ({ data }: { data: ApiQueryResults | undefined }) => {
    const chartRef = useRef(null);
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const campaignId = queryParams.get('campaignUuid') ?? '';
    const tablePrefix = projectUuid
        ? getDedupedEventTableName(projectUuid)
        : '';

    const { data: campaignData } = useGetCampaignById(campaignId);

    const eventMap = useMemo(
        () =>
            data &&
            data.rows.reduce((acc, item) => {
                const eventName = item[
                    `${tablePrefix}_${ReservedEventColumns.EVENT_NAME}`
                ]?.value?.raw as string;
                if (eventName) {
                    acc[eventName] = item;
                }
                return acc;
            }, {} as Record<string, any>),
        [data, tablePrefix],
    );

    if (!data || !eventMap) return null;

    const getCount = (eventKey: string, columnKey: string) =>
        eventMap[eventKey]?.[`${tablePrefix}_${columnKey}`]?.value?.raw ?? 0;

    const deliveredCount = getCount(
        EventStatus.DELIVERED,
        DELIVERED_EVENT_STATUS,
    );
    const bouncedCount = getCount(EventStatus.BOUNCE, DELIVERED_EVENT_STATUS);
    const undeliveredCount = getCount(
        EventStatus.UNDELIVERED,
        DELIVERED_EVENT_STATUS,
    );
    const openEventsCount = getCount(EventStatus.OPEN, OPENED_EVENT_STATUS);
    const readEventsCount = getCount(EventStatus.READ, OPENED_EVENT_STATUS);
    const clickedEventsCount = getCount(
        EventStatus.CLICKED,
        CLICKED_EVENT_STATUS,
    );
    const convertedEventsCount = getCount(
        EventStatus.CONVERTED,
        CONVERSION_EVENT_STATUS,
    );
    const failedEventsCount = getCount(EventStatus.FAILED, SENT_EVENT_STATUS);
    const errorEventsCount = getCount(EventStatus.ERROR, SENT_EVENT_STATUS);
    const droppedEventsCount = getCount(EventStatus.DROPPED, SENT_EVENT_STATUS);

    const sentFailures =
        failedEventsCount + errorEventsCount + droppedEventsCount;

    const sentCount =
        (campaignData?.communicationDetails?.runDetails?.[0]
            .materializedCampaignCount ?? 0) - sentFailures;

    const labelFormatter = (params: any) =>
        params.value > 0 ? params.value : '';

    const audienceCount =
        campaignData?.communicationDetails?.runDetails?.[0]
            .materializedCampaignCount;
    const audienceExclusionsCount =
        campaignData?.communicationDetails?.runDetails?.[0].ignoredEntityCount;

    const tooltipAxisConfig = {
        show: true,
        confine: true,
        trigger: 'axis',
        enterable: true,
        extraCssText: 'overflow-y: auto; max-height:280px;',
        axisPointer: {
            type: 'shadow',
            label: {
                show: true,
            },
        },
        formatter: () => {
            const tooltipString = `
                ${t(
                    'campaign_analytics.base_funnel_chart.label.audience',
                )}: ${audienceCount} <br />
                ${t(
                    'campaign_analytics.base_funnel_chart.label.audience_exclusions',
                )}: ${audienceExclusionsCount} <br />
                ${t(
                    'campaign_analytics.base_funnel_chart.label.sent',
                )}: ${sentCount} <br />
                ${t(
                    'campaign_analytics.base_funnel_chart.label.sent_failures',
                )}: ${sentFailures} <br />
                ${t(
                    'campaign_analytics.base_funnel_chart.label.delivered',
                )}: ${deliveredCount} <br />
                ${t(
                    'campaign_analytics.base_funnel_chart.label.undelivered',
                )}: ${undeliveredCount} <br />
                ${t(
                    'campaign_analytics.base_funnel_chart.label.bounced',
                )}: ${bouncedCount} <br />
                ${t(
                    'campaign_analytics.base_funnel_chart.label.open',
                )}: ${openEventsCount} <br />
                ${t(
                    'campaign_analytics.base_funnel_chart.label.clicked',
                )}: ${clickedEventsCount} <br />
                ${t(
                    'campaign_analytics.base_funnel_chart.label.converted',
                )}: ${convertedEventsCount} <br />
            `;
            return tooltipString;
        },
    };

    const handleOnMouseOver = () => {
        const eCharts = (chartRef.current as any)?.getEchartsInstance();
        if (eCharts) {
            eCharts.setOption({
                tooltip: tooltipItemConfig,
            });
        }
    };

    const handleOnMouseOut = () => {
        const eCharts = (chartRef.current as any)?.getEchartsInstance();
        console.log('mouseout');
        if (eCharts) {
            eCharts.setOption(
                {
                    tooltip: tooltipAxisConfig,
                },
                false,
                true, // lazy update
            );
        }
    };

    return (
        <Card
            component={Flex}
            className="tile-base"
            h="100%"
            direction="column"
            p="md"
            bg="white"
            radius="sm"
            sx={(theme) => {
                return {
                    border: `1px solid ${theme.colors.gray[1]}`,
                    width: '100%',
                };
            }}
        >
            <Text className="font-semibold text-base">
                1. Base Funnel chart
            </Text>
            <EChartsReact
                ref={chartRef}
                style={{
                    height: 500,
                }}
                option={{
                    tooltip: tooltipAxisConfig,
                    xAxis: {
                        data: [
                            'Audience',
                            'Sent',
                            'Delivered',
                            campaignData?.channel === CommunicationChannel.EMAIL
                                ? 'Open'
                                : 'Read',
                            'Clicked',
                            'Converted',
                        ],
                    },
                    yAxis: {
                        name: t(
                            'campaign_analytics.base_funnel_chart.yAxis.label',
                        ), // Label for the Y-axis
                        nameLocation: 'middle', // Position the label in the middle of the axis
                        nameTextStyle: {
                            fontSize: 14,
                            fontWeight: 'bold',
                            fontFamily: 'Hanken grotesk',
                        },
                        nameGap: 30, // Gap between the label and the axis
                        type: 'value', // The Y-axis represents numerical values
                        position: 'left',
                        show: true,
                        axisLine: {
                            show: true,
                        },
                    },
                    series: [
                        {
                            // COL 1
                            // AUDIENCE
                            name: t(
                                'campaign_analytics.base_funnel_chart.label.audience',
                            ),
                            data: [audienceCount, 0, 0, 0, 0, 0],
                            type: 'bar',
                            stack: 'x',
                            color: primaryBarColor,
                        },
                        {
                            // COL 1
                            // SRT_EXCLUSIONS
                            name: t(
                                'campaign_analytics.base_funnel_chart.label.audience_exclusions',
                            ),
                            data: [audienceExclusionsCount, 0, 0, 0, 0, 0],
                            type: 'bar',
                            stack: 'x',
                            color: secondaryBarColor,
                            label: {
                                show: true,
                                position: 'top',
                                formatter: labelFormatter,
                            },
                            emphasis: {
                                itemStyle: {
                                    // Color in emphasis state.
                                    color: secondaryBarColor,
                                },
                            },
                        },
                        {
                            // COL 2
                            // SENT
                            name: t(
                                'campaign_analytics.base_funnel_chart.label.sent',
                            ),
                            data: [0, sentCount, 0, 0, 0, 0],
                            type: 'bar',
                            stack: 'x',
                            color: primaryBarColor,
                        },
                        {
                            // COL 2
                            // SENT FAILURES
                            name: t(
                                'campaign_analytics.base_funnel_chart.label.sent_failures',
                            ),
                            data: [0, sentFailures, 0, 0, 0, 0],
                            type: 'bar',
                            stack: 'x',
                            color: secondaryBarColor,
                            label: {
                                show: true,
                                position: 'top',
                                formatter: labelFormatter,
                            },
                            emphasis: {
                                itemStyle: {
                                    // Color in emphasis state.
                                    color: secondaryBarColor,
                                },
                            },
                        },
                        {
                            // COL 3
                            // DELIVERED
                            name: t(
                                'campaign_analytics.base_funnel_chart.label.delivered',
                            ),
                            data: [0, 0, deliveredCount, 0, 0, 0],
                            type: 'bar',
                            stack: 'x',
                            color: primaryBarColor,
                        },
                        {
                            // COL 3
                            // UNDELIVERED
                            name: t(
                                'campaign_analytics.base_funnel_chart.label.undelivered',
                            ),
                            data: [0, 0, undeliveredCount, 0, 0, 0],
                            type: 'bar',
                            stack: 'x',
                            color: 'rgb(229, 229, 229)',
                            label: {
                                show: true,
                                position: 'top',
                                formatter: labelFormatter,
                            },
                            emphasis: {
                                itemStyle: {
                                    // Color in emphasis state.
                                    color: 'rgb(229, 229, 229)',
                                },
                            },
                        },
                        {
                            // COL 3
                            // BOUNCED
                            name: t(
                                'campaign_analytics.base_funnel_chart.label.bounced',
                            ),
                            data: [0, 0, bouncedCount, 0, 0, 0],
                            type: 'bar',
                            stack: 'x',
                            color: secondaryBarColor,
                            label: {
                                show: true,
                                position: 'top',
                                formatter: labelFormatter,
                            },
                            emphasis: {
                                itemStyle: {
                                    // Color in emphasis state.
                                    color: secondaryBarColor,
                                },
                            },
                        },
                        {
                            // COL 4
                            // OPEN
                            name: t(
                                'campaign_analytics.base_funnel_chart.label.open',
                            ),
                            data: [0, 0, 0, openEventsCount, 0, 0],
                            type: 'bar',
                            stack: 'x',
                            color: primaryBarColor,
                        },
                        {
                            // COL 4
                            // READ
                            name: t(
                                'campaign_analytics.base_funnel_chart.label.read',
                            ),
                            data: [0, 0, 0, readEventsCount, 0, 0],
                            type: 'bar',
                            stack: 'x',
                            color: primaryBarColor,
                        },
                        {
                            // COL 5
                            // CLICKED
                            name: t(
                                'campaign_analytics.base_funnel_chart.label.clicked',
                            ),
                            data: [0, 0, 0, 0, clickedEventsCount, 0],
                            type: 'bar',
                            stack: 'x',
                            color: primaryBarColor,
                        },
                        {
                            // COL 6
                            // CONVERTED
                            name: t(
                                'campaign_analytics.base_funnel_chart.label.converted',
                            ),
                            data: [0, 0, 0, 0, 0, convertedEventsCount],
                            type: 'bar',
                            stack: 'x',
                            color: primaryBarColor,
                        },
                    ],
                }}
                onEvents={{
                    mouseover: handleOnMouseOver,
                    mouseout: handleOnMouseOut,
                }}
            />
        </Card>
    );
};

export default BaseFunnelChart;
