import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import TextArea from '@components/common/Inputs/TextArea';
import TextInput from '@components/common/Inputs/TextInput';
import Modal from '@components/common/modal/Modal';
import useNotify from '@hooks/toaster/useNotify';
import { useCreateCustomMetricMutation } from '@hooks/useCustomMetric';
import { useFilteringTables } from '@hooks/useGetRelatedTables';
import { useLocale } from '@hooks/useLocale';
import {
    FieldType,
    QueryGenerationStrategy,
    type AdditionalMetric,
    type AudiencePreviewPayload,
    type CompiledDimension,
    type CompiledRelationTable,
    type CustomSqlDimension,
    type DimensionType,
    type FilterGroup,
    type FilterGroupItem,
    type MetricType,
} from '@lightdash/common';
import { Box, Button, Divider, Flex, Group, Loader, Text } from '@mantine/core';

import { useDisclosure } from '@mantine/hooks';
import { AsteriskSimple, CaretRight } from '@phosphor-icons/react';
import useCustomMetricContext from '@providers/CustomMetric/useCustomMetricContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ButtonVariant } from '../../mantineTheme';
import useRelationContext from '../../providers/Relation/useRelationContext';

import AudiencePreviewModal from '@components/Audience/AudiencePreview/AudiencePreviewData';
import TagsMultiSelect from '@components/common/Tags/TagsMultiSelect';
import { addFieldIdToMetricFilterRule } from '@components/Explorer/CustomMetricModal/utils';
import { useAudiencePreviewByPayload } from '@hooks/useAudiencePreview';
import _ from 'lodash';
import TablesListMenu from './CalculatedTraitBaseModal/TableListMenu';
import CustomDimensionLogic from './CustomAttributeModal/CustomDimensionLogic';
import CustomMetricLogic from './CustomAttributeModal/CustomMetricLogic';
import {
    generateDimensionPreviewPayload,
    generateMetricPreviewPayload,
    getCustomAttributeName,
    getFilteredTables,
    useCustomMetricInitialData,
} from './utils';

export type CustomMetricForm = {
    name: string;
    metric: MetricType;
    description: string | undefined;
};

const CustomMetricManagerModal = () => {
    const { t } = useLocale();

    const [previewOpened, { open: openPreview, close: previewClose }] =
        useDisclosure(false);
    const [
        confirmLeaveMetric,
        { open: openConfirmLeaveMetric, close: closeConfirmLeaveMetric },
    ] = useDisclosure(false);
    const { activeRelation, activeRelationUuid } = useRelationContext();
    const { availableTables, nonAvailableTables } =
        useFilteringTables(activeRelation);
    const {
        showMetricManagerModal,
        selectedTable,
        selectedDimension,
        customAttributePayload,
        initialCustomAttributePayload,
        duplicatedMetric,
        viewMode,
        fieldType,
        disableBackButton,
    } = useCustomMetricContext((context) => context.reducerState);
    const {
        closeCustomMetricManagerModal,
        setFilters,
        addNameDetailsToPayload,
        addDescriptionDetailsToPayload,
        toogleIsDuplicated,
        toggleIsViewMode,
        resetTheCustometricContext,
        setTags,
        selectTable,
        addBaseTableDetails,
    } = useCustomMetricContext((context) => context.actions);
    const customAttributeName = useMemo(() => {
        return getCustomAttributeName(customAttributePayload);
    }, [customAttributePayload]);

    const { mutateAsync, isLoading, isSuccess } =
        useCreateCustomMetricMutation();
    const { showToastError } = useNotify();
    // const { icon, onActiveClasses } =
    //     filterPropertyTypeMapper.find(
    //         (item) => item.propertyType === selectedTable?.type,
    //     ) || {};

    const [activeField, setActiveField] = useState<
        CompiledDimension | FieldWithSuggestions | undefined
    >(selectedDimension);

    const form = useForm<CustomMetricForm>({
        defaultValues: {
            name: customAttributeName ?? '',
            description: customAttributePayload.description ?? '',
            metric: '' as MetricType,
        },
    });
    const initialState = useCustomMetricInitialData(
        undefined,
        activeRelationUuid,
        activeRelation,
    );
    const hasPayloadChanged = _.isEqual(
        initialCustomAttributePayload,
        customAttributePayload,
    );
    const {
        mutateAsync: mutateAsyncPreview,
        // isLoading: isPreviewDataLoading,
        data: previewData,
        // isSuccess: isPreviewSuccess,
    } = useAudiencePreviewByPayload();

    useEffect(() => {
        if (customAttributeName && (duplicatedMetric || viewMode)) {
            if (duplicatedMetric) {
                toogleIsDuplicated(false);
                addNameDetailsToPayload(`Copy of ${customAttributeName}`);
            }
            setActiveField(selectedDimension);
            setFilters({
                dimensions: {
                    and: (
                        customAttributePayload.definition as AdditionalMetric
                    )?.filters?.map(
                        addFieldIdToMetricFilterRule,
                    ) as FilterGroupItem[],
                } as FilterGroup,
            });
        }
    }, [
        duplicatedMetric,
        selectedDimension,
        toogleIsDuplicated,
        addNameDetailsToPayload,
        setFilters,
        viewMode,
        customAttributeName,
        customAttributePayload.definition,
    ]);

    const handleClose = useCallback(() => {
        form.reset();
        setActiveField(undefined);
        resetTheCustometricContext(initialState);
        toggleIsViewMode(false);
        closeCustomMetricManagerModal();
    }, [
        closeCustomMetricManagerModal,
        toggleIsViewMode,
        initialState,
        resetTheCustometricContext,
        form,
    ]);
    useEffect(() => {
        if (isSuccess) {
            handleClose();
        }
    }, [isSuccess, handleClose]);
    const handleSubmit = useCallback(async () => {
        if (customAttributeName.trim().length === 0) {
            showToastError({
                title: t('custom_metric_create_name_enter_toast'),
            });
        } else {
            await mutateAsync(customAttributePayload, {
                onSuccess: () => {
                    handleClose();
                },
            });
        }
    }, [
        customAttributeName,
        mutateAsync,
        showToastError,
        t,
        handleClose,
        customAttributePayload,
    ]);
    useEffect(() => {
        if (selectedDimension) {
            setActiveField(selectedDimension);
        }
    }, [selectedDimension, setActiveField]);

    const handleCloseModal = useCallback(() => {
        if (viewMode || !hasPayloadChanged) {
            closeCustomMetricManagerModal();
            handleClose();
        } else openConfirmLeaveMetric();
    }, [
        viewMode,
        hasPayloadChanged,
        closeCustomMetricManagerModal,
        handleClose,
        openConfirmLeaveMetric,
    ]);
    const handleTableSelect = useCallback(
        (table: CompiledRelationTable) => {
            selectTable(table);
            addBaseTableDetails(table);
        },
        [selectTable, addBaseTableDetails],
    );
    const disableFinishButton = useMemo(() => {
        if (fieldType === FieldType.METRIC) {
            return (
                isLoading ||
                activeField === undefined ||
                (customAttributePayload.definition?.type?.length ?? 0) < 1
            );
        }
        return (
            isLoading ||
            (
                customAttributePayload.definition as CustomSqlDimension
            )?.sql?.trim().length <= 0
        );
    }, [isLoading, activeField, customAttributePayload, fieldType]);

    const handlePreview = useCallback(async () => {
        openPreview();
        const payload =
            fieldType === FieldType.METRIC
                ? generateMetricPreviewPayload(customAttributePayload)
                : generateDimensionPreviewPayload(customAttributePayload);
        if (payload) {
            await mutateAsyncPreview({
                relationUuid: activeRelationUuid,
                data: payload as AudiencePreviewPayload,
                dimensions: [],
                metrics: [],
            });
        }
    }, [
        activeRelationUuid,
        mutateAsyncPreview,
        customAttributePayload,
        fieldType,
        openPreview,
    ]);
    const { filteredAvailableTables, filteredNonAvailableTables } =
        useMemo(() => {
            return getFilteredTables(
                availableTables,
                nonAvailableTables,
                fieldType,
            );
        }, [availableTables, nonAvailableTables, fieldType]);
    return (
        <>
            <Modal
                opened={showMetricManagerModal}
                onClose={handleCloseModal}
                footerRightSection={
                    !viewMode && (
                        <Group>
                            <Button
                                variant={ButtonVariant.OUTLINED_ACCENTED}
                                onClick={handlePreview}
                                className="hidden"
                            >
                                {t(
                                    'custom_metric.dimension_modal_preview_button',
                                )}
                            </Button>
                            <Button
                                form="create_custom_metric_form"
                                variant={ButtonVariant.PRIMARY}
                                onClick={(
                                    e: React.MouseEvent<HTMLButtonElement>,
                                ) => {
                                    e.stopPropagation();
                                }}
                                type="submit"
                                disabled={disableFinishButton}
                                rightIcon={
                                    isLoading ? (
                                        <Loader color="white" size={14} />
                                    ) : (
                                        <CaretRight
                                            color={
                                                activeField
                                                    ? 'white'
                                                    : 'rgb(var(--color-gray-500))'
                                            }
                                        />
                                    )
                                }
                            >
                                {isLoading
                                    ? t('common.saving')
                                    : t('common.finish')}
                            </Button>
                        </Group>
                    )
                }
                fullScreen
                centered
                contentCustomClass=" overflow-x-hidden "
                onClick={(event) => {
                    event.stopPropagation();
                }}
                styles={() => ({
                    content: {
                        maxHeight: '100% !important',
                    },
                    body: {
                        padding: 0,
                    },
                })}
                withCloseButton={false}
                withContentPadding={false}
            >
                <form
                    name="create_custom_metric_form"
                    id="create_custom_metric_form"
                    onSubmit={form.handleSubmit(handleSubmit)}
                    onKeyDown={(event) => {
                        if (event.key === 'Escape') {
                            event.stopPropagation();
                        }
                    }}
                    className={` h-[90vh] overflow-y-auto`}
                >
                    <Flex
                        className="text-gray-800 py-3 border-b border-gray-250 px-9 sticky top-0 bg-white z-10"
                        align="center"
                        justify="space-between"
                    >
                        <Text className="text-gray-800 font-medium text-lg">
                            {t('custom_metric.create_new_trait_modal.title')}
                        </Text>
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={handleCloseModal}
                        >
                            {t('common.exit')}
                        </Button>
                    </Flex>

                    <Flex
                        direction="column"
                        gap="lg"
                        className="text-gray-800 !h-full py-6 px-9"
                    >
                        <Flex align="center" gap="xs">
                            <AsteriskSimple
                                weight="duotone"
                                size={14}
                                color="rgb(var(--color-purple))"
                            />
                            <Text className="text-gray-700 text-sm">
                                {t(
                                    'custom_metric.create_new_trait_modal.creating_trait_in',
                                )}
                            </Text>
                            <Box className="w-fit">
                                <TablesListMenu
                                    selectedTable={selectedTable}
                                    tables={filteredAvailableTables}
                                    nonAvailableTables={
                                        filteredNonAvailableTables
                                    }
                                    handleTableSelect={handleTableSelect}
                                    disabled={
                                        isLoading ||
                                        viewMode ||
                                        disableBackButton
                                    }
                                />
                            </Box>
                            <Box>...</Box>
                        </Flex>
                        <Text className="text-gray-800 font-medium text-lg">
                            {t(
                                'custom_metric.create_new_trait_modal.trait_details',
                            )}
                        </Text>
                        <Box>
                            <TextInput
                                data-autofocus
                                label={
                                    <Text className="text-gray-800">
                                        {t(
                                            'custom_metric.dimension_modal_name_label',
                                        )}
                                    </Text>
                                }
                                className="!w-[37rem]"
                                {...form.register('name')}
                                value={customAttributeName}
                                onChange={(event) => {
                                    const value = event.currentTarget.value;
                                    form.setValue('name', value);
                                    addNameDetailsToPayload(value);
                                }}
                                readOnly={viewMode}
                                disabled={isLoading}
                            />
                        </Box>
                        <TextArea
                            label={
                                <Box>
                                    <Text className="inline me-2">
                                        {t(
                                            'custom_metric.dimension_modal_description_label',
                                        )}
                                    </Text>
                                    <Text className="inline text-gray-500">
                                        {t(
                                            'custom_metric.dimension_modal_description_label_optional',
                                        )}
                                    </Text>
                                </Box>
                            }
                            placeholder={t(
                                'custom_metric.dimension_modal_description_description',
                            )}
                            {...form.register('description')}
                            value={customAttributePayload.description ?? ''}
                            onChange={(event) => {
                                form.setValue(
                                    'description',
                                    event.currentTarget.value,
                                );
                                addDescriptionDetailsToPayload(
                                    event.currentTarget.value,
                                );
                            }}
                            disabled={isLoading}
                            readOnly={viewMode}
                            className="!w-[37rem]"
                        />
                        <TagsMultiSelect
                            tags={customAttributePayload.tags || []}
                            onChange={(tags) => {
                                setTags(tags);
                            }}
                            isDisabled={isLoading || viewMode}
                            className="!w-[37rem]"
                        />
                        <Divider color="rgb(var(--color-gray-250))" />
                        <Text className="text-gray-800 font-medium text-lg">
                            {t(
                                'custom_metric.create_new_trait_modal.trait_logic',
                            )}
                        </Text>
                        {fieldType === FieldType.DIMENSION ? (
                            <CustomDimensionLogic isLoading={isLoading} />
                        ) : (
                            <CustomMetricLogic
                                isLoading={isLoading}
                                activeField={activeField}
                                setActiveField={setActiveField}
                                form={form}
                            />
                        )}
                    </Flex>
                </form>
            </Modal>
            <Modal
                opened={confirmLeaveMetric}
                onClose={closeConfirmLeaveMetric}
                withOverlay={true}
                title={t('custom_metric_confirm_exit_title')}
                footerRightSection={
                    <Group spacing="xs">
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={closeConfirmLeaveMetric}
                        >
                            {t('custom_metric_confirm_exit_no_button')}
                        </Button>

                        <Button
                            rightIcon={
                                <CaretRight weight="regular" color="white" />
                            }
                            onClick={() => {
                                closeConfirmLeaveMetric();
                                handleClose();
                            }}
                        >
                            {t('custom_metric_confirm_exit_button')}
                        </Button>
                    </Group>
                }
                yOffset={0}
            >
                <Text className="pt-3 text-sm text-gray-800">
                    {t('custom_metric_confirm_exit_description')}
                </Text>
            </Modal>
            {previewOpened && (
                <AudiencePreviewModal
                    getAudiencePayload={{}}
                    opened={previewOpened}
                    close={previewClose}
                    data={previewData?.rows ?? []}
                    fields={
                        (previewData?.fields as Record<
                            string,
                            { type: DimensionType }
                        >) ?? {}
                    }
                    initialColumns={{
                        previewFields: [],
                    }}
                    generationStrategy={QueryGenerationStrategy.MANUAL}
                    isEditMode={false}
                    onColumnChange={() => {}}
                    handlePreview={undefined}
                    isValidQuery={true}
                    showPropertySelect={true}
                    footerRightSection={
                        (previewData?.rows.length ?? 0) > 0 && (
                            <Button
                                variant={ButtonVariant.OUTLINED}
                                onClick={() => previewClose()}
                            >
                                {t('password_modal.secondary_btn_text')}
                            </Button>
                        )
                    }
                    bottomSection={null}
                    isApiCallTimeout={false}
                />
            )}
        </>
    );
};
export default CustomMetricManagerModal;
