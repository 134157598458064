import { Flex, Text, type TextProps } from '@mantine/core';
import { TextVariant } from './utils';

interface TitleTextProps extends TextProps {
    children: React.ReactNode;
    onClick?: () => void;
    placeholder?: string;
    variant?: TextVariant;
    viewMode?: boolean;
}

const TitleText = ({
    children,
    onClick,
    placeholder,
    variant = TextVariant.Title,
    className,
    ...rest
}: TitleTextProps) => {
    return (
        <Flex
            className={` rounded-md p-1 group/item px-3 cursor-pointer hover:bg-shade-4`}
            align="center"
            gap={8}
            onClick={onClick}
        >
            <Text
                className={`${
                    variant === 'title'
                        ? 'text-lg font-semibold text-gray-800'
                        : 'text-xs text-gray-600'
                } ${className}`}
                {...rest}
            >
                {children || (
                    <Text className="text-gray-400">{placeholder}</Text>
                )}
            </Text>
        </Flex>
    );
};

export default TitleText;
