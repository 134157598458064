import { NAVBAR_HEIGHT } from '@components/common/Page/constants';
import { Box } from '@mantine/core';
import useAiAnalystContext from '@providers/AiAnalyst/useAiAnalystContext';
import { useLocation } from 'react-router';
import { Chat } from './Chat';

const AnalystChat = () => {
    const { showAiAnalyst } = useAiAnalystContext((context) => context.state);
    const location = useLocation();

    if (
        location.pathname.includes('settings') ||
        location.pathname.includes('organization')
    ) {
        return null;
    }
    return (
        <Box
            className={`bg-transparent ${
                showAiAnalyst ? 'w-[30vw]' : 'w-0 hidden'
            } transition-all duration-500 ease-in-out`}
            style={{
                height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
            }}
        >
            <Chat />
        </Box>
    );
};

export default AnalystChat;
