import { useLocale } from '@hooks/useLocale';
import { Box, Text } from '@mantine/core';
import { CursorClick, Scroll } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import { useCallback } from 'react';
import { ControlPanel, TriggerType } from '../../types';

interface TriggerListProps {
    customClass?: string;
    setShowEventsList: (show: boolean) => void;
    setTriggerType: (triggerType: TriggerType) => void;
}

const TriggerList = ({
    setShowEventsList,
    customClass,
    setTriggerType,
}: TriggerListProps) => {
    const { t } = useLocale();

    const { journeyPayload } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const { updateTriggerNode, openControlPanel, addTriggerNode } =
        useJourneyBuilderContext((context) => context.actions);

    const handleActionClick = useCallback(
        (triggerType: TriggerType) => {
            setShowEventsList(true);
            setTriggerType(triggerType);
            openControlPanel({
                type: ControlPanel.TRIGGERS,
            });
        },
        [openControlPanel, setShowEventsList, setTriggerType],
    );

    const handleScheduledClick = () => {
        if (!journeyPayload.triggers?.entry[0]) {
            addTriggerNode({
                eventName: '',
                eventSource: '',
                isScheduledAudienceTrigger: true,
            });
            return;
        }
        updateTriggerNode({
            eventName: '',
            eventSource: '',
            associatedAudienceFilterConfig: undefined,
            isScheduledAudienceTrigger: true,
        });
        openControlPanel({
            type: ControlPanel.TRIGGER_CONFIG,
            triggerId: journeyPayload.triggers?.entry[0].id,
        });
    };

    return (
        <Box className={`${customClass}`}>
            <Box
                onClick={() => handleActionClick(TriggerType.USER_ACTION)}
                className="group gap-1 p-3 border border-gray-200 m-3 rounded-lg cursor-pointer hover:border-blu-800 hover:bg-blu-800/4"
            >
                <Box className="p-2 border border-gray-250 rounded-md w-fit group-hover:border-blu-800">
                    <CursorClick
                        size={18}
                        fill="currentColor"
                        className="text-gray-500 group-hover:text-blu-800"
                    />
                </Box>
                <Text className="text-sm font-semibold text-gray-800 mb-1 mt-3">
                    {t('trigger_list.user_action')}
                </Text>
                <Text className="text-xs font-normal text-gray-600">
                    {t('trigger_list.user_action_description')}
                </Text>
            </Box>
            <Box
                onClick={() => handleActionClick(TriggerType.BUSINESS_EVENT)}
                className="group gap-1 p-3 border border-gray-200 m-3 rounded-lg cursor-pointer hover:border-blu-800 hover:bg-blu-800/4"
            >
                <Box className="p-2 border border-gray-250 rounded-md w-fit group-hover:border-blu-800">
                    <Scroll
                        size={18}
                        fill="currentColor"
                        className="text-gray-500 group-hover:text-blu-800"
                    />
                </Box>
                <Text className="text-sm font-semibold text-gray-800 mb-1 mt-3">
                    {t('trigger_list.business_event')}
                </Text>
                <Text className="text-xs font-normal text-gray-600">
                    {t('trigger_list.business_event_description')}
                </Text>
            </Box>
            <Box
                onClick={handleScheduledClick}
                className="group gap-1 p-3 border border-gray-200 m-3 rounded-lg cursor-pointer hover:border-blu-800 hover:bg-blu-800/4"
            >
                <Box className="p-2 border border-gray-250 rounded-md w-fit group-hover:border-blu-800">
                    <Scroll
                        size={18}
                        fill="currentColor"
                        className="text-gray-500 group-hover:text-blu-800"
                    />
                </Box>
                <Text className="text-sm font-semibold text-gray-800 mb-1 mt-3">
                    {t('journey_trigger_list.scheduled')}
                </Text>
                <Text className="text-xs font-normal text-gray-600">
                    {t('journey_trigger_list.scheduled_description')}
                </Text>
            </Box>
        </Box>
    );
};

export default TriggerList;
