import { CustomListItem } from '@components/common/List';
import PropertySelect from '@components/common/Select/PropertySelect';
import { type AddditionalPropertySelectListProps } from '@components/common/Select/PropertySelect/type';
import {
    ExternalEventItem,
    InternalEventItem,
} from '@components/Journeys/Builder/ControlPanel/TriggerPanel/EventsList';
import { FiltersPropertySelectorFieldItem } from '@components/Journeys/Builder/JourneyFilters/FiltersForm/FiltersPropertySelector';
import {
    JourneyEventGroupType as JourneyEventGroupTypeEnum,
    type JourneyEvent,
    type JourneyEventGroupType,
    type JourneyProperty,
} from '@components/Journeys/Builder/JourneyFilters/types';
import { useLocale } from '@hooks/useLocale';
import { type Filters, type JourneyEventMapperSchema } from '@lightdash/common';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';
import { useEventProperties } from './useEventProperties';

const EventSelectorFieldItem: React.FC<{
    item: (JourneyEventMapperSchema | JourneyProperty) &
        AddditionalPropertySelectListProps;
}> = ({ item }) => {
    if (!item.groupKey) return null;

    if (item.groupKey === JourneyEventGroupTypeEnum.ALL_EVENTS) {
        const event = item as JourneyEventMapperSchema;
        if (event.isInternal) {
            return (
                <CustomListItem data={event}>
                    <InternalEventItem event={event} />
                </CustomListItem>
            );
        }

        return (
            <CustomListItem data={event}>
                <ExternalEventItem event={event} />
            </CustomListItem>
        );
    }

    return <FiltersPropertySelectorFieldItem item={item as JourneyProperty} />;
};

interface EventSelectorProps {
    eventsList: JourneyEventMapperSchema[];
    targetElement: React.ReactNode;
    setEvent: (item: JourneyEvent) => void;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    filters: Filters;
}

const EventSelector = ({
    eventsList,
    setEvent,
    targetElement,
}: EventSelectorProps) => {
    const [opened, { open, close }] = useDisclosure();

    const journeyProperties = useEventProperties({
        eventsList,
    });

    const { t } = useLocale();

    const handleSubmit = (field: JourneyEvent | JourneyProperty) => {
        if (!field || !field.groupKey) return;
        const groupKey = field.groupKey;

        if (groupKey === JourneyEventGroupTypeEnum.ALL_EVENTS) {
            const event = field as JourneyEventMapperSchema;
            setEvent(event);
        }
    };

    return (
        <PropertySelect<JourneyEvent | JourneyProperty, JourneyEventGroupType>
            width={500}
            items={journeyProperties}
            showGroup={true}
            headerRightSection={null}
            onSubmit={(field: (JourneyEvent | JourneyProperty)[]) => {
                if (!field[0]) return;
                handleSubmit(field[0]);
                close();
            }}
            itemTemplate={({ item }) => {
                return <EventSelectorFieldItem item={item} />;
            }}
            opened={opened}
            close={close}
            open={open}
            withinPortal={true}
            targetButton={targetElement}
            showSearch={true}
            searchKeys={['source', 'label', 'eventName']}
            searchPlaceholder={t('audience_filters.search_filter_label')}
            allowMultipleSelection={false}
            showAllItemsGroup={false}
            disabled={false}
        />
    );
};

export default EventSelector;
