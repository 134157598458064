import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import JourneyBuilderCanvas from '@components/Journeys/Builder';
import JourneyBuilderHeader from '@components/Journeys/Builder/JourneyBuilderHeader';
import { JourneyBuilderMode } from '@components/Journeys/Builder/types';
import useJourneyInitialData from '@components/Journeys/Builder/useJourneyBuilderInitialData';
import { JourneyBuilderEnums } from '@components/Journeys/utils';
import Page from '@components/Page/Page';
import {
    useGetJourneyBlocks,
    useGetJourneyById,
    useGetJourneyEvents,
    useGetJourneyVersions,
} from '@hooks/useJourney';
import { useLocale } from '@hooks/useLocale';
import useSearchParams from '@hooks/useSearchParams';
import { type JourneyAndVersionConfig } from '@lightdash/common';
import JourneyBuilderProvider from '@providers/Journey/JourneyBuilderProvider';
import { type JourneyReducerState } from '@providers/Journey/types';
import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';

export interface JourneyAnalytics {
    nodeId: string;
    entryUserCount: number;
    exitUserCount: number;
    earlyExitUserCount: number;
    waitingUserCount: number;
    failedUserCount: number;
}

export interface AnalyticsMap {
    [nodeId: string]: JourneyAnalytics;
}

const JourneyBuilder: React.FC<{}> = ({}) => {
    const { t } = useLocale();

    const { journeyUuid, projectUuid, mode } = useParams<{
        journeyUuid: string;
        projectUuid: string;
        mode?: string;
    }>();

    const { data: blocksList, isLoading } = useGetJourneyBlocks();
    const { data: eventsList, isLoading: eventsLoading } =
        useGetJourneyEvents();

    const location = useLocation();
    const isNewMode = location.pathname.includes(JourneyBuilderMode.CREATE);
    const isEditMode = isNewMode || mode === JourneyBuilderMode.EDIT;
    const journeyTemplateId = useSearchParams(JourneyBuilderEnums.TEMPLATE_ID);
    const versionIdParam = useSearchParams(JourneyBuilderEnums.VERSION_ID);

    const { data: journeyDataById, isInitialLoading } = useGetJourneyById(
        (journeyUuid ?? journeyTemplateId) || '',
    );

    const { data: journeyVersions, isInitialLoading: journeyVersionsLoading } =
        useGetJourneyVersions(journeyUuid ?? '');

    const versionId: string | undefined = useMemo(() => {
        //Info: For new journey, we don't have a versionId
        if (isNewMode) return undefined;

        //Info: For existing journey, the initial landing page is the current version
        if (!versionIdParam && journeyDataById) {
            return journeyDataById.currentVersionId;
        }
        return versionIdParam ?? undefined;
    }, [isNewMode, versionIdParam, journeyDataById]);

    const journeyDataByVersionId: JourneyAndVersionConfig | undefined =
        useMemo(() => {
            if (isNewMode) {
                return journeyDataById;
            }
            if (!journeyDataById) return undefined;
            if (!versionId || !journeyVersions) return journeyDataById;

            const selectedJourneyVersion = journeyVersions.find(
                (version) => version.id === versionId,
            );

            if (!selectedJourneyVersion) return journeyDataById;

            const journeyData: JourneyAndVersionConfig = {
                ...journeyDataById,
                config: selectedJourneyVersion.config,
                triggers: selectedJourneyVersion.triggers,
                createdAt: selectedJourneyVersion.createdAt,
                entryLogic: selectedJourneyVersion.entryLogic,
                versionName: selectedJourneyVersion.versionName,
            };

            return journeyData;
        }, [isNewMode, versionId, journeyVersions, journeyDataById]);

    const initialState: JourneyReducerState = useJourneyInitialData({
        mode: isNewMode ? JourneyBuilderMode.CREATE : JourneyBuilderMode.EDIT,
        blocksList: blocksList ?? [],
        projectUuid: projectUuid ?? '',
        journeyDataById: journeyDataByVersionId,
        selectedJourneyVersionStatus: journeyVersions?.find(
            (version) => version.id === versionId,
        )?.status,
    });

    if (
        isLoading ||
        eventsLoading ||
        isInitialLoading ||
        journeyVersionsLoading
    ) {
        return <SuboptimalState loading />;
    }

    return (
        <JourneyBuilderProvider
            initialState={initialState}
            isEditable={isEditMode}
            uuid={journeyUuid}
            journeyEvents={eventsList}
            journeyStatus={initialState.journeyStatus}
            journeyVersions={journeyVersions}
            selectedJourneyVersionId={versionId}
        >
            <Page
                withFullHeight={false}
                backgroundColor="white"
                withNavbar={false}
                header={<JourneyBuilderHeader />}
                withFullWidthHeader
                title={
                    isNewMode
                        ? t('journey.create_title')
                        : journeyDataByVersionId && journeyDataByVersionId.name
                }
            >
                <JourneyBuilderCanvas />
            </Page>
        </JourneyBuilderProvider>
    );
};

export default JourneyBuilder;
