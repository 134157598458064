import GenericOption from '@components/common/Card/GenericOption';
import { useLocale } from '@hooks/useLocale';
import { Stack, Text } from '@mantine/core';
import { Database, Drop, UserCircle } from '@phosphor-icons/react';
import useProjectContext from '@providers/Project/useProjectContext';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

interface SetupPageProps {
    description: string;
    isPrimaryVisible?: boolean;
    isBlobVisible?: boolean;
    isWarehouseVisible?: boolean;
}

const SetupPage = ({
    description,
    isPrimaryVisible = true,
    isBlobVisible = true,
    isWarehouseVisible = true,
}: SetupPageProps) => {
    const { projectData: activeProject } = useProjectContext();
    const { t } = useLocale();
    const navigate = useNavigate();

    const handlePrimaryTableSelectClick = useCallback(() => {
        void navigate(
            `/projects/${activeProject?.projectUuid}/relations/create`,
        );
    }, [navigate, activeProject?.projectUuid]);

    const handleDataConnectionClick = useCallback(() => {
        void navigate(`/projects/${activeProject?.projectUuid}/warehouse`);
    }, [navigate, activeProject?.projectUuid]);

    const handleBlobStorageClick = useCallback(() => {
        void navigate(`/projects/${activeProject?.projectUuid}/blob`);
    }, [navigate, activeProject?.projectUuid]);
    if (!activeProject) return null;
    return (
        <>
            <Stack className={'pt-10 mt-2.5'} spacing="md" align="center">
                <Text
                    align="center"
                    size="lg"
                    weight={500}
                    className="text-gray-800"
                >
                    {t('set_up_page.heading')}
                </Text>
                <Text align="center" className="text-gray-700 text-sm">
                    {description}
                </Text>

                {isWarehouseVisible &&
                    !Boolean(activeProject.warehouseConnection) && (
                        <GenericOption
                            title={t('set_up_page.data_connection_title')}
                            subtitle={t('set_up_page.data_connection_subtitle')}
                            onClick={handleDataConnectionClick}
                            iconComponent={<Database weight="duotone" />}
                        />
                    )}
                {isBlobVisible &&
                    !!activeProject.warehouseConnection &&
                    !Boolean(activeProject.blobConnection) && (
                        <GenericOption
                            title={t('set_up_page.blob_storage_title')}
                            subtitle={t('set_up_page.blob_storage_subtitle')}
                            onClick={handleBlobStorageClick}
                            iconComponent={<Drop weight="duotone" />}
                        />
                    )}

                {isPrimaryVisible &&
                    !!activeProject.warehouseConnection &&
                    !activeProject.isBaseTableConfigured && (
                        <GenericOption
                            title={t('set_up_page.primary_table_select_title')}
                            subtitle={t(
                                'set_up_page.primary_table_select_subtitle',
                            )}
                            onClick={handlePrimaryTableSelectClick}
                            iconComponent={<UserCircle weight="duotone" />}
                            disabled={!activeProject.warehouseConnection}
                        />
                    )}
            </Stack>
        </>
    );
};

export default SetupPage;
