import Checkbox from '@components/common/Checkbox';
import InputErrorText from '@components/common/InputErrorText';
import TextArea from '@components/common/Inputs/TextArea';
import TextInput from '@components/common/Inputs/TextInput';
import MultiSelect, {
    CreationLabel,
} from '@components/common/Select/MultiSelect';
import TimeInputWithOptions from '@components/common/Time/TimeInputWithOptions';
import { useGenerateCampaignNameDescription } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import { useCreateTag, useTags } from '@hooks/useTags';
import {
    CommunicationChannel,
    PeriodType as PeriodTypeEnum,
    type CampaignCreateRequest,
} from '@lightdash/common';
import { Box, Divider, Flex, Stack, Text } from '@mantine/core';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import EventFilterGroup from '../../../EventFilterGroup';

type CampaignDetailsPayloadType = Pick<
    CampaignCreateRequest,
    'name' | 'description' | 'tags'
>;

interface CampaignDetailsProps {
    validateFormRef: React.MutableRefObject<() => Promise<boolean>>;
}

const CampaignDetails: React.FC<CampaignDetailsProps> = ({
    validateFormRef,
}) => {
    const { t } = useLocale();
    const { campaignPayload, isEditMode } = useCampaignContext(
        (context) => context.state,
    );
    const {
        data: nameDescription,
        mutateAsync: mutateAsyncNameDescription,
        isLoading: isLoadingNameDescription,
    } = useGenerateCampaignNameDescription();
    const {
        setCampaignName,
        setCampaignDescription,
        setCampaignTags,
        updateConversionConfig,
        setCurrentStepCallback,
    } = useCampaignContext((context) => context.actions);
    const { mutate: createTag, isLoading: isCreatingTag } = useCreateTag();
    const { data: tags, isLoading: isLoadingTags } = useTags();
    const defaultValues: CampaignDetailsPayloadType = useMemo(() => {
        return {
            name: campaignPayload?.name || '',
            description: campaignPayload?.description || '',
            tags: campaignPayload?.tags || [],
        };
    }, [campaignPayload]);

    const [enableConversionTracking, setEnableConversionTracking] =
        useState<boolean>(false);
    const [errorsValidation, setErrorsValidation] = useState<{
        eventError: string;
    }>({
        eventError: '',
    });

    const form = useForm<CampaignDetailsPayloadType>({
        defaultValues,
    });

    const {
        trigger,
        register,
        formState: { errors },
        clearErrors,
    } = form;

    useEffect(() => {
        if (
            (!campaignPayload.name || !campaignPayload.description) &&
            !nameDescription
        ) {
            void mutateAsyncNameDescription({
                audienceId: campaignPayload?.audienceId || '',
                templateId: campaignPayload?.templateDetails?.id || '',
                channel: campaignPayload.channel ?? CommunicationChannel.EMAIL,
                schedule: campaignPayload.schedule ?? {},
            }).then((res) => {
                if (!campaignPayload.name) {
                    setCampaignName(res.name);
                    form.setValue('name', res.name);
                }
                if (!campaignPayload.description) {
                    setCampaignDescription(res.description);
                    form.setValue('description', res.description);
                }
            });
        }
    }, [
        campaignPayload,
        form,
        mutateAsyncNameDescription,
        nameDescription,
        setCampaignDescription,
        setCampaignName,
    ]);

    useEffect(() => {
        validateFormRef.current = async () => {
            const result = await trigger();
            return result && Object.keys(errors).length === 0;
        };
    }, [trigger, errors, validateFormRef]);

    const onSubmit = useCallback(() => {
        if (
            enableConversionTracking &&
            (!campaignPayload.conversionConfig ||
                (campaignPayload.conversionConfig?.events[0].eventName === '' &&
                    campaignPayload.conversionConfig?.events[0].eventSource ===
                        ''))
        ) {
            setErrorsValidation({
                ...errorsValidation,
                eventError: t('common.error.empty_text_input'),
            });
            return;
        }
    }, [
        enableConversionTracking,
        campaignPayload.conversionConfig,
        errorsValidation,
        t,
    ]);

    const skipExecutionAfterCallback = useMemo(() => {
        return (
            enableConversionTracking &&
            (!campaignPayload.conversionConfig ||
                (campaignPayload.conversionConfig?.events[0].eventName === '' &&
                    campaignPayload.conversionConfig?.events[0].eventSource ===
                        '') ||
                campaignPayload.conversionConfig?.events[0].deadline === null ||
                campaignPayload.conversionConfig?.events[0].deadline === 0)
        );
    }, [enableConversionTracking, campaignPayload.conversionConfig]);

    useEffect(() => {
        setCurrentStepCallback({
            callback: onSubmit,
            skipExecutionAfterCallback: skipExecutionAfterCallback,
        });
    }, [
        campaignPayload.conversionConfig?.events,
        onSubmit,
        setCurrentStepCallback,
        skipExecutionAfterCallback,
    ]);

    const handleCreateTag = useCallback(
        (query: string) => {
            createTag(
                { name: query },
                {
                    onSuccess: () => {
                        setCampaignTags([
                            ...(campaignPayload?.tags ?? []),
                            query,
                        ]);
                    },
                },
            );
            return query;
        },
        [createTag, campaignPayload?.tags, setCampaignTags],
    );

    const onDurationChange = useCallback(
        (duration: number | undefined, granularityValue: PeriodTypeEnum) => {
            updateConversionConfig({
                events: [
                    {
                        ...campaignPayload.conversionConfig?.events[0],
                        id:
                            campaignPayload.conversionConfig?.events[0].id ??
                            uuidv4(),
                        eventName:
                            campaignPayload.conversionConfig?.events[0]
                                .eventName ?? '',
                        eventSource:
                            campaignPayload.conversionConfig?.events[0]
                                .eventSource ?? '',
                        deadline: duration,
                        uiConfig: {
                            timeGranularity: granularityValue,
                        },
                    },
                ],
            });
        },
        [updateConversionConfig, campaignPayload.conversionConfig?.events],
    );

    const errorMessage = useMemo(() => {
        if (campaignPayload.conversionConfig?.events[0].deadline === null) {
            return t('common.error.empty_text_input');
        }
        if (campaignPayload.conversionConfig?.events[0].deadline === 0) {
            return t('duration_delay.enter_a_valid_value');
        }
        return '';
    }, [campaignPayload.conversionConfig?.events, t]);

    return (
        <>
            {/* Hiding conversion tracking for now */}
            {false && (
                <Box>
                    <Text className="text-lg font-medium text-gray-800">
                        {t('campaign_builder.conversion_tracking')}
                    </Text>
                    <Checkbox
                        className="mt-2"
                        label="Enable conversion tracking"
                        checked={enableConversionTracking}
                        onChange={(event) => {
                            setEnableConversionTracking(
                                event.currentTarget.checked,
                            );
                            if (!event.currentTarget.checked) {
                                updateConversionConfig(undefined);
                            }
                        }}
                    />
                    {enableConversionTracking && (
                        <Box className="mt-4">
                            <EventFilterGroup
                                eventConfig={{
                                    eventName:
                                        campaignPayload.conversionConfig
                                            ?.events[0].eventName || '',
                                    eventSource:
                                        campaignPayload.conversionConfig
                                            ?.events[0].eventSource || '',
                                }}
                                setEvent={(eventName, eventSource) => {
                                    updateConversionConfig({
                                        events: [
                                            {
                                                id:
                                                    campaignPayload
                                                        .conversionConfig
                                                        ?.events[0].id ??
                                                    uuidv4(),
                                                eventName,
                                                eventSource,
                                                deadline:
                                                    campaignPayload
                                                        .conversionConfig
                                                        ?.events[0].deadline,
                                            },
                                        ],
                                    });
                                    setErrorsValidation({
                                        ...errorsValidation,
                                        eventError: '',
                                    });
                                }}
                                filters={
                                    campaignPayload.conversionConfig?.events[0]
                                        .filterConfig
                                }
                                setFilters={(filters) => {
                                    updateConversionConfig({
                                        events: [
                                            {
                                                ...campaignPayload
                                                    .conversionConfig
                                                    ?.events[0],
                                                id:
                                                    campaignPayload
                                                        .conversionConfig
                                                        ?.events[0].id ??
                                                    uuidv4(),
                                                eventName:
                                                    campaignPayload
                                                        .conversionConfig
                                                        ?.events[0].eventName ??
                                                    '',
                                                eventSource:
                                                    campaignPayload
                                                        .conversionConfig
                                                        ?.events[0]
                                                        .eventSource ?? '',
                                                filterConfig: filters,
                                            },
                                        ],
                                    });
                                }}
                            />
                            {errorsValidation.eventError && (
                                <InputErrorText
                                    className="mb-2 text-sm font-medium"
                                    value={errorsValidation.eventError}
                                />
                            )}
                            <Text className="mt-4 mb-2 text-sm font-medium text-gray-800">
                                {t('campaign_builder.tracking_window')}
                            </Text>
                            <TimeInputWithOptions
                                selectedDuration={
                                    campaignPayload.conversionConfig?.events[0]
                                        .deadline
                                }
                                onDurationChange={
                                    onDurationChange ?? (() => {})
                                }
                                selectedGranularity={
                                    campaignPayload.conversionConfig?.events[0]
                                        .uiConfig?.timeGranularity ??
                                    PeriodTypeEnum.MINUTE
                                }
                                granularityOptions={[
                                    PeriodTypeEnum.MINUTE,
                                    PeriodTypeEnum.HOUR,
                                    PeriodTypeEnum.DAY,
                                    PeriodTypeEnum.WEEK,
                                    PeriodTypeEnum.MONTH,
                                ]}
                                focusOnMount={true}
                                onBlur={(value) => {
                                    if (
                                        Number.isNaN(value) &&
                                        onDurationChange
                                    ) {
                                        onDurationChange(
                                            null as unknown as number,
                                            PeriodTypeEnum.DAY,
                                        );
                                    }
                                }}
                                error={errorMessage}
                                disabled={false}
                            />

                            <Divider className="mt-4 border-gray-250" />
                        </Box>
                    )}
                </Box>
            )}
            <form name="campaign_details_form" id="campaign_details_form">
                <Stack>
                    <TextInput
                        defaultValue={form.watch('name')}
                        {...register('name', { required: 'Name is required' })}
                        label={t(
                            'campaigns_builder_details.campaign_name_label',
                        )}
                        required
                        disabled={!isEditMode}
                        labelProps={{ style: { marginTop: '8px' } }}
                        placeholder={t(
                            'campaigns_builder_details.campaign_name_placeholder',
                        )}
                        onChange={(event) => {
                            const name = event.currentTarget.value;
                            setCampaignName(name);
                            if (name.length > 0) {
                                clearErrors('name');
                                return;
                            }
                        }}
                        withAsterisk={false}
                        error={errors.name?.message}
                        data-gramm="false" //To disable grammarly
                        data-gramm_editor="false" //To disable grammarly
                        data-enable-grammarly="false" //To disable grammarly
                        isAiGeneratingData={isLoadingNameDescription}
                        aiGeneratedData={nameDescription?.name}
                        aiGenerationCustomClass="mt-2"
                    />
                    <TextArea
                        label={
                            <Text>
                                {t(
                                    'campaigns_builder_details.campaign_description_label',
                                )}{' '}
                                <span className="text-gray-600">
                                    (optional)
                                </span>
                            </Text>
                        }
                        disabled={!isEditMode}
                        labelProps={{ style: { marginTop: '8px' } }}
                        placeholder={t(
                            'campaigns_builder_details.campaign_description_placeholder',
                        )}
                        {...register('description')}
                        onChange={(event) =>
                            setCampaignDescription(event.currentTarget.value)
                        }
                        data-gramm="false"
                        data-gramm_editor="false"
                        data-enable-grammarly="false"
                        isAiGeneratingData={isLoadingNameDescription}
                        aiGeneratedData={nameDescription?.description}
                        aiGenerationCustomClass="mt-2"
                    />
                    <MultiSelect
                        placeholder={t(
                            'audience_create.modal_tags_placeholder',
                        )}
                        label={
                            <Flex gap={1}>
                                <Text className="text-sm font-medium text-gray-800">
                                    {t('common.tags')}
                                </Text>
                                <Text className="text-sm font-medium text-gray-600">
                                    {t(
                                        'custom_metric.dimension_modal_description_label_optional',
                                    )}
                                </Text>
                            </Flex>
                        }
                        {...register('tags')}
                        onChange={(value) => {
                            setCampaignTags(value);
                        }}
                        data={
                            tags?.map((tag) => ({
                                value: tag.name,
                                label: tag.name,
                            })) || []
                        }
                        searchable
                        creatable
                        getCreateLabel={(query) => (
                            <CreationLabel
                                query={query}
                                isCreating={isCreatingTag}
                            />
                        )}
                        onCreate={handleCreateTag}
                        disabled={isLoadingTags || isCreatingTag || !isEditMode}
                        disableSelectedItemFiltering
                    />
                </Stack>
            </form>
        </>
    );
};

export default React.memo(CampaignDetails);
