import {
    assertUnreachable,
    FilterType,
    JOURNEY_DYNAMIC_VARIABLE_PREFIX,
    type ConditionalRule,
} from '@lightdash/common';
import BooleanFilterInputs from './BooleanFilterInputs';
import DateFilterInputs from './DateFilterInputs';
import DefaultFilterInputs from './DefaultFilterInputs';
import FilterDynamicValueField from './FilterDynamicValueField';
import { type FilterInputsProps } from './utils';

export const FilterInputComponent = <T extends ConditionalRule>(
    props: FilterInputsProps<T>,
) => {
    const { rule } = props;
    const values = rule?.values;

    if (
        values &&
        values.length > 0 &&
        typeof values[0] === 'string' &&
        values[0].startsWith(JOURNEY_DYNAMIC_VARIABLE_PREFIX)
    ) {
        return (
            <FilterDynamicValueField
                selectedFieldId={values[0]}
                dynamicFieldValues={props.dynamicFieldValues}
                onChange={() => props.onChange({ ...rule, values: [] })}
            />
        );
    }

    switch (props.filterType) {
        case FilterType.EVENT:
        case FilterType.STRING:
        case FilterType.NUMBER:
            return <DefaultFilterInputs<T> {...props} />;
        case FilterType.DATE:
            return <DateFilterInputs<T> {...props} />;
        case FilterType.BOOLEAN:
            return <BooleanFilterInputs<T> {...props} />;
        default:
            return assertUnreachable(
                props.filterType,
                `Unexpected filter type: ${props.filterType}`,
            );
    }
};
