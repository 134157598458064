import { ActionType, type JourneyAction } from '@lightdash/common';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import React, { useCallback } from 'react';
import { useNodeData } from '../../ReactFlow/Nodes/useNodeData';
import { ControlPanel } from '../../types';
import ActionStack from '../Actions/ActionStack';
import BlockConfigContainer from './BlockConfigContainer';

interface BlockConfigPanelProps {
    nodeId: string;
}

const BlockConfigPanel: React.FC<BlockConfigPanelProps> = ({ nodeId }) => {
    const {
        updateNodeActionConfig,
        closeControlPanel,
        updateNodeConfig,
        openControlPanel,
        deleteNode,
        deleteAllChildBranches,
    } = useJourneyBuilderContext((context) => context.actions);

    const { isEditable, blocksList } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const { journeyNodeData, icon } = useNodeData(nodeId);
    const onActionChange = useCallback(
        (updatedAction: JourneyAction) => {
            if (journeyNodeData) {
                updateNodeActionConfig(journeyNodeData.id, updatedAction);
            }
        },
        [journeyNodeData, updateNodeActionConfig],
    );

    const onDescriptionChange = useCallback(
        (description: string) => {
            if (!nodeId) return;
            updateNodeConfig({
                nodeId,
                nodePayload: { description },
            });
        },
        [nodeId, updateNodeConfig],
    );

    const handleChangeButtonClick = useCallback(() => {
        if (!nodeId) return;
        openControlPanel({
            type: ControlPanel.BLOCKS_LIST,
            reactFlowNodeId: nodeId,
        });
    }, [nodeId, openControlPanel]);

    const containsEveryOneElse = useCallback(
        (blockId: string | undefined) => {
            if (!blockId) return false;
            const block = blocksList?.find((b) => b.id === blockId);
            return block?.actions.some(
                (action) => action.config && action.config.allowEveryoneElse,
            );
        },
        [blocksList],
    );

    const handleDeleteButtonClick = useCallback(() => {
        if (!nodeId) return;

        if (
            journeyNodeData?.actions.some(
                (action) => action.type === ActionType.SPLIT,
            ) ||
            containsEveryOneElse(journeyNodeData?.metadata?.blockId)
        ) {
            deleteAllChildBranches(nodeId);
        }
        deleteNode(nodeId);
    }, [
        nodeId,
        deleteNode,
        deleteAllChildBranches,
        journeyNodeData?.actions,
        containsEveryOneElse,
        journeyNodeData?.metadata?.blockId,
    ]);

    if (!nodeId || !journeyNodeData) return null;

    return (
        <BlockConfigContainer
            nodeData={journeyNodeData}
            icon={icon}
            onDescriptionChange={onDescriptionChange}
            onBlockClose={closeControlPanel}
            showModifyButtons={true}
            onChangeButtonClick={handleChangeButtonClick}
            onDeleteButtonClick={handleDeleteButtonClick}
            isEditable={isEditable}
        >
            <ActionStack
                actions={journeyNodeData.actions}
                onActionChange={onActionChange}
                nodeId={journeyNodeData.id}
            />
        </BlockConfigContainer>
    );
};

export default React.memo(BlockConfigPanel);
