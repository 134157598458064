import {
    AIMessageContext,
    AiMessageTypes,
    Author,
    type AgentJsonContent,
    type AgentMessage,
    type Chart,
} from '@lightdash/common';
import useAiAnalystContext from '@providers/AiAnalyst/useAiAnalystContext';
import { useChat } from 'ai/react';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import useAIMessageContext from '../components/AnalystChat/useAIMessageContext';

const useSorte = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { threadId } = useAiAnalystContext((context) => context.state);
    const navigate = useNavigate();
    const { setAIChatMessages, onSuccess } = useAiAnalystContext(
        (context) => context.actions,
    );
    const { getCurrentContext, getContextLocation } = useAIMessageContext();

    const [chatInput, setChatInput] = useState('');
    const [msgInput, setMsgInput] = useState('');

    const parseStream = useCallback(
        async (readableStream: any) => {
            const reader = readableStream.getReader();
            const decoder = new TextDecoder();
            let done = false;
            let result = '';

            while (!done) {
                const { value, done: doneReading } = await reader.read();
                done = doneReading;

                result += decoder.decode(value, { stream: true });

                const messagesStream = result.split('\n');
                for (let message of messagesStream) {
                    if (message.trim()) {
                        const messageData = JSON.parse(message);
                        setAIChatMessages([
                            ...messageData.filter(
                                (item: AgentMessage) =>
                                    item.content.type === AiMessageTypes.TEXT ||
                                    (item.context === AIMessageContext.CHART &&
                                        (item.content.value as Chart)
                                            .context ===
                                            AIMessageContext.CHART),
                            ),
                        ]);
                        const jsonMessage = messageData.filter(
                            (item: AgentMessage) =>
                                item.content.type === AiMessageTypes.JSON,
                        );
                        if (jsonMessage.length === 0) continue;

                        const jsonContent = jsonMessage[0]
                            .content as AgentJsonContent;
                        if (getCurrentContext === jsonContent.value.context) {
                            onSuccess(jsonContent);
                            continue;
                        }
                        const location = getContextLocation(
                            jsonContent.value.context,
                        );
                        if (location) {
                            await navigate(location, {
                                state: {
                                    aiJsonData: jsonContent,
                                },
                            });
                        }
                    }
                }
                result = '';
            }
        },
        [
            getContextLocation,
            getCurrentContext,
            navigate,
            onSuccess,
            setAIChatMessages,
        ],
    );

    const chat = useChat({
        streamProtocol: AiMessageTypes.TEXT,
        api: `/api/v1/projects/${projectUuid}/ai/agents`,
        body: {
            threadId,
            messageId: uuidv4(),
            author: Author.USER,
            context: getCurrentContext,
            content: {
                type: AiMessageTypes.TEXT,
                value: msgInput,
            },
        },
        onResponse: async (res) => {
            await parseStream(res.body);
        },
    });

    const handleSendMessage = useCallback(
        async (message?: string) => {
            if (chatInput.trim() === '' && !message) return;
            setMsgInput(message ?? chatInput);
            setChatInput('');
            await chat.append({
                role: Author.USER,
                content: msgInput,
            });
            setMsgInput('');
        },
        [chat, chatInput, setChatInput, msgInput],
    );

    return { ...chat, chatInput, setChatInput, handleSendMessage };
};

export default useSorte;
