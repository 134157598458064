import { convertFieldsIntoPropertySelectListType } from '@components/Audience/Filters/FieldListItem/utils';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { getJourneyEventPropertySelectList } from '@components/Journeys/Builder/JourneyFilters/useJourneyProperties';
import {
    type AnyType,
    type HttpConfig,
    type ResponseConfig,
} from '@lightdash/common';
import { Divider, Flex, Stack } from '@mantine/core';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import React, { useMemo } from 'react';
import { useJourneyNodeParams } from '../../useJourneyNodeParams';
import EndPointSelect from './EndPointSelect';
import Headers from './Headers';
import { getFieldIdForSuggestionsWithHandlebars } from './JsonEditorWithMentions/utils';
import RequestBody from './RequestBody';
import ResponsePayloadMapper from './ResponsePayloadMapper';
import { type CallAPIFieldAndSchemaType } from './types';
import { defaultJourneyDataSchema } from './utils';

export interface CallAPIActionProps {
    nodeId: string;
    payloadMapper: string;
    httpConfig: HttpConfig;
    responseConfig: ResponseConfig;
    handlePayloadMapperChange: (
        value: CallAPIFieldAndSchemaType<string>,
    ) => void;
    handleHttpConfigChange: (
        value: CallAPIFieldAndSchemaType<HttpConfig>,
    ) => void;
    onResponsePayloadMapperChange: (value: Record<string, AnyType>) => void;
}

const CallAPIAction: React.FC<CallAPIActionProps> = ({
    nodeId,
    handleHttpConfigChange,
    httpConfig,
    responseConfig,
    payloadMapper,
    handlePayloadMapperChange,
    onResponsePayloadMapperChange,
}) => {
    const { journeyDataSchema, warehouseFields, isLoading, isError } =
        useJourneyNodeParams(nodeId);

    const { nodes, edges, journeyPayload, isEditable } =
        useJourneyBuilderContext((context) => context.state);
    const journeyProperties = useMemo(
        () =>
            getJourneyEventPropertySelectList({
                journeyDataSchema,
                nodes,
                edges,
                nodeId: nodeId,
                journeyNodes: journeyPayload.config?.nodes ?? [],
            }),
        [journeyDataSchema, nodes, edges, nodeId, journeyPayload],
    );
    const fieldIds = useMemo(
        () =>
            getFieldIdForSuggestionsWithHandlebars(
                warehouseFields,
                journeyProperties,
            ),
        [warehouseFields, journeyProperties],
    );
    const journeyPropertiesItems = useMemo(
        () => [
            ...convertFieldsIntoPropertySelectListType(
                Object.values(warehouseFields),
                true,
            ),
            ...journeyProperties,
        ],
        [warehouseFields, journeyProperties],
    );

    if (
        isLoading ||
        ((journeyPropertiesItems.length === 0 ||
            Object.keys(warehouseFields).length === 0) &&
            !isError)
    ) {
        return (
            <Stack className="gap-4 w-[30vw]">
                <SkeletonLoader height={70} />
            </Stack>
        );
    }
    return (
        <Flex direction="column" gap="md" className="w-[30vw]">
            <EndPointSelect
                handleHttpConfigChange={handleHttpConfigChange}
                httpConfig={httpConfig}
                disabled={!isEditable}
                fieldIds={fieldIds}
                journeyProperties={journeyPropertiesItems}
                warehouseFieldsMap={warehouseFields}
                journeyDataSchema={
                    journeyDataSchema ?? defaultJourneyDataSchema
                }
            />
            <Divider className="border-t-gray-200 " />
            <Headers
                handleHttpConfigChange={handleHttpConfigChange}
                httpConfig={httpConfig}
                fieldIds={fieldIds}
                journeyProperties={journeyPropertiesItems}
                warehouseFieldsMap={warehouseFields}
                journeyDataSchema={
                    journeyDataSchema ?? defaultJourneyDataSchema
                }
                disabled={!isEditable}
            />
            <Divider className="border-t-gray-200" />
            <RequestBody
                payloadMapper={payloadMapper}
                handlePayloadMapperChange={handlePayloadMapperChange}
                fieldIds={fieldIds}
                journeyProperties={journeyPropertiesItems}
                warehouseFieldsMap={warehouseFields}
                journeyDataSchema={
                    journeyDataSchema ?? defaultJourneyDataSchema
                }
                disabled={!isEditable}
            />
            <Divider className="border-t-gray-200" />
            <ResponsePayloadMapper
                onResponsePayloadMapperChange={onResponsePayloadMapperChange}
                responseConfig={responseConfig}
                disabled={!isEditable}
            />
        </Flex>
    );
};

export default React.memo(CallAPIAction);
