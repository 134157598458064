import BuilderStepTitle from '@components/common/BuilderContainer/BuilderStepTitle';
import Checkbox from '@components/common/Checkbox';
import {
    EventBuilderStep,
    type PropertiesType,
} from '@components/EventsManager/types';
import {
    convertToMapperSchema,
    EventBuilderSteps,
    EventLabelDataTypeOptions,
} from '@components/EventsManager/utils';
import { TableTypeOptions } from '@components/Table';
import { fuzzyFilter } from '@components/Table/utils';
import { useAllColumns } from '@hooks/useEvents';
import { useLocale } from '@hooks/useLocale';
import { DimensionType, ShowDataType } from '@lightdash/common';
import { Box, Button, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PlusCircle, X } from '@phosphor-icons/react';
import useEventContext from '@providers/Events/useEventContext';
import {
    getCoreRowModel,
    useReactTable,
    type ColumnDef,
} from '@tanstack/react-table';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { ButtonVariant } from '../../../../mantineTheme';
import AddEventKeysModal from './AddEventKeysModal';
import CreatableSelect from './CreatableSelect';

const EventBuilderProperties: React.FC<{}> = () => {
    const { t } = useLocale();
    const [opened, { open, close }] = useDisclosure();
    const { state, actions } = useEventContext((context) => context);
    const { jsonPayloadKeys, isViewMode } = state;
    const { data: columnData } = useAllColumns();
    const {
        setMapperSchema,
        setCurrentStepCallback,
        setShowFooterButtons,
        setJsonPayloadKeys,
    } = actions;
    const labelMapRef = useRef<{ [key: string]: number }>({});
    const [isNextButtonClicked, setIsNextButtonClicked] = useState(false);
    const title = EventBuilderSteps.find(
        (step) => step.key === EventBuilderStep.PROPERTIES,
    )?.pageHeader;

    const [labelData, setLabelData] = useState<
        { value: string; label: string; dataType: DimensionType }[]
    >([]);
    useEffect(() => {
        setLabelData(
            columnData
                ?.map((column) => ({
                    value: column.columnName,
                    label: column.label ?? column.columnName,
                    dataType: column.columnDataType,
                }))
                .sort((a, b) =>
                    a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
                ) ?? [],
        );
    }, [columnData]);
    const [data, setData] = useState<PropertiesType[]>(() =>
        jsonPayloadKeys.filter((keyObj) => keyObj.selectedProperty),
    );
    const canMoveToNextStep = useMemo(() => {
        return data.some(
            (row) =>
                row.label === '' ||
                Object.values(labelMapRef.current).some((count) => count > 1),
        );
    }, [data]);

    const saveMapperSchema = useCallback(() => {
        setMapperSchema(
            convertToMapperSchema(
                data.map((obj) => ({
                    label: obj.columnName,
                    type: obj.type as DimensionType,
                    required: true,
                    path: obj.key,
                })),
            ),
        );
        return true;
    }, [data, setMapperSchema]);
    useEffect(() => {
        saveMapperSchema();
    }, [data, saveMapperSchema]);
    useEffect(() => {
        setCurrentStepCallback({
            callback: () => {
                setIsNextButtonClicked(true);
            },
            skipExecutionAfterCallback: canMoveToNextStep,
        });
    }, [setCurrentStepCallback, data, setMapperSchema, canMoveToNextStep]);
    useEffect(() => {
        setShowFooterButtons({
            next: true,
            disableNext: false,
            back: true,
        });
    }, [setShowFooterButtons]);

    const updateLabelMap = useCallback((newData: PropertiesType[]) => {
        const newLabelMap = newData.reduce<{ [key: string]: number }>(
            (acc, item) => {
                const label = item.label
                    ? item.label?.trim()?.toLowerCase()
                    : '';
                if (label != '') {
                    if (acc[label]) {
                        acc[label] += 1;
                    } else {
                        acc[label] = 1;
                    }
                }
                return acc;
            },
            {},
        );
        labelMapRef.current = newLabelMap;
    }, []);

    const handleDelete = useCallback(
        (rowIndex: number) => {
            const dataToDelete = data[rowIndex];
            const newData = data.filter((_, index) => index !== rowIndex);
            updateLabelMap(newData);
            setData(newData);
            setJsonPayloadKeys(
                jsonPayloadKeys.map((keyObj) => {
                    if (keyObj.key === dataToDelete.key) {
                        return { ...keyObj, selectedProperty: false };
                    }
                    return keyObj;
                }),
            );
        },
        [updateLabelMap, data, setJsonPayloadKeys, jsonPayloadKeys],
    );

    const update = useCallback(
        (rowIndex: number, updates: { [columnId: string]: unknown }) => {
            const newData = data.map((row, index) => {
                if (index === rowIndex) {
                    return { ...row, ...updates };
                }
                return row;
            });
            updateLabelMap(newData);
            setData(newData);
        },
        [updateLabelMap, data],
    );

    const isEventLabelDataType = useCallback(
        (type: string): type is DimensionType => {
            return Object.keys(EventLabelDataTypeOptions).includes(type);
        },
        [],
    );

    const getIconAndLabel = useCallback(
        (type: string, isComplexDataType: boolean) => {
            if (!isComplexDataType && isEventLabelDataType(type)) {
                const { icon, label } =
                    EventLabelDataTypeOptions[type as DimensionType];
                return (
                    <Flex gap={8} align="center">
                        {icon}
                        <Text>{label}</Text>
                    </Flex>
                );
            } else {
                const { icon } = EventLabelDataTypeOptions.array;
                const sub = type.split(' ');
                const subType =
                    EventLabelDataTypeOptions[
                        sub[sub.length - 1] as DimensionType
                    ];
                return (
                    <Flex gap={8} align="center">
                        {icon}
                        {subType?.icon}
                        <Text>{`${
                            subType?.type !== DimensionType.TIMESTAMP
                                ? `${subType?.label}s`
                                : subType?.label
                        }`}</Text>
                    </Flex>
                );
            }
        },
        [isEventLabelDataType],
    );

    const columns: ColumnDef<PropertiesType>[] = useMemo(
        () => [
            {
                header: 'Key',
                accessorKey: 'key',
                cell: ({ row }) => {
                    return (
                        <Box className="text-sm font-medium text-gray-800">
                            {row.original?.key}
                        </Box>
                    );
                },
            },
            {
                header: 'Label',
                accessorKey: 'label',
                cell: ({ row }) => {
                    const { original } = row;
                    const { type, key, reservedKey } = original;
                    const isComplexDataType = type.includes('of');

                    return (
                        <Flex className="min-w-[15rem]" align="center" gap={16}>
                            <Box className="min-w-[10rem]">
                                <CreatableSelect
                                    key={key}
                                    labelData={labelData}
                                    row={row}
                                    update={update}
                                    canChangeDataType={!reservedKey}
                                    labelMap={labelMapRef.current}
                                    highlightEmptyLabel={
                                        row.original.label === '' &&
                                        isNextButtonClicked
                                    }
                                />
                            </Box>
                            <Box className="text-sm font-medium leading-5 text-gray-600">
                                {getIconAndLabel(type, isComplexDataType)}
                            </Box>
                        </Flex>
                    );
                },
            },
            {
                header: 'Required',
                accessorKey: 'required',
                cell: ({ row }) => (
                    <Checkbox
                        size="xs"
                        checked={row.original?.required}
                        onChange={(event) =>
                            update(row.index, {
                                required: event.target.checked,
                            })
                        }
                        disabled={row.original?.reservedKey}
                    />
                ),
            },
            {
                header: '',
                accessorKey: 'cancel',
                cell: ({ row }) => (
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        className={`p-2 ${
                            row.original?.reservedKey
                                ? '!border !border-shade-2 '
                                : ''
                        }`}
                        onClick={() => handleDelete(row.index)}
                        disabled={row.original?.reservedKey || isViewMode}
                    >
                        <X
                            weight="bold"
                            color={
                                row.original?.reservedKey
                                    ? 'rgb(var(--color-gray-400))'
                                    : 'rgb(var(--color-gray-700))'
                            }
                        />
                    </Button>
                ),
            },
        ],
        [
            update,
            handleDelete,
            labelData,
            getIconAndLabel,
            isViewMode,
            isNextButtonClicked,
        ],
    );

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <Box className="pb-16 overflow-auto">
            <Flex align={'center'} justify="space-between" className="pb-5">
                <BuilderStepTitle title={title || ''} />

                {!isViewMode && (
                    <Button
                        variant={ButtonVariant.FILLED}
                        onClick={open}
                        leftIcon={<PlusCircle color="white" />}
                    >
                        {t('event_create.properties_add_properties_button')}
                    </Button>
                )}
            </Flex>
            <Box className="border border-shade-4 rounded-2xl p-0.5">
                <Box className="overflow-hidden border rounded-xl border-gray-50">
                    <Box className="max-h-[38rem] overflow-auto">
                        <TableTypeOptions
                            viewType={ShowDataType.LIST}
                            table={table}
                        />
                    </Box>
                </Box>
            </Box>
            <AddEventKeysModal
                opened={opened}
                close={close}
                addData={() => {
                    setData(
                        jsonPayloadKeys
                            .filter((keyObj) => keyObj.selectedProperty)
                            .sort((a, b) => {
                                if (a.reservedKey && !b.reservedKey) {
                                    return -1;
                                }
                                if (!a.reservedKey && b.reservedKey) {
                                    return 1;
                                }
                                return 0;
                            }),
                    );
                    const filteredAndSortedKeys = jsonPayloadKeys.filter(
                        (keyObj) => keyObj.selectedProperty,
                    );

                    updateLabelMap(filteredAndSortedKeys);
                    setIsNextButtonClicked(false);
                }}
            />
        </Box>
    );
};

export default EventBuilderProperties;
