import { useContextSelector } from 'use-context-selector';
import AiAnalystProviderContext from './context';
import { type AiAnalystContext } from './types';

function useAiAnalystContext<Selected>(
    selector: (value: AiAnalystContext) => Selected,
) {
    return useContextSelector(AiAnalystProviderContext, (context) => {
        if (context === undefined) {
            throw new Error(
                'useAiAnalyst must be used within a AiAnalystProvider',
            );
        }
        return selector(context);
    });
}

export default useAiAnalystContext;
