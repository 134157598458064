import AiBanner from '@components/common/AiBanner';
import TableIcon from '@components/common/IconPack/TableIcon';
import ButtonInput from '@components/common/Inputs/ButtonInput';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import { useDeleteTable } from '@hooks/useRelation';
import { useUpdateTableDetail } from '@hooks/useSchemaBuilder';
import { Box, Button, Drawer, Flex, ScrollArea, Text } from '@mantine/core';
import useAiAnalystContext from '@providers/AiAnalyst/useAiAnalystContext';
import useSchemaContext from '@providers/Schema/useSchemaContext';
import { useQueryClient } from '@tanstack/react-query';
import { AI_BANNER_SESSION_KEY } from '@utils/constants';
import { debounce } from 'lodash';
import {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
    type FC,
} from 'react';
import { useParams } from 'react-router';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant, FontSizes, FontWeights } from '../../../mantineTheme';
import useRelationContext from '../../../providers/Relation/useRelationContext';
import SchemaBuilderTabs from './SchemaBuilderTabs';
import { SchemaConfigStep } from './types';
import { SchemaConfigSteps } from './utils';

const debouncedUpdateDetail = debounce(async (updateDetail, field, value) => {
    await updateDetail({ [field]: value });
}, 1000);

const SchemaBuilderDrawer: FC<{}> = memo(() => {
    const { t } = useLocale();
    const { showToastError, showToastSuccess } = useNotify();
    const [showAiBanner, setShowAiBanner] = useState(false);
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { schemaPayload, isDrawerOpen, isEditMode, currentConfigStep } =
        useSchemaContext((context) => context.state);
    const { toggleDrawer } = useSchemaContext((context) => context.actions);
    const { activeRelationUuid } = useRelationContext();
    const { mutateAsync: updateTableDetail } =
        useUpdateTableDetail(activeRelationUuid);
    const queryClient = useQueryClient();
    const { mutateAsync: deleteTable } = useDeleteTable();
    const { showAiAnalyst } = useAiAnalystContext((context) => context.state);

    useEffect(() => {
        const aiBanner = JSON.parse(
            sessionStorage.getItem(AI_BANNER_SESSION_KEY) || '{}',
        );
        setShowAiBanner(aiBanner?.[schemaPayload.name] ?? false);
    }, [schemaPayload.name]);

    const CurrentConfigComponent = useMemo(
        () =>
            SchemaConfigSteps.find((step) => step.key === currentConfigStep)
                ?.component,
        [currentConfigStep],
    );

    const updateDetail = useCallback(
        async (payload: Record<string, string>) => {
            if (!schemaPayload.name) return;

            if (payload.label) {
                payload.label = payload.label.trim();
            }

            await updateTableDetail({
                tableName: schemaPayload.name,
                payload: {
                    type: schemaPayload.type,
                    ...payload,
                },
            });

            await queryClient.invalidateQueries([
                QueryKeys.RELATION_SCHEMA,
                projectUuid,
                activeRelationUuid,
            ]);
        },
        [
            activeRelationUuid,
            projectUuid,
            queryClient,
            schemaPayload,
            updateTableDetail,
        ],
    );

    const onValueChange = useCallback(
        (field: string, value: string) => {
            void debouncedUpdateDetail(updateDetail, field, value);
        },
        [updateDetail],
    );

    const handleCloseAiBanner = useCallback(() => {
        const aiBanner = JSON.parse(
            sessionStorage.getItem(AI_BANNER_SESSION_KEY) || '{}',
        );
        const newAiBanner = {
            ...aiBanner,
            [schemaPayload.name]: false,
        };
        sessionStorage.setItem(
            AI_BANNER_SESSION_KEY,
            JSON.stringify(newAiBanner),
        );
        setShowAiBanner(false);
    }, [schemaPayload.name]);

    const handleDeleteTable = async () => {
        try {
            await deleteTable({
                relationUuid: activeRelationUuid,
                tableId: schemaPayload.name,
            });
            toggleDrawer(false);
            showToastSuccess({
                title: t('schema_builder.delete_table_success'),
            });
            await queryClient.invalidateQueries([
                QueryKeys.RELATION_SCHEMA,
                projectUuid,
                activeRelationUuid,
                false,
            ]);
        } catch (error) {
            showToastError({
                title: t('schema_builder.delete_table_failure'),
            });
        }
    };

    if (!CurrentConfigComponent) return <></>;

    return (
        <>
            <Drawer.Root
                position="right"
                opened={isDrawerOpen}
                onClose={toggleDrawer}
                scrollAreaComponent={ScrollArea.Autosize}
                size="55rem"
                trapFocus={false}
            >
                <Drawer.Content
                    className={`m-4 mt-16 rounded-lg h-[calc(100%-5rem)] overflow-hidden ${
                        showAiAnalyst ? 'mr-[25vw]' : ''
                    }`}
                >
                    <Drawer.Header className="flex items-start">
                        <Drawer.Title>
                            <Flex align={'center'}>
                                <TableIcon
                                    type={schemaPayload?.type}
                                    size={FontSizes.lg}
                                />
                                {!isEditMode && (
                                    <Text className="ml-2">
                                        Add {schemaPayload?.type?.toLowerCase()}{' '}
                                        table
                                    </Text>
                                )}
                                {!!isEditMode && (
                                    <ButtonInput
                                        defaultValue={schemaPayload?.label}
                                        fontSize={FontSizes.lg}
                                        fontWeight={FontWeights.semibold}
                                        placeholder=""
                                        onChange={(value: string) =>
                                            onValueChange('label', value)
                                        }
                                        className="z-0"
                                    />
                                )}
                            </Flex>
                            {!!isEditMode && (
                                <ButtonInput
                                    defaultValue={schemaPayload?.description}
                                    fontSize={FontSizes.sm}
                                    fontWeight={FontWeights.normal}
                                    placeholder="Click to add a description"
                                    onChange={(value: string) => {
                                        onValueChange('description', value);
                                    }}
                                    className="z-0"
                                />
                            )}
                        </Drawer.Title>
                        <Drawer.CloseButton className="mt-1" />
                    </Drawer.Header>
                    <Drawer.Body className="p-0 pb-[100px] mb-[64px]">
                        <hr className="w-full mx-0 mb-2" />

                        {isEditMode && schemaPayload.isConfigured && (
                            <>
                                <SchemaBuilderTabs />
                                <hr className="w-full mx-0 my-2" />
                            </>
                        )}

                        <Box className="px-4">
                            {currentConfigStep ===
                                SchemaConfigStep.PROPERTIES && (
                                <AiBanner
                                    show={showAiBanner}
                                    onClose={handleCloseAiBanner}
                                    text="Auto-generated labels and descriptions."
                                />
                            )}
                            <CurrentConfigComponent />
                        </Box>
                        {!schemaPayload.isConfigured && isEditMode && (
                            <Box className="fixed bottom-0 left-[10px] w-full p-4 my-0 -ml-3 bg-white border-t-[1px]">
                                <Button
                                    onClick={handleDeleteTable}
                                    variant={ButtonVariant.OUTLINED}
                                >
                                    {t('common.delete')}
                                </Button>
                            </Box>
                        )}
                    </Drawer.Body>
                </Drawer.Content>
            </Drawer.Root>
        </>
    );
});

export default SchemaBuilderDrawer;
