import { Button } from '@mantine/core';
import React, { type PropsWithChildren } from 'react';
import { ButtonVariant } from '../../../../mantineTheme';

interface FilterSelectWrapperProps {
    isEditMode: boolean;
}

const FilterSelectWrapper: React.FC<
    PropsWithChildren<FilterSelectWrapperProps>
> = ({ isEditMode, children }) => {
    return (
        <Button
            variant={ButtonVariant.UNSTYLED}
            className="w-fit px-2 !h-9"
            style={{
                borderRadius: '8px',
                background: 'white',
                border: '1px solid',
                borderColor: 'rgba(0, 0, 0, 0.06)',
                boxShadow: `0px -1px 4px 0px rgba(0, 0, 0, 0.06) inset`,
                fontSize: '14px',
                color: 'rgb(var(--color-gray-600))',
                width: 'wrap-content !important',
                cursor: !isEditMode ? 'auto' : 'pointer',
                minWidth: '4rem',
            }}
            disabled={!isEditMode}
        >
            {children}
        </Button>
    );
};

export default React.memo(FilterSelectWrapper);
