import SettingsTitle from '@components/common/Settings/SettingsTitle';
import OrganizationSettingsForm from '@components/OrganizationSettings/OverviewPanel/OrganizationSettingsForm';
import { useActiveProjectUuid } from '@hooks/useActiveProject';
import { useLocale } from '@hooks/useLocale';
import { Box, Flex } from '@mantine/core';
import React from 'react';

const Overview: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { activeProjectUuid, isLoading: isActiveProjectUuidLoading } =
        useActiveProjectUuid();

    if (isActiveProjectUuidLoading) return null;

    return (
        <Box>
            <Flex className="items-center justify-between w-full pt-3">
                <SettingsTitle
                    title={t('organization_settings.overview_title')}
                />
            </Flex>

            <Box className="my-6">
                <OrganizationSettingsForm
                    activeProjectUuid={activeProjectUuid ?? ''}
                />
            </Box>
        </Box>
    );
};

export default React.memo(Overview);
