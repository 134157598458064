import React, { type FC } from 'react';
import { Helmet } from 'react-helmet';

import { Box, createStyles } from '@mantine/core';
import { TrackSection } from '@providers/Tracking/TrackingProvider';
import {
    FOOTER_HEIGHT,
    FOOTER_MARGIN,
    NAVBAR_HEIGHT,
    PAGE_MIN_CONTENT_WIDTH,
} from '@utils/constants';
import { SectionName } from 'types/Events';
import { type PageStyleProps } from 'types/Page';
import PageFooter from './PageFooter';
import PageHeader from './PageHeader';
import Sidebar from './Sidebar';

type PageProps = {
    title?: string;
    sidebar?: React.ReactNode;
    isSidebarOpen?: boolean;
    header?: React.ReactNode;
    hasBanner?: boolean;
    className?: string;
    footer?: React.ReactNode;
    withFullWidthHeader?: boolean;
    sidebarLeftPadding?: number;
    contentClassName?: string;
} & Omit<PageStyleProps, 'withSidebar' | 'withHeader'>;

const usePageStyles = createStyles<string, PageStyleProps>((theme, params) => {
    let containerHeight = '100vh';

    if (params.withNavbar) {
        containerHeight = `calc(${containerHeight} - ${NAVBAR_HEIGHT}px)`;
    }

    return {
        root: {
            backgroundColor:
                params?.backgroundColor === 'gray'
                    ? 'var(--color-gray-100)'
                    : 'white',
            ...(params.withFullHeight
                ? {
                      height: containerHeight,
                      maxHeight: containerHeight,
                  }
                : {
                      height: containerHeight,
                      overflowY: 'auto',
                  }),

            ...(params.withSidebar
                ? {
                      display: 'flex',
                      flexDirection: 'row',
                  }
                : {}),
        },

        content: {
            width: '100%',
            // minWidth: PAGE_CONTENT_WIDTH,
            flexGrow: 1,
            ...(params.withSidebar
                ? {
                      minWidth: PAGE_MIN_CONTENT_WIDTH,
                      width: PAGE_MIN_CONTENT_WIDTH,
                  }
                : {}),

            ...(params.withFooter
                ? {
                      minHeight: `calc(100% - ${FOOTER_HEIGHT}px - ${FOOTER_MARGIN}px - 1px)`,
                  }
                : {}),

            ...(params.withFullHeight
                ? {
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      maxHeight: '100%',
                      overflowY: 'auto',
                  }
                : {}),

            ...(params.withFixedFooter
                ? {
                      height: `calc(100% - ${FOOTER_HEIGHT}px - 5px)`,
                  }
                : {}),

            ...(params.withFixedContent
                ? {
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      //   width: PAGE_CONTENT_WIDTH,
                      flexShrink: 0,
                  }
                : {}),

            ...(params.withFitContent
                ? {
                      width: 'fit-content',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                  }
                : {}),

            ...(params.withPaddedContent
                ? {
                      //   paddingLeft: params.withSidebar
                      //       ? theme.spacing.xxl
                      //       : theme.spacing['8xl'],
                      //   paddingRight: params.withSidebar
                      //       ? ''
                      //       : theme.spacing['8xl'],
                      paddingLeft: theme.spacing.xxl,
                      paddingRight: theme.spacing.xxl,
                      paddingTop: theme.spacing.md,
                      paddingBottom: theme.spacing.md,
                  }
                : {}),

            ...(params.withCenteredContent
                ? {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                  }
                : {}),
        },
    };
});

const Page: FC<React.PropsWithChildren<PageProps>> = ({
    title,
    header,
    sidebar,
    withCenteredContent = false,
    withFitContent = false,
    withFixedContent = false,
    withFullHeight = false,
    withNavbar = true,
    withPaddedContent = false,
    withSidebarFooter = false,
    hasBanner = false,
    className,
    children,
    backgroundColor = 'gray',
    isSidebarOpen = true,
    withFixedFooter = false,
    withFullWidthHeader = false,
    footer,
    sidebarLeftPadding,
    contentClassName,
}) => {
    const { classes } = usePageStyles(
        {
            withCenteredContent,
            withFitContent,
            withFixedContent,
            withFooter: !!footer,
            withFullHeight,
            withHeader: !!header,
            withNavbar,
            withPaddedContent,
            withSidebar: !!sidebar,
            withSidebarFooter,
            hasBanner,
            backgroundColor,
            withFixedFooter,
        },
        { name: 'Page' },
    );
    return (
        <>
            {title ? (
                <Helmet>
                    <title>{title} · Sortment</title>
                </Helmet>
            ) : null}

            <Box className={`${classes.root} ${className}`}>
                {header ? (
                    <PageHeader withFullWidthHeader={withFullWidthHeader}>
                        {header}
                    </PageHeader>
                ) : null}
                {sidebar ? (
                    <Sidebar
                        isOpen={isSidebarOpen}
                        sidebarLeftPadding={sidebarLeftPadding}
                    >
                        {sidebar}
                    </Sidebar>
                ) : null}

                <Box
                    component="main"
                    className={`${classes.content} ${contentClassName}`}
                >
                    <TrackSection name={SectionName.PAGE_CONTENT}>
                        {children}
                    </TrackSection>
                </Box>
                {footer ? <PageFooter>{footer}</PageFooter> : null}
            </Box>
        </>
    );
};

export default Page;
