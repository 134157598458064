import { type JourneyEvent } from '@components/Journeys/Builder/JourneyFilters/types';
import { useGetJourneyEvents } from '@hooks/useJourney';
import {
    CommunicationChannel,
    DimensionType,
    FieldType,
    FynoEventNames,
    RelationTableType,
    Sources,
} from '@lightdash/common';
import {
    CAMPAIGN_EVENT_EMAIL_BOUNCED,
    CAMPAIGN_EVENT_EMAIL_DELIVERED,
    CAMPAIGN_EVENT_EMAIL_LINK_CLICKED,
    CAMPAIGN_EVENT_EMAIL_OPENED,
    CAMPAIGN_EVENT_EMAIL_REJECTED,
    CAMPAIGN_EVENT_EMAIL_SENT,
    CAMPAIGN_EVENT_EMAIL_UNDELIVERED,
    CAMPAIGN_EVENT_SMS_DELIVERED,
    CAMPAIGN_EVENT_SMS_LINK_CLICKED,
    CAMPAIGN_EVENT_SMS_REJECTED,
    CAMPAIGN_EVENT_SMS_SENT,
    CAMPAIGN_EVENT_SMS_UNDELIVERED,
    CAMPAIGN_EVENT_TABLE_NAME,
    CAMPAIGN_EVENT_WHATSAPP_DELIVERED,
    CAMPAIGN_EVENT_WHATSAPP_LINK_CLICKED,
    CAMPAIGN_EVENT_WHATSAPP_READ,
    CAMPAIGN_EVENT_WHATSAPP_REJECTED,
    CAMPAIGN_EVENT_WHATSAPP_SENT,
    CAMPAIGN_EVENT_WHATSAPP_UNDELIVERED,
} from '@utils/constants';
import { t as translate } from 'i18next';
import { useMemo } from 'react';

const getFynoEventLabel = (eventName: string, sourceLabel: string) => {
    if (sourceLabel === CommunicationChannel.EMAIL) {
        switch (eventName) {
            case FynoEventNames.OPEN:
                return CAMPAIGN_EVENT_EMAIL_OPENED;
            case FynoEventNames.DELIVERED:
                return CAMPAIGN_EVENT_EMAIL_DELIVERED;
            case FynoEventNames.CLICKED:
                return CAMPAIGN_EVENT_EMAIL_LINK_CLICKED;
            case FynoEventNames.SENT:
                return CAMPAIGN_EVENT_EMAIL_SENT;
            case FynoEventNames.BOUNCE:
                return CAMPAIGN_EVENT_EMAIL_BOUNCED;
            case FynoEventNames.UNDELIVERED:
                return CAMPAIGN_EVENT_EMAIL_UNDELIVERED;
            case FynoEventNames.REJECTED:
                return CAMPAIGN_EVENT_EMAIL_REJECTED;
            default:
                return eventName;
        }
    }
    if (sourceLabel === CommunicationChannel.SMS) {
        switch (eventName) {
            case FynoEventNames.DELIVERED:
                return CAMPAIGN_EVENT_SMS_DELIVERED;
            case FynoEventNames.CLICKED:
                return CAMPAIGN_EVENT_SMS_LINK_CLICKED;
            case FynoEventNames.SENT:
                return CAMPAIGN_EVENT_SMS_SENT;
            case FynoEventNames.REJECTED:
                return CAMPAIGN_EVENT_SMS_REJECTED;
            case FynoEventNames.UNDELIVERED:
                return CAMPAIGN_EVENT_SMS_UNDELIVERED;
            default:
                return eventName;
        }
    }
    if (sourceLabel === CommunicationChannel.WHATSAPP) {
        switch (eventName) {
            case FynoEventNames.DELIVERED:
                return CAMPAIGN_EVENT_WHATSAPP_DELIVERED;
            case FynoEventNames.READ:
                return CAMPAIGN_EVENT_WHATSAPP_READ;
            case FynoEventNames.SENT:
                return CAMPAIGN_EVENT_WHATSAPP_SENT;
            case FynoEventNames.CLICKED:
                return CAMPAIGN_EVENT_WHATSAPP_LINK_CLICKED;
            case FynoEventNames.REJECTED:
                return CAMPAIGN_EVENT_WHATSAPP_REJECTED;
            case FynoEventNames.UNDELIVERED:
                return CAMPAIGN_EVENT_WHATSAPP_UNDELIVERED;
            default:
                return eventName;
        }
    }
    return eventName;
};

const convertToDimension = (event: JourneyEvent) => ({
    type: DimensionType.STRING,
    tableType: RelationTableType.EVENT,
    fieldType: FieldType.DIMENSION as const,
    name: event.eventName,
    tableLabel: translate('common.campaigns'),
    table: CAMPAIGN_EVENT_TABLE_NAME,
    label: getFynoEventLabel(event.eventName, event.sourceLabel ?? ''),
    suggestions: [],
    uuid: event.uuid,
    sql: '',
    hidden: false,
    //Here we use the sourceLabel as the uniqueIdentifier
    //because the sourceLabel is the channel and i need channel in creation of addinonal metric and labeling in the selector.

    uniqueIdentifier: event.sourceLabel,
    fieldReference: event.eventName,
});

const getEventSortOrder = (eventName: string, channel: string): number => {
    // Channel multiplier to separate channels (lower = higher priority)
    const channelMultiplier =
        channel === CommunicationChannel.EMAIL
            ? 0
            : channel === CommunicationChannel.WHATSAPP
            ? 1000
            : channel === CommunicationChannel.SMS
            ? 2000
            : 3000;

    // Event order within each channel
    const eventOrder = (() => {
        switch (eventName) {
            // Sending events
            case FynoEventNames.SENT:
                return 1;
            // Delivery status events
            case FynoEventNames.REJECTED:
                return 2;
            case FynoEventNames.UNDELIVERED:
                return 3;
            case FynoEventNames.BOUNCE:
                return 4;
            case FynoEventNames.DELIVERED:
                return 5;
            // User action events
            case FynoEventNames.OPEN:
                return 6;
            case FynoEventNames.READ:
                return 7;
            case FynoEventNames.CLICKED:
                return 8;
            default:
                return 999;
        }
    })();

    return channelMultiplier + eventOrder;
};

export const useFieldsWithCampaignEvents = () => {
    const { data: eventData, isLoading } = useGetJourneyEvents();
    const internalFynoEvents = useMemo(() => {
        if (!eventData) return [];
        const fynoEvents = eventData.filter(
            (event) => event.isInternal && event.source === Sources.FYNO,
        );
        return fynoEvents
            .map(convertToDimension)
            .sort(
                (a, b) =>
                    getEventSortOrder(
                        a.fieldReference,
                        a.uniqueIdentifier ?? '',
                    ) -
                    getEventSortOrder(
                        b.fieldReference,
                        b.uniqueIdentifier ?? '',
                    ),
            );
    }, [eventData]);

    return { internalFynoEvents, isLoading };
};
