import { convertColumnMetric } from '../types/dbt';
import { type CompiledTable } from '../types/explore';
import type { Metric } from '../types/field';
import type { AdditionalMetric } from '../types/metricQuery';
import type { CompiledRelationJoin } from '../types/relation/relations';

type ConvertAdditionalMetricArgs = {
    additionalMetric: AdditionalMetric;
    table: CompiledTable;
};

export const convertAdditionalMetric = ({
    additionalMetric,
    table,
}: ConvertAdditionalMetricArgs): Metric => {
    const metric = convertColumnMetric({
        modelName: table.name,
        dimensionSql: additionalMetric.sql,
        name: additionalMetric.name,
        metric: { ...additionalMetric, filters: undefined },
        tableLabel: table.label,
    });

    return {
        ...metric,
        ...(additionalMetric.filters && {
            filters: additionalMetric.filters,
        }),
        ...(additionalMetric.formatOptions && {
            formatOptions: additionalMetric.formatOptions,
        }),
    };
};

export const getPathBetweenTables = (
    sourceTable: string,
    targetTable: string,
    joinedTables: CompiledRelationJoin[] | undefined,
): string[] => {
    const graph: Record<string, string[]> = {};

    // Build bidirectional graph from joins
    for (const joinRelation of Object.values(joinedTables || [])) {
        const source = joinRelation.source.table;
        const target = joinRelation.target.table;

        if (!graph[source]) graph[source] = [];
        if (!graph[target]) graph[target] = [];

        graph[source].push(target);
        graph[target].push(source);
    }

    // BFS to find shortest path
    const queue: Array<{ table: string; path: string[] }> = [];
    const visited = new Set<string>();

    queue.push({ table: sourceTable, path: [sourceTable] });
    visited.add(sourceTable);

    while (queue.length > 0) {
        const { table, path } = queue.shift()!;

        if (table === targetTable) {
            return path;
        }

        for (const nextTable of graph[table] || []) {
            if (!visited.has(nextTable)) {
                visited.add(nextTable);
                queue.push({
                    table: nextTable,
                    path: [...path, nextTable],
                });
            }
        }
    }

    return []; // Return empty array if no path found
};
