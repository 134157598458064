import Timestamp from '@components/common/Timestamp';
import { Box, Text } from '@mantine/core';

interface CreatedAtTableCellProps {
    createdAt: Date | undefined;
    createdBy: string | undefined;
}

const CreatedAtTableCell: React.FC<CreatedAtTableCellProps> = ({
    createdAt,
    createdBy,
}) => {
    if (!createdAt) {
        return <Text>-</Text>;
    }
    return (
        <Box className="text-sm text-gray-800">
            <Timestamp timestamp={new Date(createdAt).toISOString()} />
            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                {createdBy && createdBy !== ' ' && `by ${createdBy || ''}`}
            </Text>
        </Box>
    );
};

export default CreatedAtTableCell;
