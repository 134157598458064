import HiddenButton, { type HideProps } from '@components/Hidden/HiddenButton';
import DataTable from '@components/Table';
import UpdateTraitsModal from '@components/UpdateTraits/UpdateTraitsModal';
import { useLocale } from '@hooks/useLocale';
import {
    ShowDataType,
    type CustomAttribute,
    type UserTraitFieldConfig,
    type UserTraits,
} from '@lightdash/common';
import { useDebouncedState, useDisclosure } from '@mantine/hooks';
import { type ColumnDef, type Row } from '@tanstack/react-table';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import Fuse from 'fuse.js';
import React, { useCallback, useMemo, useState, type ReactNode } from 'react';
import CreateCustomMetric from '../CreateCustomMetric';
import { CustomMetricTab } from '../type';
import { useCustomMetricCoulumns } from './useCustomMetricTableUtils';
interface CustomMetricTableProps extends HideProps {
    customMetrics: CustomAttribute[];
    selectedTab: CustomMetricTab;
    setSelectedTab: (tab: CustomMetricTab) => void;
    tabs: { value: string; label: ReactNode; key: string }[];
    updateTraits: UserTraits | undefined;
}
const CustomMetricTable: React.FC<CustomMetricTableProps> = ({
    customMetrics,
    isHiddenActive,
    onHiddenChange,
    hideHiddenButton,
    selectedTab,
    setSelectedTab,
    tabs,
    updateTraits,
}) => {
    const [searchString, setSearchString] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );
    const [openedUpdateTraitsModal, { open, close }] = useDisclosure();
    const [customMetricId, setCustomMetricId] = useState<string>('');
    const filterItems = useMemo(() => {
        const isCalculatedTab = selectedTab === CustomMetricTab.CALCULATED;
        const hasSearchQuery = Boolean(searchString);

        if (isCalculatedTab) {
            if (!hasSearchQuery) return customMetrics;

            const fuse = new Fuse(customMetrics, {
                keys: ['name'],
                threshold: 0.3,
            });
            return fuse.search(searchString).map((res) => res.item);
        }

        const traits = Object.values(
            updateTraits?.config.fieldConfig ?? {},
        ).sort((a, b) => {
            const dateA = new Date(a.createdAt || 0);
            const dateB = new Date(b.createdAt || 0);
            return dateB.getTime() - dateA.getTime();
        });
        if (!hasSearchQuery) return traits;

        const fuse = new Fuse(traits, {
            keys: ['label'],
            threshold: 0.3,
        });
        return fuse.search(searchString).map((res) => res.item);
    }, [
        customMetrics,
        searchString,
        selectedTab,
        updateTraits?.config.fieldConfig,
    ]);
    const customMetricColumns = useCustomMetricCoulumns(
        selectedTab,
        updateTraits,
    );
    const [openedViewMode, { open: openViewMode, close: closeViewMode }] =
        useDisclosure(false);

    const { t } = useLocale();
    const [selectedFieldConfig, setSelectedFieldConfig] = useState<
        UserTraitFieldConfig | undefined
    >(undefined);

    const handleRowClick = useCallback(
        (row: Row<CustomAttribute | UserTraitFieldConfig>) => {
            if (selectedTab === CustomMetricTab.DYNAMIC) {
                setSelectedFieldConfig(row.original as UserTraitFieldConfig);
                open();
                return;
            }
            if (customMetricId === (row.original as CustomAttribute).uuid) {
                openViewMode();
                return;
            }

            setCustomMetricId((row.original as CustomAttribute).uuid);
            openViewMode();
        },
        [customMetricId, selectedTab, open, openViewMode],
    );

    return (
        <div>
            {customMetrics && customMetricColumns && (
                <DataTable<CustomAttribute | UserTraitFieldConfig>
                    tableData={filterItems}
                    options={[
                        {
                            format: ShowDataType.LIST,
                            formatData: customMetricColumns as ColumnDef<
                                CustomAttribute | UserTraitFieldConfig
                            >[],
                        },
                    ]}
                    searchPlaceholder={t(
                        'custom_metric_manager.table_search_placeholder',
                    )}
                    paginationText={t('custom_metric_manager.pagination_text')}
                    totalResultCount={
                        selectedTab === CustomMetricTab.CALCULATED
                            ? filterItems.length
                            : Object.keys(
                                  updateTraits?.config.fieldConfig ?? {},
                              ).length
                    }
                    isManualPagination={false}
                    isEditable={false}
                    searchValue={searchString}
                    onSearchChange={setSearchString}
                    handleRowClick={handleRowClick}
                    leftSection={
                        !hideHiddenButton &&
                        selectedTab === CustomMetricTab.CALCULATED && (
                            <HiddenButton
                                isHiddenActive={isHiddenActive}
                                onHiddenChange={onHiddenChange}
                                activeHideText={t(
                                    'custom_metric_manager.active_hide_button_text',
                                )}
                            />
                        )
                    }
                    selectedTab={selectedTab}
                    tabs={tabs}
                    onTabChange={(tab: string) => {
                        setSelectedTab(tab as CustomMetricTab);
                    }}
                />
            )}

            <UpdateTraitsModal
                opened={openedUpdateTraitsModal}
                onClose={close}
                isEditMode={false}
                items={undefined}
                updateTrait={updateTraits}
                updateTraitFieldConfig={selectedFieldConfig}
            />

            {customMetricId && (
                <CreateCustomMetric
                    isViewMode={openedViewMode}
                    customMetricId={customMetricId}
                    customMetricData={(filterItems as CustomAttribute[]).find(
                        (customMetric) => customMetric.uuid === customMetricId,
                    )}
                    openBaseTable={undefined}
                    tableName={undefined}
                    isDuplicate={undefined}
                    onModalClose={() => {
                        closeViewMode();
                    }}
                    disableTableChange={undefined}
                    fieldType={undefined}
                />
            )}
        </div>
    );
};
export default React.memo(CustomMetricTable);
