import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import BuilderContainer from '@components/common/BuilderContainer';
import UnsavedChangesConfirmModal from '@components/common/modal/UnsavedChangesConfirmModal';
import UserCount from '@components/common/UserCount';
import PageSpinner from '@components/PageSpinner';
import useNotify from '@hooks/toaster/useNotify';
import { useAudienceCountByPayload } from '@hooks/useAudience';
import {
    useAudiencePreviewById,
    useAudiencePreviewByPayload,
} from '@hooks/useAudiencePreview';
import { useIsEqual } from '@hooks/useIsEqual';
import { useLocale } from '@hooks/useLocale';
import {
    addFilterRule,
    AIMessageContext,
    AudienceBuilderHashParams,
    QueryGenerationStrategy,
    type AgentJsonContent,
    type AndNestedMetricQuery,
    type Audience,
    type AudiencePreviewPayload,
    type CompiledRelationTablePrimary,
    type MetricQuery,
    type OrNestedMetricQuery,
} from '@lightdash/common';
import {
    ActionIcon,
    Box,
    Button,
    Flex,
    Group,
    Overlay,
    Text,
    Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    ArrowArcLeft,
    ArrowArcRight,
    ArrowRight,
    CheckCircle,
    CopySimple,
    Table,
} from '@phosphor-icons/react';
import useApp from '@providers/App/useApp';
import useAudienceContext from '@providers/Audience/useAudienceContext';
import {
    DefaultAudienceName,
    metricQueryDefaultState,
} from '@providers/Audience/utils';
import { convertUndefinedToEmptyString } from '@utils/helpers';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useBlocker, useLocation, useNavigate, useParams } from 'react-router';
import { v4 as uuid4 } from 'uuid';
import { ButtonVariant, PageLoaderColor } from '../../mantineTheme';

import TitleText from '@components/common/Text/TitleText';
import { TextVariant } from '@components/common/Text/utils';
import useBlockRedo from '@hooks/useBlockRedo';
import useAiAnalystContext from '@providers/AiAnalyst/useAiAnalystContext';
import useRelationContext from '../../providers/Relation/useRelationContext';
import { TIMEOUT_STATUS_CODE } from '../../utils/constants';
import AudiencePreviewModal from './AudiencePreview/AudiencePreviewData';
import AudienceSchedule from './AudienceSchedule';
import AudienceTitleModal from './AudienceTitleModal';
import { useFieldsWithSuggestions } from './Filters/FiltersCard/useFieldsWithSuggestions';
import Insights from './Insights';
import SchedulersModal from './Scheduler/SchedulerModal';
import SQLEditor from './SQLEditor';
import UserSelectionWithPreview from './UserSelectionWithPreview';
import { getDimensionsAndMetrics } from './utils';
import VisualBuilder from './VisualBuilder';

enum AudienceAction {
    SAVE = 'SAVE',
    PUBLISH = 'PUBLISH',
}

interface AudienceContainerProps {
    isEditMode: boolean;
    audienceUuid: string | undefined;
    audienceData?: Audience;
}

const isMac = () => {
    if (
        'userAgentData' in window.navigator &&
        window.navigator.userAgentData === 'macOS'
    ) {
        return true;
    }
    if (window.navigator.userAgent.toLowerCase().includes('mac')) {
        return true;
    }
    return false;
};

const AudienceContainer: React.FC<AudienceContainerProps> = ({
    isEditMode,
    audienceUuid,
    audienceData,
}) => {
    const { t } = useLocale();
    const { showToastError } = useNotify();
    const { isSuperAdmin, organization } = useApp();
    const navigate = useNavigate();
    const { user } = useApp();
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();
    const { activeRelationUuid, activeRelation } = useRelationContext();
    const { registerCallback, unregisterCallback, setAiContext } =
        useAiAnalystContext((context) => context.actions);
    const location = useLocation();
    const isNewMode = location.pathname.includes('create');
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
        hideOrphanedTables: true,
    });
    const [view] = useDisclosure(false);
    const [
        scheduleModal,
        { open: scheduleModalOpen, close: scheduleModalClose },
    ] = useDisclosure(false);

    const [opened, { open: titleModalOpen, close: titleModalClose }] =
        useDisclosure(false);

    const { state, actions, historyStack, historyStackPointer } =
        useAudienceContext((context) => context);
    const {
        setAudienceName,
        setAudienceDescription,
        setGenerationStategy,
        createAudience,
        updateAudience,
        setUnsavedAudienceFilter,
        setSqlQuery,
        setAudiencePreviewConfigData,
        setAdditionalMetrics,
        setFilters,
        undoFilterChange,
        redoFilterChange,
        resetHistoryStack,
    } = actions;

    const {
        audiencePayload,
        initialAudiencePayload,
        isValidQuery,
        isCreatingAudience,
        isPublishingAudience,
        isUpdatingAudience,
    } = state;
    const [hasChanged, setHasChanged] = useState<boolean>(true);
    const [isApiCallTimeout, setIsApiCallTimeout] = useState(false);
    const selectedActionRef = useRef<AudienceAction | null>(null);

    const isEqual = useIsEqual(
        convertUndefinedToEmptyString({
            ...initialAudiencePayload,
            generationStrategy: '',
        }),
        convertUndefinedToEmptyString({
            ...audiencePayload,
            generationStrategy: '',
        }),
    );

    const blocker = useBlocker(({ currentLocation }) => {
        if (isEditMode && hasChanged) {
            return !currentLocation.pathname.includes(
                `/projects/${projectUuid}/audiences/create`,
            );
        }
        return false;
    });

    useEffect(() => {
        if (isEqual) {
            setHasChanged(true);
        } else {
            setHasChanged(false);
        }
    }, [audiencePayload, isEqual, initialAudiencePayload]);
    useEffect(() => {
        const checkReload = (event: BeforeUnloadEvent) => {
            if (isEditMode && hasChanged) {
                const message = t('audience_unsaved_change_confirm_message');
                event.returnValue = message;
                return message;
            }
        };
        window.addEventListener('beforeunload', checkReload);
        return () => {
            window.removeEventListener('beforeunload', checkReload);
        };
    }, [hasChanged, isEditMode, t]);

    const {
        mutateAsync: mutateAsyncPreview,
        isLoading: isPreviewDataLoading,
        data: previewData,
        isSuccess: isPreviewSuccess,
    } = useAudiencePreviewByPayload();
    const {
        mutateAsync: mutateAsyncView,
        data: viewData,
        isSuccess: isViewSuccess,
    } = useAudiencePreviewById();

    useBlockRedo();

    useEffect(() => {
        if (isPreviewSuccess) {
            const newColumns = Object.keys(previewData.fields);
            setAudiencePreviewConfigData(newColumns);
            return;
        }
        if (isViewSuccess) {
            const newColumns = Object.keys(viewData.fields);
            setAudiencePreviewConfigData(newColumns);
        }
    }, [
        previewData,
        isPreviewSuccess,
        isViewSuccess,
        setAudiencePreviewConfigData,
        viewData,
    ]);

    const {
        mutateAsync: mutateAsyncCount,
        isLoading: isCountDataLoading,
        data: countData,
        reset: resetCountData,
    } = useAudienceCountByPayload();

    const [previewOpened, { open: openPreview, close: previewClose }] =
        useDisclosure(false);
    const { generationStrategy, name, description, previewConfig } =
        audiencePayload;

    const [
        userSelectionOpened,
        { open: openUserSelection, close: closeUserSelection },
    ] = useDisclosure(false);

    const [initialAudienceCount, setInitialAudienceCount] = React.useState<
        number | null
    >(null);

    const ability = useAbilityContext();
    const canEditAudience = ability.can(
        'manage',
        subject('VisualAudience', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const canEditSQLVisualizer = ability.can(
        'manage',
        subject('SQLAudience', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const getInitialAudienceCount = useCallback(async () => {
        if (!activeRelationUuid) return;
        const response = await mutateAsyncCount({
            relationUuid: activeRelationUuid,
            payload: {},
        });
        setInitialAudienceCount(response.count);
        resetCountData();
    }, [activeRelationUuid, mutateAsyncCount, resetCountData]);

    useEffect(() => {
        void getInitialAudienceCount();
    }, [getInitialAudienceCount]);

    const getAudiencePayload = useMemo(() => {
        let payload: AudiencePreviewPayload = {};
        if (
            audiencePayload.generationStrategy ===
            QueryGenerationStrategy.AUDIENCE_BUILDER
        ) {
            payload = {
                metricQuery: audiencePayload.nestedMetricQuery,
            };
        }

        if (
            audiencePayload.generationStrategy === QueryGenerationStrategy.AI ||
            audiencePayload.generationStrategy ===
                QueryGenerationStrategy.MANUAL
        ) {
            payload = {
                sqlQuery: audiencePayload.sqlQuery,
            };
        }

        return payload;
    }, [
        audiencePayload.generationStrategy,
        audiencePayload.nestedMetricQuery,
        audiencePayload.sqlQuery,
    ]);

    const handleFetchAudienceCount = useCallback(async () => {
        if (!activeRelationUuid) return;

        await mutateAsyncCount({
            relationUuid: activeRelationUuid,
            payload: getAudiencePayload,
        });
    }, [activeRelationUuid, getAudiencePayload, mutateAsyncCount]);

    const handleNameChange = useCallback(
        (value: string) => {
            setAudienceName(value);
        },
        [setAudienceName],
    );

    const handleAudienceDescription = useCallback(
        (value: string) => {
            setAudienceDescription(value);
        },
        [setAudienceDescription],
    );

    const toggleGenerationStrategy = useCallback(() => {
        setAudiencePreviewConfigData([]);
        setSqlQuery('');
        setUnsavedAudienceFilter([metricQueryDefaultState]);

        setGenerationStategy(
            generationStrategy === QueryGenerationStrategy.AUDIENCE_BUILDER
                ? QueryGenerationStrategy.MANUAL
                : QueryGenerationStrategy.AUDIENCE_BUILDER,
        );
        resetHistoryStack();
        void navigate(
            `/projects/${projectUuid}/audiences/create#${
                generationStrategy === QueryGenerationStrategy.AUDIENCE_BUILDER
                    ? AudienceBuilderHashParams.SQL
                    : AudienceBuilderHashParams.VISUAL
            }`,
        );
    }, [
        setAudiencePreviewConfigData,
        setSqlQuery,
        setUnsavedAudienceFilter,
        setGenerationStategy,
        generationStrategy,
        navigate,
        projectUuid,
        resetHistoryStack,
    ]);
    useEffect(() => {
        if (window.location.hash) {
            setGenerationStategy(
                window.location.hash === `#${AudienceBuilderHashParams.SQL}`
                    ? QueryGenerationStrategy.MANUAL
                    : QueryGenerationStrategy.AUDIENCE_BUILDER,
            );
        }
    }, [setGenerationStategy]);

    const [handleDraft, setHandleDraft] = useState(false);
    useEffect(() => {
        if (handleDraft) {
            void navigate('/projects/' + projectUuid + '/audiences');
        }
    }, [hasChanged, handleDraft, navigate, projectUuid]);
    const handleSave = useCallback(async () => {
        if (
            !isValidQuery &&
            audiencePayload.generationStrategy ===
                QueryGenerationStrategy.AUDIENCE_BUILDER
        ) {
            showToastError({
                title: t(
                    'audience_preview.please_add_a_filter_to_save_the_audience',
                ),
            });
            return;
        }
        if (!audiencePayload.name.trim()) {
            selectedActionRef.current = AudienceAction.SAVE;
            titleModalOpen();
            return;
        }
        try {
            if (audienceUuid && !isNewMode) {
                await updateAudience(audienceUuid);
            } else {
                await createAudience();
            }
            setHasChanged(false);
            setHandleDraft(true);
        } catch (err) {
            console.log(err);
        }
    }, [
        isValidQuery,
        audiencePayload.generationStrategy,
        audiencePayload.name,
        showToastError,
        t,
        titleModalOpen,
        audienceUuid,
        isNewMode,
        updateAudience,
        createAudience,
    ]);
    const handlePreviewData = useCallback(async () => {
        const { dimensions, metrics } = getDimensionsAndMetrics(
            audiencePayload.previewConfig?.previewFields ?? [],
            fieldsWithSuggestions,
        );
        await mutateAsyncPreview(
            {
                relationUuid: activeRelationUuid,
                data: getAudiencePayload,
                dimensions,
                metrics,
            },
            {
                onError: (error) => {
                    if (error.toString().includes(TIMEOUT_STATUS_CODE)) {
                        setIsApiCallTimeout(true);
                    }
                },
            },
        );
    }, [
        mutateAsyncPreview,
        activeRelationUuid,
        getAudiencePayload,
        audiencePayload.previewConfig,
        fieldsWithSuggestions,
    ]);
    useEffect(() => {
        if (isApiCallTimeout) {
            openUserSelection();
        }
    }, [isApiCallTimeout, openUserSelection]);

    const getAudiencePreviewData = useCallback(
        async (previewDataColumns: string[]) => {
            const { dimensions: dimensionsFieldIds, metrics: metricsFieldIds } =
                getDimensionsAndMetrics(
                    previewDataColumns,
                    fieldsWithSuggestions,
                );
            if (isEditMode) {
                await mutateAsyncPreview(
                    {
                        relationUuid: activeRelationUuid,
                        data: getAudiencePayload,
                        dimensions: dimensionsFieldIds,
                        metrics: metricsFieldIds,
                    },
                    {
                        onSuccess: (response) => {
                            setAudiencePreviewConfigData(
                                Object.keys(response.fields),
                            );
                        },
                    },
                );
                return;
            }
            if (audienceUuid)
                await mutateAsyncView(
                    {
                        dimensions: dimensionsFieldIds,
                        metrics: metricsFieldIds,
                        audienceId: audienceUuid,
                    },
                    {
                        onSuccess: (response) => {
                            setAudiencePreviewConfigData(
                                Object.keys(response.fields),
                            );
                        },
                    },
                );
        },
        [
            mutateAsyncView,
            mutateAsyncPreview,
            activeRelationUuid,
            audienceUuid,
            isEditMode,
            getAudiencePayload,
            setAudiencePreviewConfigData,
            fieldsWithSuggestions,
        ],
    );

    const handlePreview = useCallback(
        async (fields: string[]) => {
            const { dimensions: dimensionsFieldIds, metrics: metricsFieldIds } =
                getDimensionsAndMetrics(fields, fieldsWithSuggestions);
            if (
                !isValidQuery &&
                audiencePayload.generationStrategy ===
                    QueryGenerationStrategy.AUDIENCE_BUILDER
            ) {
                const baseTable = activeRelation?.baseTable ?? '';
                const userId = (
                    activeRelation?.tables[
                        baseTable
                    ] as CompiledRelationTablePrimary
                ).userId;
                const fieldId = `${baseTable}_${userId.replaceAll('.', '__')}`;
                const defaultMetric = addFilterRule({
                    filters: {},
                    field: fieldsWithSuggestions[fieldId],
                    value: [],
                });
                await mutateAsyncPreview({
                    relationUuid: activeRelationUuid,
                    data: {
                        metricQuery: {
                            and: [
                                {
                                    filters: defaultMetric,
                                    additionalMetrics: [],
                                    dimensions: [],
                                    exploreName: '',
                                    limit: 500,
                                    metrics: [],
                                    sorts: [],
                                    tableCalculations: [],
                                },
                            ],
                            id: uuid4(),
                        },
                    },
                    dimensions: dimensionsFieldIds,
                    metrics: metricsFieldIds,
                });
                openPreview();
                return;
            }
            if (
                (audiencePayload.generationStrategy ===
                    QueryGenerationStrategy.MANUAL ||
                    audiencePayload.generationStrategy ===
                        QueryGenerationStrategy.AI) &&
                audiencePayload.sqlQuery?.length === 0
            ) {
                await mutateAsyncPreview({
                    relationUuid: activeRelationUuid,
                    data: {
                        sqlQuery: `SELECT * FROM ${activeRelation?.baseTable}`,
                    },
                    dimensions: dimensionsFieldIds,
                    metrics: metricsFieldIds,
                });
                openPreview();
                return;
            }
            if (previewConfig?.previewFields) {
                await getAudiencePreviewData(previewConfig?.previewFields);
                openPreview();
            }
        },
        [
            openPreview,
            previewConfig?.previewFields,
            getAudiencePreviewData,
            isValidQuery,
            activeRelation,
            mutateAsyncPreview,
            activeRelationUuid,
            fieldsWithSuggestions,
            audiencePayload.generationStrategy,
            audiencePayload.sqlQuery,
        ],
    );

    const handleSaveVerify = useCallback(async () => {
        await handlePreviewData();
        openUserSelection();
    }, [openUserSelection, handlePreviewData]);

    const handleVerifyUsersModal = useCallback(async () => {
        if (isValidQuery) {
            if (
                !audiencePayload.name.trim() ||
                audiencePayload.name.trim() === DefaultAudienceName
            ) {
                selectedActionRef.current = AudienceAction.PUBLISH;
                titleModalOpen();
                return;
            }
            await handleSaveVerify();
            return;
        }

        if (
            audiencePayload.generationStrategy ===
            QueryGenerationStrategy.AUDIENCE_BUILDER
        ) {
            showToastError({
                title: t(
                    'audience_preview.please_add_a_filter_to_save_the_audience',
                ),
            });
            return;
        }
        showToastError({
            title: t(
                'audience_preview.please_add_a_query_to_save_the_audience',
            ),
        });
        return;
    }, [
        isValidQuery,
        audiencePayload.generationStrategy,
        audiencePayload.name,
        showToastError,
        t,
        handleSaveVerify,
        titleModalOpen,
    ]);

    const getUserCount = useMemo(() => {
        if (initialAudienceCount === null) return null;
        return (
            <Flex align="center" className="gap-1.5">
                <UserCount
                    count={initialAudienceCount}
                    formatValue={false}
                    withRightSection={false}
                />
                {isValidQuery && (
                    <>
                        <ArrowRight />
                        {isCountDataLoading ? (
                            <Text className="text-sm text-gray-500">
                                {t('audience_builder.user_count_loading')}
                            </Text>
                        ) : (
                            <>
                                {countData && (
                                    <UserCount
                                        count={countData.count}
                                        formatValue={false}
                                        withLeftSection={false}
                                    />
                                )}
                                <Button
                                    onClick={handleFetchAudienceCount}
                                    variant="default"
                                >
                                    {countData
                                        ? t(
                                              'audience_builder.user_count_again_btn',
                                          )
                                        : t('audience_builder.user_count_btn')}
                                </Button>
                            </>
                        )}
                    </>
                )}
            </Flex>
        );
    }, [
        countData,
        handleFetchAudienceCount,
        initialAudienceCount,
        isCountDataLoading,
        isValidQuery,
        t,
    ]);

    const handleUpdate = useCallback(async () => {
        if (selectedActionRef.current === AudienceAction.SAVE) {
            await handleSave();
        }
        if (selectedActionRef.current === AudienceAction.PUBLISH) {
            await handleVerifyUsersModal();
        }
        selectedActionRef.current = null;
    }, [handleSave, handleVerifyUsersModal]);

    const handleDuplicateAudience = useCallback(() => {
        if (!audienceUuid) return;
        window.location.href = `/projects/${projectUuid}/audiences/create?templateId=${audienceUuid}#${
            audienceData?.generationStrategy ===
            QueryGenerationStrategy.AUDIENCE_BUILDER
                ? AudienceBuilderHashParams.VISUAL
                : AudienceBuilderHashParams.SQL
        }`;
    }, [audienceUuid, projectUuid, audienceData]);

    const handleMessageChange = useCallback(
        (jsonContent: AgentJsonContent) => {
            if (
                jsonContent.value.context === AIMessageContext.VISUAL_AUDIENCE
            ) {
                const val = jsonContent.value;
                const andFilters = (
                    (val?.nestedMetricQuery as AndNestedMetricQuery)
                        ?.and?.[0] as MetricQuery
                )?.filters;
                const orFilters = (
                    (val?.nestedMetricQuery as OrNestedMetricQuery)
                        ?.or?.[0] as MetricQuery
                )?.filters;
                if (andFilters) {
                    const additionalMetrics = (
                        (val?.nestedMetricQuery as AndNestedMetricQuery)
                            ?.and?.[0] as MetricQuery
                    ).additionalMetrics;
                    setFilters(andFilters, false, 0);
                    if (additionalMetrics && additionalMetrics.length > 0) {
                        setAdditionalMetrics(additionalMetrics, false, 0);
                    }
                } else if (orFilters) {
                    const additionalMetrics = (
                        (val?.nestedMetricQuery as OrNestedMetricQuery)
                            ?.or?.[0] as MetricQuery
                    ).additionalMetrics;
                    setFilters(orFilters, false, 0);
                    if (additionalMetrics && additionalMetrics.length > 0) {
                        setAdditionalMetrics(additionalMetrics, false, 0);
                    }
                }
                if (
                    window.location.hash === `#${AudienceBuilderHashParams.SQL}`
                ) {
                    void navigate(
                        `/projects/${projectUuid}/audiences/create#${AudienceBuilderHashParams.VISUAL}`,
                    );
                }
            } else if (
                jsonContent.value.context === AIMessageContext.SQL_AUDIENCE
            ) {
                setSqlQuery(jsonContent.value.sql);
                if (
                    window.location.hash ===
                    `#${AudienceBuilderHashParams.VISUAL}`
                ) {
                    void navigate(
                        `/projects/${projectUuid}/audiences/create#${AudienceBuilderHashParams.SQL}`,
                    );
                }
            }
        },
        [setFilters, setAdditionalMetrics, setSqlQuery, projectUuid, navigate],
    );

    useEffect(() => {
        if (location.state?.aiJsonData) {
            const { aiJsonData } = location.state;
            handleMessageChange(aiJsonData);
            setGenerationStategy(
                aiJsonData.value.context === AIMessageContext.VISUAL_AUDIENCE
                    ? QueryGenerationStrategy.AUDIENCE_BUILDER
                    : QueryGenerationStrategy.MANUAL,
            );
            location.state.aiJsonData = null;
        }
    }, [handleMessageChange, location, setGenerationStategy]);

    useEffect(() => {
        registerCallback(handleMessageChange);
        return () => unregisterCallback();
    }, [
        handleMessageChange,
        registerCallback,
        unregisterCallback,
        setAiContext,
        generationStrategy,
    ]);

    const renderBuilderContainerRightSection = useMemo(() => {
        if (!isEditMode) {
            return (
                canEditAudience && (
                    <Box className="flex justify-around">
                        <Button
                            className="m-2"
                            variant={ButtonVariant.OUTLINED}
                            onClick={handleDuplicateAudience}
                            leftIcon={<CopySimple />}
                        >
                            Duplicate audience
                        </Button>
                    </Box>
                )
            );
        }

        return (
            <Group className="gap-2">
                {canEditSQLVisualizer &&
                    (!organization?.isInternal || isSuperAdmin) && (
                        <Button
                            variant={ButtonVariant.OUTLINED_ACCENTED}
                            onClick={toggleGenerationStrategy}
                            disabled={
                                isCreatingAudience ||
                                isUpdatingAudience ||
                                isPublishingAudience
                            }
                        >
                            {generationStrategy ===
                            QueryGenerationStrategy.AUDIENCE_BUILDER
                                ? t('audience.builder.visual.switch')
                                : t('audience.builder.sql.switch')}
                        </Button>
                    )}
                <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={handleSave}
                    loading={isCreatingAudience || isUpdatingAudience}
                    disabled={isPublishingAudience}
                    loaderProps={{
                        color: 'rgb(var(--color-gray-700))',
                        size: 'xs',
                    }}
                >
                    {isCreatingAudience || isUpdatingAudience
                        ? t('common.saving')
                        : t('audience_builder.save_draft')}
                </Button>
                <Button
                    onClick={handleVerifyUsersModal}
                    loading={isPublishingAudience}
                    disabled={isCreatingAudience || isUpdatingAudience}
                    leftIcon={<CheckCircle color="white" />}
                    loaderProps={{
                        size: 'xs',
                    }}
                >
                    {isPublishingAudience
                        ? t('common.publishing')
                        : t('audience_builder.publish')}
                </Button>
            </Group>
        );
    }, [
        isEditMode,
        canEditSQLVisualizer,
        organization?.isInternal,
        isSuperAdmin,
        toggleGenerationStrategy,
        generationStrategy,
        t,
        handleSave,
        isCreatingAudience,
        isUpdatingAudience,
        isPublishingAudience,
        handleVerifyUsersModal,
        canEditAudience,
        handleDuplicateAudience,
    ]);

    const previewDataFields = useMemo(() => {
        return (
            (previewData && previewData.fields) ||
            (viewData && viewData.fields) ||
            {}
        );
    }, [previewData, viewData]);
    const previewDataRows = useMemo(() => {
        return (
            (previewData && previewData.rows) ||
            (viewData && viewData.rows) ||
            []
        );
    }, [previewData, viewData]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if ((e.metaKey || e.ctrlKey) && e.key === 'z') {
            e.preventDefault();
            undoFilterChange();
        }
        if ((e.metaKey || e.ctrlKey) && e.key === 'y') {
            e.preventDefault();
            redoFilterChange();
        }
    };

    if (isEditMode && !canEditAudience) {
        void navigate('/');
    }
    return (
        <>
            <UnsavedChangesConfirmModal
                opened={blocker.state === 'blocked'}
                close={() => {
                    if (blocker.state === 'blocked') {
                        blocker.reset();
                    }
                }}
                primaryActionButtonClick={() => {
                    if (blocker.state === 'blocked') {
                        blocker.proceed();
                    }
                }}
                secondaryActionButtonClick={() => {
                    if (blocker.state === 'blocked') {
                        blocker.reset();
                    }
                }}
            />
            <Group
                className="flex flex-row flex-nowrap"
                tabIndex={0}
                onKeyDown={handleKeyDown}
            >
                <BuilderContainer
                    isEditable={canEditAudience}
                    title={name}
                    onTitleChange={handleNameChange}
                    titlePlaceholder={t('audience_builder.name_placeholder')}
                    description={description}
                    descriptionPlaceholder={t(
                        'audience_builder.description_placeholder',
                    )}
                    onDescriptionChange={handleAudienceDescription}
                    withContentPadding={false}
                    rightSection={renderBuilderContainerRightSection}
                    titleSection={
                        <TitleText
                            onClick={titleModalOpen}
                            placeholder={t('audience_builder.name_placeholder')}
                            className="w-fit max-w-[29rem] truncate"
                            variant={TextVariant.Title}
                        >
                            {name}
                        </TitleText>
                    }
                    subtitle={
                        description && (
                            <TitleText
                                onClick={titleModalOpen}
                                placeholder={t(
                                    'audience_builder.description_placeholder',
                                )}
                                className="w-fit  max-w-[29rem] truncate"
                                variant={TextVariant.Description}
                            >
                                {description}
                            </TitleText>
                        )
                    }
                    rootClassName={`h-[100%] !mt-0 !ml-1 grow`}
                    handleTitleClick={() => {
                        titleModalOpen();
                    }}
                >
                    <Flex
                        className="p-3.5 border-y border-shade-6 rounded-none bg-shade-2"
                        justify="space-between"
                        align="flex-center"
                        direction="row"
                    >
                        <Flex justify="center" align="center">
                            <Box className="pr-4">{getUserCount}</Box>
                            <Button
                                leftIcon={<Table />}
                                variant="default"
                                onClick={() => handlePreview([])}
                            >
                                {t('audience_builder.preview_data')}
                            </Button>
                        </Flex>

                        {isNewMode && (
                            <Group spacing={4}>
                                <Tooltip
                                    label={`Undo (${
                                        isMac() ? 'Cmd' : 'Ctrl'
                                    } + Z)`}
                                    position="bottom"
                                >
                                    <ActionIcon
                                        variant={ButtonVariant.FILLED}
                                        className="bg-white rounded-lg hover:bg-shade-4 border-base"
                                        disabled={
                                            historyStack.length === 0 ||
                                            historyStackPointer === -1
                                        }
                                        onClick={() => undoFilterChange()}
                                        size={'lg'}
                                    >
                                        <ArrowArcLeft
                                            color="rgb(var(--color-gray-700))"
                                            weight="duotone"
                                        />
                                    </ActionIcon>
                                </Tooltip>
                                <Tooltip
                                    label={`Redo (${
                                        isMac() ? 'Cmd' : 'Ctrl'
                                    } + Y)`}
                                    position="bottom"
                                >
                                    <ActionIcon
                                        variant={ButtonVariant.FILLED}
                                        className="bg-white rounded-lg hover:bg-shade-4 border-base"
                                        disabled={
                                            historyStack.length === 0 ||
                                            historyStackPointer ===
                                                historyStack.length - 1
                                        }
                                        onClick={() => redoFilterChange()}
                                        size={'lg'}
                                    >
                                        <ArrowArcRight
                                            color="rgb(var(--color-gray-700))"
                                            weight="duotone"
                                        />
                                    </ActionIcon>
                                </Tooltip>
                            </Group>
                        )}

                        {audienceData && !isNewMode && (
                            <AudienceSchedule
                                audienceData={audienceData}
                                scheduleModalOpen={scheduleModalOpen}
                            />
                        )}
                    </Flex>

                    <Box
                        className={
                            generationStrategy ===
                            QueryGenerationStrategy.AUDIENCE_BUILDER
                                ? 'p-3.5'
                                : 'ps-0.5 pb-0.5'
                        }
                    >
                        {generationStrategy ===
                        QueryGenerationStrategy.AUDIENCE_BUILDER ? (
                            <VisualBuilder
                                isEditMode={isEditMode}
                                onUpdateMetricQuery={undefined}
                                dynamicFieldValues={undefined}
                            />
                        ) : (
                            <SQLEditor isEditMode={isEditMode} />
                        )}
                    </Box>
                </BuilderContainer>
                {generationStrategy ===
                    QueryGenerationStrategy.AUDIENCE_BUILDER && (
                    <Box className="w-[31%] h-full">
                        <Insights />
                    </Box>
                )}
            </Group>
            {view && (
                <Overlay
                    className="cursor-not-allowed"
                    zIndex={30}
                    color="#000"
                    opacity={0.12}
                />
            )}

            {previewOpened && (
                <AudiencePreviewModal
                    getAudiencePayload={getAudiencePayload}
                    opened={previewOpened}
                    close={previewClose}
                    data={previewDataRows}
                    fields={previewDataFields}
                    initialColumns={previewConfig}
                    generationStrategy={audiencePayload.generationStrategy}
                    isEditMode={previewData ? true : false}
                    onColumnChange={(columns: string[]) => {
                        if (isValidQuery) {
                            setAudiencePreviewConfigData(columns);
                        }
                    }}
                    handlePreview={handlePreview}
                    isValidQuery={isValidQuery}
                    showPropertySelect={true}
                    footerRightSection={
                        previewDataRows.length > 0 && (
                            <Button
                                variant={ButtonVariant.OUTLINED}
                                onClick={() => previewClose()}
                            >
                                {t('password_modal.secondary_btn_text')}
                            </Button>
                        )
                    }
                    bottomSection={null}
                    isApiCallTimeout={undefined}
                />
            )}

            {scheduleModal && audienceUuid && (
                <SchedulersModal
                    audienceData={audienceData}
                    isOpen={scheduleModal}
                    onClose={scheduleModalClose}
                />
            )}
            <UserSelectionWithPreview
                getAudiencePayload={getAudiencePayload}
                open={openUserSelection}
                opened={userSelectionOpened}
                close={() => {
                    closeUserSelection();
                    setIsApiCallTimeout(false);
                }}
                previewData={previewData?.rows || []}
                fields={previewDataFields}
                audienceData={audienceData}
                initialColumns={previewConfig}
                handlePreview={handlePreview}
                isApiCallTimeout={isApiCallTimeout}
            />

            <PageSpinner
                isVisible={isPreviewDataLoading}
                withOverlay
                loaderColor={PageLoaderColor.GRAY}
            />

            <AudienceTitleModal
                isEditMode={isEditMode}
                opened={opened}
                close={() => {
                    selectedActionRef.current = null;
                    titleModalClose();
                }}
                onUpdate={handleUpdate}
            />
        </>
    );
};

export default AudienceContainer;
