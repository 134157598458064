import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import TimezoneSelect from '@components/common/Select/TimezoneSelect';
import { useIsEqual } from '@hooks/useIsEqual';
import { useLocale } from '@hooks/useLocale';
import {
    useUpdateProjectDescriptionMutation,
    useUpdateSettingsMutation,
} from '@hooks/useProject';
import {
    KBSingleDocumentContentTypes,
    KnowledgeBaseEntities,
    ProjectSettings,
    type KnowledgeBaseDocument,
    type Project,
    type UpdateProjectSettings,
} from '@lightdash/common';
import { Stack, Textarea, TextInput } from '@mantine/core';
import useApp from '@providers/App/useApp';
import React, { useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import UnsavedChangesModal from '../common/modal/UnsavedChangesModal';

interface WorkspaceSettingsFormProps {
    projectUuid: string;
    projectData: Project;
    projectDescription: KnowledgeBaseDocument[];
}

const WorkspaceSettingsForm: React.FC<WorkspaceSettingsFormProps> = ({
    projectUuid,
    projectData,
    projectDescription,
}) => {
    const updateMutation = useUpdateSettingsMutation(projectUuid);
    const {
        mutateAsync: updateDescriptionMutation,
        isLoading: isUpdatingDescription,
    } = useUpdateProjectDescriptionMutation();
    const { isLoading: isSaving, mutateAsync } = updateMutation;
    const { user } = useApp();
    const { t } = useLocale();
    const ability = useAbilityContext();
    const canEditProjectNameAndTimezone = ability.can(
        'update',
        subject(ProjectSettings.overview, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid: projectUuid,
        }),
    );
    const defaultValues: UpdateProjectSettings & { description: string } =
        useMemo(() => {
            return {
                name: projectData?.name ?? '',
                timezone: projectData?.timezone ?? null,
                description: projectDescription?.[0]?.content ?? '',
            };
        }, [projectData, projectDescription]);

    const form = useForm<UpdateProjectSettings & { description: string }>({
        defaultValues,
    });

    const { control, handleSubmit, register, reset } = form;
    const watchFields = useWatch<
        UpdateProjectSettings & { description: string }
    >({ control });
    const hasFormChanged = useIsEqual<
        UpdateProjectSettings & { description: string }
    >(defaultValues, watchFields);

    const submitForm = async (
        formData: UpdateProjectSettings & { description: string },
    ) => {
        const { name, timezone, description } = formData;
        await Promise.all([
            mutateAsync({ name, timezone }),
            updateDescriptionMutation({
                content: description,
                contentType: KBSingleDocumentContentTypes.INFO,
                entity: KnowledgeBaseEntities.PROJECT,
                entityId: projectUuid,
            }),
        ]);
    };

    return (
        <>
            <form
                name="update_workspace_name"
                id="update_workspace_name"
                onSubmit={(_value) => {
                    void handleSubmit(submitForm)();
                }}
            >
                <Stack>
                    <TextInput
                        id="workspace-name-input"
                        placeholder={t(
                            'workspace_settings_form.workspace_name_placeholder',
                        )}
                        label={t(
                            'workspace_settings_form.workspace_name_label',
                        )}
                        type="text"
                        required
                        disabled={
                            isSaving ||
                            !canEditProjectNameAndTimezone ||
                            isUpdatingDescription
                        }
                        data-cy="workspace-name-input"
                        {...register('name')}
                        className="w-72"
                    />
                    <Textarea
                        id="workspace-description-input"
                        placeholder={t(
                            'organization_settings.description_placeholder',
                        )}
                        label={t('organization_settings.description_label')}
                        labelProps={{
                            className: 'text-sm font-semibold',
                        }}
                        description={t('workspace_settings.description')}
                        descriptionProps={{
                            className: 'text-sm w-[30rem] font-medium mb-2',
                        }}
                        disabled={
                            isSaving ||
                            !canEditProjectNameAndTimezone ||
                            isUpdatingDescription
                        }
                        data-cy="workspace-description-input"
                        {...register('description')}
                        className="w-72"
                    />

                    <Controller
                        name="timezone"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <TimezoneSelect
                                id="workspace-timezone-select"
                                label={t(
                                    'workspace_settings_form.timezone_label',
                                )}
                                placeholder={t(
                                    'workspace_settings_form.timezone_placeholder',
                                )}
                                data={[]}
                                value={value || ''}
                                onChange={(zone) => onChange(zone)}
                                searchable
                                disabled={
                                    isSaving ||
                                    !canEditProjectNameAndTimezone ||
                                    isUpdatingDescription
                                }
                                maxDropdownHeight={300}
                                className="!w-72"
                                styles={() => ({
                                    dropdown: {
                                        width: '350px !important',
                                    },
                                })}
                            />
                        )}
                    />
                </Stack>
            </form>

            <UnsavedChangesModal
                opened={hasFormChanged}
                secondaryActionButtonClick={() => reset()}
                disableButtons={isSaving}
                form="update_workspace_name"
                type="submit"
            />
        </>
    );
};

export default WorkspaceSettingsForm;
