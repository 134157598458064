import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
// import SearchInput from '@components/SearchInput';
import { useLocale } from '@hooks/useLocale';
import {
    ActionIcon,
    Box,
    Button,
    Flex,
    Group,
    Stack,
    Text,
} from '@mantine/core';
import { Dropzone, MIME_TYPES, type FileWithPath } from '@mantine/dropzone';
import {
    CaretRight,
    CheckCircle,
    FileArrowUp,
    FileCsv,
    SpinnerGap,
    Trash,
    WarningCircle,
} from '@phosphor-icons/react';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
// import Fuse from 'fuse.js';
import InputErrorText from '@components/common/InputErrorText';
import useFileUpload from '@hooks/useFileUpload';
import {
    AudienceType,
    type ApiResponse,
    type UploadCSVFileResponse,
} from '@lightdash/common';
import { CSV_AUDIENCE_PREVIEW_LIMIT } from '@utils/constants';
import Papa from 'papaparse';
import React, { useMemo, useRef, useState } from 'react';
import { Link, useParams } from 'react-router';
import { ButtonVariant } from '../../../../../mantineTheme';
import CsvAudiencePreviewModal from './CsvAudiencePreviewModal';

const MAX_FILE_SIZE = 50 * 1024 * 1024;

type AudienceUploadModalProps = {
    open: boolean;
    onClose: () => void;
};
const AudienceUploadModal: React.FC<AudienceUploadModalProps> = ({
    open,
    onClose,
}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const openRef = useRef<() => void>(null);
    const [file, setFile] = useState<FileWithPath | null>(null);
    const [error, setError] = useState<string>('');
    const [previewModal, setPreviewModal] = useState<boolean>(false);
    const [columns, setColumns] = useState<string[]>([]);
    const { actions } = useCampaignContext((context) => context);
    const {
        setAudienceCsvData,
        setAudienceCsvUploadData,
        setCampaignType,
        setCampaignAudience,
    } = actions;

    const {
        data,
        mutate: uploadCsv,
        isLoading,
    } = useFileUpload<UploadCSVFileResponse & ApiResponse>('/campaigns/csv');

    const handleFileUpload = (files: FileWithPath[]) => {
        if (files) {
            Papa.parse(files[0] as any, {
                preview: CSV_AUDIENCE_PREVIEW_LIMIT,
                header: true,
                complete(results) {
                    if (results.meta.fields) {
                        if (results.meta.fields.includes('distinct_id')) {
                            setFile(files[0]);
                            uploadCsv(files as unknown as FileList);
                            setAudienceCsvData(results.data);
                            setColumns(results.meta.fields);
                            setError('');
                        } else {
                            setError(t('audience_upload.invalid_file_format'));
                        }
                    }
                },
            });
        }
    };

    const renderCsvUploadStatus = useMemo(() => {
        if (error) {
            return <InputErrorText value={error} size="xs" />;
        }
        if (isLoading) {
            return (
                <Text className="text-xs text-gray-600">
                    {t('audience_upload.processing')}
                </Text>
            );
        }
        return (
            <>
                <CheckCircle
                    weight="duotone"
                    color="rgba(var(--color-green))"
                    size={14}
                />
                <Text className="text-xs text-gray-600">
                    {t('audience_upload.ready_to_process', {
                        count: data?.counts.valid,
                    })}
                </Text>
                <Text
                    className="text-xs text-blu-800 hover:cursor-pointer"
                    onClick={() => setPreviewModal(true)}
                >
                    {t('common.preview')}
                </Text>
            </>
        );
    }, [data?.counts.valid, error, isLoading, t]);

    const renderModalContent = () => {
        if (file) {
            return (
                <Stack spacing={0}>
                    {data?.counts.duplicate ? (
                        <Group
                            spacing={6}
                            className="p-2 mb-3 border rounded-lg border-mustard-800/40 bg-mustard-800/2"
                        >
                            <WarningCircle
                                weight="duotone"
                                size={14}
                                color="rgba(var(--color-mustard-800))"
                            />
                            <Text className="font-medium text-warn">
                                {t('audience_upload.duplicate_users', {
                                    duplicate: data?.counts.duplicate,
                                    total: data?.counts.total,
                                })}
                            </Text>
                        </Group>
                    ) : null}
                    <Group position="apart" className="p-2 border rounded-lg">
                        <Group>
                            <Flex
                                align={'center'}
                                justify={'center'}
                                className="border rounded-lg h-[2.375rem] w-[2.375rem]"
                            >
                                {isLoading ? (
                                    <SpinnerGap
                                        size={14}
                                        weight="duotone"
                                        color="rgba(var(--color-blu-800))"
                                    />
                                ) : (
                                    <FileCsv
                                        size={14}
                                        weight="duotone"
                                        color="rgba(var(--color-blu-800))"
                                    />
                                )}
                            </Flex>
                            <Stack spacing={4}>
                                <Text className="font-semibold text-gray-700">
                                    {file?.name ?? 'some file name'}
                                </Text>
                                <Group spacing={6}>
                                    {renderCsvUploadStatus}
                                </Group>
                            </Stack>
                        </Group>
                        <Group spacing={'xs'}>
                            <ActionIcon onClick={() => setFile(null)}>
                                <Trash size={14} weight="duotone" />
                            </ActionIcon>
                        </Group>
                    </Group>
                    <Text className="mt-2 text-gray-600">
                        {t('audience_upload.description')}
                    </Text>
                </Stack>
            );
        }
        return (
            <Box>
                <Dropzone
                    accept={[MIME_TYPES.csv]}
                    openRef={openRef}
                    onDrop={handleFileUpload}
                    activateOnClick={false}
                    styles={{
                        root: {
                            background: 'rgba(var(--color-gray-50))',
                            borderColor: 'rgba(var(--color-gray-200))',
                        },
                        inner: {
                            pointerEvents: 'all',
                        },
                    }}
                    maxSize={MAX_FILE_SIZE}
                >
                    <Stack
                        w={'100%'}
                        justify={'center'}
                        align="center"
                        spacing={'sm'}
                    >
                        <Button
                            leftIcon={<FileArrowUp color="white" size={14} />}
                            onClick={() => openRef.current?.()}
                            style={{ pointerEvents: 'all' }}
                        >
                            {t('audience_upload.button_label')}
                        </Button>
                        <Text className="text-xs text-gray-500">
                            {t('audience_upload.max_file_size')}
                        </Text>
                        <Text className="text-gray-600">
                            {t('audience_upload.file_guideline')}
                        </Text>
                        <Text className="font-semibold text-blu-800">
                            <a
                                download
                                href="/csv_audience_sample_file.csv"
                                type="text/csv"
                            >
                                {t('audience_upload.download_label')}
                            </a>
                        </Text>
                    </Stack>
                </Dropzone>
                {error && (
                    <Box className="mt-2">
                        <InputErrorText value={error} />
                    </Box>
                )}
                <Flex className="px-2 py-3 mt-3 border rounded-lg" gap={'xs'}>
                    <Text className="text-gray-600">
                        {t('audience_upload.or')}{' '}
                        <Link
                            to={`/projects/${projectUuid}/audiences/create`}
                            className="font-semibold text-blu-800"
                        >
                            {t('audience_upload.click_here')}
                        </Link>{' '}
                        {t('audience_upload.sql_query')}
                    </Text>
                </Flex>
            </Box>
        );
    };

    const handleClose = () => {
        setFile(null);
        setCampaignType(AudienceType.WAREHOUSE);
        setError('');
        onClose();
    };

    const handleContinue = () => {
        if (data) {
            const payload = {
                uploadId: data.uploadId,
                audienceCount: data.counts.valid,
                fileName: data.originalname,
                columns,
                blobPath: data.blobPath,
            };
            setCampaignAudience(undefined);
            setCampaignType(AudienceType.CSV);
            setAudienceCsvUploadData(payload);
        }
        onClose();
    };

    return (
        <>
            <Modal
                size={'lg'}
                opened={open}
                onClose={handleClose}
                title={t('audience_upload.title')}
                footerRightSection={
                    <ModalFooter
                        primaryText={t('common.continue_button')}
                        secondaryText={t('common.cancel')}
                        primaryButtonClick={handleContinue}
                        secondaryButtonClick={handleClose}
                        showPrimaryButton
                        showSecondaryButton
                        primaryLeftIcon={undefined}
                        primaryRightIcon={<CaretRight color="white" />}
                        secondaryLeftIcon={undefined}
                        secondaryRightIcon={undefined}
                        isLoading={false}
                        primaryButtonVariant={undefined}
                        secondaryButtonVariant={ButtonVariant.OUTLINED}
                        primaryButtonDisabled={
                            Boolean(error.length) || !file || isLoading
                        }
                        primaryButtonCustomClass={undefined}
                    />
                }
            >
                {renderModalContent()}
            </Modal>
            {previewModal && (
                <CsvAudiencePreviewModal
                    open={previewModal}
                    onClose={() => setPreviewModal(false)}
                    fileName={data?.originalname}
                    count={data?.counts.valid}
                />
            )}
        </>
    );
};

export default AudienceUploadModal;
