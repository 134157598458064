import { buttonInputStyles } from '@components/common/Inputs/ButtonInput/ButtonInput.styles';
import {
    Box,
    Container,
    Flex,
    Group,
    Skeleton,
    Stack,
    Text,
    Title,
} from '@mantine/core';
import React from 'react';
import { FontSizes, FontWeights } from '../../../mantineTheme';
import TextInputVariableWidth from '../Inputs/TextInputVariableWidth';
import {
    type BaseProps,
    type BuilderContainerProps,
    type EditableProps,
    type NonEditableProps,
    type WithDescription,
} from './builderContainerTypes';

const SkeletonLoader: React.FC = () => (
    <Stack className="max-w-xl gap-1">
        <Skeleton h={30} w={250} />
        <Skeleton h={30} w={250} />
    </Stack>
);

export const EditableContainer: React.FC<
    Omit<EditableProps, 'isEditable'> &
        Partial<WithDescription> &
        Pick<BaseProps, 'leftSection'>
> = ({
    title,
    onTitleChange,
    titlePlaceholder,
    buttonInputProps,
    leftSection,
    subtitle,
    handleTitleClick,
    titleSection,
}) => {
    const { rightSection, ...restButtonInputProps } = buttonInputProps || {};
    return (
        <Stack className="gap-0">
            <Flex align="center" gap={4} className="group">
                {titleSection ? (
                    titleSection
                ) : (
                    <TextInputVariableWidth
                        key={titlePlaceholder}
                        value={title}
                        minWidth={200}
                        placeholder={titlePlaceholder}
                        maxWidth={540}
                        onChange={(e) => onTitleChange(e.target.value)}
                        className="inline-block border-none !shadow-none"
                        onClick={handleTitleClick}
                        styles={{
                            input: {
                                ...buttonInputStyles({
                                    fontSize: FontSizes.lg,
                                    fontWeight: FontWeights.semibold,
                                    textColor: 'rgb(var(--color-gray-800))',
                                    placeholderColor:
                                        'rgb(var(--color-gray-400))',
                                }).input,
                                '&:focus-within': {
                                    background: 'transparent',
                                    boxShadow: 'none',
                                },
                            },
                        }}
                        {...restButtonInputProps}
                    />
                )}
                <Box className="opacity-0 group-hover:opacity-100 transition-opacity">
                    {rightSection}
                </Box>
            </Flex>
            {subtitle && <Box className="pt-1">{subtitle}</Box>}
            {leftSection && <Box>{leftSection}</Box>}
        </Stack>
    );
};

const NonEditableContainer: React.FC<
    Omit<NonEditableProps, 'isEditable'> &
        Partial<WithDescription> &
        Pick<BaseProps, 'leftSection'>
> = ({ title, description, leftSection, subtitle }) => (
    <>
        {title && (
            <Title order={2} className="text-lg font-semibold text-gray-800">
                {title}
            </Title>
        )}
        {subtitle && <Box>{subtitle}</Box>}
        {description && (
            <Text size={'xs'} className="text-xs text-gray-600">
                {description}
            </Text>
        )}
        {leftSection && <Box>{leftSection}</Box>}
    </>
);

const BuilderContainer: React.FC<BuilderContainerProps> = (props) => {
    return (
        <Box
            className={`border border-shade-4 rounded-2xl p-0.5 mt-4 bg-white ${props?.rootClassName}`}
        >
            <Container
                fluid
                p={2}
                className="relative w-full p-0 bg-white border rounded-xl border-gray-50 "
            >
                <Flex
                    className="px-3.5 py-3"
                    gap={0}
                    justify="space-between"
                    align="flex-start"
                    direction="row"
                    wrap="wrap"
                >
                    <Flex
                        gap={0}
                        justify="flex-start"
                        align="flex-start"
                        direction="column"
                        wrap="wrap"
                    >
                        {props.isLoading ? (
                            <>
                                <SkeletonLoader />
                            </>
                        ) : (
                            <>
                                {props.isEditable ? (
                                    <EditableContainer
                                        title={props.title}
                                        subtitle={props.subtitle}
                                        titlePlaceholder={
                                            props.titlePlaceholder
                                        }
                                        onTitleChange={props.onTitleChange}
                                        buttonInputProps={
                                            props.buttonInputProps
                                        }
                                        leftSection={props.leftSection}
                                        handleTitleClick={
                                            props.handleTitleClick
                                        }
                                        titleSection={props.titleSection}
                                    />
                                ) : (
                                    <NonEditableContainer
                                        title={props.title}
                                        leftSection={props.leftSection}
                                        subtitle={props.subtitle}
                                    />
                                )}
                            </>
                        )}
                    </Flex>
                    <Group>{props.rightSection}</Group>
                </Flex>

                {props.children && (
                    <Box
                        className={`w-full ${
                            props.withContentPadding ? 'p-3.5' : ''
                        }`}
                    >
                        {props.children}
                    </Box>
                )}
            </Container>
        </Box>
    );
};
export default BuilderContainer;
