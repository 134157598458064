import List from '@components/common/List';
import { CampaignListItemTemplate } from '@components/Journeys/Builder/JourneyFilters/EventFilters/JourneyInternalEventFilterRuleForm';
import { useLocale } from '@hooks/useLocale';
import { type ReducedCampaign } from '@lightdash/common';
import { Box, Flex, Menu, Text } from '@mantine/core';

interface CampaignMenuProps {
    campaignData: ReducedCampaign[];
    targetButton: React.ReactNode;
    opened: boolean;
    onOpen: () => void;
    onClose: () => void;
    onChange: (id: string) => void;
    isDisabled: boolean;
}

const CampaignMenu = ({
    campaignData,
    targetButton,
    opened,
    onOpen,
    onClose,
    onChange,
    isDisabled,
}: CampaignMenuProps) => {
    const { t } = useLocale();
    return (
        <Menu
            opened={opened}
            onOpen={onOpen}
            onClose={onClose}
            position="bottom-start"
            width={450}
            withinPortal={true}
            disabled={isDisabled}
        >
            <Menu.Target>{targetButton}</Menu.Target>

            <Menu.Dropdown>
                <Box className="max-h-[400px] overflow-y-scroll">
                    {campaignData.length > 0 ? (
                        <List<ReducedCampaign>
                            items={campaignData}
                            itemTemplate={(campaign) => (
                                <CampaignListItemTemplate
                                    campaginName={campaign.name ?? ''}
                                    className=" !max-w-[250px] "
                                />
                            )}
                            onItemClick={(campaign) => {
                                onChange(campaign.id ?? '');
                                onClose();
                            }}
                            itemSize={40}
                        />
                    ) : (
                        <Flex justify="center" align="center" h={40}>
                            <Text className="text-sm text-gray-600">
                                {t('common.no_campaigns')}
                            </Text>
                        </Flex>
                    )}
                </Box>
            </Menu.Dropdown>
        </Menu>
    );
};

export default CampaignMenu;
