import Select from '@components/common/Select';
import { useLocale } from '@hooks/useLocale';
import { type HttpConfig } from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import JsonEditorWithMentions from '../JsonEditorWithMentions'; // Ensure this import is correct based on your project structure
import {
    BorderColor,
    type CallAPIFieldAndSchemaType,
    type FieldsDataForMentions,
} from '../types';
import {
    CallAPIMethod,
    revertStringifyStringWithVariables,
    stringifyStringWithVariables,
} from '../utils';

interface EndPointSelectProps extends FieldsDataForMentions {
    handleHttpConfigChange: (
        value: CallAPIFieldAndSchemaType<HttpConfig>,
    ) => void;
    httpConfig: HttpConfig;
    disabled: boolean;
}

const EndPointSelect: React.FC<EndPointSelectProps> = ({
    handleHttpConfigChange,
    httpConfig,
    fieldIds,
    journeyProperties,
    warehouseFieldsMap,
    journeyDataSchema,
    disabled,
}) => {
    const [borderColor, setBorderColor] = useState<BorderColor>(
        BorderColor.DEFAULT,
    );
    const [errorMessage, setErrorMessage] = useState<string>('');
    const { t } = useLocale();

    // Helper function to validate URLs using the URL constructor
    function isValidUrl(url: string, base?: string): boolean {
        try {
            new URL(url, base);
            return true;
        } catch (e) {
            return false;
        }
    }

    // Debounce the validateEndpoint function
    const validateEndpoint = debounce((value: string) => {
        const handlebarsPattern = /\{\{.*?\}\}/g;
        const cleanUrl = value.replace(handlebarsPattern, '');

        if (value === '') {
            setBorderColor(BorderColor.DEFAULT);
            setErrorMessage('');
        } else {
            const isValid = isValidUrl(cleanUrl);
            setBorderColor(isValid ? BorderColor.VALID : BorderColor.INVALID);
            setErrorMessage(
                isValid ? '' : t('journey_builder.invalid_endpoint'),
            );
        }
    }, 300);

    const handleUrlChange = useCallback(
        (value: string) => {
            console.log('value', value);
            handleHttpConfigChange({
                value: {
                    ...httpConfig,
                    url: stringifyStringWithVariables(value),
                },
                journeyDataSchema,
            });
        },
        [httpConfig, handleHttpConfigChange, journeyDataSchema],
    );
    const handleMethodChange = useCallback(
        (value: string) => {
            if (value) {
                const method = value as CallAPIMethod;
                handleHttpConfigChange({
                    value: {
                        ...httpConfig,
                        method,
                    },
                    journeyDataSchema,
                });
            }
        },
        [httpConfig, handleHttpConfigChange, journeyDataSchema],
    );

    return (
        <Flex gap="md">
            <Select
                withinPortal
                label={t('journey_builder.call_api_method_label')}
                dropdownPosition="bottom"
                defaultValue={httpConfig.method}
                data={Object.values(CallAPIMethod)}
                className="!w-[6rem]"
                onChange={handleMethodChange}
                disabled={disabled}
            />
            <Box>
                <Text className="pb-1 text-sm text-gray-800">
                    {t('journey_builder.call_api_endpoint_label')}
                </Text>
                <Box
                    className={`w-[20rem] border p-1.5 rounded-md overflow-hidden ${borderColor}`}
                >
                    <JsonEditorWithMentions
                        value={revertStringifyStringWithVariables(
                            httpConfig.url,
                        )}
                        onChange={handleUrlChange}
                        items={journeyProperties}
                        fieldIds={fieldIds}
                        warehouseFieldsMap={warehouseFieldsMap}
                        journeyDataSchema={journeyDataSchema}
                        journeyProperties={journeyProperties}
                        setError={() => {}}
                        isOneLiner={true}
                        placeholder={t(
                            'journey_builder.call_api_endpoint_placeholder',
                        )}
                        isEditMode={!disabled}
                        handleInnerFocus={(isFocused) => {
                            if (!isFocused) {
                                validateEndpoint(
                                    revertStringifyStringWithVariables(
                                        httpConfig.url,
                                    ),
                                );
                            } else {
                                setBorderColor(BorderColor.DEFAULT);
                            }
                        }}
                    />
                </Box>
                {errorMessage.length > 0 && (
                    <Text className="mt-1 text-sm text-halt-800">
                        {errorMessage}
                    </Text>
                )}
            </Box>
        </Flex>
    );
};

export default React.memo(EndPointSelect);
