import { AIMessageContext, AudienceBuilderHashParams } from '@lightdash/common';
import { useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router';

const useAIMessageContext = () => {
    const location = useLocation();
    const { projectUuid } = useParams<{ projectUuid: string }>();

    const getCurrentContext = useMemo(() => {
        const hashContextMap = {
            '#visual': AIMessageContext.VISUAL_AUDIENCE,
            '#sql': AIMessageContext.SQL_AUDIENCE,
        };

        const eventContextMap = {
            '/events': AIMessageContext.EVENT_SETUP,
        };
        const traitContextMap = {
            '/traits': AIMessageContext.CUSTOM_METRIC,
        };
        const analyticsContextMap = {
            '/spaces': AIMessageContext.CHART,
        };
        const relationContextMap = {
            '/relations': AIMessageContext.RELATIONS,
        };

        const pathContextMap = {
            ...eventContextMap,
            ...traitContextMap,
            ...analyticsContextMap,
            ...relationContextMap,
        };

        for (const [hash, context] of Object.entries(hashContextMap)) {
            if (location.hash.includes(hash)) {
                return context;
            }
        }

        for (const [path, context] of Object.entries(pathContextMap)) {
            if (location.pathname.includes(path)) {
                return context;
            }
        }

        return AIMessageContext.OTHERS;
    }, [location.hash, location.pathname]);

    const getContextLocation = useCallback(
        (context: AIMessageContext) => {
            switch (context) {
                case AIMessageContext.VISUAL_AUDIENCE:
                    return `/projects/${projectUuid}/audiences/create#${AudienceBuilderHashParams.VISUAL}`;
                case AIMessageContext.SQL_AUDIENCE:
                    return `/projects/${projectUuid}/audiences/create#${AudienceBuilderHashParams.SQL}`;
                case AIMessageContext.EVENT_SETUP:
                    return `/projects/${projectUuid}/events`;
                case AIMessageContext.SCHEMA_SEARCH:
                    return `/projects/${projectUuid}/relations`;
                case AIMessageContext.CUSTOM_METRIC:
                    return `/projects/${projectUuid}/traits`;
                default:
                    return AIMessageContext.OTHERS;
            }
        },
        [projectUuid],
    );

    return {
        getCurrentContext,
        getContextLocation,
    };
};

export default useAIMessageContext;
