import { nanoid } from 'nanoid';

// Adding it here to avoid circular dependency, not exporting it though
const snakeCaseNameSortment = (text: string): string =>
    text
        .replace(/\W+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map((word) => word.toLowerCase())
        .join('_');

export const getCustomAttributeName = (
    table: string,
    label: string,
    dimensionName: string,
) => {
    // Some warehouses don't support long names, so we need to truncate these custom metrics if the name is too long
    if (table.length + dimensionName.length + label.length <= 62) {
        return `${dimensionName}_${snakeCaseNameSortment(label)}`;
    }

    // 64 (max characters in postgres) - 3 (underscores) - 14 (timestamp length) = 47
    const maxPartLength = Math.floor((47 - table.length) / 2);
    // If the name is still too long, we truncate each part and add a timestamp to the end to make it unique
    return `${dimensionName.slice(0, maxPartLength)}_${snakeCaseNameSortment(
        label,
    ).slice(0, maxPartLength)}_${new Date().getTime()}`;
};

export const generateShortUUID = (totalCharacters: number = 8): string =>
    nanoid(totalCharacters);
