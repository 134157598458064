import { useLocale } from '@hooks/useLocale';
import {
    JourneyVersionStatus as JourneyVersionStatusEnum,
    type JourneyStatus,
    type JourneyVersionStatus,
} from '@lightdash/common';
import { Box, Button, Group, Menu, ScrollArea, Text } from '@mantine/core';
import {
    CaretDown,
    Check,
    FileDashed,
    SunHorizon,
} from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ButtonVariant } from '../../../../mantineTheme';
import { getJourneyStatusIcon } from '../utils';

interface VersionSwitcherProps {}

interface VersionItem {
    label: string;
    value: string;
    isCurrentVersion: boolean;
    status: JourneyVersionStatus;
    isSelected: boolean;
    showSelectedIcon?: boolean;
    journeyStatus: JourneyStatus;
    endDate: Date | undefined;
}

interface ItemProps
    extends React.ComponentPropsWithoutRef<'div'>,
        VersionItem {}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    (
        {
            label,
            value,
            isCurrentVersion,
            status,
            isSelected,
            showSelectedIcon = true,
            journeyStatus,
            endDate,
            ...others
        }: ItemProps,
        ref,
    ) => {
        const { t } = useLocale();
        const statusIcon = useMemo(() => {
            if (isCurrentVersion) {
                if (endDate && endDate < new Date()) {
                    return (
                        <SunHorizon
                            color={'rgb(var(--color-orange-800))'}
                            size={14}
                            weight="duotone"
                        />
                    );
                }
                return getJourneyStatusIcon(journeyStatus, false);
            }
            if (status === JourneyVersionStatusEnum.ACTIVE) {
                return (
                    <SunHorizon
                        color={'rgb(var(--color-orange-800))'}
                        size={14}
                        weight="duotone"
                    />
                );
            }

            return (
                <FileDashed color={'rgb(var(--color-gray-700))'} size={14} />
            );
        }, [isCurrentVersion, status, endDate, journeyStatus]);

        const statusText = useMemo(() => {
            if (isCurrentVersion) {
                if (endDate && endDate < new Date()) {
                    return `(${t('journey.version_status.old_version')})`;
                }
                return `(${t('journey.version_status.current')})`;
            }
            if (status === JourneyVersionStatusEnum.DRAFT) {
                return `(${t('journey.version_status.draft')})`;
            }
            return '';
        }, [isCurrentVersion, status, t, endDate]);

        return (
            <Box ref={ref} {...others}>
                <Group className="gap-1">
                    {statusIcon}
                    <Text className="text-sm font-semibold text-gray-700">
                        {label}
                    </Text>
                    <Text className="text-sm font-normal text-gray-600">
                        {statusText}
                    </Text>

                    {isSelected && showSelectedIcon && (
                        <Check
                            size={12}
                            color={'rgb(var(--color-gray-600))'}
                            className="ml-2"
                            weight="bold"
                        />
                    )}
                </Group>
            </Box>
        );
    },
);

const VersionSwitcher: React.FC<VersionSwitcherProps> = ({}) => {
    const {
        journeyPayload,
        uuid,
        journeyVersions,
        selectedJourneyVersionId,
        journeyStatus,
    } = useJourneyBuilderContext((context) => context.state);
    const navigate = useNavigate();
    const { projectUuid } = useParams<{ projectUuid: string }>();

    const versions: VersionItem[] | undefined = useMemo(() => {
        if (!journeyVersions) return undefined;
        const modifiedVersions = journeyVersions?.map((version) => ({
            value: version.id,
            label: version.versionName,
            isCurrentVersion: version.id === journeyPayload.currentVersionId, //Current version is the one that is currently published.
            status: version.status,
            isSelected: version.id === selectedJourneyVersionId,
            journeyStatus: journeyStatus,
            endDate: journeyPayload.endDate
                ? new Date(journeyPayload.endDate)
                : undefined,
        }));
        const sortedVersions = modifiedVersions?.sort((a, b) => {
            if (
                a.status === JourneyVersionStatusEnum.DRAFT &&
                b.status !== JourneyVersionStatusEnum.DRAFT
            ) {
                return -1;
            }
            if (
                b.status === JourneyVersionStatusEnum.DRAFT &&
                a.status !== JourneyVersionStatusEnum.DRAFT
            ) {
                return 1;
            }
            if (
                a.status === JourneyVersionStatusEnum.ACTIVE &&
                a.isCurrentVersion
            ) {
                return -1;
            }
            if (
                b.status === JourneyVersionStatusEnum.ACTIVE &&
                b.isCurrentVersion
            ) {
                return 1;
            }
            if (
                a.status === JourneyVersionStatusEnum.ACTIVE &&
                b.status !== JourneyVersionStatusEnum.ACTIVE
            ) {
                return -1;
            }
            if (
                b.status === JourneyVersionStatusEnum.ACTIVE &&
                a.status !== JourneyVersionStatusEnum.ACTIVE
            ) {
                return 1;
            }
            return b.label.localeCompare(a.label);
        });
        return sortedVersions;
    }, [
        journeyPayload.currentVersionId,
        journeyPayload.endDate,
        journeyStatus,
        journeyVersions,
        selectedJourneyVersionId,
    ]);

    const handleSelectVersion = useCallback(
        (id: string) => {
            if (id === selectedJourneyVersionId) {
                return;
            }
            if (!versions) return;
            const selectedVersion = versions.find(
                (version) => version.value === id,
            );
            if (!selectedVersion) return;
            let redirectType = 'view';
            if (selectedVersion.status === JourneyVersionStatusEnum.DRAFT) {
                redirectType = 'edit';
            }
            void navigate(
                `/projects/${projectUuid}/journeys/${uuid}/${redirectType}?versionId=${id}`,
            );
        },
        [selectedJourneyVersionId, versions, navigate, projectUuid, uuid],
    );

    const selectedVersion = versions?.find(
        (version) => version.value === selectedJourneyVersionId,
    );

    if (
        !uuid ||
        (selectedJourneyVersionId && !journeyPayload.currentVersionId) ||
        !selectedVersion ||
        !versions
    )
        return null;

    return (
        <Menu withinPortal>
            <Menu.Target>
                <Button
                    variant={ButtonVariant.OUTLINED}
                    className="px-3 py-2 border-gray-250"
                >
                    <SelectItem {...selectedVersion} showSelectedIcon={false} />
                    <CaretDown
                        color="rgb(var(--color-gray-700))"
                        className="ml-2"
                    />
                </Button>
            </Menu.Target>

            <Menu.Dropdown>
                <ScrollArea className="max-h-[300px] overflow-y-auto">
                    {versions.map((version) => (
                        <Menu.Item
                            key={version.value}
                            onClick={() => handleSelectVersion(version.value)}
                        >
                            <SelectItem {...version} />
                        </Menu.Item>
                    ))}
                </ScrollArea>
            </Menu.Dropdown>
        </Menu>
    );
};

export default React.memo(VersionSwitcher);
