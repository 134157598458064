import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import {
    FilterOperator,
    generateShortUUID,
    getFilterRulesFromGroup,
    ReservedCampaignEventColumns,
    type FilterRule,
    type Filters,
    type JourneyConditionalOperator,
    type JourneyEventMapperSchema,
    type JourneyFilterGroup,
} from '@lightdash/common';
import { Stack } from '@mantine/core';
import { CursorClick } from '@phosphor-icons/react';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import JourneyActiveEventField from '@components/Journeys/Builder/JourneyFilters/JourneyActiveEventField';
import {
    JourneyEventType,
    type ActionEventField,
} from '@components/Journeys/Builder/JourneyFilters/types';
import { type JourneyEventFilterConfig } from '@components/Journeys/Builder/types';
import useCampaignContext from '@providers/Campaign/useCampaignContext';
import { useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import EventFilterRuleForm from './EventFilterRuleForm';
import EventSelector from './EventSelector';

enum ReservedSourceNames {
    FYNO = 'fyno',
}

interface EventFiltersProps {
    /** The list of events that are available for the selected node in the journey. */
    eventList: JourneyEventMapperSchema[];

    /** The list of filter rules that are currently applied to the selected node in the journey. */
    filterRules: FilterRule[];

    /** Indicates if the component is in edit mode. */
    isEditMode: boolean;

    /** Function called when the filter rules are changed. */
    onChange: (value: FilterRule[]) => void;

    /** The list of filters that are currently applied to the selected node in the journey. */
    filters: Filters;

    /** Function called when the filters are changed. */
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;

    /** The project UUID. */
    projectUuid: string;

    allFields: FieldWithSuggestions[];

    eventConfig: Omit<JourneyEventFilterConfig, 'eventFilters'>;

    setEventConfig: (value: JourneyEventFilterConfig) => void;
}

/**** INFO *****
    1. To render Internal Event Filter: https://www.figma.com/design/Y89jAFoieVCVviLXdVhyji/%F0%9F%8F%9D%EF%B8%8F-Journeys-0?node-id=1461-180337&m=dev
        - Internal Events like Fyno events have a different UI. We only have Event Field, with property to specify the campaign id.
    
    2. To render Whitelisted Event Filter: https://www.figma.com/design/Y89jAFoieVCVviLXdVhyji/%F0%9F%8F%9D%EF%B8%8F-Journeys-0?node-id=1269-81602&m=dev
        - Whitelisted Events are rendered using JourneyEventPropertySelector component where the properties are based on the selected event profiles coming from JourneyParams API.
****/
const EventFilters: React.FC<EventFiltersProps> = ({
    eventList,
    filterRules,
    isEditMode,
    filters,
    setFilters,
    eventConfig,
    allFields,
    setEventConfig,
}: EventFiltersProps) => {
    const { campaignUuid } = useParams<{ campaignUuid: string }>();
    const { campaignPayload } = useCampaignContext((context) => context.state);
    const { updateConversionConfig } = useCampaignContext(
        (context) => context.actions,
    );
    const getEventType = useCallback(() => {
        if (!eventConfig) {
            return null;
        }

        const { eventName, eventSource } = eventConfig;

        const mappedEvent = eventList.find(
            (event) =>
                event.eventName === eventName && event.source === eventSource,
        );

        if (mappedEvent) {
            return mappedEvent.isInternal
                ? JourneyEventType.INTERNAL_EVENT
                : JourneyEventType.WHITELISTED_EVENT;
        }
    }, [eventConfig, eventList]);

    const activeEventField: ActionEventField | undefined = useMemo(() => {
        const eventType = getEventType();

        if (eventType === JourneyEventType.INTERNAL_EVENT) {
            const internalEvent = eventList.find(
                (event) => event.eventName === eventConfig.eventName,
            );
            if (internalEvent) {
                return {
                    eventName: internalEvent.eventName,
                    eventLabel: internalEvent.label ?? internalEvent.eventName,
                    eventSourceIcon: (
                        <ChannelIcon
                            channel={internalEvent.sourceLabel}
                            showChannelName={false}
                        />
                    ),
                    eventSourceLabel: internalEvent.sourceLabel ?? '',
                    eventSource: internalEvent.source,
                    eventType: JourneyEventType.INTERNAL_EVENT,
                };
            }
        }

        if (eventType === JourneyEventType.WHITELISTED_EVENT) {
            const whitelistedEvent = eventList.find(
                (event) => event.eventName === eventConfig.eventName,
            );
            if (whitelistedEvent) {
                return {
                    eventName: whitelistedEvent.eventName,
                    eventLabel:
                        whitelistedEvent.label ?? whitelistedEvent.eventName,
                    eventSourceIcon: (
                        <CursorClick color={'rgb(var(--color-mustard-800))'} />
                    ),
                    eventSourceLabel:
                        whitelistedEvent.sourceLabel ?? whitelistedEvent.source,
                    eventSource: whitelistedEvent.source,
                    eventType: JourneyEventType.WHITELISTED_EVENT,
                };
            }
        }
    }, [eventConfig.eventName, eventList, getEventType]);

    const isDimensionsEmpty =
        isEmpty(filters.dimensions) ||
        getFilterRulesFromGroup(filters.dimensions).length === 0;

    const handleSetEvent = useCallback(
        (event: JourneyEventMapperSchema) => {
            if (event.isInternal && event.source === ReservedSourceNames.FYNO) {
                const journeyFilterGroup: JourneyFilterGroup = {
                    and: [
                        {
                            operator:
                                FilterOperator.EQUALS as unknown as JourneyConditionalOperator,
                            target: {
                                fieldId: `${event.source}_${ReservedCampaignEventColumns.CUSTOM_ID}`,
                            },
                            values: [campaignUuid ?? ''],
                        },
                    ],
                    id: generateShortUUID(),
                } as JourneyFilterGroup;
                updateConversionConfig({
                    events: [
                        {
                            ...campaignPayload.conversionConfig?.events[0],
                            id:
                                campaignPayload.conversionConfig?.events[0]
                                    .id ?? uuidv4(),
                            eventName: event.eventName,
                            eventSource: event.source,
                            filterConfig: journeyFilterGroup,
                        },
                    ],
                });
                return;
            }
            setEventConfig({
                eventName: event.eventName,
                eventSource: event.source,
            });
        },
        [campaignUuid, campaignPayload, updateConversionConfig, setEventConfig],
    );

    return (
        <Stack className="gap-2">
            {/* Event field that is selected in the journey */}
            <EventSelector
                targetElement={
                    <JourneyActiveEventField
                        activeEventField={activeEventField}
                        isEditMode={isEditMode}
                    />
                }
                setEvent={handleSetEvent}
                eventsList={eventList}
                setFilters={setFilters}
                filters={filters}
            />

            {/* Filter group that is applied to the event field */}
            <Stack
                className={`border-l-4 border-gray-100 relative ${
                    isDimensionsEmpty ? 'pl-8' : 'pl-12'
                }`}
            >
                <EventFilterRuleForm
                    activeEventField={activeEventField}
                    filterRules={filterRules}
                    isDimensionsEmpty={isDimensionsEmpty}
                    allFields={allFields}
                    isEditMode={isEditMode}
                    filters={filters}
                    setFilters={setFilters}
                    eventsList={eventList}
                />
            </Stack>
        </Stack>
    );
};

export default EventFilters;
