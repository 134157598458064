import { type FieldValueProperty } from '@components/Audience/Filters/FiltersProvider/types';
import { CronInternalInputs } from '@components/Audience/Scheduler/ReactHookForm/CronInput';
import Checkbox from '@components/common/Checkbox';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import SkeletonLoader from '@components/common/SkeletonLoader';
import {
    JourneyStatus,
    JourneyTriggerType,
    PeriodType,
    type EntryTrigger,
    type JourneyFiltersConfig,
} from '@lightdash/common';
import { Box, Divider, Group, Stack, Text } from '@mantine/core';
import { Cursor, Info } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import cronstrue from 'cronstrue';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { getJourneyEventPropertySelectList } from '../../JourneyFilters/useJourneyProperties';
import { useNodeData } from '../../ReactFlow/Nodes/useNodeData';
import { TriggerType } from '../../types';
import ActionTitle from '../Actions/ActionTitle';
import BaseTriggerEventFilter from '../Actions/BaseTriggerEventFilter';
import ReEntryBlock from '../Actions/ReEntry';
import BlockConfigContainer from '../BlockConfigPanel/BlockConfigContainer';
import JourneyAudienceFilter from '../JourneyAudienceFilter';
import { useJourneyNodeParams } from '../useJourneyNodeParams';

/*
Reentry logic 
- if contextTotal is -1, then user can reenter the journey infinite times
- if contextTotal is 1, then user can reenter the journey once
- if contextTotal is n, then user can reenter the journey n times
- if contextTotal is greater than 1, then cooldown time is mandatory
*/

// CRON_DATA represents a cron expression that triggers at 1 AM on the first day of every month.
const CRON_DATA = '0 0 1 * *';

interface TriggerConfigPanelProps {
    triggerId: string;
}

const TriggerConfigPanel: React.FC<TriggerConfigPanelProps> = ({
    triggerId,
}) => {
    const { journeyNodeData: triggerBlockData, icon } = useNodeData(triggerId);
    const { journeyPayload, isEditable, journeyStatus, nodes, edges } =
        useJourneyBuilderContext((context) => context.state);

    const triggerData = useMemo(
        () => journeyPayload.triggers?.entry[0],
        [journeyPayload.triggers],
    );

    const {
        closeControlPanel,
        setEntryLogic,
        updateTriggerNode,
        removeFilterFromConfig,
        getTriggerType,
    } = useJourneyBuilderContext((context) => context.actions);

    const { entryLogic } = journeyPayload;

    const cooldown = entryLogic!.cooldown;
    const contextTotal = entryLogic?.contextTotal ?? -1;
    const cooldownDuration = cooldown >= 0 ? cooldown : undefined;
    const cooldownType = entryLogic?.uiConfig?.cooldownType ?? PeriodType.DAY;
    const isReEntryBlockChecked = contextTotal !== 1 && contextTotal !== -1;

    const onDescriptionChange = useCallback(
        (description: string) => {
            if (!triggerBlockData) return;
            updateTriggerNode({
                metadata: {
                    description,
                },
            });
        },
        [triggerBlockData, updateTriggerNode],
    );

    const onBlockClose = () => {
        closeControlPanel();
    };

    const handleDurationChange = useCallback(
        (duration: number, granularity: PeriodType) => {
            setEntryLogic({
                cooldown: duration,
                uiConfig: {
                    cooldownType: granularity,
                },
            });
        },
        [setEntryLogic],
    );

    const handleReEntryChange = useCallback(
        (checked: boolean) => {
            setEntryLogic({
                cooldown: checked ? undefined : -1,
                contextTotal: checked ? -1 : 1,
            });
        },
        [setEntryLogic],
    );

    const handleEventChange = useCallback(
        (eventName: string, eventSource: string) => {
            updateTriggerNode({
                eventName,
                eventSource,
                filterConfig: undefined,
            });
            removeFilterFromConfig(undefined);
        },
        [updateTriggerNode, removeFilterFromConfig],
    );

    const handleFiltersChange = useCallback(
        (filters: JourneyFiltersConfig) => {
            updateTriggerNode({
                filterConfig: filters,
            });
        },
        [updateTriggerNode],
    );

    const triggerType = getTriggerType();

    const renderRepeatSection = useMemo(
        () => (
            <Box className="mt-3">
                <CronInternalInputs
                    disabled={false}
                    onChange={(value) => {
                        updateTriggerNode({
                            cronConfig: {
                                cron: value,
                                jobId: undefined,
                            },
                        });
                    }}
                    value={
                        (journeyPayload.triggers?.entry[0] as EntryTrigger)
                            ?.cronConfig?.cron ?? CRON_DATA
                    }
                    name="cron"
                    disableHourly={false}
                />
            </Box>
        ),
        [journeyPayload.triggers?.entry, updateTriggerNode],
    );

    const cronHelperText = useMemo(() => {
        const cronHumanString = cronstrue.toString(
            (journeyPayload.triggers?.entry[0] as EntryTrigger)?.cronConfig
                ?.cron ?? CRON_DATA,
            {
                verbose: true,
                throwExceptionOnParseError: false,
            },
        );
        return cronHumanString;
    }, [journeyPayload.triggers?.entry]);

    const prevEventConfigRef = useRef<EntryTrigger | undefined>(undefined);

    const {
        journeyDataSchema,
        isLoading: isJourneyNodeParamsLoading,
        getJourneyNodeParams,
    } = useJourneyNodeParams(
        triggerType === TriggerType.BUSINESS_EVENT
            ? JourneyTriggerType.ENTRY
            : undefined,
    );

    useEffect(() => {
        if (
            prevEventConfigRef.current &&
            (triggerData?.eventName !== prevEventConfigRef.current.eventName ||
                triggerData?.eventSource !==
                    prevEventConfigRef.current.eventSource)
        ) {
            void getJourneyNodeParams();
            updateTriggerNode({
                associatedAudienceFilterConfig: undefined,
            });
        }

        prevEventConfigRef.current = triggerData;
    }, [getJourneyNodeParams, triggerData, updateTriggerNode]);

    const journeyEventsPropertySelectList:
        | PropertySelectListType<FieldValueProperty>[]
        | undefined = useMemo(() => {
        if (triggerType !== TriggerType.BUSINESS_EVENT) return;
        if (!journeyDataSchema) return [];
        return getJourneyEventPropertySelectList({
            journeyDataSchema,
            nodes,
            edges,
            nodeId: JourneyTriggerType.ENTRY,
            journeyNodes: journeyPayload.config?.nodes ?? [],
        });
    }, [
        triggerType,
        journeyDataSchema,
        nodes,
        edges,
        journeyPayload.config?.nodes,
    ]);

    if (!triggerId || !triggerBlockData || !triggerData) return null;

    return (
        <BlockConfigContainer
            nodeData={triggerBlockData}
            icon={icon}
            onDescriptionChange={onDescriptionChange}
            onBlockClose={onBlockClose}
            showModifyButtons={false}
            isEditable={isEditable}
        >
            <Stack className="w-full">
                {triggerType === TriggerType.SCHEDULED ? (
                    <Box className="border rounded-lg border-gray-250">
                        <Box className="p-3">
                            {
                                <Box>
                                    <Box className="flex items-center gap-2 mb-3">
                                        <Info
                                            size={16}
                                            color="rgb(var(--color-gray-500))"
                                        />
                                        <Text className="text-sm font-medium text-gray-500">
                                            {t(
                                                'journey_trigger_panel.users_will_enter_as_soon_as_the_journey_is_launched',
                                            )}
                                            {(
                                                journeyPayload.triggers
                                                    ?.entry[0] as EntryTrigger
                                            ).cronConfig?.cron
                                                ? ` ${t(
                                                      'journey_trigger_panel.and_then',
                                                  )} ${cronHelperText}`
                                                : '.'}
                                        </Text>
                                    </Box>
                                    <Divider className="border-t-gray-200" />
                                    <Text className="my-3 text-sm font-medium text-gray-500">
                                        {t(
                                            'journey_trigger_panel.recurring_schedule',
                                        )}
                                    </Text>
                                    <Checkbox
                                        disabled={
                                            journeyStatus ===
                                            JourneyStatus.ACTIVE
                                        }
                                        label="Repeat"
                                        checked={
                                            (
                                                journeyPayload.triggers
                                                    ?.entry[0] as EntryTrigger
                                            ).cronConfig?.cron
                                                ? true
                                                : false
                                        }
                                        onChange={(event) => {
                                            updateTriggerNode({
                                                cronConfig: {
                                                    cron: event.currentTarget
                                                        .checked
                                                        ? CRON_DATA
                                                        : undefined,
                                                    jobId: undefined,
                                                },
                                            });
                                        }}
                                    />
                                    {(
                                        journeyPayload.triggers
                                            ?.entry[0] as EntryTrigger
                                    ).cronConfig?.cron && renderRepeatSection}
                                </Box>
                            }
                        </Box>
                    </Box>
                ) : (
                    <>
                        <Group className="gap-1">
                            <Cursor color={'rgb(var(--color-gray-500))'} />
                            <ActionTitle
                                title={t('journey_builder.event_action_title')}
                            />
                        </Group>

                        <BaseTriggerEventFilter
                            nodeId={JourneyTriggerType.ENTRY}
                            eventConfig={{
                                eventName: triggerData?.eventName,
                                eventSource: triggerData?.eventSource,
                            }}
                            setEvent={handleEventChange}
                            filters={triggerData?.filterConfig}
                            setFilters={handleFiltersChange}
                        />
                    </>
                )}
                <Divider className="border-t-gray-200" />

                {isJourneyNodeParamsLoading ? (
                    <SkeletonLoader height={70} />
                ) : (
                    <JourneyAudienceFilter
                        onUpdate={(metricQuery) => {
                            updateTriggerNode({
                                associatedAudienceFilterConfig: metricQuery,
                            });
                        }}
                        metricQuery={
                            (triggerData as EntryTrigger)
                                ?.associatedAudienceFilterConfig
                        }
                        dynamicFieldValues={journeyEventsPropertySelectList}
                    />
                )}

                <Divider className="border-t-gray-200" />

                <ReEntryBlock
                    checked={isReEntryBlockChecked}
                    duration={cooldownDuration}
                    granularity={cooldownType}
                    onDurationChange={handleDurationChange}
                    onReEntryChange={handleReEntryChange}
                    isEditable={isEditable}
                />
            </Stack>
        </BlockConfigContainer>
    );
};

export default React.memo(TriggerConfigPanel);
