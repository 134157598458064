import LinkButton from '@components/common/LinkButton';
import TitleText from '@components/common/Text/TitleText';
import SettingsModal from '@components/Journeys/Settings/SettingsModal';
import { useLocale } from '@hooks/useLocale';
import { ActionIcon, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    // ArrowUUpLeft,
    // ArrowUUpRight,
    CaretLeft,
    GearSix,
} from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import React from 'react';
import { useParams } from 'react-router';
import { ButtonVariant } from '../../../../mantineTheme';
import ActionButtons from './ActionButtons';
import JourneyTitleModal from './JourneyTitleModal';
import VersionSwitcher from './VersionSwitcher';

const JourneyBuilderHeader: React.FC<{}> = () => {
    const { t } = useLocale();

    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [showSettings, { open: openSettings, close: closeSettings }] =
        useDisclosure(false);
    const [titleModalOpened, { open: titleModalOpen, close: titleModalClose }] =
        useDisclosure(false);

    const { journeyPayload, isLoading, isEditable } = useJourneyBuilderContext(
        (context) => context.state,
    );

    return (
        <>
            <Group className="items-center justify-between w-full">
                <Group>
                    <ActionIcon>
                        <LinkButton
                            href={`/projects/${projectUuid}/journeys`}
                            variant={ButtonVariant.UNSTYLED}
                        >
                            <CaretLeft color={'rgb(var(--color-gray-700))'} />
                        </LinkButton>
                    </ActionIcon>

                    <Group className="gap-2">
                        <TitleText
                            placeholder={t(
                                'journey_builder.header_title_placeholder',
                            )}
                            className="truncate max-w-60"
                            viewMode={!isEditable || isLoading}
                            onClick={titleModalOpen}
                        >
                            {journeyPayload.name}
                        </TitleText>

                        <VersionSwitcher />
                    </Group>
                </Group>
                <Group>
                    {/* <ActionIcon>
                        <ArrowUUpLeft color={'rgb(var(--color-gray-700))'} />
                    </ActionIcon>
                    <ActionIcon>
                        <ArrowUUpRight color={'rgb(var(--color-gray-700))'} />
                    </ActionIcon> */}
                    <ActionIcon onClick={() => openSettings()}>
                        <GearSix color={'rgb(var(--color-gray-700))'} />
                    </ActionIcon>
                    <ActionButtons />
                </Group>
            </Group>
            <SettingsModal
                showSettings={showSettings}
                onClose={() => closeSettings()}
            />
            <JourneyTitleModal
                isEditMode={isEditable}
                opened={titleModalOpened}
                open={titleModalOpen}
                close={titleModalClose}
            />
        </>
    );
};

export default React.memo(JourneyBuilderHeader);
