import { FilterOperator, type ConditionalOperator } from '@lightdash/common';
import {
    createStyles,
    Flex,
    Menu,
    Text,
    type SelectProps,
} from '@mantine/core';
import { Check } from '@phosphor-icons/react';
import { useMemo } from 'react';

const useStyles = createStyles((_theme) => ({
    dropdown: {
        maxHeight: '300px',
        overflowY: 'auto',
    },
}));

type FilterOperatorSelectProps = Omit<
    SelectProps,
    'value' | 'data' | 'onChange'
> & {
    isDisabled?: boolean;
    filterOperatorOptions: {
        value: ConditionalOperator;
        label: string;
    }[];
    selectedOperator: ConditionalOperator;
    onChange: (value: string | null) => void;
};

const FilterOperatorSelect = ({
    isDisabled = false,
    filterOperatorOptions,
    selectedOperator,
    onChange,
}: FilterOperatorSelectProps) => {
    const { classes } = useStyles();

    const selectedLabel = useMemo(() => {
        const selectedOption = filterOperatorOptions.find(
            (op) => op.value === selectedOperator,
        );
        return selectedOption ? selectedOption.label : null;
    }, [filterOperatorOptions, selectedOperator]);

    return (
        <Menu
            classNames={classes}
            withinPortal
            position="bottom-start"
            disabled={isDisabled}
        >
            <Menu.Target>
                <button
                    className={`p-2 min-h-[2.3rem] rounded-lg bg-white border border-shade-6 shadow-card  text-gray-600 text-sm cursor-${
                        isDisabled ? 'auto' : 'pointer hover:bg-gray-50'
                    }`}
                    form="derived_metric_filter"
                >
                    <Text>{selectedLabel}</Text>
                </button>
            </Menu.Target>
            <Menu.Dropdown className="!p-0">
                {filterOperatorOptions
                    .filter(
                        (operator) =>
                            operator.value !==
                                FilterOperator.EQUALS_CASE_INSENSITIVE &&
                            operator.value !==
                                FilterOperator.NOT_EQUALS_CASE_INSENSITIVE,
                    )
                    .map((operator) => (
                        <Menu.Item
                            key={operator.value}
                            onClick={() => onChange(operator.value)}
                        >
                            <Flex
                                justify={'space-between'}
                                align={'center'}
                                gap={4}
                            >
                                <Text className="text-sm font-medium text-gray-800">
                                    {operator.label}
                                </Text>
                                {selectedLabel === operator.label && (
                                    <Check
                                        color={'rgb(var(--color-gray-600))'}
                                    />
                                )}
                            </Flex>
                        </Menu.Item>
                    ))}
            </Menu.Dropdown>
        </Menu>
    );
};

export default FilterOperatorSelect;
