import {
    ConditionalOperator,
    FieldType,
    isCustomSqlDimension,
    type AdditionalMetric,
    type CompiledRelation,
    type CompiledRelationTable,
    type CustomAttribute,
    type CustomDimension,
    type InsertCustomAttribute,
} from '@lightdash/common';
import { type CustomMetricReducerState } from '@providers/CustomMetric/types';
import { useMemo } from 'react';
import { v4 as uuid4 } from 'uuid';

export interface CreateCustomMetricProps {
    openBaseTable: boolean | undefined;
    tableName: CompiledRelationTable | undefined;
    isDuplicate: boolean | undefined;
    isViewMode: boolean | undefined;
    customMetricData: CustomAttribute | undefined;
    onModalClose: () => void;
    customMetricId: string | undefined;
    disableTableChange: boolean | undefined;
    fieldType: FieldType | undefined;
}

export const useCustomMetricInitialData = (
    customMetricData: CustomAttribute | undefined,
    relationUuid: string,
    activeRelation: CompiledRelation | undefined,
): CustomMetricReducerState => {
    return useMemo(() => {
        return {
            customAttributePayload: {
                name: customMetricData?.name || '',
                description: customMetricData?.description || '',
                relationUuid: relationUuid,
                srcTable:
                    customMetricData?.srcTable || activeRelation?.baseTable,
                definition: customMetricData?.definition || null,
                type: FieldType.METRIC,
                isActive: true,
                groupByColumn: null,
                tags: customMetricData?.tags || undefined,
            } as InsertCustomAttribute,
            initialCustomAttributePayload: {
                name: customMetricData?.name || '',
                description: customMetricData?.description || '',
                relationUuid: relationUuid,
                srcTable:
                    customMetricData?.srcTable || activeRelation?.baseTable,
                definition: customMetricData?.definition || null,
                type: FieldType.METRIC,
                isActive: true,
                groupByColumn: null,
                tags: customMetricData?.tags || undefined,
            } as InsertCustomAttribute,
            showBaseTableModal: false,
            showMetricManagerModal: false,
            selectedTable: activeRelation?.tables[activeRelation.baseTable],
            selectedDimension: undefined,
            filters: undefined,
            duplicatedMetric: false,
            viewMode: false,
            disableBackButton: false,
            fieldType: undefined,
        };
    }, [customMetricData, relationUuid, activeRelation]);
};

/**
 * Generate metric preview payload
 * @param customAttributePayload
 * @returns preview payload for metric
 */
export const generateMetricPreviewPayload = (
    customAttributePayload: InsertCustomAttribute,
) => ({
    metricQuery: {
        and: [
            {
                filters: {
                    metrics: {
                        id: uuid4(),
                        and: [
                            {
                                id: uuid4(),
                                target: {
                                    fieldId: `${customAttributePayload.definition?.table}_${customAttributePayload.definition?.name}`,
                                },
                                operator: ConditionalOperator.NOT_NULL,
                                values: [],
                            },
                        ],
                    },
                },
                additionalMetrics: [customAttributePayload.definition],
                dimensions: [],
                exploreName: '',
                limit: 500,
                metrics: [
                    `${customAttributePayload.definition?.table}_${customAttributePayload.definition?.name}`,
                ],
                sorts: [],
                tableCalculations: [],
            },
        ],
        id: uuid4(),
    },
    dimensions: [],
    metrics: [
        {
            metric: customAttributePayload.definition?.name ?? '',
            table: customAttributePayload.definition?.table ?? '',
        },
    ],
});

/**
 * Generate dimension preview payload
 * @param customAttributePayload
 * @returns preview payload for dimension
 */
export const generateDimensionPreviewPayload = (
    customAttributePayload: InsertCustomAttribute,
) => ({
    metricQuery: {
        and: [
            {
                filters: {
                    metrics: {
                        id: uuid4(),
                        and: [
                            {
                                id: uuid4(),
                                target: {
                                    fieldId: (
                                        customAttributePayload.definition as CustomDimension
                                    )?.id,
                                },
                                operator: ConditionalOperator.NOT_NULL,
                                values: [],
                            },
                        ],
                    },
                },
                additionalMetrics: [],
                dimensions: [
                    (customAttributePayload.definition as CustomDimension)?.id,
                ],
                exploreName: '',
                limit: 500,
                metrics: [],
                sorts: [],
                tableCalculations: [],
                customDimensions: [customAttributePayload.definition],
            },
        ],
        id: uuid4(),
    },
    dimensions: [],
    metrics: [],
});

export const getCustomAttributeName = (
    customAttributePayload: InsertCustomAttribute,
) => {
    if (!customAttributePayload.definition) {
        return customAttributePayload.name;
    }
    if (isCustomSqlDimension(customAttributePayload?.definition)) {
        return customAttributePayload.definition.name;
    }
    return (customAttributePayload.definition as AdditionalMetric)?.label ?? '';
};

/**
 * this function filters the tables based on the field type
 * @param availableTables
 * @param nonAvailableTables
 * @param fieldType
 * @returns filtered tables
 */
export const getFilteredTables = (
    availableTables: CompiledRelationTable[],
    nonAvailableTables: CompiledRelationTable[],
    fieldType?: FieldType,
) => {
    const nonOrphanNonInternalAvailableTables = availableTables.filter(
        (table) => table.isConfigured && !table.isReserved,
    );
    const nonOrphanNonInternalNonAvailableTables = nonAvailableTables.filter(
        (table) => table.isConfigured && !table.isReserved,
    );

    if (fieldType === FieldType.DIMENSION) {
        return {
            filteredAvailableTables: [
                ...nonOrphanNonInternalAvailableTables,
                ...nonOrphanNonInternalNonAvailableTables,
            ],
            filteredNonAvailableTables: [],
        };
    }

    return {
        filteredAvailableTables: nonOrphanNonInternalAvailableTables,
        filteredNonAvailableTables: nonAvailableTables,
    };
};

/**
 * this function sanitizes the custom metric name
 * @param name
 * @returns sanitized name
 */
export const sanitizeCustomMetricName = (name: string) => {
    // remove all non-alphanumeric characters and replace spaces with underscores
    return name.replace(/[^a-zA-Z0-9_]/g, '_').replace(/\s+/g, '_');
};

/**
 * this function sanitizes the custom metric description
 * @param description
 * @returns sanitized description
 */
export const getInsertCustomAttribute = (
    customAttribute: CustomAttribute,
): InsertCustomAttribute => {
    return {
        name: customAttribute.name,
        description: customAttribute.description,
        relationUuid: customAttribute.relationUuid,
        srcTable: customAttribute.srcTable,
        type: customAttribute.type,
        definition: customAttribute.definition,
        groupByColumn: customAttribute.groupByColumn,
        tags: customAttribute.tags,
    };
};
