import BeeFreeSDK from '@beefree.io/sdk';
import TextArea from '@components/common/Inputs/TextArea';
import TextInput from '@components/common/Inputs/TextInput';
import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
import {
    useCreateTemplate,
    useGenerateTemplateNameDescription,
    useInitiateBeefree,
    useUpdateTemplateContent,
} from '@hooks/useTemplate';
import {
    CommunicationChannel,
    previewTextVariable,
    type AnyType,
} from '@lightdash/common';
import { Stack } from '@mantine/core';
import useTemplateBuilderContext from '@providers/TemplateBuilder/useTemplateBuilderContext';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../mantineTheme';
import ConfirmTemplateSaveModal from '../Builder/modals/ConfirmTemplateSaveModal';
import { type HtmlTemplateDetails } from '../HTMLManager/types';
import { appendPreheader } from '../utils';
import useBeeFreeConfig, { starterTemplate } from './useBeeFreeConfig';

type BeeFreeEditorProps = {
    templateDetail: HtmlTemplateDetails;
    onClose: (() => void) | undefined;
};
const BeeFreeEditor = ({ templateDetail, onClose }: BeeFreeEditorProps) => {
    const query = useQueryClient();
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showSaveAsNewModal, setShowSaveAsNewModal] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [templateDescription, setTemplateDescription] = useState('');
    const [templateJson, setTemplateJson] = useState<Record<string, AnyType>>(
        {},
    );
    const [templateHtml, setTemplateHtml] = useState<string>('');
    const { setOpenCreateTemplateModal } = useTemplateBuilderContext(
        (context) => context.actions,
    );

    const { mutateAsync: mutateAsyncCreateTemplate } = useCreateTemplate();

    const { mutateAsync: mutateAsyncUpdateTemplate } = useUpdateTemplateContent(
        templateDetail.id ?? '',
        1,
    );

    const {
        mutateAsync: mutateAsyncGenerateTemplateNameDescription,
        data: nameDescription,
        isLoading: isLoadingNameDescription,
    } = useGenerateTemplateNameDescription();

    const { data: initiateBeefreeData, isLoading: initiateBeefreeLoading } =
        useInitiateBeefree();

    const handleSave = (jsonFile: unknown, htmlFile: unknown) => {
        setTemplateJson(JSON.parse(jsonFile as string));
        setTemplateHtml(htmlFile as string);
        if (templateDetail.id) {
            setShowSaveAsNewModal(true);
        } else {
            setShowSaveModal(true);
        }
    };

    const handleTemplateUpdate = () => {
        if (!templateDetail.id) return;

        mutateAsyncUpdateTemplate({
            html: appendPreheader(templateHtml, previewTextVariable),
            json: templateJson,
            subject: '',
        })
            .then(() => {
                setOpenCreateTemplateModal(false);
                if (onClose && typeof onClose === 'function') {
                    onClose();
                }
                void query.refetchQueries([QueryKeys.GET_TEMPLATE]);
            })
            .catch(() => {});
    };

    const handleSaveTemplate = () => {
        if (!templateName) {
            return;
        }
        mutateAsyncCreateTemplate({
            name: templateName,
            description: templateDescription,
            channel: CommunicationChannel.EMAIL,
            consideredInLibrary: true,
            tags: [],
            content: {
                html: appendPreheader(templateHtml, previewTextVariable),
                subject: '',
                json: templateJson,
            },
            previewUrl: '',
        })
            .then(() => {
                setShowSaveModal(false);
                setOpenCreateTemplateModal(false);
                if (onClose && typeof onClose === 'function') {
                    onClose();
                }
                void query.refetchQueries([QueryKeys.GET_TEMPLATE]);
            })
            .catch(() => {});
    };
    const beeConfig = useBeeFreeConfig({
        handleSave,
    });

    useEffect(() => {
        if (
            showSaveModal &&
            !templateName &&
            !templateDescription &&
            !nameDescription
        ) {
            void mutateAsyncGenerateTemplateNameDescription({
                channel: CommunicationChannel.EMAIL,
                templateContent: {
                    html: appendPreheader(templateHtml, previewTextVariable),
                    subject: '',
                    json: templateJson,
                },
            }).then((res) => {
                setTemplateName(res.name);
                setTemplateDescription(res.description);
            });
        }
    }, [
        mutateAsyncGenerateTemplateNameDescription,
        nameDescription,
        showSaveModal,
        templateDescription,
        templateHtml,
        templateJson,
        templateName,
    ]);

    useEffect(() => {
        if (!initiateBeefreeLoading) {
            const beeInstance = new BeeFreeSDK(initiateBeefreeData);
            beeInstance
                .start(beeConfig, templateDetail?.json ?? starterTemplate)
                .then(() => {})
                .catch(() => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initiateBeefreeData, initiateBeefreeLoading]);

    return (
        <>
            <div
                style={{
                    width: '100vw',
                    height: 'calc(100vh - 56px)',
                }}
                id="beefree-sdk-container"
            ></div>
            <Modal
                opened={showSaveModal}
                title="Save Template"
                onClose={() => setShowSaveModal(false)}
            >
                <Stack className="mb-3">
                    <TextInput
                        value={templateName}
                        onChange={(e) => setTemplateName(e.target.value)}
                        label="Template Name"
                        placeholder="Enter template name"
                        isAiGeneratingData={isLoadingNameDescription}
                        aiGeneratedData={nameDescription?.name}
                    />
                    <TextArea
                        label="Description (Optional)"
                        placeholder="Enter description"
                        value={templateDescription}
                        onChange={(e) => setTemplateDescription(e.target.value)}
                        isAiGeneratingData={isLoadingNameDescription}
                        aiGeneratedData={nameDescription?.description}
                    />
                </Stack>
                <ModalFooter
                    justify="right"
                    secondaryLeftIcon={undefined}
                    secondaryRightIcon={undefined}
                    primaryButtonVariant={undefined}
                    secondaryButtonVariant={ButtonVariant.OUTLINED}
                    isLoading={false}
                    primaryButtonCustomClass=""
                    primaryButtonDisabled={false}
                    primaryButtonClick={handleSaveTemplate}
                    primaryText="Save"
                    secondaryButtonClick={() => setShowSaveModal(false)}
                    secondaryText="Cancel"
                    showPrimaryButton
                    showSecondaryButton
                    primaryLeftIcon={undefined}
                    primaryRightIcon={undefined}
                />
            </Modal>
            <ConfirmTemplateSaveModal
                opened={showSaveAsNewModal}
                handleSave={handleTemplateUpdate}
                handleSaveAsNewTemplate={() => {
                    setShowSaveModal(true);
                    setShowSaveAsNewModal(false);
                }}
                isLoading={false}
                onClose={() => setShowSaveAsNewModal(false)}
            />
        </>
    );
};

export default BeeFreeEditor;
