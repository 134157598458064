import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import {
    type AddditionalPropertySelectListProps,
    type PropertySelectListType,
} from '@components/common/Select/PropertySelect/type';
import { type JourneyEventMapperSchema } from '@lightdash/common';

export type ActionEventField = {
    eventName: string;
    eventLabel: string;
    eventSourceIcon: React.ReactNode;
    eventSourceLabel: string;
    eventSource: string;
    eventType: JourneyEventType;
};

export enum JourneyEventType {
    INTERNAL_EVENT = 'internalEvent',
    WHITELISTED_EVENT = 'whitelistedEvent',
    JOURNEY_PARAM_EVENT = 'journeyParamEvent',
}

export enum JourneyEventGroupType {
    ALL_EVENTS = 'ALL_EVENTS',
    JOURNEY_PARAMS = 'JOURNEY_PARAMS',
    WAREHOUSE = 'WAREHOUSE',
    UPDATE_TRAITS = 'UPDATE_TRAITS',
}

export type JourneyProperty = FieldWithSuggestions &
    AddditionalPropertySelectListProps;

export type JourneyEvent = JourneyEventMapperSchema &
    AddditionalPropertySelectListProps;

export type PropertySelectAccumulator<T, K extends string = string> = {
    [key: string]: PropertySelectListType<T, K>;
};
