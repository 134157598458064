import PropertySelect from '@components/common/Select/PropertySelect';
import { type AddditionalPropertySelectListProps } from '@components/common/Select/PropertySelect/type';
import { type FieldWithSuggestions } from '@components/Explorer/FiltersCard/useFieldsWithSuggestions';
import { useLocale } from '@hooks/useLocale';
import { Button } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import { ButtonVariant } from '../../../../../mantineTheme';
import FieldListItem from '../../FieldListItem';
import { convertFieldsIntoPropertySelectListType } from '../../FieldListItem/utils';

interface DerivedMetricPropertySelectProps {
    tableFields: FieldWithSuggestions[];
    onSubmit: (items: FieldWithSuggestions[]) => void;
    opened: boolean;
    close: () => void;
    open: () => void;
}

export const DerivedMetricPropertySelect: React.FC<
    DerivedMetricPropertySelectProps
> = ({ tableFields, onSubmit, opened, close, open }) => {
    const { t } = useLocale();
    return (
        <PropertySelect<
            FieldWithSuggestions & AddditionalPropertySelectListProps
        >
            items={convertFieldsIntoPropertySelectListType(tableFields, false)}
            showGroup={false}
            onSubmit={onSubmit}
            itemTemplate={({ item }) => {
                return (
                    <FieldListItem
                        item={item}
                        checked={item.isChecked ?? false}
                        showCheckbox={false}
                        disabled={item.isDisabled ?? false}
                        showFieldSource={true}
                    />
                );
            }}
            headerRightSection={null}
            opened={opened}
            close={close}
            open={open}
            targetButton={
                <Button
                    variant={ButtonVariant.SUBTLE}
                    className="text-gray-800 w-fit"
                    size="md"
                >
                    <PlusCircle
                        color="rgb(var(--color-gray-800))"
                        className="mr-1"
                    />
                    {t('common.property')}
                </Button>
            }
            showSearch={true}
            searchKeys={['label', 'tableLabel']}
            searchPlaceholder={'Search'}
            allowMultipleSelection={false}
            height={350}
            width={300}
        />
    );
};
