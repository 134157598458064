import TransparentInput from '@components/common/Inputs/TransparentInput';
import UnsavedChangesModal from '@components/common/modal/UnsavedChangesModal';
import BaseTriggerEventFilter from '@components/Journeys/Builder/ControlPanel/Actions/BaseTriggerEventFilter';
import { useLocale } from '@hooks/useLocale';
import { JourneyTriggerType } from '@lightdash/common';
import { Box, Button, Text } from '@mantine/core';
import { DoorOpen, PlusCircle, Trash } from '@phosphor-icons/react';
import useJourneyBuilderContext from '@providers/Journey/useJourneyBuilderContext';
import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ButtonVariant } from '../../../../mantineTheme';
import SettingsTitle from '../SettingsTitle';

const ExitTriggers: React.FC = () => {
    const { t } = useLocale();

    const { journeyPayload, initialJourneyPayload, isEditable } =
        useJourneyBuilderContext((context) => context.state);

    const { mutateAsyncJourney } = useJourneyBuilderContext(
        (context) => context.actions,
    );

    const { triggers } = journeyPayload;
    const { exit } = triggers!;

    const { setExitTriggers, updateExitNode } = useJourneyBuilderContext(
        (context) => context.actions,
    );

    const [hasDataChanged, setHasDataChanged] = useState<boolean>(false);

    const handleAddRule = useCallback(() => {
        setHasDataChanged(true);
        setExitTriggers([
            ...(journeyPayload.triggers?.exit ?? []),
            {
                id: uuidv4(),
                eventName: '',
                eventSource: '',
                filterConfig: undefined,
                metadata: {
                    title: t('journey_builder.exit_trigger_placeholder_title', {
                        triggerNumber: exit && exit.length + 1,
                    }),
                },
            },
        ]);
    }, [exit, journeyPayload.triggers?.exit, setExitTriggers, t]);

    return (
        <Box>
            <SettingsTitle
                icon={<DoorOpen size={14} weight="duotone" />}
                title={t('exit_triggers.title')}
                subtitle={t('exit_triggers.subtitle')}
            />
            {exit &&
                exit.map((item, index) => (
                    <Box
                        key={index}
                        className="border-[1px] m-3 p-1 rounded-lg"
                    >
                        <Box className="flex items-center justify-between p-3 bg-gray-50 rounded-xl">
                            <Box className="flex items-center">
                                <Text className="border-[1px] px-2 py-1 rounded-lg bg-white text-sm font-medium text-gray-800">
                                    {index + 1}
                                </Text>
                                <TransparentInput
                                    styles={() => ({
                                        input: {
                                            backgroundColor:
                                                'rgb(var( --color-gray-50)) !important',
                                        },
                                    })}
                                    onChange={(e) =>
                                        updateExitNode({
                                            payload: {
                                                metadata: {
                                                    title: e.target.value,
                                                },
                                            },
                                            id: item.id,
                                        })
                                    }
                                    value={item.metadata?.title}
                                    isDisabled={!isEditable}
                                />
                            </Box>
                            {isEditable && (
                                <Trash
                                    onClick={() => {
                                        setHasDataChanged(true);
                                        setExitTriggers(
                                            exit.filter(
                                                (trigger) =>
                                                    trigger.id !== item.id,
                                            ),
                                        );
                                    }}
                                    className="mx-3 my-2 cursor-pointer"
                                    size={14}
                                    weight="duotone"
                                />
                            )}
                        </Box>
                        <Box className="m-3">
                            <BaseTriggerEventFilter
                                nodeId={JourneyTriggerType.EXIT}
                                eventConfig={{
                                    eventName: item.eventName,
                                    eventSource: item.eventSource,
                                }}
                                setEvent={(eventName, eventSource) => {
                                    setHasDataChanged(true);
                                    updateExitNode({
                                        payload: {
                                            eventName,
                                            eventSource,
                                        },
                                        id: item.id,
                                    });
                                }}
                                filters={item.filterConfig}
                                setFilters={(filters) => {
                                    setHasDataChanged(true);
                                    updateExitNode({
                                        payload: {
                                            filterConfig: filters,
                                        },
                                        id: item.id,
                                    });
                                }}
                            />
                        </Box>
                    </Box>
                ))}
            {isEditable && (
                <Button
                    className="mx-3"
                    onClick={handleAddRule}
                    variant={ButtonVariant.OUTLINED_ACCENTED}
                    leftIcon={<PlusCircle color="rgb(var(--color-blu-800))" />}
                >
                    {t(
                        'workspace_settings.delivery_controls.frequency_capping_button',
                    )}
                </Button>
            )}

            <UnsavedChangesModal
                opened={hasDataChanged}
                secondaryActionButtonClick={() => {
                    setExitTriggers(initialJourneyPayload.triggers?.exit);
                    setHasDataChanged(false);
                }}
                disableButtons={false}
                primaryActionButtonClick={async () => {
                    setHasDataChanged(false);
                    await mutateAsyncJourney();
                }}
            />
        </Box>
    );
};

export default ExitTriggers;
