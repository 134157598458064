import { useEffect, useState } from 'react';
import { Markdown } from './Markdown';
export const StreamingText: React.FC<{
    stream: boolean;
    html: string;
    streamedMessages: number[];
    setStreamedMessages: (value: number[]) => void;
    index: number;
}> = ({ stream, html, streamedMessages, setStreamedMessages, index }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [streamIndex, setStreamIndex] = useState(0);

    useEffect(() => {
        if (stream && !streamedMessages.includes(index)) {
            if (streamIndex < html.length) {
                const timeoutId = setTimeout(() => {
                    setDisplayedText((prev) => prev + html[streamIndex]);
                    setStreamIndex((prev) => prev + 1);
                }, 20); // Adjust the speed of the animation here
                return () => clearTimeout(timeoutId);
            } else {
                setStreamedMessages([...streamedMessages, index]);
            }
        } else {
            setDisplayedText(html);
        }
    }, [
        streamIndex,
        html,
        stream,
        setStreamedMessages,
        streamedMessages,
        index,
    ]);
    return <Markdown>{displayedText}</Markdown>;
};

export default StreamingText;
