import { useEffect } from 'react';

const useBlockRedo = () => {
    // INFO - This is to block default browser behaviour on Cmd+Y which is to open history tab
    const blockCmdY = (e: KeyboardEvent) => {
        if ((e.metaKey || e.ctrlKey) && e.key === 'y') {
            e.preventDefault();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', blockCmdY);
        return () => {
            window.removeEventListener('keydown', blockCmdY);
        };
    }, []);
};

export default useBlockRedo;
