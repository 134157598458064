import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import {
    DimensionType,
    FieldType,
    RelationTableType,
    type JourneyDimension,
    type JourneyEventMapperSchema,
} from '@lightdash/common';
import { useMemo } from 'react';

const useEventFilter = (eventsList: JourneyEventMapperSchema[]) => {
    const eventPayload = useMemo(() => {
        if (!eventsList) return undefined;

        return eventsList.map((event) => {
            const dimensions: Record<string, JourneyDimension> = Object.entries(
                event.mapperSchema,
            ).reduce((acc, [key]) => {
                acc[key] = {
                    type: DimensionType.STRING,
                    label: key,
                    name: key,
                    table: `${event.eventName}`,
                };
                return acc;
            }, {} as Record<string, JourneyDimension>);

            return {
                dimensions,
                eventSource: event.source,
                eventName: event.eventName,
            };
        });
    }, [eventsList]);

    const eventsFields = useMemo(() => {
        if (!eventsList) return undefined;

        return eventsList.reduce<FieldsWithSuggestions>((acc, event) => {
            const table = `${event.eventName}`;
            Object.entries(event.mapperSchema).forEach(([key, value]) => {
                const fieldId = `${table}_${key}`;
                acc[fieldId] = {
                    type: value.dataType,
                    label: key,
                    name: key,
                    table: table,
                    suggestions: [],
                    tableType: RelationTableType.EVENT,
                    fieldType: FieldType.DIMENSION,
                    tableLabel: table,
                    sql: ``,
                    hidden: false,
                };
            });
            return acc;
        }, {});
    }, [eventsList]);

    return { eventPayload, eventsFields };
};

export default useEventFilter;
