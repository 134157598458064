import type { FieldValueProperty } from '@components/Audience/Filters/FiltersProvider/types';
import {
    createFilterRuleForCampaignEvent,
    isTimeWindowFilterInCampaignEvent,
} from '@components/Audience/Filters/utils';
import { campaignEventFieldRef } from '@components/Audience/utils';
import type { PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { addFieldIdToMetricFilterRule } from '@components/Explorer/CustomMetricModal/utils';
import { useLocale } from '@hooks/useLocale';
import {
    CommonEventColumns,
    FilterGroupOperator,
    FilterOperator,
    type AdditionalMetric,
    type FilterableDimension,
    type FilterableField,
    type FilterRule,
    type MetricFilterRule,
} from '@lightdash/common';
import { Box, Button, Flex, Stack, Text } from '@mantine/core';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { ButtonVariant } from '../../../../../../mantineTheme';
import FilterRuleForm from '../../FilterRuleForm';
import { SetTimeWindowButton } from '../SetTimeWIndowButton';
import CampaignFilterRuleForm from './CampaignFilterRuleForm';

interface CampaignEventFilterGroupProps {
    activeField: FilterableField | undefined;
    isEditMode: boolean;
    additionalMetrics: AdditionalMetric[];
    setAdditionalMetrics: (
        metrics: AdditionalMetric[],
        isNested: boolean,
        groupIndex: number,
    ) => void;
    tableFields: FilterableDimension[];
    groupIndex: number;
    dynamicFieldValues:
        | PropertySelectListType<FieldValueProperty>[]
        | undefined;
}
const CampaignEventFilterGroup: React.FC<CampaignEventFilterGroupProps> = ({
    activeField,
    isEditMode,
    additionalMetrics,
    setAdditionalMetrics,
    groupIndex,
    tableFields,
    dynamicFieldValues,
}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    const isMatchingMetric = useCallback(
        (metric: AdditionalMetric): boolean => {
            return (
                metric.name === activeField?.name &&
                metric.table === activeField?.table
            );
        },
        [activeField],
    );
    const matchedAdditionalMetric = additionalMetrics.find((metric) =>
        isMatchingMetric(metric),
    );
    // Helper function to check if a filter is a time window filter

    const campaignFilters = matchedAdditionalMetric?.filters
        ?.slice(2)
        .filter(
            (filter) => !isTimeWindowFilterInCampaignEvent(filter, projectUuid),
        )
        .map(addFieldIdToMetricFilterRule);

    const timeWindowFilter = matchedAdditionalMetric?.filters
        ?.slice(2)
        .find((filter) =>
            isTimeWindowFilterInCampaignEvent(filter, projectUuid),
        );

    // Handlers
    const changedAdditionalMetrics = useCallback(
        (filters: MetricFilterRule[]) => {
            const newAdditionalMetrics = additionalMetrics.map((metric) =>
                isMatchingMetric(metric) ? { ...metric, filters } : metric,
            );
            setAdditionalMetrics(newAdditionalMetrics, false, groupIndex);
        },
        [additionalMetrics, groupIndex, setAdditionalMetrics, isMatchingMetric],
    );

    const handleSetTimeWindow = useCallback(() => {
        const newTimeWindowFilter = createFilterRuleForCampaignEvent({
            dimension: CommonEventColumns.CLIENT_TIMESTAMP,
            projectUuid: projectUuid ?? '',
            operator: FilterOperator.EQUALS,
            value: '',
        });

        const newFilters = [
            ...(matchedAdditionalMetric?.filters ?? []),
            {
                ...newTimeWindowFilter,
                target: {
                    fieldRef: campaignEventFieldRef(
                        projectUuid ?? '',
                        CommonEventColumns.CLIENT_TIMESTAMP,
                    ),
                },
            },
        ];
        changedAdditionalMetrics(newFilters);
    }, [
        projectUuid,
        changedAdditionalMetrics,
        matchedAdditionalMetric?.filters,
    ]);

    const handleTimeWindowFilterDelete = useCallback(() => {
        const filteredMetrics = (matchedAdditionalMetric?.filters ?? []).filter(
            (filter) => !isTimeWindowFilterInCampaignEvent(filter, projectUuid),
        );
        changedAdditionalMetrics(filteredMetrics);
    }, [
        projectUuid,
        matchedAdditionalMetric?.filters,
        changedAdditionalMetrics,
    ]);

    const handleTimeWindowFilterChange = useCallback(
        (value: FilterRule) => {
            const updatedFilters = (matchedAdditionalMetric?.filters ?? []).map(
                (filter) =>
                    isTimeWindowFilterInCampaignEvent(filter, projectUuid)
                        ? {
                              ...value,
                              target: {
                                  ...value.target,
                                  fieldRef: campaignEventFieldRef(
                                      projectUuid ?? '',
                                      CommonEventColumns.CLIENT_TIMESTAMP,
                                  ),
                              },
                          }
                        : filter,
            );
            changedAdditionalMetrics(updatedFilters);
        },
        [
            projectUuid,
            matchedAdditionalMetric?.filters,
            changedAdditionalMetrics,
        ],
    );

    // Render helper components
    const renderCampaignFilterForm = useMemo(() => {
        if (!activeField) return null;
        return (
            <CampaignFilterRuleForm
                campaignFilters={campaignFilters ?? []}
                activeField={activeField}
                isEditMode={isEditMode}
                additionalMetrics={additionalMetrics}
                setAdditionalMetrics={setAdditionalMetrics}
                groupIndex={groupIndex}
            />
        );
    }, [
        campaignFilters,
        activeField,
        isEditMode,
        additionalMetrics,
        setAdditionalMetrics,
        groupIndex,
    ]);

    const renderTimeWindowButton = useMemo(
        () => <SetTimeWindowButton onClick={handleSetTimeWindow} />,
        [handleSetTimeWindow],
    );

    const renderTimeWindowFilter = useMemo(
        () => (
            <Flex gap={6} align="center">
                {(campaignFilters?.length ?? 0) === 0 ? (
                    <Text className="text-sm text-gray-600">
                        {t('filter_group_form.group_prefix_where')}
                    </Text>
                ) : (
                    <Button
                        variant={ButtonVariant.SUBTLE}
                        disabled={true}
                        className="p-2 text-sm font-normal text-gray-600 border-dashed hover:font-medium border-base border-gray-250"
                    >
                        {FilterGroupOperator.and}
                    </Button>
                )}
                <Box>
                    <FilterRuleForm
                        filterRule={addFieldIdToMetricFilterRule(
                            timeWindowFilter!,
                        )}
                        fields={tableFields}
                        isEditMode={isEditMode}
                        onChange={handleTimeWindowFilterChange}
                        onDelete={handleTimeWindowFilterDelete}
                        filters={{}}
                        showFieldSource={false}
                        setFilters={() => {}}
                        groupIndex={groupIndex}
                        additionalMetrics={additionalMetrics}
                        disableFieldSelector={true}
                        dynamicFieldValues={dynamicFieldValues}
                    />
                </Box>
            </Flex>
        ),
        [
            timeWindowFilter,
            tableFields,
            isEditMode,
            handleTimeWindowFilterChange,
            handleTimeWindowFilterDelete,
            groupIndex,
            additionalMetrics,
            campaignFilters,
            t,
            dynamicFieldValues,
        ],
    );
    if (!activeField) return null;

    return (
        <Stack className="gap-2">
            {(campaignFilters?.length ?? 0) > 0 && (
                <>
                    <Flex align="center" gap={8}>
                        {renderCampaignFilterForm}
                    </Flex>
                    {!timeWindowFilter && renderTimeWindowButton}
                </>
            )}

            {timeWindowFilter && renderTimeWindowFilter}

            {(campaignFilters?.length ?? 0) === 0 && (
                <Flex align="center" gap={8}>
                    {renderCampaignFilterForm}
                    {!timeWindowFilter && renderTimeWindowButton}
                </Flex>
            )}
        </Stack>
    );
};

export default CampaignEventFilterGroup;
