import BuilderContainer from '@components/common/BuilderContainer';
import LoadingState from '@components/common/LoadingState';
import { useLocale } from '@hooks/useLocale';
import { useGetBlobUserTraits, useUpdateTraits } from '@hooks/useUpdateTrait';
import { type UserTraitsConfig } from '@lightdash/common';
import { Box, Card, Flex, Text, Tooltip } from '@mantine/core';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
const UserUpdateTraits: React.FC<{}> = () => {
    const { profileId } = useParams<{ profileId: string }>();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const { data: userTraits, isLoading } = useGetBlobUserTraits(
        projectUuid,
        profileId,
    );
    const { data: userTraitsData, isLoading: isLoadingUserTraits } =
        useUpdateTraits(projectUuid);

    const currentTrait = useCallback(
        (key: string) => {
            return userTraitsData?.config.fieldConfig[
                key as keyof UserTraitsConfig
            ];
        },
        [userTraitsData],
    );

    const hasNoTraits = useMemo(() => {
        return (
            !isLoading &&
            !isLoadingUserTraits &&
            Object.entries(userTraits ?? {}).length === 0
        );
    }, [isLoading, isLoadingUserTraits, userTraits]);

    const shouldShowTraits = useMemo(() => {
        return !isLoading && !isLoadingUserTraits;
    }, [isLoading, isLoadingUserTraits]);

    return (
        <BuilderContainer
            isEditable={false}
            title={t('user_profile_page.update_traits_header')}
            withContentPadding={false}
        >
            <Box className="overflow-y-auto max-h-[24vh] border-t border-shade-6">
                {(isLoading || isLoadingUserTraits) && (
                    <LoadingState title={''} />
                )}

                {hasNoTraits && (
                    <Box className="flex items-center justify-center p-4 text-gray-500">
                        {t('user_profile_page.no_traits')}
                    </Box>
                )}

                {shouldShowTraits &&
                    Object.entries(userTraits ?? {}).map(([key, value]) => (
                        <Card
                            key={key}
                            className="p-3.5 border-b border-shade-6 rounded-none hover:cursor-default"
                        >
                            <Tooltip
                                withinPortal
                                offset={-15}
                                label={
                                    <Flex direction="column" gap={2}>
                                        <Text>
                                            {userTraitsData?.config.fieldConfig[
                                                key as keyof UserTraitsConfig
                                            ]?.label ?? key}
                                        </Text>
                                        {currentTrait(key) &&
                                            'description' in
                                                (currentTrait(key) ?? {}) &&
                                            currentTrait(key)?.description && (
                                                <Text className="text-gray-600 whitespace-pre-wrap">
                                                    {
                                                        currentTrait(key)
                                                            ?.description
                                                    }
                                                </Text>
                                            )}
                                    </Flex>
                                }
                                position="bottom-start"
                                maw={350}
                            >
                                <Box>
                                    <Text className="text-gray-800 border-b-2 border-dashed border-shade-4 w-fit hover:border-gray-400">
                                        {currentTrait(key)?.label ?? key}
                                    </Text>
                                    <Text className="text-gray-600 whitespace-pre-wrap break-words break-normal">
                                        {String(value).length === 0
                                            ? t('user_profile_page.no_value')
                                            : String(value)}
                                    </Text>
                                </Box>
                            </Tooltip>
                        </Card>
                    ))}
            </Box>
        </BuilderContainer>
    );
};

export default UserUpdateTraits;
