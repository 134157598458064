// import FilterGroupForm from '@components/Audience/Filters/FilterGroupForm';
import FilterGroupForm from '@components/Audience/Filters/FilterGroupForm';
import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider/types';
import {
    JourneyEventType,
    type ActionEventField,
    type JourneyProperty,
} from '@components/Journeys/Builder/JourneyFilters/types';
import { convertToFilterableField } from '@components/Journeys/Builder/JourneyFilters/utils';
import { useLocale } from '@hooks/useLocale';
import {
    addFilterRule,
    type FilterRule,
    type Filters,
    type JourneyDimension,
    type JourneyEventMapperSchema,
} from '@lightdash/common';
import { Button, Text } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import useRelationContext from '@providers/Relation/useRelationContext';
import React, { useCallback } from 'react';
import { ButtonVariant } from '../../../mantineTheme';
import EventPropertySelector from './EventPropertySelector';

interface EventFilterRuleFormProps {
    activeEventField: ActionEventField | undefined;
    filterRules: FilterRule[];
    isDimensionsEmpty: boolean;
    allFields: FieldWithSuggestions[];
    isEditMode: boolean;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    eventsList: JourneyEventMapperSchema[];
}

const EventFilterRuleForm: React.FC<EventFilterRuleFormProps> = ({
    activeEventField,
    isDimensionsEmpty,
    allFields,
    isEditMode,
    filters,
    setFilters,
    eventsList,
}) => {
    const { t } = useLocale();
    const { activeRelation } = useRelationContext();

    const addEventProperty = useCallback(
        (item: JourneyProperty) => {
            if (!activeEventField) return;

            const filterableField = convertToFilterableField({
                ...item,
                table: item.table,
            } as JourneyDimension);
            const newFilterRule = addFilterRule({
                filters,
                field: filterableField,
            });

            setFilters(newFilterRule, false, 0);
        },
        [activeEventField, filters, setFilters],
    );

    if (!activeEventField) return null;

    if (activeEventField.eventType === JourneyEventType.WHITELISTED_EVENT) {
        const allowedFields = allFields.filter(
            (field) => field.table === `${activeEventField.eventName}`,
        );
        return (
            <>
                {!isDimensionsEmpty && filters.dimensions && (
                    <FilterGroupForm
                        allowConvertToGroup={false}
                        hideLine
                        hideButtons
                        conditionLabel="dimension"
                        filterGroup={filters.dimensions}
                        fields={allowedFields}
                        isEditMode={isEditMode}
                        onChange={(value) =>
                            setFilters(
                                {
                                    ...filters,
                                    dimensions: value,
                                },
                                false,
                                0,
                            )
                        }
                        onDelete={() => {}}
                        filters={filters}
                        setFilters={setFilters}
                        groupIndex={0}
                        showFieldSource={false}
                        relation={activeRelation}
                        nestedLevel={0}
                        customCss="!w-12"
                        additionalMetrics={undefined}
                        setAdditionalMetrics={undefined}
                        dynamicFieldValues={[]}
                    />
                )}
                {isEditMode && (
                    <EventPropertySelector
                        targetElement={
                            <Button variant={ButtonVariant.UNSTYLED}>
                                <PlusCircle
                                    color={'rgb(var(--color-gray-700))'}
                                />
                                <Text className="ml-1 text-sm font-semibold text-gray-700">
                                    {t('common.property')}
                                </Text>
                            </Button>
                        }
                        allFields={allFields}
                        eventField={activeEventField}
                        onSubmit={addEventProperty}
                        eventsList={eventsList}
                    />
                )}
            </>
        );
    }

    return null;
};

export default EventFilterRuleForm;
