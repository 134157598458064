import Delay, {
    type DelayActionProps,
} from '@components/Journeys/Builder/ControlPanel/Actions/Delay';
import Filter, {
    type FilterActionProps,
} from '@components/Journeys/Builder/ControlPanel/Actions/Filter';
import SendCampaign, {
    type SendCampaignProps,
} from '@components/Journeys/Builder/ControlPanel/Actions/SendCampaign';
import {
    ActionType,
    PeriodType,
    TimeConfigType,
    type AnyType,
    type ApiAction,
    type DifferenceOperator,
    type ExperimentAction,
    type FilterAction,
    type HttpConfig,
    type JourneyAction,
    type JourneyFiltersConfig,
    type SendMessageAction,
    type SetAction,
    type SplitAction,
    type UpdateTraitAction,
    type UpdateTraitConfig,
    type WaitAction,
    type WaitUntilAction,
} from '@lightdash/common';
import type React from 'react';
import CallAPIAction, { type CallAPIActionProps } from './CallAPI';
import { type CallAPIFieldAndSchemaType } from './CallAPI/types';
import { updateWarehouseFields } from './CallAPI/utils';
import Experiment, { type ExperimentActionProps } from './Experiment';
import Split, { type SplitActionProps } from './Split';
import UpdateTrait from './UpdateTrait';
import { type UpdateTraitProps } from './UpdateTrait/UpdateTraitBlock';
import WaitUntil, { type WaitUntilProps } from './WaitUntil';

const DELAY_DURATION = 60000;
// Map ActionType to Specific Action Types
type ActionTypeMap = {
    [ActionType.WAIT]: WaitAction;
    [ActionType.SPLIT]: SplitAction;
    [ActionType.FILTER]: FilterAction;
    [ActionType.SET]: SetAction;
    [ActionType.API]: ApiAction;
    [ActionType.WAIT_UNTIL]: WaitUntilAction;
    [ActionType.SEND_MESSAGE]: SendMessageAction;
    [ActionType.UPDATE_TRAIT]: UpdateTraitAction;
    [ActionType.EXPERIMENT]: ExperimentAction;
};

// Map ActionType to Component Props
export type ActionComponentProps = {
    [ActionType.WAIT]: DelayActionProps;
    [ActionType.SPLIT]: SplitActionProps;
    [ActionType.FILTER]: FilterActionProps;
    [ActionType.SET]: DelayActionProps;
    [ActionType.API]: CallAPIActionProps;
    [ActionType.WAIT_UNTIL]: WaitUntilProps;
    [ActionType.SEND_MESSAGE]: SendCampaignProps;
    [ActionType.UPDATE_TRAIT]: UpdateTraitProps;
    [ActionType.EXPERIMENT]: ExperimentActionProps;
};

// Type for the Mapper
type Mapper = {
    [K in ActionType]: {
        component: React.ComponentType<ActionComponentProps[K]>;
        getProps: (
            action: ActionTypeMap[K],
            onActionChange: (payload: JourneyAction) => void,
            nodeId: string,
        ) => ActionComponentProps[K];
    };
};

// Define the actionMapper with the correct types
export const actionMapper: Mapper = {
    [ActionType.WAIT]: {
        component: Delay,
        getProps: (action, onActionChange, nodeId) => {
            if (
                action.config.timeConfig &&
                action.config.timeConfig.timeConfigType ===
                    TimeConfigType.DATE_TIME
            ) {
                return {
                    selectedValue: action.config.timeConfig.dateTime.value,
                    time: action.config.timeConfig.dateTime.time,
                    timeDiffOperator:
                        action.config.timeConfig.timeDifference.operator,
                    timeDiffValue:
                        action.config.timeConfig.timeDifference.value,
                    actionTitle: undefined,
                    onDataChange: (
                        value: string | number,
                        timeDiffValue: number,
                        timeDiffOperator: DifferenceOperator,
                        time: string,
                        granularity: PeriodType,
                    ) => {
                        onActionChange({
                            type: ActionType.WAIT,
                            config: {
                                timeConfig: {
                                    timeConfigType: TimeConfigType.DATE_TIME,
                                    dateTime: {
                                        value: value,
                                        time: time,
                                    },
                                    timeDifference: {
                                        value: timeDiffValue,
                                        operator: timeDiffOperator,
                                    },
                                },
                                responseConfig: action.config.responseConfig,
                            },
                            uiConfig: {
                                timeGranularity: granularity,
                            },
                        });
                    },
                    granularity:
                        action.uiConfig?.timeGranularity ?? PeriodType.HOUR,
                    durationOptions: [
                        PeriodType.MINUTE,
                        PeriodType.HOUR,
                        PeriodType.DAY,
                        PeriodType.WEEK,
                    ],
                    type: action.config.timeConfig.timeConfigType,
                    nodeId,
                };
            }

            return {
                selectedDuration:
                    action.config.timeConfig.timeConfigType ===
                    TimeConfigType.DURATION
                        ? action.config.timeConfig.duration
                        : DELAY_DURATION,
                onDurationChange: (
                    duration: number,
                    granularity: PeriodType,
                ) => {
                    onActionChange({
                        type: ActionType.WAIT,
                        config: {
                            timeConfig: {
                                timeConfigType: TimeConfigType.DURATION,
                                duration: duration,
                            },
                            responseConfig: action.config.responseConfig,
                        },
                        uiConfig: {
                            timeGranularity: granularity,
                        },
                    });
                },
                granularity:
                    action.uiConfig?.timeGranularity ?? PeriodType.MINUTE,
                durationOptions: [
                    PeriodType.MINUTE,
                    PeriodType.HOUR,
                    PeriodType.DAY,
                    PeriodType.WEEK,
                ],
                type: action.config.timeConfig
                    ? action.config.timeConfig.timeConfigType ??
                      TimeConfigType.DURATION
                    : TimeConfigType.DURATION, //This is to handle backward compatibility of the older journeys
                actionTitle: undefined,
                nodeId,
            };
        },
    },

    [ActionType.SPLIT]: {
        component: Split,
        getProps: (action, onActionChange, nodeId) => {
            // Define and return props for the SPLIT action
            // Add any specific callback connections here
            return {
                nodeId,
            } as SplitActionProps;
        },
    },
    [ActionType.FILTER]: {
        component: Filter,
        getProps: (action, onActionChange, nodeId) => {
            return {
                filters: action.config.filterConfig,
                nodeId: nodeId,
                setFilters: (value: JourneyFiltersConfig) => {
                    onActionChange({
                        type: ActionType.FILTER,
                        config: {
                            filterConfig: value,
                            responseConfig: action.config.responseConfig,
                        },
                    });
                },
                actionTitle: undefined,
            };
        },
    },
    [ActionType.SET]: {
        component: Delay,
        getProps: () => {
            // Define and return props for the SET action
            // Add any specific callback connections here
            return {
                // Your props here
            } as DelayActionProps;
        },
    },

    [ActionType.API]: {
        component: CallAPIAction,
        getProps: (action, onActionChange, nodeId) => {
            return {
                nodeId: nodeId,
                payloadMapper: action.config.apiConfig.payloadMapper,
                httpConfig: action.config.apiConfig.httpConfig,
                responseConfig: action.config.responseConfig,
                handlePayloadMapperChange: ({
                    value,
                    journeyDataSchema,
                }: CallAPIFieldAndSchemaType<string>) => {
                    onActionChange({
                        type: ActionType.API,
                        config: {
                            ...action.config,
                            apiConfig: {
                                ...action.config.apiConfig,
                                payloadMapper: value,
                            },
                        },
                        warehouseFields: updateWarehouseFields({
                            url: action.config.apiConfig.httpConfig.url,
                            headers: action.config.apiConfig.httpConfig.headers,
                            payloadMapper: value,
                            journeyDataSchema,
                        }),
                    });
                },
                handleHttpConfigChange: ({
                    value,
                    journeyDataSchema,
                }: CallAPIFieldAndSchemaType<HttpConfig>) => {
                    const { url, headers } = value;
                    const { payloadMapper } = action.config.apiConfig;
                    onActionChange({
                        type: ActionType.API,
                        config: {
                            ...action.config,
                            apiConfig: {
                                ...action.config.apiConfig,
                                httpConfig: value,
                            },
                        },
                        warehouseFields: updateWarehouseFields({
                            url,
                            headers,
                            payloadMapper,
                            journeyDataSchema,
                        }),
                    });
                },
                onResponsePayloadMapperChange: (
                    value: Record<string, AnyType>,
                ) => {
                    onActionChange({
                        type: ActionType.API,
                        config: {
                            ...action.config,
                            responseConfig: value,
                        },
                        warehouseFields: action.warehouseFields,
                    });
                },
            } as CallAPIActionProps;
        },
    },
    [ActionType.WAIT_UNTIL]: {
        component: WaitUntil,
        getProps: (action, onActionChange, nodeId) => {
            return {
                filters: action.config.eventConfig.filterConfig,
                nodeId: nodeId,
                actionTitle: undefined,
                setFilters: (value: JourneyFiltersConfig) => {
                    onActionChange({
                        type: ActionType.WAIT_UNTIL,
                        config: {
                            ...action.config,
                            eventConfig: {
                                ...action.config.eventConfig,
                                filterConfig: value,
                            },
                        },
                    });
                },
                setEvent: ({ eventName, eventSource }) => {
                    onActionChange({
                        type: ActionType.WAIT_UNTIL,
                        config: {
                            ...action.config,
                            eventConfig: {
                                ...action.config.eventConfig,
                                filterConfig: undefined,
                                eventName,
                                eventSource,
                            },
                        },
                        uiConfig: {
                            ...action.uiConfig,
                        },
                    });
                },
                selectedDuration:
                    action.config.timeConfig.timeConfigType ===
                    TimeConfigType.DURATION
                        ? action.config.timeConfig.duration
                        : 0,
                onDurationChange: (
                    duration: number,
                    granularity: PeriodType,
                ) => {
                    onActionChange({
                        type: ActionType.WAIT_UNTIL,
                        config: {
                            ...action.config,
                            timeConfig: {
                                timeConfigType: TimeConfigType.DURATION,
                                duration: duration,
                            },
                            responseConfig: action.config.responseConfig,
                        },
                        uiConfig: {
                            timeGranularity: granularity,
                        },
                    });
                },
                granularity:
                    action.uiConfig?.timeGranularity ?? PeriodType.HOUR,
                durationOptions: [
                    PeriodType.MINUTE,
                    PeriodType.HOUR,
                    PeriodType.DAY,
                    PeriodType.WEEK,
                ],
                event: {
                    eventName: action.config.eventConfig.eventName,
                    eventSource: action.config.eventConfig.eventSource,
                },
            } as WaitUntilProps;
        },
    },
    [ActionType.SEND_MESSAGE]: {
        component: SendCampaign,
        getProps: (action, onActionChange, nodeId) => {
            return {
                messageConfig: action.config.messageConfig,
                onCampaignDetailsChange: (messageConfig) => {
                    onActionChange({
                        type: ActionType.SEND_MESSAGE,
                        config: {
                            messageConfig,
                            responseConfig: {},
                        },
                    });
                },
                nodeId: nodeId,
            } as SendCampaignProps;
        },
    },
    [ActionType.UPDATE_TRAIT]: {
        component: UpdateTrait,
        getProps: (action, onActionChange, nodeId) => {
            return {
                traitConfig: action.config.updateTraitConfig,
                onUpdateConfigChange: (config: UpdateTraitConfig) => {
                    onActionChange({
                        type: ActionType.UPDATE_TRAIT,
                        config: {
                            updateTraitConfig: config,
                            responseConfig: action.config.responseConfig,
                        },
                    });
                },
                nodeId,
            } as UpdateTraitProps;
        },
    },
    [ActionType.EXPERIMENT]: {
        component: Experiment,
        getProps: (action, onActionChange, nodeId) => {
            return {
                nodeId: nodeId,
                experimentConfig: action.config.experimentConfig,
                onExperimentConfigChange: (config) => {
                    onActionChange({
                        type: ActionType.EXPERIMENT,
                        config: {
                            ...action.config,
                            experimentConfig: config,
                        },
                    });
                },
            } as ExperimentActionProps;
        },
    },
};
