import { type Project } from '@lightdash/common';

/***
 * this function is to predict out whether project set up is completed or not
 * it takes project data and verifies if all the setup is completed or not
 * and returns a boolean
 */
export const isProjectSetUpCompleted = (project: Project) => {
    return (
        project.isBaseTableConfigured &&
        !!project.warehouseConnection &&
        !!project.blobConnection
    );
};
