import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization/useAbilityContext';
import useNotify from '@hooks/toaster/useNotify';
import {
    useActiveProjectUuid,
    useUpdateActiveProjectMutation,
} from '@hooks/useActiveProject';
import { useLocale } from '@hooks/useLocale';
import { type OrganizationProject } from '@lightdash/common';
import { Badge, Flex, Menu, Stack, Text } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import useApp from '@providers/App/useApp';
import { swappableProjectRoutes } from '@utils/constants';
import { useCallback, useMemo } from 'react';
import { matchRoutes, useLocation, useMatch, useNavigate } from 'react-router';

interface WorkspacesMenuProps {
    projects: OrganizationProject[];
    targetButton: React.ReactNode;
    isDemo: boolean;
    opened: boolean;
    close: () => void;
}

const WorkspacesMenu = ({
    projects,
    targetButton,
    isDemo,
    opened,
    close,
}: WorkspacesMenuProps) => {
    const ability = useAbilityContext();
    const { mutate: setLastProjectMutation } = useUpdateActiveProjectMutation();
    const { showToastSuccess } = useNotify();
    const { user } = useApp();
    const navigate = useNavigate();
    const { activeProjectUuid } = useActiveProjectUuid();
    const { t } = useLocale();
    const showCreateWorkspace = ability.can(
        'create',
        subject('Project', {
            organizationUuid: user.data?.organizationUuid,
        }),
    );
    const location = useLocation();
    const isHomePage = !!useMatch(`/projects/${activeProjectUuid}/relations`);
    const adjustProjects = useMemo(() => {
        if (!activeProjectUuid) return projects;
        const activeProject = projects.find(
            (project) => project.projectUuid === activeProjectUuid,
        );
        if (!activeProject) return projects;
        return [
            activeProject,
            ...projects.filter(
                (project) => project.projectUuid !== activeProjectUuid,
            ),
        ];
    }, [projects, activeProjectUuid]);

    const routeMatches =
        matchRoutes(
            activeProjectUuid
                ? swappableProjectRoutes(activeProjectUuid).map((path) => ({
                      path,
                  }))
                : [],
            location,
        ) || [];
    const swappableRouteMatch = routeMatches ? routeMatches[0]?.route : null;

    const shouldSwapProjectRoute = !!swappableRouteMatch && activeProjectUuid;

    const handleNewProject = useCallback(() => {
        void navigate('/create-workspace');
    }, [navigate]);
    const handleProjectChange = useCallback(
        (newUuid: string) => {
            if (!newUuid) return;

            if (newUuid === activeProjectUuid) {
                close();
                return;
            }

            const project = projects?.find((p) => p.projectUuid === newUuid);
            if (!project) return;

            close();

            setLastProjectMutation(project.projectUuid);

            showToastSuccess({
                title: t('workspace_settings_menu.project_switch_success', {
                    projectName: project.name,
                }),
                action:
                    !isHomePage && shouldSwapProjectRoute
                        ? {
                              children: t(
                                  'workspace_settings_menu.project_switch_success_btn',
                              ),
                              onClick: () => {
                                  void navigate(
                                      `/projects/${project.projectUuid}/home`,
                                  );
                              },
                          }
                        : undefined,
            });

            setTimeout(() => {
                if (shouldSwapProjectRoute) {
                    void navigate(
                        swappableRouteMatch.path.replace(
                            activeProjectUuid,
                            project.projectUuid,
                        ),
                    );
                } else {
                    void navigate(`/projects/${project.projectUuid}/relations`);
                }
            }, 50);
        },
        [
            activeProjectUuid,
            navigate,
            projects,
            shouldSwapProjectRoute,
            swappableRouteMatch,
            t,
            isHomePage,
            setLastProjectMutation,
            showToastSuccess,
            close,
        ],
    );

    return (
        <Menu
            withinPortal
            position="right-start"
            opened={opened}
            onClose={close}
            aria-label={t('workspace_settings_menu.menu_label')}
            closeOnItemClick={true}
        >
            <Menu.Target>{targetButton}</Menu.Target>
            <Menu.Dropdown role="menu">
                {adjustProjects.length > 0 ? (
                    <>
                        {!isDemo && (
                            <Menu.Label role="presentation">
                                <Text className="text-sm font-semibold text-gray-500">
                                    {t(
                                        'workspace_settings_menu.your_workspaces',
                                    )}
                                </Text>
                            </Menu.Label>
                        )}
                        <Stack className="flex flex-col gap-0 overflow-y-scroll max-h-60">
                            {adjustProjects?.map((project) => (
                                <Menu.Item
                                    role="menuitem"
                                    key={project?.projectUuid}
                                    className={` ${
                                        project?.projectUuid ===
                                        activeProjectUuid
                                            ? 'bg-gray-200'
                                            : ''
                                    }`}
                                    onClick={(
                                        e: React.MouseEvent<HTMLDivElement>,
                                    ) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handleProjectChange(
                                            project?.projectUuid ?? '',
                                        );
                                    }}
                                    onKeyDown={(
                                        e: React.KeyboardEvent<HTMLDivElement>,
                                    ) => {
                                        if (
                                            e.key === 'Enter' ||
                                            e.key === ' '
                                        ) {
                                            e.preventDefault();
                                            handleProjectChange(
                                                project?.projectUuid ?? '',
                                            );
                                        }
                                    }}
                                    aria-current={
                                        project?.projectUuid ===
                                        activeProjectUuid
                                    }
                                >
                                    <Flex gap={4} align="center">
                                        <Text className="text-sm font-medium text-gray-800">
                                            {project?.name}
                                        </Text>
                                        {isDemo && (
                                            <Badge
                                                variant="filled"
                                                className="bg-gray-500 rounded text-xs px-1 normal-case "
                                                size="md"
                                            >
                                                {t(
                                                    'workspace_settings.demo_label',
                                                )}
                                            </Badge>
                                        )}
                                    </Flex>
                                </Menu.Item>
                            ))}
                        </Stack>
                    </>
                ) : (
                    <Text className="text-sm font-medium text-gray-600 text-center p-2">
                        {t('workspace_settings.no_workspaces')}
                    </Text>
                )}
                {showCreateWorkspace && !isDemo && (
                    <Flex
                        role="menuitem"
                        className="text-gray-600 hover:cursor-pointer py-1.5 px-3 gap-x-1.5 hover:bg-shade-4"
                        onClick={handleNewProject}
                        align="center"
                        gap="xs"
                    >
                        <PlusCircle />
                        <Text>
                            {t('workspace_settings_menu.create_workspace_text')}
                        </Text>
                    </Flex>
                )}
            </Menu.Dropdown>
        </Menu>
    );
};

export default WorkspacesMenu;
