import {
    type useChartVersionResultsMutation,
    type useQueryResults,
} from '@hooks/useAudienceQueryResults';
import {
    type AdditionalMetric,
    type Audience,
    type AudienceInsights,
    type CreateSavedChartVersion,
    type CustomDimension,
    type CustomSqlDimension,
    type Dimension,
    type FilterGroupOperatorType,
    type InsertAudience,
    type MetricQuery,
    type MetricType,
    type NestedMetricQueryGroup,
    type QueryGenerationStrategy,
    type SavedChart,
} from '@lightdash/common';

export enum ActionType {
    SET_FILTERS,
    SET_FETCH_RESULTS_FALSE,
    ADD_NEW_GROUP,
    SET_GLOBAL_FILTER_CONDITION,
    SET_AUDIENCE_NAME,
    SET_AUDIENCE_DESCRIPTION,
    SET_SQL_QUERY,
    SET_AI_PROMPT,
    SET_GENERATION_STRATEGY,
    SET_UNSAVED_AUDIENCE_FILTER,
    SET_USER_ALIAS,
    ADD_UNSAVED_AUDIENCE_FILTER,
    REMOVE_UNSAVED_AUDIENCE_FILTER,
    SET_AUDIENCE_PREVIEW_CONFIG,
    SET_INITIAL_AUDIENCE_DATA,
    SET_ADDITIONAL_METRICS,
    SET_INSIGHTS_OVERLAP_PAYLOAD,
    SET_INSIGHTS_BREAKDOWN_PAYLOAD,
    SET_INSIGHTS_REACHABILITY_PAYLOAD,
    SET_AUDIENCE_TAGS,
    SET_CUSTOM_DIMENSIONS,
    UNDO_FILTERS,
    REDO_FILTERS,
}

export type AudienceFiltersHistoryStack = {
    modifiedUnsavedChartVersion: CreateSavedChartVersion[];
    modifiedNestedMetricQuery: NestedMetricQueryGroup | undefined;
};

export interface AudienceReduceState {
    shouldFetchResults: boolean;
    unsavedChartVersion: CreateSavedChartVersion[];
    previouslyFetchedState?: MetricQuery;
    modals: {
        additionalMetric: {
            isOpen: boolean;
            isEditing?: boolean;
            item?: Dimension | AdditionalMetric;
            type?: MetricType;
        };
        customDimension: {
            isOpen: boolean;
            isEditing?: boolean;
            item?: Dimension | CustomDimension;
        };
    };
    globalFilterCondition: FilterGroupOperatorType;
    audiencePayload: InsertAudience;
    initialAudiencePayload: InsertAudience;
}

export interface AudienceState extends AudienceReduceState {
    isEditMode: boolean;
    savedChart: SavedChart | undefined;
    isValidQuery: boolean;
    isValidName: boolean;
    isCreatingAudience: boolean;
    isPublishingAudience: boolean;
    isUpdatingAudience: boolean;
}

export interface AudienceContext {
    state: AudienceState;
    queryResults: ReturnType<
        typeof useQueryResults | typeof useChartVersionResultsMutation
    >;
    actions: {
        setFilters: (
            filters: MetricQuery['filters'],
            syncPristineState: boolean,
            index: number,
        ) => void;
        fetchResults: () => void;
        addNewGroup: () => void;
        setGlobalFilterCondition: (condition: FilterGroupOperatorType) => void;
        createAudience: () => Promise<Audience>;
        activateAudience: (audienceUuid: string) => Promise<void>;
        updateAudience: (audienceUuid: string) => Promise<void>;
        setAudienceName: (name: string) => void;
        setAudienceDescription: (description: string) => void;
        setGenerationStategy: (strategy: QueryGenerationStrategy) => void;
        setSqlQuery: (sqlQuery: string) => void;
        setAIPrompt: (aiPrompt: string) => void;
        createActivateAudience: () => Promise<Audience>;
        setUnsavedAudienceFilter: (
            chartVersionPayload: CreateSavedChartVersion[],
        ) => void;
        setUserAlias: (userAlias: string) => void;
        addUnsavedAudienceFilter: (
            filterGroup: CreateSavedChartVersion,
            groupIndex: number,
        ) => void;
        removeUnsavedAudienceFilter: (index: number) => void;
        setAudiencePreviewConfigData: (data: string[]) => void;
        setInitialAudienceData: () => void;
        updateActivateAudience: (audienceId: string) => Promise<void>;
        setAdditionalMetrics: (
            additionalMetrics: AdditionalMetric[],
            shouldFetchResults: boolean,
            index: number,
        ) => void;
        setInsightsOverlapPayload: (
            payload: AudienceInsights['overlapFilters'],
        ) => void;
        setInsightsBreakdownPayload: (
            payload: AudienceInsights['breakdownFilters'],
        ) => void;
        setAudienceTags: (tags: string[]) => void;
        setCustomDimensions: (
            customDimensions: CustomSqlDimension[],
            shouldFetchResults: boolean,
            index: number,
        ) => void;
        undoFilterChange: () => void;
        redoFilterChange: () => void;
        resetHistoryStack: () => void;
    };
    historyStack: AudienceFiltersHistoryStack[];
    historyStackPointer: number;
}
